import { FunctionComponent } from 'react';
import useMe from '../../hooks/useMe';
import LinkButton from '../Button/LinkButton';
import Banner from './Banner';
import './SubscriptionBanner.css';
import SVG from 'react-inlinesvg';

import externalLinkIcon from '../../svgs/icon-external-link.svg';

export type BannerProps = {};

const SubscriptionBanner: FunctionComponent<BannerProps> = () => {
  const { me } = useMe();

  return (
    <>
      {me && !me.subscriptionStatus ? (
        <Banner backgroundColor="#40C2D9" className="subscription-banner">
          <LinkButton
            className="subscription-banner__link"
            local={true}
            link={`/subscription?email=${me.email ? encodeURI(me.email) : ''}${
              me.source ? 'code=' + encodeURI(me.source) : ''
            }`}>
            {' '}
            To finish activating your profile you need to choose a payment plan{' '}
            <SVG src={externalLinkIcon} />{' '}
          </LinkButton>
        </Banner>
      ) : null}
    </>
  );
};

export default SubscriptionBanner;
