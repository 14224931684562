import _extends from "@babel/runtime/helpers/esm/extends";
import { useContext, useMemo } from 'react';
import { bindActionCreators } from '@frontegg/redux-store';
import { authActions, authStoreName } from '@frontegg/redux-store';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from '../FronteggStoreContext';
import { ContextHolder } from '@frontegg/rest-api';
import { ShadowDomContext } from '../common';
const defaultMapper = {
  state: state => state,
  actions: actions => actions
};
export function useAuth(stateMapper = defaultMapper.state) {
  return useSelector(state => stateMapper(state[authStoreName]), shallowEqual);
}

/**
 * ```jsx
 * export const MyFunctionComponent = () => {
 *   const { isAuthenticated, user } = useAuth();
 *   const loginWithRedirect = useLoginWithRedirect();
 *
 *   if (!isAuthenticated) {
 *     loginWithRedirect();
 *     return <></>;
 *   }
 *
 *   return (<div>Hello User {user.name}</div>);
 * }
 * ```
 *
 * use this frontegg hook function to get if user is "Authenticated"
 */
export const useLoginWithRedirect = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(authActions.requestHostedLoginAuthorize, dispatch), [authActions.requestHostedLoginAuthorize]);
};
export const useAuthActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(authActions, dispatch), [authActions]);
};
export const useOnRedirectTo = () => {
  var _context$onRedirectTo;
  const context = useContext(ShadowDomContext);
  return (_context$onRedirectTo = context == null ? void 0 : context.onRedirectTo) != null ? _context$onRedirectTo : ContextHolder.onRedirectTo;
};
export const useAuthRoutes = () => useAuth(state => _extends({}, state.routes));

/**
 * ```jsx
 * export const MyFunctionComponent = () => {
 *   const isAuthenticated  = useIsAuthenticated();
 *   return isAuthenticated ? <div>Hello User</div> : <Redirect to={'/login'}/>
 * }
 * ```
 *
 * use this frontegg hook function to get if user is "Authenticated"
 */
export const useIsAuthenticated = () => useSelector(({
  [authStoreName]: {
    isAuthenticated
  }
}) => isAuthenticated, shallowEqual);

/**
 * ```jsx
 * export const MyFunctionComponent = () => {
 *   const user = useAuthUser();
 *   return user ? <div>Hello {user.name}!</div> : <div>Hello Guest!</div>
 * }
 * ```
 *
 * use this frontegg hook function to get the authenticated user
 * the return user is null if not authenticated
 */
export const useAuthUser = () => {
  const routes = useAuthRoutes();
  const onRedirectTo = useOnRedirectTo();
  const user = useAuthUserOrNull();
  const isSSR = typeof window === 'undefined';
  const noUser = {};
  if (user == null && !isSSR) {
    onRedirectTo(routes.loginUrl, {
      refresh: true
    });
    return noUser;
  }
  return user || noUser;
};
export const useAuthUserOrNull = () => {
  const {
    user
  } = useSelector(({
    [authStoreName]: {
      user
    }
  }) => ({
    user
  }), shallowEqual);
  return user || null;
};

/**
 * hooks helpers
 */
export const sliceReducerActionsBy = reducer => {
  const reducerKeys = Object.keys(reducer);
  const reducerActions = reducerKeys.map(key => ({
    [key]: authActions[key]
  }));
  return reducerActions.reduce((p, n) => _extends({}, p, n), {});
};
export const stateHookGenerator = (stateMapper, subState) => {
  return useSelector(state => {
    var _stateMapper;
    return (_stateMapper = stateMapper == null ? void 0 : stateMapper(state[authStoreName][subState])) != null ? _stateMapper : state[authStoreName][subState];
  }, shallowEqual);
};
export const reducerActionsGenerator = (actions, reducers) => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(_extends({}, actions, sliceReducerActionsBy(reducers)), dispatch), [dispatch]);
};