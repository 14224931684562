import './ScheduleMeetingPage.css';

import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InlineSVG from 'react-inlinesvg/esm';

import useMeetingSchedule from '../../hooks/useMeetingSchedule';

import InfoMessage from '../../components/InfoMessage/InfoMessage';
import ContentSection from '../../components/ContentSection/ContentSection';
import MeetingScheduler from '../../components/MeetingScheduler/MeetingScheduler';
import Button from '../../components/Button/Button';
import Avatar from '../../components/Avatar/Avatar';

import sadCat from '../../svgs/sad-cat.svg';
import Loader from '../../components/Loader/Loader';
import { Member } from '../../models/Member';
import LinkButton from '../../components/Button/LinkButton';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';
import { ScheduleSubmissionStage } from '../../models/Meeting';
import DateTile from '../../components/DateTile/DateTile';
import LargeTimeTile from '../../components/TimeTile/LargeTimeTile';

export type MeetingSchedulePageProps = {};

enum PAGE_STATUS {
  LOADING,
  NOT_FOUND,
  SCHEDULE_A,
  SCHEDULE_B,
  FIRST_FAIL,
  SECOND_FAIL,
  DONE_SCHEDULE,
  SCHEDULED,
}

const ScheduleMeetingPage: FunctionComponent<MeetingSchedulePageProps> = () => {
  const { id } = useParams();
  const {
    meeting,
    meetingLoading,
    updateMeetingSchedule,
    refetchMeeting,
    updateMeetingScheduleLoading,
  } = useMeetingSchedule(id);
  const [suggestedDates, setSuggestedDates] = useState<Dayjs[]>();
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [me, setMe] = useState<Member>();
  const [otherUser, setOtherUser] = useState<Member>();
  const [pageStatus, setPageStatus] = useState<PAGE_STATUS>(
    PAGE_STATUS.LOADING,
  );
  const [showDateFailModal, setShowDateFailModal] = useState(false);
  const [showNotRightTimeModal, setShowNotRightTimeModal] = useState(false);
  const [showInfiniteLoopModal, setShowInfiniteLoopModal] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    if (meetingLoading) {
      return;
    }
    if (!meeting) {
      setPageStatus(PAGE_STATUS.NOT_FOUND);
      return;
    }

    switch (meeting?.scheduleStage) {
      case ScheduleSubmissionStage.SCHEDULED:
        setPageStatus(PAGE_STATUS.SCHEDULED);
        break;
      case ScheduleSubmissionStage.FIRST_FAIL:
        setPageStatus(PAGE_STATUS.FIRST_FAIL);
        break;
      case ScheduleSubmissionStage.SECOND_FAIL:
        setPageStatus(PAGE_STATUS.SECOND_FAIL);
        break;
      case ScheduleSubmissionStage.SUGGESTED:
      case ScheduleSubmissionStage.PROPOSAL:
      case ScheduleSubmissionStage.RESCHEDULE:
      case ScheduleSubmissionStage.RESCHEDULE_PROPOSAL:
        if (me?.id === meeting.lastScheduleMemberId) {
          if (meeting?.suggestedDates) {
            setSelectedDates(
              meeting?.suggestedDates.map((d) => d.toISOString()),
            );
          }
          return setPageStatus(PAGE_STATUS.DONE_SCHEDULE);
        }
        setSuggestedDates(meeting?.suggestedDates);
        setPageStatus(PAGE_STATUS.SCHEDULE_B);
        break;
      default:
        setSuggestedDates(meeting?.availableDates);
        setPageStatus(PAGE_STATUS.SCHEDULE_A);
        break;
    }

    if (meeting?.me) {
      const m = meeting.members.find((member) => member.id === meeting.me?.id);
      const ou = meeting.members.find((member) => member.id !== meeting.me?.id);
      if (!m) {
        return setPageStatus(PAGE_STATUS.NOT_FOUND);
      }
      setMe(m);
      setOtherUser(ou);
    } else {
      return setPageStatus(PAGE_STATUS.NOT_FOUND);
    }
  }, [meeting, meetingLoading, me]);

  const handleDateClick = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleStruggleClick = () => {
    setShowNotRightTimeModal(true);
  };

  const handleSubmit = async () => {
    let stage: ScheduleSubmissionStage = ScheduleSubmissionStage.UNSCHEDULED;
    switch (meeting?.scheduleStage) {
      case ScheduleSubmissionStage.UNSCHEDULED:
        stage = ScheduleSubmissionStage.SUGGESTED;
        break;
      case ScheduleSubmissionStage.SUGGESTED:
        stage =
          // If you are still looking at the suggested dates then we assume scheduled otherwise we are proposing new dates
          pageStatus === PAGE_STATUS.SCHEDULE_B
            ? ScheduleSubmissionStage.SCHEDULED
            : ScheduleSubmissionStage.PROPOSAL;
        break;
      case ScheduleSubmissionStage.RESCHEDULE:
        stage =
          // If you are still looking at the suggested dates then we assume scheduled otherwise we are proposing new dates
          pageStatus === PAGE_STATUS.SCHEDULE_B
            ? ScheduleSubmissionStage.SCHEDULED
            : ScheduleSubmissionStage.RESCHEDULE_PROPOSAL;
        break;
      case ScheduleSubmissionStage.PROPOSAL:
      case ScheduleSubmissionStage.RESCHEDULE_PROPOSAL:
        stage = ScheduleSubmissionStage.SCHEDULED;
        break;
      default:
        break;
    }

    await updateMeetingSchedule({
      variables: {
        meetingId: meeting?.id,
        stage,
        dates: selectedDates.map((d) => dayjs(d).toDate()),
      },
    });

    if (pageStatus === PAGE_STATUS.SCHEDULE_B) {
      await refetchMeeting();
      setPageStatus(PAGE_STATUS.SCHEDULED);
      return;
    }
    setPageStatus(PAGE_STATUS.DONE_SCHEDULE);
  };

  const handleDateFail = () => {
    if (
      meeting?.scheduleStage === ScheduleSubmissionStage.SUGGESTED ||
      meeting?.scheduleStage === ScheduleSubmissionStage.RESCHEDULE
    ) {
      return setShowDateFailModal(true);
    }
    setShowInfiniteLoopModal(true);
  };

  const handleDismissModal = () => {
    setShowDateFailModal(false);
    setShowNotRightTimeModal(false);
    setShowInfiniteLoopModal(false);
  };

  const handlePickNewTimes: ModalActionFunction = async (event, done) => {
    setSuggestedDates(meeting?.availableDates);
    setPageStatus(PAGE_STATUS.SCHEDULE_A);
    done();
  };

  const handleStruggle: ModalActionFunction = async (event, done) => {
    await updateMeetingSchedule({
      variables: {
        meetingId: meeting?.id,
        stage: showNotRightTimeModal
          ? ScheduleSubmissionStage.FIRST_FAIL
          : ScheduleSubmissionStage.SECOND_FAIL,
      },
    });
    setPageStatus(
      showNotRightTimeModal ? PAGE_STATUS.FIRST_FAIL : PAGE_STATUS.SECOND_FAIL,
    );
    done();
  };

  return (
    <ContentSection title="Choose a time">
      <div className="schedule-meeting">
        {pageStatus === PAGE_STATUS.LOADING ||
        meetingLoading ||
        pageStatus === PAGE_STATUS.NOT_FOUND ? (
          <>
            {pageStatus === PAGE_STATUS.NOT_FOUND ? (
              <div>
                <InlineSVG src={sadCat} className="schedule-meeting__sad-cat" />
                <InfoMessage>
                  Sorry scheduling isn't found for this meeting.
                </InfoMessage>
              </div>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <>
            <div className="schedule-meeting__members">
              <Avatar
                memberAvatar={me?.avatar}
                className="schedule-meeting__members-avatar"
              />
              <h2 className="h2">
                {me?.firstName} + {otherUser?.firstName}
              </h2>
              <Avatar
                memberAvatar={otherUser?.avatar}
                className="schedule-meeting__members-avatar"
              />
            </div>
            {/* 
            
            ------------------------- SCHEDULING STATES -----------------------------
            
            */}
            {pageStatus === PAGE_STATUS.SCHEDULE_A ? (
              <>
                {selectedDates.length < 1 ? (
                  <InfoMessage>
                    Let's find a time for your conversation! Just click on the
                    times that work for you and hit 'Submit'. Please select at
                    least five time slots to give {otherUser?.firstName} some
                    choice. And you can always click the 'plus' button to add in
                    more options.
                  </InfoMessage>
                ) : (
                  <InfoMessage>
                    {5 - selectedDates.length > 0 ? (
                      <>
                        You have selected {selectedDates.length} times, please
                        select {5 - selectedDates.length} more so{' '}
                        {otherUser?.firstName} has some choice! If you need more
                        dates, just click on the plus button
                      </>
                    ) : (
                      <>
                        Great! You've selected five options… Just hit 'Submit'
                        and then it's over to {otherUser?.firstName}! 🤞
                      </>
                    )}
                    <Button
                      action={handleSubmit}
                      label="Submit"
                      disabled={
                        selectedDates.length < 5 || updateMeetingScheduleLoading
                      }
                      className="schedule-meeting__submit-button"
                      active={updateMeetingScheduleLoading}
                    />
                  </InfoMessage>
                )}
                <MeetingScheduler
                  suggestedDates={suggestedDates}
                  dateSelected={handleDateClick}
                  struggleClick={handleStruggleClick}
                  showStruggle={true}
                />
                <InfoMessage rightHanded={true}>
                  {selectedDates.length < 5 ? (
                    <>
                      You have selected {selectedDates.length} times, please
                      select {5 - selectedDates.length} more so{' '}
                      {otherUser?.firstName} has some choice! If you need more
                      dates, just click on the plus button
                    </>
                  ) : (
                    <>
                      Great! You've selected five options… Just hit 'Submit' and
                      then it's over to {otherUser?.firstName}! 🤞
                    </>
                  )}
                  <Button
                    action={handleSubmit}
                    label="Submit"
                    disabled={
                      selectedDates.length < 5 || updateMeetingScheduleLoading
                    }
                    className="schedule-meeting__submit-button"
                    active={updateMeetingScheduleLoading}
                  />
                </InfoMessage>
              </>
            ) : null}

            {pageStatus === PAGE_STATUS.SCHEDULE_B ? (
              <>
                <InfoMessage>
                  {meeting?.scheduleStage ===
                    ScheduleSubmissionStage.RESCHEDULE ||
                  meeting?.scheduleStage ===
                    ScheduleSubmissionStage.RESCHEDULE_PROPOSAL ? (
                    <>
                      Unfortunately we need to rearrange your conversation but
                      hopefully it'll be quick and easy!
                    </>
                  ) : (
                    <>Let's find a time for your conversation!</>
                  )}{' '}
                  {otherUser?.firstName} has already picked{' '}
                  {suggestedDates?.length} options for you so just click on your
                  preferred time and hit 'Submit'.
                </InfoMessage>
                <MeetingScheduler
                  suggestedDates={suggestedDates}
                  dateSelected={handleDateClick}
                  struggleClick={handleStruggleClick}
                />
                <InfoMessage rightHanded={true}>
                  <Button
                    action={handleSubmit}
                    label="Submit"
                    disabled={
                      selectedDates.length < 1 || updateMeetingScheduleLoading
                    }
                    className="schedule-meeting__submit-button"
                    active={updateMeetingScheduleLoading}
                  />
                  <LinkButton
                    label="Help! None of these times work for me…."
                    action={handleDateFail}
                    className="schedule-meeting__help-link"
                  />
                </InfoMessage>
              </>
            ) : null}
            {/* 
            
            ------------------------- FAIL STATES -----------------------------
            
            */}
            {pageStatus === PAGE_STATUS.FIRST_FAIL ? (
              <div>
                <InlineSVG src={sadCat} className="schedule-meeting__sad-cat" />
                <InfoMessage>
                  Ok, thanks thanks for letting us know. Our member success team
                  will be in contact with you to figure out next steps 🤫. In
                  the meantime, don't worry, we will manage any communication
                  with {otherUser?.firstName}.
                </InfoMessage>
              </div>
            ) : null}

            {pageStatus === PAGE_STATUS.SECOND_FAIL ? (
              <div>
                <InlineSVG src={sadCat} className="schedule-meeting__sad-cat" />
                <InfoMessage>
                  It looks like you and {otherUser?.firstName} had some
                  challenges in scheduling, our member success team should have
                  reached out but, in case it ended in your spam box or went
                  wandering somehow you can reach out directly on{' '}
                  <a href="mailto:hello@joinvoco.com">hello@joinvoco.com</a>
                </InfoMessage>
              </div>
            ) : null}
            {/* 
            
            ------------------------- DONE STATES -----------------------------
            
            */}
            {pageStatus === PAGE_STATUS.DONE_SCHEDULE ? (
              <div>
                <InfoMessage>
                  Thanks for choosing some times. We've let{' '}
                  {otherUser?.firstName} know and will confirm your conversation
                  ASAP!
                </InfoMessage>
              </div>
            ) : null}

            {pageStatus === PAGE_STATUS.SCHEDULED ? (
              <div>
                <InfoMessage>
                  Excellent! You're all done! You'll receive a meeting invite
                  that includes a link to join your conversation. You'll also
                  find a button to join the call in 'Actions' on your Voco
                  Dashboard. Enjoy!
                </InfoMessage>

                {meeting?.meetingStartAt ? (
                  <div className="schedule-meeting__scheduled-date">
                    <DateTile date={meeting?.meetingStartAt} />
                    <LargeTimeTile
                      selected={true}
                      date={meeting?.meetingStartAt}
                    />
                  </div>
                ) : null}

                <LinkButton
                  className="schedule-meeting__help-link"
                  label="Help! This time doesn't work for me anymore!"
                  link={`/meeting/${id}/reschedule`}
                  local={true}
                />
              </div>
            ) : null}
            {/* 
            
            ------------------------- MODALS -----------------------------
            
            */}
            {showNotRightTimeModal ? (
              <Modal
                title="NOT THE RIGHT TIME?"
                actionText="I Need help"
                action={handleStruggle}
                dismissHandler={handleDismissModal}>
                <p>
                  A tough few weeks at work 😬…? Perhaps a well deserved holiday
                  🏖️…? Either way we get it that sometimes the times just aren't
                  right. Don't stress, just click the blue button above if these
                  dates simply don't work for you - the Voco member success team
                  will get in touch to figure out the best way forward. If you
                  want to have another look, just click on the big “x” to go
                  back tot eh previous screen.
                </p>
              </Modal>
            ) : null}
            {showDateFailModal ? (
              <Modal
                title="Is it a date fail?"
                actionText="Pick New Times"
                action={handlePickNewTimes}
                dismissHandler={handleDismissModal}>
                <p>
                  Ok so you have indicated that none of the dates{' '}
                  {otherUser?.firstName} suggested work for you. If you would
                  like to review {otherUser?.firstName}'s times again just in
                  case, simply close this window. If you are sure they don't
                  work, click the button above and you will be asked to pick 5
                  alternative dates to suggest to {otherUser?.firstName}. Don't
                  worry we will handle all the communication to{' '}
                  {otherUser?.firstName}!
                </p>
              </Modal>
            ) : null}
            {showInfiniteLoopModal ? (
              <Modal
                title="AHHHHH!"
                actionText="I Need help"
                action={handleStruggle}
                dismissHandler={handleDismissModal}>
                <p>
                  OK this is really not going well, you and{' '}
                  {otherUser?.firstName} seem to be stuck in an infinite
                  scheduling fail loop. 😱. It's rare but it happens in this
                  busy world! If you are here by accident, just click the 'X' to
                  return to the previous screen. If you are really stuck click
                  the button above to confirm the dates don't work and our
                  members success team will reach out and figure out the best
                  next steps.
                </p>
              </Modal>
            ) : null}
          </>
        )}
      </div>
    </ContentSection>
  );
};

export default ScheduleMeetingPage;
