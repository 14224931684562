export let SocialLoginProviders;

(function (SocialLoginProviders) {
  SocialLoginProviders["Microsoft"] = "microsoft";
  SocialLoginProviders["Facebook"] = "facebook";
  SocialLoginProviders["Google"] = "google";
  SocialLoginProviders["Github"] = "github";
  SocialLoginProviders["Slack"] = "slack";
  SocialLoginProviders["Apple"] = "apple";
  SocialLoginProviders["Linkedin"] = "linkedin";
})(SocialLoginProviders || (SocialLoginProviders = {}));

export let AuthStrategyEnum;

(function (AuthStrategyEnum) {
  AuthStrategyEnum["EmailAndPassword"] = "EmailAndPassword";
  AuthStrategyEnum["MagicLink"] = "MagicLink";
  AuthStrategyEnum["Code"] = "Code";
  AuthStrategyEnum["NoLocalAuthentication"] = "NoLocalAuthentication";
  AuthStrategyEnum["SmsCode"] = "SmsCode";
})(AuthStrategyEnum || (AuthStrategyEnum = {}));

export let MachineToMachineAuthStrategy;

(function (MachineToMachineAuthStrategy) {
  MachineToMachineAuthStrategy["ClientCredentials"] = "ClientCredentials";
  MachineToMachineAuthStrategy["AccessToken"] = "AccessToken";
})(MachineToMachineAuthStrategy || (MachineToMachineAuthStrategy = {}));