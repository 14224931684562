import _extends from "@babel/runtime/helpers/esm/extends";
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { api, ProviderType } from '@frontegg/rest-api';
import { informationActions } from './index';
import { SubscriptionStatus } from '../../general.interfaces';
import { toSubscriptionCancellation, toSubscriptionStatus } from '../../mapper';
import { planResponseMock, subscriptionResponseMock } from '../../dummy';
import { loadSubscription } from '../Subscription/saga';
export function* subscriptionBillingInformationSagas() {
  yield takeEvery(informationActions.loadBillingInformation, loadBillingInformation);
}
export function* loadBillingInformation() {
  yield loadBillingInformationAction(false);
}
function* loadBillingInformationAction(forceActive) {
  const paymentProvider = yield select(state => {
    var _state$subscriptions$;
    return (_state$subscriptions$ = state.subscriptions.config.config) == null ? void 0 : _state$subscriptions$.paymentProvider;
  });
  const tenantId = yield select(state => {
    var _state$auth, _state$auth$user;
    return (_state$auth = state.auth) == null ? void 0 : (_state$auth$user = _state$auth.user) == null ? void 0 : _state$auth$user.tenantId;
  });
  if (!paymentProvider || !tenantId) {
    yield put(informationActions.setError(!paymentProvider ? 'Internal feature failure' : 'Not authorized'));
    return;
  }
  yield loadSummaries(tenantId, forceActive);
}
export function* loadSummaries(tenantId, forceActive) {
  yield put(informationActions.setLoading(true));
  try {
    var _planResponse$price, _planResponse$price2;
    const summary = yield call(api.subscriptions.getSubscriptionSummaries, tenantId);
    const {
      currentPlanId,
      externallyManaged
    } = summary;
    let subscriptionResponse = null;
    let planResponse;
    if (!externallyManaged) {
      [, planResponse] = yield all([call(loadSubscription), call(api.subscriptions.getSubscriptionPlan, currentPlanId)]);
      subscriptionResponse = yield select(state => state.subscriptions.billing.subscription.subscription || null);
    } else {
      planResponse = yield call(api.subscriptions.getSubscriptionPlan, currentPlanId);
    }
    yield put(informationActions.setState(_extends({
      loading: false,
      fetching: false,
      summary
    }, subscriptionResponse ? {
      subscription: {
        id: subscriptionResponse.id,
        externalId: subscriptionResponse.externalId,
        startDate: subscriptionResponse.startDate,
        currentPeriodStart: subscriptionResponse.currentPeriodStart,
        currentPeriodEnd: subscriptionResponse.currentPeriodEnd,
        status: forceActive ? SubscriptionStatus.ACTIVE : toSubscriptionStatus(subscriptionResponse.status),
        cancellation: subscriptionResponse.cancellation && toSubscriptionCancellation(subscriptionResponse.cancellation),
        trialEnd: subscriptionResponse.trialEnd ? subscriptionResponse.trialEnd : null
      }
    } : {}, planResponse ? {
      plan: {
        id: planResponse.id,
        name: planResponse.name,
        description: planResponse.description,
        price: ((_planResponse$price = planResponse.price) == null ? void 0 : _planResponse$price.amount) || 0,
        currency: ((_planResponse$price2 = planResponse.price) == null ? void 0 : _planResponse$price2.currency) || 'usd',
        recurringInterval: 'month',
        slug: planResponse.slug
      }
    } : {})));
  } catch (e) {
    yield put(informationActions.setError(e.message));
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadBillingInformationMock() {
  yield loadSummariesMock();
}
export function* loadSummariesMock() {
  var _planResponseMock$0$p, _planResponseMock$0$p2;
  yield put(informationActions.setLoading(true));
  yield delay(500);
  yield put(informationActions.setState({
    loading: false,
    fetching: false,
    summary: {
      subscriptionId: subscriptionResponseMock.id,
      paymentMethodId: 'mockPaymentMethodId',
      tenantConfigurationId: 'mockTenantConfigurationId',
      providerType: ProviderType.Stripe,
      externallyManaged: false,
      currentPlanId: planResponseMock[0].id,
      defaultPlanId: planResponseMock[0].id
    },
    plan: _extends({}, planResponseMock[0], {
      price: (_planResponseMock$0$p = planResponseMock[0].price) == null ? void 0 : _planResponseMock$0$p.amount,
      currency: (_planResponseMock$0$p2 = planResponseMock[0].price) == null ? void 0 : _planResponseMock$0$p2.currency,
      recurringInterval: 'month'
    })
  }));
}
export function* subscriptionBillingInformationSagasMock() {
  yield takeEvery(informationActions.loadBillingInformation, loadBillingInformationMock);
}