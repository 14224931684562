import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import TitleCard from '../TitleCard/TitleCard';
import './StepCard.css';

export type StepCardLink = { title: string; link: string; external?: boolean };

export type StepCardProps = {
  typeText?: string;
  title?: string;
  colour?: 'orange' | 'teal' | 'dark-orange';
  links?: StepCardLink[];
};

const StepCard: FunctionComponent<StepCardProps> = ({
  typeText,
  colour = 'teal',
  title,
  links = [],
}) => {
  return (
    <div className={`step-card step-card--${colour}`}>
      <TitleCard
        title={title}
        subtitle={typeText}
        colour={colour}
        className="step-card__title"
      />
      <div className="step-card__links">
        {links.map((link, i) => (
          <Link
            key={`step-card-link-${i}`}
            to={link.link}
            className="step-card__link"
            target={link.external ? '_blank' : undefined}>
            {link.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StepCard;
