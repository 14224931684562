import { FunctionComponent, ReactNode } from 'react';
import SVG from 'react-inlinesvg';
import './DropDownMenu.css';
import menuIcon from '../../svgs/icon-menu.svg';
import { useState, useEffect, useRef } from 'react';

export type DropDownMenuProps = {
  children?: ReactNode;
};

const DropDownMenu: FunctionComponent<DropDownMenuProps> = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [opened, setOpened] = useState(false);

  const open = () => {
    setOpened(!opened);
  };

  const close = () => {
    setOpened(false);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     * (see https://stackoverflow.com/a/42234988)
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as HTMLElement)
      ) {
        close();
      }
    }
    if (opened) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, opened]);

  return (
    <div ref={wrapperRef} className="drop-down-menu" onClick={open}>
      <SVG src={menuIcon} className="drop-down-menu__icon" />
      <div
        className={
          opened
            ? 'drop-down-menu__content drop-down-menu__content--open'
            : 'drop-down-menu__content'
        }>
        {children}
      </div>
    </div>
  );
};

export default DropDownMenu;
