import _extends from "@babel/runtime/helpers/esm/extends";
import { createSlice } from '@reduxjs/toolkit';
import { typeReducer } from './utils';
import { initialState, reinitializeState } from './initialState';
import { authStoreName } from '../constants';
import { loginActions, loginReducers } from './LoginState';
import { activateAccountActions, activateAccountReducers } from './ActivateState';
import { acceptInvitationActions, acceptInvitationReducers } from './AcceptInvitationState';
import { forgotPasswordActions, forgotPasswordReducers } from './ForgotPasswordState';
import { ssoActions, ssoReducers } from './SSOState';
import { mfaActions, mfaReducers } from './MfaState';
import { profileActions, profileReducers } from './ProfileState';
import { teamActions, teamReducers } from './TeamState';
import { socialLoginsActions, socialLoginsReducer } from './SocialLogins';
import { signUpActions, signUpReducers } from './SignUp';
import { apiTokensActions, apiTokensReducers } from './ApiTokensState';
import { securityPolicyActions, securityPolicyReducers } from './SecurityPolicyState';
import { accountSettingsActions, accountSettingsReducers } from './AccountSettingsState';
import { tenantsActions, tenantsReducers } from './TenantsState';
import { rolesActions, rolesReducers } from './RolesState';
import { resetPhoneNumberActions, resetPhoneNumberReducers } from './ResetPhoneNumberState';
import { sessionsActions, sessionsReducers } from './SessionsState';
import { sessionsPolicyActions, sessionsPolicyReducers } from './SessionsPolicyState';
import { restrictionsActions, restrictionsReducers } from './RestrictionsState';
import { provisioningActions, provisioningReducers } from './Provisioning';
import { impersonateActions, impersonateReducers } from './ImpersonationState';
import { passkeysActions, passkeysReducers } from './PasskeysState';
import { groupsActions, groupsReducers } from './GroupsState';
import { groupsDialogsActions, groupsDialogsReducers } from './GroupsState/groupsDialogsState';
const {
  reducer,
  actions: sliceActions
} = createSlice({
  name: authStoreName,
  initialState,
  reducers: _extends({
    resetState: state => _extends({}, state, reinitializeState),
    setState: (state, {
      payload
    }) => _extends({}, state, payload),
    setUser: typeReducer('user')
  }, loginReducers, socialLoginsReducer, activateAccountReducers, impersonateReducers, acceptInvitationReducers, forgotPasswordReducers, resetPhoneNumberReducers, signUpReducers, profileReducers, ssoReducers, mfaReducers, teamReducers, groupsReducers, groupsDialogsReducers, apiTokensReducers, securityPolicyReducers, accountSettingsReducers, tenantsReducers, rolesReducers, sessionsReducers, sessionsPolicyReducers, restrictionsReducers, provisioningReducers, passkeysReducers)
});
const actions = _extends({}, sliceActions, loginActions, socialLoginsActions, activateAccountActions, acceptInvitationActions, forgotPasswordActions, resetPhoneNumberActions, signUpActions, profileActions, ssoActions, mfaActions, teamActions, groupsActions, groupsDialogsActions, apiTokensActions, securityPolicyActions, accountSettingsActions, tenantsActions, rolesActions, sessionsActions, sessionsPolicyActions, restrictionsActions, provisioningActions, impersonateActions, passkeysActions);
export { reducer, actions };