import './EditAccountModal.css';

import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import Avatar from '../../components/Avatar/Avatar';
import LabelInput from '../../components/LabelInput/LabelInput';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';
import { Member } from '../../models/Member';
import LabelSelect from '../../components/LabelSelect/LabelSelect';

export type EditAccountModalProps = {
  account?: Member;
  updateAccount: Function;
  updatingAccount: boolean;
};

const EditAccountModal: FunctionComponent<EditAccountModalProps> = ({
  account,
  updateAccount,
  updatingAccount,
}) => {
  type FormProps = {
    preferredPronouns?: string;
    firstName?: string;
    lastName?: string;
    ethnicity?: string;
    dateOfBirth?: string;
    dependents?: string;
    linkedIn?: string;
    email?: string;
    timezone?: string;
    phoneNumber?: string;
  };

  const [formValues, setFormValues] = useState<FormProps>({
    preferredPronouns: '',
    firstName: '',
    lastName: '',
    ethnicity: '',
    dateOfBirth: '',
    dependents: '',
    linkedIn: '',
    email: '',
    timezone: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (account) {
      setFormValues({
        preferredPronouns: account.preferredPronouns,
        firstName: account.firstName,
        lastName: account.lastName,
        ethnicity: account.ethnicity,
        dateOfBirth: account.dateOfBirth,
        dependents: account.dependents,
        linkedIn: account.linkedIn,
        email: account.email,
        timezone: account.timezone,
        phoneNumber: account.phoneNumber,
      });
    }
  }, [account]);

  const handleFormChanges: ChangeEventHandler = (event) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLSelectElement;
    if (formValues) {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  // SAVE GOAL UPDATES
  const save: ModalActionFunction = async (event, done) => {
    if (!formValues) {
      return;
    }

    const result = await updateAccount({
      variables: { me: formValues },
    });
    if (!result?.data?.updateMe?.success) {
      console.error(result);
    }
    return done();
  };

  return (
    <Modal
      title="Account Details"
      action={save}
      actionInProgress={updatingAccount}
      returnURL="/account">
      <div className="edit-account">
        <div className="edit-account__avatar">
          {account ? <Avatar memberAvatar={account.avatar} /> : null}
        </div>
        <div className="edit-account__form">
          <LabelSelect
            label="Preferred Pronoun:"
            name="preferredPronouns"
            value={formValues.preferredPronouns}
            optionsArray={[
              '',
              'he/him',
              'she/her',
              'them/they',
              'I would rather not say',
            ]}
            onChange={handleFormChanges}
          />
          <LabelInput
            label="First Name:"
            name="firstName"
            value={formValues.firstName}
            onChange={handleFormChanges}
          />
          <LabelInput
            label="Surname:"
            name="lastName"
            value={formValues.lastName}
            onChange={handleFormChanges}
          />
          {/* <LabelInput
            label="Ethnicity:"
            name="ethnicity"
            value={formValues.ethnicity}
            onChange={handleFormChanges}
          />
          <LabelInput
            label="Date of Birth"
            name=""
            value={formValues.dateOfBirth}
            type="date"
            onChange={handleFormChanges}
          />
          <LabelInput
            label="Dependents:"
            name="dependents"
            value={formValues.dependents}
            type="number"
            onChange={handleFormChanges}
          /> */}
          <LabelInput
            label="LinkedIn Profile:"
            name="linkedIn"
            value={formValues.linkedIn}
            type="url"
            placeholder="https://www.linkedin.com/in/<username>"
            onChange={handleFormChanges}
          />
          {/* <LabelInput
            label="Dependents:"
            name="timezone"
            value={formValues.timezone}
            type="number"
            onChange={handleFormChanges}
          /> */}
          <LabelInput
            label="Phone Number:"
            name="phoneNumber"
            value={formValues.phoneNumber}
            type="tel"
            onChange={handleFormChanges}
          />
        </div>
        <span>
          To update any details not listed above, please email{' '}
          <a href="mailto:hello@joinvoco.io?subject=Please%20update%20my%20details">
            hello@joinvoco.io
          </a>
        </span>
      </div>
    </Modal>
  );
};

export default EditAccountModal;
