import './DateTile.css';

import { Dayjs } from 'dayjs';
import { FunctionComponent } from 'react';

export type DateTileProps = {
  date: Dayjs;
  disabled?: boolean;
  showDay?: boolean;
  colour?: 'dark-teal' | 'orange';
};

const DateTile: FunctionComponent<DateTileProps> = ({
  date,
  disabled = false,
  showDay = true,
  colour = 'dark-teal',
}) => {
  return (
    <div
      className={`date-tile date-tile--${colour}${
        disabled ? ' date-tile--disabled' : ''
      }`}>
      {showDay ? (
        <span className="date-tile__day">{date.format('dddd')}</span>
      ) : null}
      <span className="date-tile__date">{date.format('DD')}</span>
      <span className="date-tile__month">{date.format('MMMM')}</span>
    </div>
  );
};

export default DateTile;
