import { call, put, takeLeading } from 'redux-saga/effects';
import { actions } from '../reducer';
import { api } from '@frontegg/rest-api';
import { AcceptInvitationStep } from './interfaces';
function* acceptInvitation({
  payload
}) {
  if (!payload.token || !payload.userId) {
    yield put(actions.setAcceptInvitationState({
      error: undefined,
      step: AcceptInvitationStep.invalid
    }));
    return;
  }
  try {
    yield put(actions.setAcceptInvitationState({
      error: undefined,
      step: AcceptInvitationStep.pending
    }));
    yield call(api.auth.acceptInvitation, payload);
    yield put(actions.setState({
      isAuthenticated: false,
      user: null,
      acceptInvitationState: {
        error: undefined,
        step: AcceptInvitationStep.success
      }
    }));
  } catch (e) {
    yield put(actions.setAcceptInvitationState({
      step: AcceptInvitationStep.failed,
      error: e.message
    }));
  }
}
export function* acceptInvitationSagas() {
  yield takeLeading(actions.acceptInvitation, acceptInvitation);
}