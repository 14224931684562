import { Guide } from './Guide';
import { Meeting } from './Meeting';
import { Theme } from './Theme';

export class Journey {
  static get AD_HOC() {
    return 'ad-hoc';
  }

  id?: string;
  name?: string;
  slug?: string;
  title?: string;
  introduction?: string;
  guides: Guide[];
  themes: Theme[];
  meetings: Meeting[];
  createdAt?: Date;
  updatedAt?: Date;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.title = data.title;
    this.introduction = data.introduction;
    this.guides = data.guides ? data.guides.map((g: any) => new Guide(g)) : [];
    this.themes = data.themes ? data.themes.map((t: any) => new Theme(t)) : [];
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.meetings = [];
  }

  get uncompleteThemes(): Theme[] {
    const completedThemeIds = this.meetings.reduce<string[]>((a, m) => {
      if (m.theme?.id) {
        a.push(m.theme?.id);
      }
      return a;
    }, []);

    return this.themes.filter((t, i) => {
      return (
        t.slug !== Theme.AD_HOC && t.id && !completedThemeIds.includes(t.id)
      );
    });
  }
}
