import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Member } from '../models/Member';

// GET Users Actions
const GET_ME = gql`
  query me {
    me {
      id
      source
      email
      subscriptionStatus
    }
  }
`;

const useMe = () => {
  const { data: meData, loading: meLoading } = useQuery(GET_ME);
  const [me, setMe] = useState<Member>();

  useEffect(() => {
    if (meData) {
      setMe(new Member(meData.me));
    }
  }, [meData]);

  return {
    me,
    meLoading,
  };
};

export default useMe;
