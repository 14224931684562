import './NavBar.css';
import NavButton from './NavButton';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@frontegg/react';
import Button from '../Button/Button';
import { MouseEventHandler } from 'react';

import logo from '../../svgs/logo.svg';
import iconHome from '../../svgs/icon-home.svg';
import iconUser from '../../svgs/icon-user.svg';
import iconSettings from '../../svgs/icon-settings.svg';
import iconLocation from '../../svgs/icon-location.svg';

export type NavBarProps = {};

function NavBar() {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const navigate = useNavigate();

  const login: MouseEventHandler<HTMLButtonElement> = () => {
    localStorage.setItem('AUTH_LOCATION', location.pathname);
    navigate('/account/login');
  };

  return (
    <div className="navbar">
      <div className="navbar__content content-wrapper">
        <div className="navbar__branding">
          <NavLink className="navbar__branding-link" to="/">
            <img src={logo} alt="Voco" className="navbar__logo" />
          </NavLink>
        </div>
        <div className="navbar__items">
          {isAuthenticated ? (
            <>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? 'nav-link-button-active' : ''
                }>
                <NavButton label="DASHBOARD" icon={iconHome} />
              </NavLink>
              <NavLink
                to="/journeys"
                className={({ isActive }) =>
                  isActive ? 'nav-link-button-active' : ''
                }>
                <NavButton label="JOURNEYS" icon={iconLocation} />
              </NavLink>
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  isActive ? 'nav-link-button-active' : ''
                }>
                <NavButton label="PROFILE" icon={iconUser} />
              </NavLink>
              <NavLink
                to="/account"
                className={({ isActive }) =>
                  isActive ? 'nav-link-button-active' : ''
                }>
                <NavButton label="ACCOUNT" icon={iconSettings} />
              </NavLink>
            </>
          ) : (
            <Button action={login} label="LOGIN" />
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
