import './JourneyStep.css';
import { FunctionComponent, ReactNode } from 'react';
import DateTile from '../DateTile/DateTile';
import { Dayjs } from 'dayjs';

export type JourneyStepProps = {
  colour?: 'teal' | 'orange';
  children?: ReactNode;
  complete?: boolean;
  date?: Dayjs;
};

const JourneyStep: FunctionComponent<JourneyStepProps> = ({
  colour = 'teal',
  children,
  complete = false,
  date,
}) => {
  return (
    <div className={`journey-step journey-step--${colour}`}>
      <div className="journey-step__path-bar"></div>
      {date ? (
        <DateTile date={date} showDay={false} colour={'orange'} />
      ) : (
        <div className="journey-step__no-date"></div>
      )}
      <div className="journey-step__path">
        <div
          className={`journey-step__diamond ${
            complete ? ' journey-step__diamond--filled' : ''
          }`}></div>
      </div>
      <div>{children}</div>
      {date ? (
        <span className="journey-step__date-mobile">
          {date.format('Do MMMM YYYY')}
        </span>
      ) : null}
    </div>
  );
};

export default JourneyStep;
