import './ThemePage.css';

import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useTheme from '../../hooks/useTheme';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import TitleCard from '../../components/TitleCard/TitleCard';
import ThemeContent from '../../components/Theme/ThemeContent';

const ThemePage: FunctionComponent = () => {
  const { slug } = useParams();
  const { loadTheme, theme, themeLoading } = useTheme();

  useEffect(() => {
    loadTheme({ variables: { slug } });
  }, [loadTheme, slug]);

  return (
    <div className="theme-page content-wrapper">
      {themeLoading ? (
        <Loader />
      ) : (
        <>
          <TitleCard
            title={theme?.name}
            subtitle="Talking Points"
            colour="orange"
            className="theme-page__title"
            link={
              theme?.guide?.slug ? `/guides/${theme?.guide?.slug}` : undefined
            }
            linkTitle="Read deep dive"
          />

          <ThemeContent theme={theme} />

          {theme?.guide ? (
            <div className="theme-page__footer">
              <Button
                className="theme-page__guide-button"
                label="Want more detail? Read deep dive"
                local={true}
                link={`/guides/${theme.guide.slug}`}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ThemePage;
