import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Member } from '../models/Member';

const GET_ACCOUNT = gql`
  query {
    me {
      id
      firstName
      lastName
      avatarURL
      linkedIn
      email
      memberStatus
      preferredPronouns
      ethnicity
      dependents
      dateOfBirth
      timezone
      phoneNumber
      commsPreference
      subscriptionStatus
      subscription {
        endAt
        stripeEmail
      }
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation updateIntroText($me: MemberInput) {
    updateMe(me: $me) {
      me {
        id
        preferredPronouns
        firstName
        lastName
        ethnicity
        dateOfBirth
        dependents
        linkedIn
        email
        timezone
        phoneNumber
        commsPreference
      }
      success
    }
  }
`;

export function useAccount() {
  const [account, setAccount] = useState<Member>();
  const { data, loading } = useQuery(GET_ACCOUNT);

  const [updateAccountMutation, updateAccountData] = useMutation(
    UPDATE_ACCOUNT,
    {
      update: (
        cache,
        {
          data: {
            updateMe: {
              me: {
                preferredPronouns,
                firstName,
                lastName,
                ethnicity,
                dateOfBirth,
                dependents,
                linkedIn,
                email,
                timezone,
                phoneNumber,
                commsPreference,
              },
              success,
            },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_ACCOUNT });

        // If the cache is empty or we failed to update
        if (!success || !cachedRequest?.me?.introText) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_ACCOUNT,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              preferredPronouns,
              firstName,
              lastName,
              ethnicity,
              dateOfBirth,
              dependents,
              linkedIn,
              email,
              timezone,
              phoneNumber,
              commsPreference,
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    if (data?.me) {
      setAccount(new Member(data.me));
    }
  }, [data]);

  return {
    account,
    accountLoading: loading,
    updateAccountMutation,
    accountUpdating: updateAccountData.loading,
  };
}
