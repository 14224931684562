export let ISubscriptionStatus;

(function (ISubscriptionStatus) {
  ISubscriptionStatus["ACTIVE"] = "active";
  ISubscriptionStatus["CANCELED"] = "canceled";
  ISubscriptionStatus["INCOMPLETE"] = "incomplete";
  ISubscriptionStatus["EXPIRED"] = "expired";
  ISubscriptionStatus["TRIALING"] = "trialing";
  ISubscriptionStatus["TRIAL_ENDED"] = "trialEnded";
})(ISubscriptionStatus || (ISubscriptionStatus = {}));

export let PaymentMethodType;

(function (PaymentMethodType) {
  PaymentMethodType["UNKNWON"] = "unknown";
  PaymentMethodType["CARD"] = "card";
})(PaymentMethodType || (PaymentMethodType = {}));

export let ISubscriptionCancellationPolicy;

(function (ISubscriptionCancellationPolicy) {
  ISubscriptionCancellationPolicy["AT_PERIOD_END"] = "atPeriodEnd";
})(ISubscriptionCancellationPolicy || (ISubscriptionCancellationPolicy = {}));

export let ProviderType;

(function (ProviderType) {
  ProviderType["Stripe"] = "Stripe";
})(ProviderType || (ProviderType = {}));

export let TrialEndAction;

(function (TrialEndAction) {
  TrialEndAction["CHANGE_TO_DEFAULT"] = "change_to_default";
  TrialEndAction["RETAIN_TRIAL"] = "retain_trial";
})(TrialEndAction || (TrialEndAction = {}));

export let TenantCreatedAction;

(function (TenantCreatedAction) {
  TenantCreatedAction["CREATE"] = "create_customer";
})(TenantCreatedAction || (TenantCreatedAction = {}));