import './AdminPage.css';

import { useAuthUser } from '@frontegg/react';
import { FunctionComponent } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ContentSection from '../../components/ContentSection/ContentSection';
import MembersPage from './Members/MembersPage';
import MemberQueuePage from './Member/MemberPage';
import { ConfigEditorPage } from './Config/ConfigEditorPage';
import { MatcherConfigsPage } from './Config/MatcherConfigsPage';

export type AdminPageProps = {};

const AdminPage: FunctionComponent<AdminPageProps> = () => {
  const user = useAuthUser();
  const params = useParams();

  const matches = params['*']?.match(/(\w*)\/members/);
  const matcherKey = matches && matches?.length > 1 ? matches[1] : undefined;

  const isVocoAdmin = () => {
    return user && user.roles.find((r) => r.key === 'voco-admin') !== undefined;
  };

  return (
    <>
      {isVocoAdmin() ? (
        <div className="admin-page">
          <ContentSection title="Admin" className="admin-page__nav">
            <Button
              link={
                matcherKey ? `/admin/${matcherKey}/members` : `/admin/members`
              }
              label="Members"
              local={true}
              className="admin-page__nav-link"
            />
            <Button
              link={'/admin/configs'}
              label="Config Editor"
              local={true}
              className="admin-page__nav-link"
            />
          </ContentSection>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={{ pathname: '/admin/members' }} />}
            />
            <Route path="/:matcherKey?/members/*" element={<MembersPage />} />
            <Route
              path="/:matcherKey?/members/member/:id/*"
              element={<MemberQueuePage />}
            />
            <Route path="/configs/" element={<MatcherConfigsPage />} />
            <Route path="/configs/:matcherKey" element={<ConfigEditorPage />} />
          </Routes>
        </div>
      ) : (
        <Navigate
          to={{
            pathname: '/',
          }}
        />
      )}
    </>
  );
};

export default AdminPage;
