import { FunctionComponent } from 'react';

export type URLFormatterProps = {
  text: string;
};

const URLFormatter: FunctionComponent<URLFormatterProps> = ({ text }) => {
  if (!text) {
    return <span>No Content</span>;
  }
  const MATCHER_REGEX =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

  const replacedText = text.replaceAll(MATCHER_REGEX, (substr) => {
    const withProtocol = substr.startsWith('http')
      ? substr
      : 'https://' + substr;
    const url = new URL(withProtocol);

    return `<a href="${
      url.href
    }" class="link" target="_blank" rel="noreferrer">${
      url.pathname === '/' ? url.hostname : url.hostname + '/...'
    }</a>`;
  });
  return <span dangerouslySetInnerHTML={{ __html: replacedText }}></span>;
};

export default URLFormatter;
