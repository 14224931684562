import './MessageInput.css';

import {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  useState,
} from 'react';
import { Member } from '../../models/Member';
import Avatar from '../Avatar/Avatar';

import newMessageCallout from '../../svgs/new-message-callout.svg';
import messageButtonIcon from '../../svgs/icon-message-button.svg';
import InlineSVG from 'react-inlinesvg/esm';
import { Waveform } from '@uiball/loaders';

export type MessageInputProps = {
  addMessageMutation: Function;
  addingMessage: boolean;
  me?: Member;
};

const MessageInput: FunctionComponent<MessageInputProps> = ({
  addMessageMutation,
  addingMessage,
  me,
}) => {
  const [text, setText] = useState<string>();

  const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setText(event.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && e.metaKey) {
      handleSend();
    }
  };

  const handleSend = async () => {
    if (!text) {
      return;
    }

    const result = await addMessageMutation({
      variables: { message: { text } },
    });

    if (!result?.data?.addMessage?.success) {
      return console.error(result);
    }

    setText('');

    return;
  };

  return (
    <div className="message-input">
      <div className="message-input__avatar">
        <Avatar memberAvatar={me?.avatar} />
      </div>
      <img
        src={newMessageCallout}
        alt="Callout"
        className="message-input__input-callout"
      />
      <div className="message-input__input">
        <textarea
          className="message-input__text"
          value={text}
          onChange={handleTextChange}
          onKeyDown={handleKeyPress}
          disabled={!me?.membershipActive || addingMessage}
          placeholder="Make a note for yourself"></textarea>
        <button
          className="message-input__button"
          disabled={!me?.membershipActive || addingMessage}
          onClick={handleSend}>
          <InlineSVG
            src={messageButtonIcon}
            className="message-input__button-image"
          />
        </button>
        {addingMessage ? (
          <div className="message-input__in-progress">
            <Waveform size={25} lineWeight={3} speed={1} color="#0c343d" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageInput;
