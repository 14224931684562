import './NewSubscriptionPage.css';

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InfoMessage from '../../components/InfoMessage/InfoMessage';
import Loader from '../../components/Loader/Loader';
import useSubscriptionTable from '../../hooks/useSubscriptionTable';

import vocoMan from '../../svgs/voco-man.svg';
import { config } from '../../config/config';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const NewSubscriptionPage: FunctionComponent = () => {
  const [params] = useSearchParams();
  const [stripePricingTableId, setStripePricingTableId] = useState<string>();
  const navigate = useNavigate();

  const { pricingTableId, description, pricingTableIdLoading } =
    useSubscriptionTable(params.get('code') || undefined);

  useEffect(() => {
    if (!pricingTableId) {
      return;
    }
    if (pricingTableId === 'corporate' || pricingTableId === 'complimentary') {
      navigate('/subscription/confirmation');
      return;
    } else {
      setStripePricingTableId(pricingTableId);
    }
  }, [pricingTableId, navigate]);

  return (
    <div>
      {pricingTableIdLoading || !stripePricingTableId ? (
        <Loader />
      ) : (
        <div className="new-subscription">
          <InfoMessage className="new-subscription__info">
            {params.get('code') && description ? (
              <>
                {description.split('\n').map((paragraph) => (
                  <p className="new-subscription__info-text">{paragraph}</p>
                ))}
              </>
            ) : (
              <h1 className="h1 new-subscription__thanks">
                THANKS FOR JOINING VOCO - NOW JUST CONFIRM YOUR PAYMENT
                PREFERENCE PLEASE
              </h1>
            )}
          </InfoMessage>
          <img
            src={vocoMan}
            alt="Man happy signing up for Voco"
            className="new-subscription__image"
          />
          {stripePricingTableId ? (
            <div className="new-subscription__pricing-table">
              <stripe-pricing-table
                pricing-table-id={stripePricingTableId}
                customer-email={params.get('email')}
                publishable-key={
                  config.env === 'production'
                    ? 'pk_live_51KVdANBfvyHnywQlHMDlXr8fMebJ3svoaFoW1yQqf7CculOa2BuAFOIuQFCvINbC8l0dp6ARhdgkbX1MbXNQTJbB00HllNX7LG'
                    : 'pk_test_51KVdANBfvyHnywQlkhIs2Yj7UK4i06tAfvFyBqDPPG75hlPc5fp4jGwDoUMgrFpGTlOfYq1VQjmHPr8NdLFs4pOu00EJ3Wouyt'
                }></stripe-pricing-table>
            </div>
          ) : (
            <p>
              Error loading subscriptions, please contact{' '}
              <a href="mailto: hello@joinvoco.io">hello@joinvoco.io</a> to
              complete your purchase
            </p>
          )}
        </div>
      )}
    </div>
  );
};
export default NewSubscriptionPage;
