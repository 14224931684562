import { ReactNode } from 'react';
import './Banner.css';

export type BannerProps = {
  message?: string;
  children?: ReactNode;
  backgroundColor: string;
  color?: string;
  className?: string;
};

function Banner({
  message,
  backgroundColor,
  children,
  color = 'inherit',
  className,
}: BannerProps) {
  return (
    <div
      className={className ? 'banner ' + className : 'banner'}
      style={{
        backgroundColor,
        color,
      }}>
      {message ? message : children}
    </div>
  );
}

export default Banner;
