import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Meeting } from '../models/Meeting';

// GET Users Actions
const GET_MEETING_ANONYMOUS = gql`
  query meeting($id: ID) {
    meeting(id: $id) {
      id
      members {
        firstName
        avatarURL
      }
      meetingStartAt
      meetingEndAt
      embedURL
      themeSlug
      originalThemeSlug
    }
  }
`;

const GET_MEETING = gql`
  query meeting($id: ID) {
    meeting(id: $id) {
      id
      members {
        id
        firstName
        lastName
        avatarURL
      }
      me {
        id
      }
      meetingStartAt
      meetingEndAt
      embedURL
      themeSlug
      originalThemeSlug
    }
  }
`;

function useMeeting(id: string | undefined) {
  const [
    loadAnonymousMeeting,
    {
      called: anonymousCalled,
      data: anonymousData,
      loading: anonymousLoading,
      refetch: refetchAnonymousMeeting,
    },
  ] = useLazyQuery(GET_MEETING_ANONYMOUS, {
    variables: { id },
  });
  const [
    loadMeeting,
    { called, data, loading: meetingLoading, refetch: refetchMeeting },
  ] = useLazyQuery(GET_MEETING, {
    variables: { id },
  });
  const [meeting, setMeeting] = useState<Meeting>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (anonymousCalled && anonymousData?.meeting) {
      setMeeting(new Meeting(anonymousData.meeting));
    }
    if (called && data?.meeting) {
      setMeeting(new Meeting(data.meeting));
    }
  }, [anonymousCalled, called, anonymousData, data]);

  useEffect(() => {
    if (meetingLoading) {
      setLoading(true);
    } else if (anonymousLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [anonymousLoading, meetingLoading]);

  return {
    loadAnonymousMeeting,
    refetchAnonymousMeeting,
    loadMeeting,
    refetchMeeting,
    meeting,
    loading,
  };
}

export default useMeeting;
