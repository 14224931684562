import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["events", "url"];
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { api, ContextHolder } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { SignUpStage } from './interfaces';
import { AuthenticationTypes } from '../interfaces';
import { getMfaRequiredState, isMfaRequired } from '../LoginState/saga';
import { getFeatureFlags } from '../../helpers';
export function* loadAllowSignUps() {
  yield put(actions.setSignUpState({
    loading: true
  }));
  try {
    const policy = yield call(api.auth.getVendorConfig);
    const authStrategies = yield call(api.auth.getVendorPublicAuthStrategiesConfig);
    const [withLoginPerTenant] = yield call(getFeatureFlags, ['admin_portal_login_per_tenant']);
    if (withLoginPerTenant) {
      const authStrategies = yield call(api.auth.getVendorPublicAuthStrategiesConfig);
      if (authStrategies.mainAuthStrategies.length > 0) {
        policy.authStrategy = authStrategies.mainAuthStrategies[0].strategy;
      }
    }
    const {
      allowSignups: allowSignUps,
      allowNotVerifiedUsersLogin
    } = policy;
    yield put(actions.setSignUpState({
      loading: false,
      allowSignUps,
      allowNotVerifiedUsersLogin,
      firstLoad: false
    }));
    yield put(actions.setSecurityPolicyPublicState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSignUpState({
      loading: false,
      error: e.message,
      firstLoad: false
    }));
  }
}
export function* signUpUser(_ref) {
  let {
      payload: {
        events,
        url
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setSignUpState({
    loading: true
  }));
  try {
    const {
      routes,
      onRedirectTo
    } = yield select(state => state.auth);
    const {
      shouldActivate,
      user,
      tenantId,
      userId
    } = yield call(api.auth.signUpUser, payload);
    if (!payload.invitationToken) {
      var _events$signUpComplet;
      const {
        email,
        name,
        companyName
      } = payload;
      events == null ? void 0 : (_events$signUpComplet = events.signUpComplete) == null ? void 0 : _events$signUpComplet.call(events, {
        email,
        name,
        companyName,
        url,
        authenticationType: AuthenticationTypes.PASSWORD,
        id: userId,
        tenantId,
        createdAt: new Date()
      });
    }
    ContextHolder.setAccessToken(user == null ? void 0 : user.accessToken);
    ContextHolder.setUser(user);
    if (!shouldActivate && user && isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl);
    } else {
      yield put(actions.setSignUpState({
        loading: false,
        shouldActivate,
        stage: SignUpStage.SignUpSuccess
      }));
      yield put(actions.setState({
        user,
        isAuthenticated: !!(user != null && user.accessToken)
      }));
      if (!shouldActivate) {
        yield put(actions.loadTenants());
      }
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setSignUpState({
      loading: false,
      error: e.message
    }));
  }
  yield put(actions.setSignUpState({
    loading: false
  }));
}
export function* resetSignUpStateSoft() {
  const {
    allowSignUps,
    allowNotVerifiedUsersLogin
  } = yield select(state => state.auth.signUpState);
  yield put(actions.resetSignUpState());
  yield put(actions.setSignUpState({
    allowSignUps,
    allowNotVerifiedUsersLogin
  }));
}
export function* signUpSaga() {
  yield takeLeading(actions.signUpUser, signUpUser);
  yield takeLeading(actions.resetSignUpStateSoft, resetSignUpStateSoft);
}