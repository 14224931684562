import './MemberPage.css';

import { ChangeEventHandler, FunctionComponent, useState } from 'react';
import {
  Link,
  LinkProps,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from 'react-router-dom';
import ContentSection from '../../../components/ContentSection/ContentSection';
import MemberInfoGrid, {
  MemberInfoObject,
} from '../../../components/MemberInfo/MemberInfoGrid';
import useMember from '../../../hooks/useMember';

import Loader from '../../../components/Loader/Loader';
import Button from '../../../components/Button/Button';
import { MatchScoresTable } from './MatchScoresTable';
import { useMatcherKeys } from '../../../hooks/useMatcherKeys';
import LabelSelect from '../../../components/LabelSelect/LabelSelect';
import LabelInput from '../../../components/LabelInput/LabelInput';
import LinkButton from '../../../components/Button/LinkButton';

const ALL_LABEL = 'All';
export type MemberPageProps = {};

const MemberPage: FunctionComponent<MemberPageProps> = () => {
  const { id, matcherKey } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { membersMatcherKeys } = useMatcherKeys();
  const {
    member,
    memberLoading,
    scoreMember,
    scoreMemberCooldown,
    matchDecision,
    makeMatch,
    makeMatchLoading,
    needsMatch,
    needsMatchLoading,
  } = useMember(id, matcherKey);

  const [search, setSearch] = useState<string | undefined>();

  function CustomNavLink({ children, to, ...props }: LinkProps) {
    const path = matcherKey
      ? `/admin/${matcherKey}/members/member/${member?.id}${to}`
      : `/admin/members/member/${member?.id}${to}`;
    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <Link
        className={
          match
            ? 'link-button button--primary'
            : 'link-button button--secondary'
        }
        to={path}
        {...props}>
        {children}
      </Link>
    );
  }

  const matcherKeySelected: ChangeEventHandler = (event) => {
    const { value } = event.target as HTMLSelectElement;

    let subpage = '';
    if (location.pathname.endsWith('approved-matches')) {
      subpage = '/approved-matches';
    } else if (location.pathname.endsWith('rejected-matches')) {
      subpage = '/rejected-matches';
    }
    if (value && value !== ALL_LABEL) {
      navigate(`/admin/${value}/members/member/${member?.id}${subpage}`);
    } else {
      navigate(`/admin/members/member/${member?.id}${subpage}`);
    }
  };

  const handleToggleNeedsMatch = () => {
    if (!member?.id || member?.totalMatches === 0) {
      return;
    }

    needsMatch(member.id, !member?.seekingMatch);
  };

  return (
    <>
      {!member && memberLoading ? (
        <Loader />
      ) : (
        <>
          <ContentSection
            subSection={true}
            loading={memberLoading}
            className="member-queue-page"
            title={`${member?.fullName || 'Loading...'}`}>
            <MemberInfoGrid
              member={member as unknown as MemberInfoObject}
              properties={[
                {
                  label: 'Member Name',
                  property: 'fullName',
                },
                {
                  label: 'Join Date',
                  property: 'memberSince',
                  value: member?.memberSince?.format('DD/MM/YYYY'),
                },
                { label: 'No Matches', property: 'totalMatches' },
                {
                  label: 'Needs Match',
                  property: 'seekingMatch',
                  value: member?.seekingMatch ? 'Yes' : 'No',
                },
                {
                  label: 'Request Date',
                  property: 'addNewMatchesDate',
                  value: member?.addNewMatchesDate?.format('DD/MM/YYYY'),
                },
                { label: 'Function', property: 'functionalArea' },
                { label: 'Sector', property: 'sector' },
                { label: 'Career Start', property: 'careerStartYear' },
                { label: 'Job Level', property: 'jobLevel' },
                { label: 'Org Size', property: 'orgSize' },
                {
                  label: 'Context',
                  property: 'context',
                  value: member?.context.join(', '),
                },

                { label: 'Dependents', property: 'dependents' },
                { label: 'Source', property: 'source' },
                { label: 'Cohort', property: 'cohort' },
                { label: 'Airtable ID', property: 'appId' },
                {
                  label: 'Last Meeting',
                  property: 'lastMeeting',
                  value: member?.lastMeeting?.format('DD/MM/YYYY'),
                },
                { label: 'LinkedIn Profile', property: 'linkedIn' },
                { label: 'Timezone', property: 'timezone' },
              ]}
            />
          </ContentSection>
          <div className="member-queue-page__tab-bar">
            <div className="content-wrapper">
              <div className="member-queue-page__tabs">
                <CustomNavLink to={``}>Match Queue</CustomNavLink>
                <CustomNavLink to={`/approved-matches`}>Approved</CustomNavLink>
                <CustomNavLink to={`/rejected-matches`}>Rejected</CustomNavLink>
                <CustomNavLink to={`/matched-matches`}>Made</CustomNavLink>
                <Button
                  className="member-queue-page__tab-button"
                  action={handleToggleNeedsMatch}
                  label={
                    member?.seekingMatch
                      ? 'Unset Needs Match'
                      : 'Set Needs Match'
                  }
                  local={true}
                  disabled={
                    memberLoading ||
                    needsMatchLoading ||
                    member?.totalMatches === 0
                  }
                />
                <Button
                  className="member-queue-page__tab-button"
                  action={() => scoreMember()}
                  label="Rescore Member"
                  local={true}
                  disabled={scoreMemberCooldown}
                />
              </div>

              <div className="member-queue-page__controls">
                {membersMatcherKeys ? (
                  <LabelSelect
                    label="Select Algo View"
                    name="selectedAlgo"
                    optionsArray={[ALL_LABEL, ...membersMatcherKeys]}
                    value={matcherKey || ALL_LABEL}
                    onChange={matcherKeySelected}
                  />
                ) : null}
                <LabelInput
                  label="Search"
                  name="search"
                  value={search}
                  onChange={(e) =>
                    setSearch(
                      (e.target as HTMLInputElement).value.toLowerCase(),
                    )
                  }
                />
                <LinkButton
                  className="member-queue-page__clear-search"
                  label="✖︎"
                  action={() => setSearch('')}
                />
              </div>
            </div>
          </div>
          <Routes>
            <Route
              path="/approved-matches/*"
              element={
                <MatchScoresTable
                  matcherKey={matcherKey}
                  matchQueue={member?.approvedMatches}
                  title="Approved Matches"
                  decisionFunction={matchDecision}
                  makeMatchFunction={makeMatch}
                  makeMatchLoading={makeMatchLoading}
                  member={member}
                  search={search}
                />
              }
            />
            <Route
              path="/rejected-matches/*"
              element={
                <MatchScoresTable
                  matcherKey={matcherKey}
                  matchQueue={member?.rejectedMatches}
                  title="Rejected Matches"
                  decisionFunction={matchDecision}
                  member={member}
                  search={search}
                />
              }
            />
            <Route
              path="/matched-matches/*"
              element={
                <MatchScoresTable
                  matcherKey={matcherKey}
                  matchQueue={member?.allMatchedMatches}
                  title="Matched Matches"
                  member={member}
                  search={search}
                  hideActions
                  showLastMatchMeeting
                />
              }
            />
            <Route
              path="/*"
              element={
                <MatchScoresTable
                  matcherKey={matcherKey}
                  matchQueue={member?.matchQueue}
                  decisionFunction={matchDecision}
                  member={member}
                  search={search}
                />
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default MemberPage;
