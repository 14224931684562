import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "events"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { call, delay, put, select, takeLeading } from 'redux-saga/effects';
import { api, ContextHolder } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { afterAuthNavigation, getMfaRequiredState, isMfaRequired } from '../LoginState/saga';
import { UserVeirifedOriginTypes } from '../interfaces';
import { ActivateAccountStep } from './interfaces';
import { TeamStateKeys } from '../TeamState/interfaces';
function* preActivateAccount({
  payload: {
    userId,
    token
  }
}) {
  yield put(actions.setActivateState({
    loading: true,
    step: ActivateAccountStep.activating
  }));
  try {
    const {
      isAuthenticated
    } = yield select(state => state.auth);
    if (isAuthenticated) {
      yield put(actions.silentLogout());
    }
    const strategy = yield call(api.auth.getActivateAccountStrategy, {
      userId,
      token
    });
    yield put(actions.setActivateStrategyState({
      strategy,
      loading: false,
      error: undefined
    }));
    if (strategy.shouldSetPassword) {
      yield put(actions.setActivateState({
        loading: false,
        step: ActivateAccountStep.activatingForm
      }));
    }
  } catch (e) {
    yield put(actions.setActivateState({
      loading: false,
      error: e.message
    }));
  }
}
function* activateAccount(_ref) {
  let {
      payload: {
        callback,
        events
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setActivateState({
    loading: true
  }));
  try {
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes
    } = yield select(state => state.auth);
    const data = yield call(api.auth.activateAccount, payload);
    if (data.redirectLocation) {
      window.location.href = data.redirectLocation;
      return;
    }
    if (isMfaRequired(data)) {
      yield put(actions.setActivateState({
        step: ActivateAccountStep.success
      }));
      const mfaRequiredState = yield getMfaRequiredState(data);
      yield put(actions.setState(mfaRequiredState));
      yield delay(1000);
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      var _events$userVerified;
      yield put(actions.setActivateState({
        step: ActivateAccountStep.success
      }));
      const {
        user,
        tenants
      } = yield call(api.auth.generateLoginResponseV2, data);
      events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, {
        email: user.email,
        origin: UserVeirifedOriginTypes.ACTIVATION_LINK,
        id: user.id,
        tenantId: user.tenantId,
        name: user.name,
        createdAt: new Date()
      });
      yield put(actions.setTenantsState({
        tenants,
        loading: false
      }));
      yield put(actions.setState({
        user,
        isAuthenticated: true
      }));
      yield delay(1000);
      yield afterAuthNavigation();
      yield put(actions.resetActivateState());
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setActivateState({
      loading: false,
      error: e.message
    }));
  }
}
function* getActivateAccountStrategy({
  payload
}) {
  const {
      callback
    } = payload,
    params = _objectWithoutPropertiesLoose(payload, _excluded2);
  yield put(actions.setActivateStrategyState({
    loading: true
  }));
  try {
    const strategy = yield call(api.auth.getActivateAccountStrategy, params);
    yield put(actions.setActivateStrategyState({
      strategy,
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(strategy);
  } catch (e) {
    yield put(actions.setActivateStrategyState({
      loading: false,
      error: e.message
    }));
  }
}
function* resendActivationEmailFunction({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded3);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: body.email
  }));
  yield put(actions.setActivateState({
    loading: true
  }));
  try {
    yield call(api.auth.resendActivationEmail, body);
    yield put(actions.setActivateState({
      loading: false,
      error: undefined,
      resentEmail: true
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setActivateState({
      loading: false,
      error: e.message
    }));
    yield put(actions.setTeamError({
      key: TeamStateKeys.RESEND_ACTIVATE_LINK,
      value: e.message
    }));
    callback == null ? void 0 : callback(null, e.message);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: false
  }));
}
export function* activateSagas() {
  yield takeLeading(actions.activateAccount, activateAccount);
  yield takeLeading(actions.preActivateAccount, preActivateAccount);
  yield takeLeading(actions.getActivateAccountStrategy, getActivateAccountStrategy);
  yield takeLeading(actions.resendActivationEmail, resendActivationEmailFunction);
}