export default class CareerMotivator {
  title: string;
  score?: string;

  constructor(data: any) {
    this.title = data.title;
    this.score = data.score;
  }

  public get formattedScore(): string {
    return this.score ? this.score : '?';
  }
}
