import { FunctionComponent, useState } from 'react';
import Modal, { ModalActionFunction } from '../../../components/Modal/Modal';
import { MatchQueue, Member } from '../../../models/Member';

export type ConfirmMakeMatchModalProps = {
  makeMatch?: (matchScoreId: string) => Promise<void>;
  member?: Member;
  match?: MatchQueue;
  makeMatchLoading: boolean;
};

const ConfirmMakeMatchModal: FunctionComponent<ConfirmMakeMatchModalProps> = ({
  makeMatch,
  member,
  match,
  makeMatchLoading,
}) => {
  const [makingMatch, setMakingMatch] = useState<boolean>(false);

  const makeMatchHandler: ModalActionFunction = async (event, done) => {
    if (makeMatch && match?.id) {
      setMakingMatch(true);
      await makeMatch(match.id);
      setMakingMatch(false);
      done();
    }
  };

  return (
    <Modal
      title="Make a Match?"
      returnURL={`/admin/members/member/${member?.id}/approved-matches`}
      action={makeMatchHandler}
      actionText="Make Match"
      actionInProgress={makeMatchLoading || makingMatch}>
      <div className="info-motivators">
        <p className="info-motivators__text">
          Are you sure you would like to make a match between {member?.fullName}{' '}
          and {match?.member?.fullName}
        </p>
      </div>
    </Modal>
  );
};

export default ConfirmMakeMatchModal;
