import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { subscriptionActions } from './index';
import { api, ISubscriptionCancellationPolicy, ISubscriptionStatus } from '@frontegg/rest-api';
import { subscriptionResponseMock } from '../../dummy';
import { loadSummaries } from '../Information/saga';
export function* subscriptionSagas() {
  yield takeEvery(subscriptionActions.load, loadSubscriptionTenant);
  yield takeEvery(subscriptionActions.cancelSubscription, cancelSubscription);
  yield takeEvery(subscriptionActions.renewSubscription, renewSubscription);
}
function* loadSubscriptionTenant() {
  const tenantId = yield select(state => {
    var _state$auth, _state$auth$user;
    return (_state$auth = state.auth) == null ? void 0 : (_state$auth$user = _state$auth.user) == null ? void 0 : _state$auth$user.tenantId;
  });
  yield loadSummaries(tenantId);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* loadSubscription() {
  yield put(subscriptionActions.setLoading(true));
  try {
    const [subscription] = yield call(api.subscriptions.getManagedSubscriptions);
    yield put(subscriptionActions.setState({
      subscription,
      fetching: false,
      loading: false,
      error: null
    }));
  } catch (e) {
    yield put(subscriptionActions.setError(e));
  }
}
function* cancelSubscription() {
  const {
    subscription
  } = yield select(state => state.subscriptions.billing.subscription);
  if (!subscription) {
    yield put(subscriptionActions.setCancellationError('Subscription not found.'));
    return;
  }
  if (subscription != null && subscription.externallyManaged) {
    yield put(subscriptionActions.setCancellationError('Billing is externally managed.'));
    return;
  }
  const {
    id: subscriptionId,
    cancellation,
    status
  } = subscription || {};
  const isCancellable = !cancellation && status === ISubscriptionStatus.ACTIVE;
  if (isCancellable) {
    try {
      yield put(subscriptionActions.setCancellationLoading(true));
      yield call(api.subscriptions.cancelManagedSubscription, subscriptionId);
      yield loadSubscription();
      yield put(subscriptionActions.setCancellationLoading(false));
    } catch (e) {
      yield put(subscriptionActions.setCancellationError(e.message));
    }
  }
}
function* renewSubscription() {
  const {
    subscription
  } = yield select(state => state.subscriptions.billing.subscription);
  if (!subscription) {
    yield put(subscriptionActions.setCancellationError('Subscription not found.'));
    return;
  }
  if (subscription != null && subscription.externallyManaged) {
    yield put(subscriptionActions.setCancellationError('Billing is externally managed'));
    return;
  }
  const {
    id: subscriptionId,
    cancellation
  } = subscription || {};
  const renewable = (cancellation == null ? void 0 : cancellation.policy) === ISubscriptionCancellationPolicy.AT_PERIOD_END;
  if (renewable) {
    try {
      yield put(subscriptionActions.setRenewalLoading(true));
      yield call(api.subscriptions.renewManagedSubscription, subscriptionId);
      yield loadSubscription();
      yield put(subscriptionActions.setRenewalLoading(false));
    } catch (e) {
      yield put(subscriptionActions.setCancellationError(e.message));
    }
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadMock() {
  yield put(subscriptionActions.setLoading(true));
  yield delay(500);
  yield put(subscriptionActions.setState({
    subscription: subscriptionResponseMock,
    fetching: false,
    loading: false,
    error: null
  }));
}
export function* subscriptionSagasMock() {
  yield takeEvery(subscriptionActions.load, loadMock);
}