import { FunctionComponent } from 'react';
import { FeedItem } from '../../models/FeedItem';
import { JournalEntry } from '../../models/JournalEntry';

import commentCallout from '../../svgs/comment-callout.svg';
import Avatar from '../Avatar/Avatar';
import URLFormatter from '../URLFormatter/URLFormatter';

import './JournalEntryCard.css';

export type JournalEntryCardProps = {
  feedItem: FeedItem;
};

const JournalEntryCard: FunctionComponent<JournalEntryCardProps> = ({
  feedItem,
}) => {
  return (
    <div className="journal-entry-card">
      <div className="journal-entry-card__avatar">
        {feedItem.avatars.length > 1 ? (
          <Avatar
            memberAvatar={feedItem.avatars[0]}
            relatedMember={feedItem.avatars[1]}
          />
        ) : (
          <Avatar memberAvatar={feedItem.avatars[0]} />
        )}
      </div>
      <div className="journal-entry-card__content">
        <img
          src={commentCallout}
          alt="Callout"
          className="journal-entry-card__content-callout"
        />
        <div className="journal-entry-card__content-date">
          <span className="journal-entry-card__content-date-day">
            {feedItem.date.format('D')}
          </span>
          <span className="journal-entry-card__content-date-month">
            {feedItem.date.format('MMMM')}
          </span>
          <span className="journal-entry-card__content-date-year">
            {feedItem.date.format('YYYY')}
          </span>
        </div>
        <div className="journal-entry-card__content-body">
          {feedItem instanceof JournalEntry ? (
            <h4 className="journal-entry-card__content-title">
              {feedItem.title}
            </h4>
          ) : null}
          <p className="journal-entry-card__content-text">
            <URLFormatter text={feedItem.text} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default JournalEntryCard;
