import './GuidePage.css';

import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import TextCard from '../../components/TextCard/TextCard';
import useGuide from '../../hooks/useGuide';
import Loader from '../../components/Loader/Loader';
import TitleCard from '../../components/TitleCard/TitleCard';

const GuidePage: FunctionComponent = () => {
  const { slug } = useParams();
  const { guide, guideLoading } = useGuide(slug);
  return (
    <div className="guide-page content-wrapper">
      {guideLoading ? (
        <Loader />
      ) : (
        <>
          <TitleCard title={guide?.name} subtitle="Deep Dive" colour="teal" />
          <TextCard bodyHTML={guide?.body} className="guide-page__body-item" />
        </>
      )}
    </div>
  );
};

export default GuidePage;
