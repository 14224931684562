import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey, typeReducerNestedKey } from '../utils';
import { authStoreName } from '../../constants';
const securityPolicyState = {
  globalPolicy: {
    loading: true
  },
  publicPolicy: {
    loading: false
  },
  vendorMfaPolicy: {
    loading: true
  },
  mfaPolicy: {
    loading: true
  },
  vendorLockoutPolicy: {
    loading: true
  },
  lockoutPolicy: {
    loading: true
  },
  captchaPolicy: {
    loading: true
  },
  passwordHistoryPolicy: {
    loading: true
  },
  passwordPolicy: {
    loading: true
  },
  vendorPasswordHistoryPolicy: {
    loading: true
  },
  publicAuthStrategyPolicy: {
    loading: true
  }
};
const reducers = {
  setSecurityPolicyState: typeReducerForKey('securityPolicyState'),
  setSecurityPolicyGlobalState: typeReducerNestedKey('securityPolicyState', 'globalPolicy'),
  setSecurityPolicyPublicState: typeReducerNestedKey('securityPolicyState', 'publicPolicy'),
  setSecurityPolicyAuthStrategyPublicState: typeReducerNestedKey('securityPolicyState', 'publicAuthStrategyPolicy'),
  setSecurityPolicyMfaState: typeReducerNestedKey('securityPolicyState', 'mfaPolicy'),
  setSecurityPolicyVendorMfaState: typeReducerNestedKey('securityPolicyState', 'vendorMfaPolicy'),
  setSecurityPolicyVendorLockoutState: typeReducerNestedKey('securityPolicyState', 'vendorLockoutPolicy'),
  setSecurityPolicyVendorPasswordHistoryState: typeReducerNestedKey('securityPolicyState', 'vendorPasswordHistoryPolicy'),
  setSecurityPolicyLockoutState: typeReducerNestedKey('securityPolicyState', 'lockoutPolicy'),
  setSecurityPolicyCaptchaState: typeReducerNestedKey('securityPolicyState', 'captchaPolicy'),
  setSecurityPolicyPasswordHistoryState: typeReducerNestedKey('securityPolicyState', 'passwordHistoryPolicy'),
  setSecurityPolicyPasswordState: typeReducerNestedKey('securityPolicyState', 'passwordPolicy'),
  resetSecurityPolicyState: resetStateByKey('securityPolicyState', {
    securityPolicyState
  })
};
const actions = {
  loadSecurityPolicy: createAction(`${authStoreName}/loadSecurityPolicy`),
  loadPublicSecurityPolicy: createAction(`${authStoreName}/loadPublicSecurityPolicy`),
  loadSecurityPolicyMfa: createAction(`${authStoreName}/loadSecurityPolicyMfa`),
  loadSecurityPolicyVendorMfa: createAction(`${authStoreName}/loadSecurityPolicyVendorMfa`),
  saveSecurityPolicyMfa: createAction(`${authStoreName}/saveSecurityPolicyMfa`, payload => ({
    payload
  })),
  loadSecurityPolicyLockout: createAction(`${authStoreName}/loadSecurityPolicyLockout`),
  loadSecurityPolicyVendorLockout: createAction(`${authStoreName}/loadSecurityPolicyVendorLockout`),
  saveSecurityPolicyLockout: createAction(`${authStoreName}/saveSecurityPolicyLockout`, payload => ({
    payload
  })),
  loadSecurityPolicyCaptcha: createAction(`${authStoreName}/loadSecurityPolicyCaptcha`),
  loadSecurityPolicyPasswordHistory: createAction(`${authStoreName}/loadSecurityPolicyPasswordHistory`),
  loadSecurityPolicyVendorPasswordHistory: createAction(`${authStoreName}/loadSecurityPolicyVendorPasswordHistory`),
  saveSecurityPolicyPasswordHistory: createAction(`${authStoreName}/saveSecurityPolicyPasswordHistory`, payload => ({
    payload
  })),
  loadVendorPasswordConfig: createAction(`${authStoreName}/loadVendorPasswordConfig`),
  loadPublicAuthStrategiesPolicy: createAction(`${authStoreName}/loadPublicAuthStrategiesPolicy`)
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { securityPolicyState, reducers as securityPolicyReducers, actions as securityPolicyActions };