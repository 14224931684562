import _extends from "@babel/runtime/helpers/esm/extends";
import { AuthStrategyEnum, RestrictionType, GroupManagedByEnum } from '@frontegg/rest-api';
import { MachineToMachineAuthStrategy } from './ApiTokensState/interfaces';
export const apiTokensDataDemo = {
  clientId: 'CLIENT_ID_16806d3d-8fc3-4450-be97-abdaf66b723e',
  secret: 'SECRET_16806d3d-8fc3-4450-be97-abdaf66b723e',
  createdAt: 'createdAt',
  description: 'Demo API Token (preview only)'
};
export const apiTokensDataTenantDemo = _extends({}, apiTokensDataDemo, {
  roleIds: [],
  tenantId: 'my-tenant-id',
  createdByUserId: 'createdByUserId'
});
export const rolesAdminViewerDemo = [{
  id: 'a040dcf6-b907-4e83-9970-6bd23c88fd40',
  key: 'ReadOnly',
  name: 'Read Only',
  description: null,
  isDefault: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  permissions: ['5b7dafe6-12a1-402b-9082-8eb2777cfcd8', 'da1d1990-c99c-4086-b845-2d465de3227b'],
  tenantId: 'tenantId',
  vendorId: 'vendorId'
}, {
  id: 'b43b2c4b-e056-4eec-8c55-d200a475bbc0',
  key: 'Admin',
  name: 'Admin',
  description: null,
  isDefault: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  permissions: ['502b112e-50fd-4e8d-875e-3abda628d94a', 'da015508-7cb1-4dcd-9436-d0518a2ecd21'],
  tenantId: 'tenantId',
  vendorId: 'vendorId'
}];
export const roleDemo = [{
  id: 'roleId',
  key: 'admin',
  isDefault: false,
  name: 'Admin',
  description: null,
  permissions: ['adminPermissionId'],
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  createdAt: new Date(),
  updatedAt: new Date()
}];
export const rolePermissionDemo = [{
  id: 'adminPermissionId',
  key: 'fe.*',
  name: 'General Admin',
  description: undefined,
  categoryId: 'category',
  fePermission: true,
  createdAt: new Date(),
  updatedAt: new Date()
}];
export const userProfileDemo = {
  id: 'testId',
  name: 'Demo User Name',
  email: 'demo-user@frontegg.com',
  mfaEnrolled: true,
  profileImage: undefined,
  profilePictureUrl: 'https://www.gravatar.com/avatar/42b2ad2bad6fc9b9db5086dfcf8072ac?d=https://ui-avatars.com/api/fe/128/random?t=1617261890875?t=1617261917434',
  roles: roleDemo,
  permissions: rolePermissionDemo,
  tenantId: 'my-tenant-id',
  tenantIds: ['my-tenant-id'],
  activatedForTenant: true,
  metadata: JSON.stringify({
    phoneNumber: '+972-54123456',
    jobTitle: 'Developer',
    address: {
      city: 'Tel Aviv',
      country: 'Israel'
    }
  }),
  roleIds: undefined,
  verified: undefined
};
export const userDemo = _extends({}, userProfileDemo, {
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1MTY5YmY0Zi02YmI5LTQ5NGMtOGNkZS05MDc4NDQ0NWY4MDciLCJuYW1lIjoiRHVtbXkgVXNlciIsImVtYWlsIjoiZHVtbXlAZnJvbnRlZ2cuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm1ldGFkYXRhIjp7fSwicm9sZXMiOlsiYWRtaW4iXSwicGVybWlzc2lvbnMiOlsiZmUuKiJdLCJ0ZW5hbnRJZCI6Im15LXRlbmFudC1pZCIsInRlbmFudElkcyI6WyJteS10ZW5hbnQtaWQiXSwicHJvZmlsZVBpY3R1cmVVcmwiOiJodHRwczovL3d3dy5ncmF2YXRhci5jb20vYXZhdGFyLzQyYjJhZDJiYWQ2ZmM5YjlkYjUwODZkZmNmODA3MmFjP2Q9aHR0cHM6Ly91aS1hdmF0YXJzLmNvbS9hcGkvZmUvMTI4L3JhbmRvbT90PTE2MTcyNjE4OTA4NzU_dD0xNjE3MjYxOTE3NDM0IiwidHlwZSI6InVzZXJUb2tlbiIsImlhdCI6MTYxNzkwNjMyNCwiZXhwIjoxNjE3OTkyNzI0LCJpc3MiOiJmcm9udGVnZyJ9.paaXLkpWEWbQmUtoK2P8IwXCxK4WJp7XhXonvzF8g1I',
  expiresIn: 86400,
  mfaRequired: false,
  refreshToken: 'refresh-token-dummy-de39dc9c-9d22-4852-b7f5-c3c0aa613b58',
  type: 'userToken',
  iat: 1617906324,
  exp: 1617992724,
  iss: 'frontegg',
  email_verified: true
});
export const profileStateDemo = {
  loading: false,
  error: undefined,
  saving: true,
  profile: userProfileDemo
};
export const policyDemo = {
  id: 'id',
  enforceMFAType: 'DontForce',
  createdAt: new Date(),
  updatedAt: new Date(),
  allowOverrideEnforcePasswordHistory: true,
  allowOverridePasswordComplexity: false,
  allowOverridePasswordExpiration: false,
  allowSignups: true,
  apiTokensEnabled: true,
  cookieSameSite: 'cookieSameSite',
  defaultRefreshTokenExpiration: 0,
  defaultTokenExpiration: 1,
  publicKey: 'publicKey'
};
export const publicSecurityPolicy = {
  allowSignups: true,
  allowNotVerifiedUsersLogin: false,
  apiTokensEnabled: true,
  forcePermissions: false,
  authStrategy: AuthStrategyEnum.EmailAndPassword,
  machineToMachineAuthStrategy: MachineToMachineAuthStrategy.ClientCredentials
};
export const policyMfaDemo = {
  id: 'id',
  enforceMFAType: 'DontForce',
  createdAt: new Date(),
  updatedAt: new Date()
};
export const policyLockoutDemo = {
  id: 'id',
  enabled: true,
  maxAttempts: 1,
  createdAt: new Date(),
  updatedAt: new Date()
};
export const policyPasswordHistoryDemo = {
  id: 'id',
  enabled: true,
  historySize: 1,
  createdAt: new Date(),
  updatedAt: new Date()
};
export const samlConfigurationDemo = {
  enabled: true,
  domain: 'domain',
  validated: true,
  generatedVerification: 'generatedVerification',
  ssoEndpoint: 'ssoEndpoint',
  publicCertificate: 'publicCertificate',
  signRequest: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  acsUrl: undefined,
  spEntityId: undefined,
  oidcClientId: undefined,
  oidcSecret: undefined,
  type: undefined
};
export const samlMetadataDemo = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  properties: [],
  vendorId: 'vendorId',
  entityName: 'saml',
  isActive: true,
  configuration: {
    acsUrl: 'acsUrl',
    spEntityId: 'spEntityId'
  }
};
export const rolesDemo = [{
  id: 'id',
  description: 'description',
  key: 'key',
  name: 'name',
  permissions: [],
  permissionLevel: 1
}];
export const ssoStateDemo = {
  firstLoad: false,
  loading: false,
  error: undefined,
  saving: false,
  samlConfiguration: samlConfigurationDemo,
  roles: rolesDemo,
  authorizationRoles: [],
  errors: {},
  loaders: {},
  ssoConfigurations: []
};
export const permissionsDemo = [{
  description: 'string',
  fePermission: true,
  id: 'id',
  key: 'key',
  name: 'name',
  roleIds: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  categoryId: 'categoryId'
}];
export const userTeamDemo2 = {
  id: 'id2',
  email: 'demo-user2@frontegg.com',
  name: 'Demo User Name2',
  phone: undefined,
  profileImage: undefined,
  profileImageUrl: undefined,
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  roleIds: [],
  activatedForTenant: true,
  verified: false,
  createdAt: new Date().toISOString(),
  customData: undefined,
  lastLogin: undefined,
  mfaEnabled: undefined,
  roles: [rolesAdminViewerDemo[0]],
  groups: [{
    id: 'id2',
    name: 'Developers',
    color: '#03A9F4',
    roles: [rolesAdminViewerDemo[0]],
    managedBy: GroupManagedByEnum.FRONTEGG
  }]
}; // TODO add verified to ITeamUser

export const userTeamDemo = {
  id: 'id',
  email: 'demo-user@frontegg.com',
  name: 'Demo User Name',
  phone: undefined,
  profileImage: undefined,
  profileImageUrl: undefined,
  tenantId: 'tenantId',
  vendorId: 'vendorId',
  roleIds: [],
  activatedForTenant: true,
  createdAt: new Date(2022, 4, 1).toISOString(),
  verified: true,
  customData: undefined,
  lastLogin: new Date(2022, 5, 25).toISOString(),
  mfaEnabled: undefined,
  roles: [rolesAdminViewerDemo[1]],
  groups: [{
    id: 'id1',
    name: 'Designers',
    color: '#E1F5E2',
    roles: [rolesAdminViewerDemo[0]],
    managedBy: GroupManagedByEnum.FRONTEGG
  }]
};
export const userSubTenantDemo = {
  metadata: '',
  mfaEnrolled: false,
  provider: '',
  sub: '',
  tenantIds: [],
  tenants: [],
  verified: false,
  id: 'id',
  email: 'email',
  name: 'name',
  tenantId: 'tenantId',
  createdAt: new Date()
};
export const usersDemo = [userTeamDemo, userTeamDemo2];
export const allUsersDemo = [userSubTenantDemo];
export const tenantsDemo = [{
  id: 'my-tenant-id',
  isReseller: false,
  name: 'Frontegg demo',
  deletedAt: null,
  metadata: undefined,
  tenantId: 'my-tenant-id',
  vendorId: 'vendorId',
  createdAt: new Date(),
  updatedAt: new Date(),
  address: undefined,
  timezone: undefined,
  dateFormat: undefined,
  timeFormat: undefined,
  currency: undefined,
  logo: undefined
}];
export const sessionsMock = [{
  id: `laptop`,
  userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15',
  createdAt: new Date(),
  ipAddress: '190.194.88.251',
  current: true
}, {
  id: `iphone`,
  userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_2_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.2 Mobile/15E148 Safari/604.1',
  createdAt: new Date(Date.now() - 10000),
  ipAddress: '191.49.203.21'
}, {
  id: `tablet`,
  userAgent: 'Mozilla/5.0 (Linux; Android 6.0.1; SGP771 Build/32.2.A.0.253; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/52.0.2743.98 Safari/537.36',
  createdAt: new Date(Date.now() - 30000),
  ipAddress: '130.19.196.136'
}, {
  id: `anotherlaptop`,
  userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Safari/605.1.15',
  createdAt: new Date(Date.now() - 1002232300),
  ipAddress: '2.228.163.92'
}];
export const sessionsConfigDummies = {
  sessionConcurrentConfiguration: {
    isActive: true,
    maxSessions: 10
  },
  sessionIdleTimeoutConfiguration: {
    isActive: true,
    timeout: 3600
  },
  sessionTimeoutConfiguration: {
    isActive: true,
    timeout: 7776000
  }
};
export const dummyIpConfig = {
  isActive: true,
  strategy: RestrictionType.ALLOW
};
export const dummyIps = [{
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '1',
  ip: '255.255.255.255',
  isActive: true,
  strategy: RestrictionType.ALLOW,
  description: 'My IP'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '2',
  ip: '255.255.255.254',
  isActive: true,
  strategy: RestrictionType.ALLOW,
  description: 'IP 2'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '3',
  ip: '255.255.255.253',
  isActive: true,
  strategy: RestrictionType.BLOCK,
  description: 'IP 3'
}, {
  updatedAt: new Date(),
  createdAt: new Date(),
  id: '4',
  ip: '255.255.255.253',
  isActive: true,
  strategy: RestrictionType.BLOCK,
  description: 'IP 4'
}];
const generateGroupUsers = count => {
  const users = [];
  Array.from({
    length: count
  }).forEach((_, i) => {
    users.push(_extends({}, userTeamDemo, {
      name: 'Demo User',
      id: `id${i}`,
      profilePictureUrl: null,
      createdAt: new Date()
    }));
  });
  return users;
};
const generateRoleByName = roleName => {
  return {
    id: 'b43b2c4b-e056-4eec-8c55-d200a475bbc0' + roleName,
    key: roleName,
    name: roleName,
    description: null,
    isDefault: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    permissions: [],
    tenantId: 'tenantId',
    vendorId: 'vendorId'
  };
};
export const allGroupsDummy = [{
  id: 'id1',
  name: 'Designers',
  color: '#E1F5E2',
  description: 'The whole design team',
  users: generateGroupUsers(6),
  roles: [generateRoleByName('Editor')],
  metadata: '{"lastTermsCheck":"2022-08-16T10:31:11.270Z"}',
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id2',
  name: 'Developers',
  color: '#03A9F4',
  description: 'All frontend and backend developers',
  users: generateGroupUsers(44),
  roles: [generateRoleByName('Editor')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id3',
  name: 'Product',
  color: '#E1583E',
  description: 'PMs and team leads',
  users: generateGroupUsers(8),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id4',
  name: 'Management',
  color: '#9AE0FF',
  description: 'Executives',
  users: generateGroupUsers(5),
  roles: [generateRoleByName('Admin')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id5',
  name: 'Sales',
  color: '#ED8E7C',
  users: generateGroupUsers(21),
  description: 'BDR, AE, and inside sales',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id6',
  name: 'Support',
  color: '#A79D7B',
  users: generateGroupUsers(23),
  description: 'Dev success and customer success',
  roles: [generateRoleByName('Admin')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id7',
  name: 'Marketing',
  color: '#2CA744',
  users: generateGroupUsers(10),
  description: 'The marketing department',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id8',
  name: 'HR',
  color: '#EAE1C2',
  users: generateGroupUsers(4),
  description: 'Human resources',
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id9',
  name: 'Finance',
  color: '#5587C0',
  users: generateGroupUsers(3),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id10',
  name: 'Operations',
  color: '#B1CAE7',
  users: generateGroupUsers(8),
  roles: [generateRoleByName('Editor')],
  managedBy: GroupManagedByEnum.FRONTEGG
}, {
  id: 'id11',
  name: 'Legal',
  color: '#2CA744',
  users: generateGroupUsers(4),
  roles: [generateRoleByName('Viewer')],
  managedBy: GroupManagedByEnum.FRONTEGG
}];