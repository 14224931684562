import './GoalContainer.css';

import { FunctionComponent } from 'react';
import { Goal } from 'src/models/Goal';
import GoalCard from '../GoalCard/GoalCard';

export type GoalContainerProps = {
  goals?: Goal[];
};

const GoalContainer: FunctionComponent<GoalContainerProps> = ({
  goals = [],
}) => {
  const goalIndexes: number[] = [1, 2, 3, 4];
  return (
    <div className="goal-container">
      {goalIndexes.map((index) => (
        <GoalCard key={index} goal={goals[index - 1]} number={index} />
      ))}
    </div>
  );
};

export default GoalContainer;
