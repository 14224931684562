import { FunctionComponent } from 'react';
import ContentSection from '../../../components/ContentSection/ContentSection';
import { useMatcherKeys } from '../../../hooks/useMatcherKeys';
import { Link } from 'react-router-dom';

export type MatcherConfigPageProps = {};

export const MatcherConfigsPage: FunctionComponent<
  MatcherConfigPageProps
> = () => {
  const { membersMatcherKeys } = useMatcherKeys();

  return (
    <div className="matcher-config-page">
      <ContentSection title="Matcher (Algo) Configs">
        <h2 className="h2">Matcher Keys</h2>
        <ul>
          {membersMatcherKeys?.map((matcherKey) => (
            <li key={matcherKey}>
              <Link className="link" to={`/admin/configs/${matcherKey}`}>
                {matcherKey}
              </Link>
            </li>
          ))}
        </ul>
      </ContentSection>
    </div>
  );
};
