import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["type"],
      _excluded2 = ["type"];
import { getTenants } from "../tenants";
export * from "./secutiry-poilicy";
export * from "./enums";
import { urls } from "../constants";
import { ContextHolder } from "../ContextHolder";
import { Delete, Get, Patch, Post, Put } from "../fetch";
import { jwtDecode } from "../jwt";
export async function generateLoginResponse(loginResponse) {
  if (!loginResponse.accessToken) {
    return loginResponse;
  }

  ContextHolder.setAccessToken(loginResponse.accessToken);
  const me = await Get(`${urls.identity.users.v2}/me`);
  const decodedContent = loginResponse.accessToken ? jwtDecode(loginResponse.accessToken) : {};

  const user = _extends({}, loginResponse, decodedContent, me);

  ContextHolder.setUser(user);
  return user;
}
export async function generateLoginResponseV2(loginResponse) {
  if (!loginResponse.accessToken) {
    return {
      user: loginResponse
    };
  }

  ContextHolder.setAccessToken(loginResponse.accessToken);
  const [me, tenants] = await Promise.all([Get(`${urls.identity.users.v2}/me`), getTenants()]);
  const decodedContent = loginResponse.accessToken ? jwtDecode(loginResponse.accessToken) : {};

  const user = _extends({}, loginResponse, decodedContent, me);

  ContextHolder.setUser(user);
  return {
    user,
    tenants
  };
}
export async function generateLoginResponseFromOAuthResponse(oauthResponse) {
  ContextHolder.setAccessToken(oauthResponse.id_token);
  const me = await Get(`${urls.identity.users.v2}/me`);
  const decodedContent = oauthResponse.id_token ? jwtDecode(oauthResponse.id_token) : {};

  const user = _extends({
    mfaRequired: false,
    accessToken: oauthResponse.id_token,
    refreshToken: oauthResponse.refresh_token
  }, decodedContent, me, {
    expiresIn: decodedContent.exp ? Math.ceil(decodedContent.exp - new Date().getTime() / 1000) : 300
  });

  ContextHolder.setUser(user);
  return user;
}
export async function preLogin(body) {
  try {
    const {
      address
    } = await Post(`${urls.identity.auth.v1}/user/saml/prelogin`, body);
    return address;
  } catch {
    return null;
  }
}
export async function preLoginWithIdpType(body) {
  return Post(`${urls.identity.auth.v1}/user/saml/prelogin`, body);
}
export async function postLogin(body) {
  const data = await Post(`${urls.identity.auth.v1}/user/saml/postlogin`, body);
  return generateLoginResponse(data);
}
export async function oidcPostLogin(body) {
  const data = await Post(`${urls.identity.auth.v1}/user/oidc/postlogin`, body);
  return generateLoginResponse(data);
}
export async function login(body) {
  const data = await Post(`${urls.identity.auth.v1}/user`, body);
  return generateLoginResponse(data);
}
export async function loginWithMfa(body) {
  const data = await Post(`${urls.identity.auth.v1}/user/mfa/verify`, body);
  return generateLoginResponse(data);
}
export async function activateAccount(body) {
  return Post(`${urls.identity.users.v1}/activate`, body);
}
export async function getActivateAccountStrategy(params) {
  return Get(`${urls.identity.users.v1}/activate/strategy`, params);
}
export async function resendActivationEmail(body) {
  return Post(`${urls.identity.users.v1}/activate/reset`, body);
}
export async function resendInvitationEmail(body) {
  return Post(`${urls.identity.users.v1}/invitation/reset`, body);
}
export async function acceptInvitation(body) {
  return Post(`${urls.identity.users.v1}/invitation/accept`, body);
}
export async function refreshToken() {
  const data = await Post(`${urls.identity.auth.v1}/user/token/refresh`);
  return generateLoginResponse(data);
}
export async function refreshTokenV2() {
  const data = await Post(`${urls.identity.auth.v1}/user/token/refresh`);
  return generateLoginResponseV2(data);
}
export async function logout() {
  return Post(`${urls.identity.auth.v1}/logout`);
}
export async function OAuthLogout(params) {
  return Get(`${urls.oauth.v1}/logout`, params);
}
export async function forgotPassword(body) {
  return Post(`${urls.identity.users.v1}/passwords/reset`, body);
}
export async function resetPassword(body) {
  return Post(`${urls.identity.users.v1}/passwords/reset/verify`, body);
}
export async function loadPasswordConfig(params) {
  return Get(`${urls.identity.users.v1}/passwords/config`, params);
}
export async function recoverMfaToken(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/recover`, body);
}
export async function enrollMfa() {
  return Post(`${urls.identity.users.v1}/mfa/enroll`);
}
export async function preEnrollMFAAuthenticatorApp() {
  return Post(`${urls.identity.users.v1}/mfa/authenticator/enroll`);
}
export async function enrollMFAAuthenticatorApp(body) {
  return Post(`${urls.identity.users.v1}/mfa/authenticator/enroll/verify`, body);
}
export async function preEnrollMFASMS(body) {
  return Post(`${urls.identity.users.v1}/mfa/sms/enroll`, body);
}
export async function enrollMFASMS(body) {
  return Post(`${urls.identity.users.v1}/mfa/sms/enroll/verify`, body);
}
export async function preEnrollMFAWebAuthn() {
  return Post(`${urls.identity.users.v1}/mfa/webauthn/enroll`);
}
export async function enrollMFAWebAuthn(body) {
  return Post(`${urls.identity.users.v1}/mfa/webauthn/enroll/verify`, body);
}
export async function verifyMfa(body) {
  return Post(`${urls.identity.users.v1}/mfa/enroll/verify`, body);
}
export async function disableMfa(body) {
  return Post(`${urls.identity.users.v1}/mfa/disable`, body);
}
export async function disableMFAAuthenticatorApp(deviceId, body) {
  return Post(`${urls.identity.users.v1}/mfa/authenticator/${deviceId}/disable/verify`, body);
}
export async function preDisableMFASMS(deviceId) {
  return Post(`${urls.identity.users.v1}/mfa/sms/${deviceId}/disable`);
}
export async function disableMFASMS(deviceId, body) {
  return Post(`${urls.identity.users.v1}/mfa/sms/${deviceId}/disable/verify`, body);
}
export async function preDisableMFAWebAuthn(deviceId) {
  return Post(`${urls.identity.users.v1}/mfa/webauthn/${deviceId}/disable`);
}
export async function disableMFAWebAuthn(deviceId, body) {
  return Post(`${urls.identity.users.v1}/mfa/webauthn/${deviceId}/disable/verify`, body);
}
export async function getMFADevices() {
  return Get(`${urls.identity.users.v1}/mfa/devices`);
}
export async function preEnrollMFAAuthenticatorAppForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/authenticator/enroll`, body);
}
export async function enrollMFAAuthenticatorAppForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/authenticator/enroll/verify`, body);
}
export async function verifyMFAAuthenticatorAppForLogin(deviceId, body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/authenticator/${deviceId}/verify`, body);
}
export async function preEnrollMFASMSForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/sms/enroll`, body);
}
export async function enrollMFASMSForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/sms/enroll/verify`, body);
}
export async function preVerifyMFASMSForLogin(deviceId, body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/sms/${deviceId}`, body);
}
export async function verifyMFASMSForLogin(deviceId, body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/sms/${deviceId}/verify`, body);
}
export async function preEnrollMFAWebAuthnForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/webauthn/enroll`, body);
}
export async function enrollMFAWebAuthnForLogin(body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/webauthn/enroll/verify`, body);
}
export async function preVerifyMFAWebAuthnForLogin(deviceId, body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}`, body);
}
export async function verifyMFAWebAuthnForLogin(deviceId, body) {
  return Post(`${urls.identity.auth.v1}/user/mfa/webauthn/${deviceId}/verify`, body);
}
export async function getSamlConfiguration() {
  return Get(`${urls.team.sso.v1}/saml/configurations`);
}
export async function updateSamlConfiguration(body) {
  return Post(`${urls.team.sso.v1}/saml/configurations`, body);
}
export async function getSamlVendorConfiguration() {
  return Get(`${urls.team.sso.v1}/saml/configurations/vendor-config`);
}
export async function updateSamlVendorMetadata(body) {
  return Put(`${urls.team.sso.v1}/saml/configurations/metadata`, body);
}
export async function validateSamlDomain() {
  return Put(`${urls.team.sso.v1}/saml/validations/domain`);
}
export async function getSamlRoles() {
  return Get(`${urls.team.sso.v1}/saml/configurations/roles/default`);
}
export async function getSamlRolesGroups() {
  return Get(`${urls.team.sso.v2}/saml/configurations/groups`);
}
export async function updateSamlRoles({
  roleIds
}) {
  return Post(`${urls.team.sso.v1}/saml/configurations/roles/default`, {
    roleIds
  });
}
export async function createSamlGroup({
  roleIds,
  group
}) {
  return Post(`${urls.team.sso.v2}/saml/configurations/groups`, {
    group,
    roleIds
  });
}
export async function updateSamlGroup({
  roleIds,
  group,
  id
}) {
  return Patch(`${urls.team.sso.v2}/saml/configurations/groups/${id}`, {
    group,
    roleIds
  });
}
export async function deleteSamlGroup({
  id
}) {
  return Delete(`${urls.team.sso.v2}/saml/configurations/groups/${id}`);
}
export async function getOidcConfiguration() {
  return Get(`${urls.team.sso.v1}/oidc/configurations`);
}
export async function getSSOPublicConfiguration() {
  return Get(`${urls.team.sso.v2}/configurations/public`);
}
export async function getSocialLoginProviders() {
  return Get(urls.identity.sso.v1);
}
export async function getSocialLoginProvidersV2() {
  return Get(urls.identity.sso.v2);
}
export async function getCustomSocialLoginProvidersV1() {
  return Get(urls.identity.sso.custom.v1);
}
export async function loginViaSocialLogin({
  provider,
  code,
  idToken,
  redirectUri,
  codeVerifier,
  metadata,
  invitationToken,
  state
}) {
  const params = {};

  if (code) {
    params.code = code;
  }

  if (idToken) {
    params.id_token = idToken;
  }

  if (redirectUri) {
    params.redirectUri = redirectUri;
  }

  if (codeVerifier) {
    params.code_verifier = codeVerifier;
  }

  if (state) {
    params.state = state;
  }

  return Post(`${urls.identity.auth.v1}/user/sso/${provider}/postlogin`, {
    metadata,
    invitationToken
  }, {
    params
  });
}
export async function getVendorConfig() {
  return Get(`${urls.identity.configurations.v1}/public`);
}
export async function signUpUser(body) {
  const {
    shouldActivate,
    authResponse,
    userId,
    tenantId
  } = await Post(`${urls.identity.users.v1}/signUp`, body);
  const loginResponse = !shouldActivate && authResponse ? await generateLoginResponse(authResponse) : undefined;
  return {
    shouldActivate,
    user: loginResponse,
    userId,
    tenantId
  };
}
export async function getCurrentUserSessions() {
  return Get(urls.identity.users.sessions.currentUser.v1);
}
export async function getCurrentUserSession() {
  return Get(`${urls.identity.users.sessions.currentUser.v1}/current`);
}
export async function revokeSessionsForUser(userId) {
  return Post(`${urls.identity.users.sessions.v1}/revoke`, {
    userId
  });
}
export async function getSessionConfigurations() {
  return Get(urls.identity.users.sessions.configurations.v1);
}
export async function createOrUpdateSessionConfigrations(body) {
  await Post(urls.identity.users.sessions.configurations.v1, body);
}
export async function deleteSessionForUser(id) {
  await Delete(`${urls.identity.users.sessions.currentUser.v1}/${id}`);
}
export async function deleteAllSessionsForUser() {
  await Delete(`${urls.identity.users.sessions.currentUser.v1}/all`);
}
export async function getUserAccessTokensData() {
  return Get(urls.identity.users.accessTokens.v1);
}
export async function getTenantAccessTokensData() {
  return Get(urls.identity.tenants.accessTokens.v1);
}
export async function deleteTenantAccessToken({
  id
}) {
  return Delete(`${urls.identity.tenants.accessTokens.v1}/${id}`);
}
export async function deleteUserAccessToken({
  id
}) {
  return Delete(`${urls.identity.users.accessTokens.v1}/${id}`);
}
export async function createUserAccessToken(body) {
  return Post(urls.identity.users.accessTokens.v1, body);
}
export async function createTenantAccessToken(body) {
  return Post(urls.identity.tenants.accessTokens.v1, body);
}
export async function getUserApiTokensData() {
  return Get(urls.identity.users.apiTokens.v1);
}
export async function getTenantApiTokensData() {
  return Get(urls.identity.tenants.apiTokens.v1);
}
export async function updateUserApiTokensData(body) {
  return Post(urls.identity.users.apiTokens.v1, body);
}
export async function updateTenantApiTokensData(body) {
  return Post(urls.identity.tenants.apiTokens.v2, body);
}
export async function deleteTenantApiToken({
  tokenId
}) {
  return Delete(`${urls.identity.tenants.apiTokens.v1}/${tokenId}`);
}
export async function deleteUserApiToken({
  tokenId
}) {
  return Delete(`${urls.identity.users.apiTokens.v1}/${tokenId}`);
}
export async function getUserById({
  userId
}) {
  return Get(`${urls.identity.users.v1}/${userId}`);
}
export async function checkIfAllowToRememberMfaDevice(mfaToken) {
  return Get(`${urls.identity.configurations.v1}/mfa-policy/allow-remember-device`, {
    mfaToken
  });
}
export async function passwordlessPreLogin(_ref) {
  let {
    type
  } = _ref,
      body = _objectWithoutPropertiesLoose(_ref, _excluded);

  return Post(`${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/prelogin`, body);
}
export async function passwordlessPostLogin(_ref2) {
  let {
    type
  } = _ref2,
      body = _objectWithoutPropertiesLoose(_ref2, _excluded2);

  return Post(`${urls.identity.auth.v1}/passwordless/${type.toLocaleLowerCase()}/postlogin`, body);
}
export async function verifyInviteToken({
  token
}) {
  return Post(urls.identity.tenants.invites.verify.v1, {
    token
  });
}
export async function getSSOConfigurations() {
  return Get(`${urls.team.sso.v1}/configurations`);
}
export async function createSSOConfiguration(body) {
  return Post(`${urls.team.sso.v1}/configurations`, body);
}
export async function updateSSOConfiguration(ssoConfigId, body) {
  return Patch(`${urls.team.sso.v1}/configurations/${ssoConfigId}`, body);
}
export async function deleteSSOConfiguration(ssoConfigId) {
  return Delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}`);
}
export async function createSSOConfigurationByMetadata(body) {
  return Post(`${urls.team.sso.v1}/configurations/metadata`, body);
}
export async function updateSSOConfigurationByMetadata(ssoConfigId, body) {
  return Put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/metadata`, body);
}
export async function createSSODomain(ssoConfigId, body) {
  return Post(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains`, body);
}
export async function deleteSSODomain(ssoConfigId, domainId) {
  return Delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains/${domainId}`);
}
export async function validateSSODomain(ssoConfigId, domainId) {
  return Put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/domains/${domainId}/validate`);
}
export async function getSSODefaultRoles(ssoConfigId) {
  return Get(`${urls.team.sso.v1}/configurations/${ssoConfigId}/roles`);
}
export async function setSSODefaultRoles(ssoConfigId, body) {
  return Put(`${urls.team.sso.v1}/configurations/${ssoConfigId}/roles`, body);
}
export async function createSSOGroup(ssoConfigId, body) {
  return Post(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups`, body);
}
export async function updateSSOGroup(ssoConfigId, {
  roleIds,
  group,
  id
}) {
  return Patch(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups/${id}`, {
    group,
    roleIds
  });
}
export async function deleteSSOGroup(ssoConfigId, groupId) {
  return Delete(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups/${groupId}`);
}
export async function getSSOGroups(ssoConfigId) {
  return Get(`${urls.team.sso.v1}/configurations/${ssoConfigId}/groups`);
}
export async function preLoginV2(body) {
  return Post(`${urls.identity.auth.v2}/user/sso/prelogin`, body);
}
export async function oidcPostLoginV2(body) {
  const data = await Post(`${urls.identity.auth.v2}/user/oidc/postlogin`, body);
  return generateLoginResponse(data);
}
export async function exchangeOAuthTokens(body) {
  const data = await Post(`${urls.oauth.v1}/token`, body);
  return generateLoginResponseFromOAuthResponse(data);
}
export async function silentOAuthRefreshToken() {
  const data = await Post(`${urls.oauth.v1}/authorize/silent`);
  return generateLoginResponseFromOAuthResponse(data);
}
export async function resetPhoneNumber(body) {
  return Post(`${urls.identity.users.v1}/phone/reset`, body);
}
export async function verifyResetPhoneNumber(body) {
  return Post(`${urls.identity.users.v1}/phone/reset/verify`, body);
}
export async function changePhoneNumberWithVerification(body) {
  return Post(`${urls.identity.users.v1}/phone`, body);
}
export async function verifyChangePhoneNumber(body) {
  return Post(`${urls.identity.users.v1}/phone/verify`, body);
}
export async function changePhoneNumber(body) {
  return Put(`${urls.identity.users.v1}/phone/change`, body);
}
export async function webAuthnPreLogin(body) {
  return Post(`${urls.identity.auth.v1}/webauthn/prelogin`, body);
}
export async function webAuthnPostLogin(body) {
  return Post(`${urls.identity.auth.v1}/webauthn/postlogin`, body);
}
export async function webAuthnCreateNewDeviceSession() {
  return Post(urls.identity.webAuthnDevices.v1);
}
export async function getWebAuthnDevices() {
  return Get(urls.identity.webAuthnDevices.v1);
}
export async function deleteWebAuthnDevice(deviceId) {
  return Delete(`${urls.identity.webAuthnDevices.v1}/${deviceId}`);
}
export async function verifyNewDeviceSession(body) {
  return Post(`${urls.identity.webAuthnDevices.v1}/verify`, body);
}
export async function getVendorPublicAuthStrategiesConfig() {
  return Get(`${urls.identity.configurations.v1}/auth/strategies/public`);
}
export async function getMFAStrategiesConfig() {
  return Get(`${urls.identity.configurations.v1}/mfa/strategies`);
}