import './ActionUnpauseModal.css';

import { FunctionComponent } from 'react';

import Modal from '../../components/Modal/Modal';

export type ActionUnpauseModalProps = {};

const ActionUnpauseModal: FunctionComponent<ActionUnpauseModalProps> = () => {
  return (
    <Modal title="Reactivate Profile" showHeart={true} returnURL="/">
      <div className="unpause-pofile-modal">
        <p className="p">
          It looks like you are not currently in an active Voco match. This
          could be for a number of reasons, perhaps you were an early Voco
          member and we didn't have a great match for you (sorry!), perhaps we
          lost contact with you or perhaps you and your previous match just ran
          out of steam!
        </p>
        <p className="p">
          Whatever the reason, we would love to have you as an active member of
          the community. If you are keen to meet new Voco members please email{' '}
          <a
            className="link"
            href="mailto:hello@joinvoco.io?subject=Please%20Reactivate%20My%20Account">
            hello@joinvoco.io
          </a>{' '}
          and we will get things moving!
        </p>
      </div>
    </Modal>
  );
};

export default ActionUnpauseModal;
