import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Member } from '../models/Member';

const GET_PROFILE = gql`
  query {
    me {
      id
      introText
      penPortrait
      currentEmployer
      currentJobTitle
      careerStartYear
      functionalArea
      careerInterests
      membershipActive
      careerMotivators {
        title
        score
      }
    }
  }
`;

const UPDATE_INTRO_TEXT = gql`
  mutation updateIntroText($me: MemberInput) {
    updateMe(me: $me) {
      me {
        id
        introText
      }
      success
    }
  }
`;

const UPDATE_EMPLOYMENT = gql`
  mutation updateIntroText($me: MemberInput) {
    updateMe(me: $me) {
      me {
        id
        currentEmployer
        currentJobTitle
        careerStartYear
        functionalArea
      }
      success
    }
  }
`;

export function useProfile() {
  const [profile, setProfile] = useState<Member>();
  const { data, loading } = useQuery(GET_PROFILE);
  const [updateIntroTextMutation, updateIntroTextData] = useMutation(
    UPDATE_INTRO_TEXT,
    {
      update: (
        cache,
        {
          data: {
            updateMe: {
              me: { introText },
              success,
            },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_PROFILE });

        // If the cache is empty or we failed to update
        if (!success || !cachedRequest?.me?.introText) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_PROFILE,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              introText,
            },
          },
        });
      },
    },
  );

  const [updateEmploymentMutation, updateEmploymentTextData] = useMutation(
    UPDATE_EMPLOYMENT,
    {
      update: (
        cache,
        {
          data: {
            updateMe: {
              me: {
                currentEmployer,
                currentJobTitle,
                careerStartYear,
                functionalArea,
              },
              success,
            },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_PROFILE });

        // If the cache is empty or we failed to update
        if (!success || !cachedRequest?.me?.introText) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_PROFILE,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              currentEmployer,
              currentJobTitle,
              careerStartYear,
              functionalArea,
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    if (data?.me) {
      setProfile(new Member(data.me));
    }
  }, [data]);

  return {
    profile,
    updateIntroTextMutation,
    updateEmploymentMutation,
    profileLoading: loading,
    updatingIntroText: updateIntroTextData.loading,
    updatingEmployment: updateEmploymentTextData.loading,
  };
}
