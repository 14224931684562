import './TextSection.css';
import { FunctionComponent } from 'react';

export type TextSectionProps = {
  body?: string;
  className?: string;
};

const TextSection: FunctionComponent<TextSectionProps> = ({
  body,
  className,
}) => {
  return <div className={`text-section ${className}`}>{body}</div>;
};

export default TextSection;
