import dayjs, { Dayjs } from 'dayjs';
import iconAlert from '../svgs/icon-action-alert.svg';
import iconCalendar from '../svgs/icon-action-calendar.svg';
import iconFeedback from '../svgs/icon-action-feedback.svg';
import iconPlay from '../svgs/icon-action-play.svg';
import iconGlasses from '../svgs/icon-action-glasses.svg';
import iconHand from '../svgs/icon-action-hand.svg';
import iconProfile from '../svgs/icon-action-profile.svg';
import iconStar from '../svgs/icon-action-star.svg';
import { Member } from './Member';

export class Action {
  static JOIN_CALL = 'ACTION- Join Call';
  static NEW_MATCH = 'ACTION- New Match Schedule';
  static SCHEDULE = 'ACTION- Schedule';
  static FEEDBACK = 'ACTION- Feedback';
  static RESCHEDULE = 'ACTION- Reschedule';
  static WATCH = 'ACTION- Watch';
  static READ = 'ACTION- Read';
  static JOIN_IN = 'ACTION- Join In';
  static COMPLETE = 'ACTION- Complete Profile';
  static PAUSED = 'Action- Paused';

  static ACTION_TYPES = [
    this.JOIN_CALL,
    this.NEW_MATCH,
    this.SCHEDULE,
    this.FEEDBACK,
    this.RESCHEDULE,
    this.WATCH,
    this.READ,
    this.JOIN_IN,
    this.COMPLETE,
    this.PAUSED,
  ];

  static isAction(data: any) {
    if (data?.journal_type && this.ACTION_TYPES.includes(data.journal_type)) {
      return true;
    }
    return false;
  }

  id: string;
  date: Dayjs;
  text: string;
  description: string;
  actionType: string;
  url: string;
  createdAt: Dayjs;
  relatedMember?: Member;

  constructor(data: any) {
    this.id = data.id;
    this.date = dayjs(data.date);
    this.text = data.text;
    this.description = data.description;
    this.actionType = data.actionType;
    this.url = data.url;
    this.createdAt = dayjs(data.createdAt);
    this.relatedMember = data.relatedMember
      ? new Member(data.relatedMember)
      : undefined;
  }

  public get icon(): string {
    switch (this.actionType) {
      case Action.JOIN_CALL:
        return iconAlert;
      case Action.NEW_MATCH:
        return iconStar;
      case Action.SCHEDULE:
        return iconCalendar;
      case Action.FEEDBACK:
        return iconFeedback;
      case Action.RESCHEDULE:
        return iconCalendar;
      case Action.WATCH:
      case Action.PAUSED:
        return iconPlay;
      case Action.READ:
        return iconGlasses;
      case Action.JOIN_IN:
        return iconHand;
      case Action.COMPLETE:
        return iconProfile;
      default:
        return iconStar;
    }
  }

  public get linkTo(): string | { pathname: string } {
    if (this.isUnpausable) {
      return `/actions/${this.id}/unpause`;
    }
    return { pathname: this.url };
  }

  public get linkText(): string {
    return this.text;
  }

  public get isUnpausable(): boolean {
    return [Action.PAUSED].includes(this.actionType);
  }

  public get isDeletable(): boolean {
    return [
      Action.WATCH,
      Action.READ,
      Action.JOIN_IN,
      Action.FEEDBACK,
      Action.SCHEDULE,
      Action.PAUSED,
    ].includes(this.actionType);
  }

  public get isReschedulable(): boolean {
    return [Action.JOIN_CALL].includes(this.actionType);
  }

  public get rescheduleURL(): string | undefined {
    const matcher = /\/meeting\/(\w*)/i;
    if (matcher.test(this.url)) {
      const components = matcher.exec(this.url);
      if (components && components.length > 1) {
        return `/meeting/${components[1]}/reschedule`;
      }
    }
    return `/actions/${this.id}/reschedule`;
  }

  public get hasSecondaryAction(): boolean {
    return this.isDeletable || this.isReschedulable;
  }
}
