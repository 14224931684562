import './MembersPage.css';

import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ContentSection from '../../../components/ContentSection/ContentSection';

import { useMembers } from '../../../hooks/useMembers';
import Button from '../../../components/Button/Button';
import LabelSelect from '../../../components/LabelSelect/LabelSelect';
import { useMatcherKeys } from '../../../hooks/useMatcherKeys';
import LabelInput from '../../../components/LabelInput/LabelInput';
import { Member } from '../../../models/Member';
import LinkButton from '../../../components/Button/LinkButton';

export type MembersPageProps = {};

const ALL_LABEL = 'All';

const MembersPage: FunctionComponent<MembersPageProps> = () => {
  const { membersMatcherKeys } = useMatcherKeys();
  const {
    members,
    membersLoading,
    loadMembers,
    scoreAllUsers,
    scoreAllUsersCooldown,
  } = useMembers();
  const [filteredMembers, setFilteredMembers] = useState<
    Member[] | undefined
  >();
  const [search, setSearch] = useState<string | undefined>();
  const { matcherKey } = useParams();
  const navigate = useNavigate();
  const [getMatches, setGetMatches] = useState(false);

  useEffect(() => {
    if (matcherKey) {
      loadMembers({ variables: { matcherKey } });
    } else {
      loadMembers();
    }
  }, [loadMembers, matcherKey]);

  useEffect(() => setFilteredMembers(members), [members]);

  const matcherKeySelected: ChangeEventHandler = (event) => {
    const { value } = event.target as HTMLSelectElement;
    if (value && value !== ALL_LABEL) {
      navigate(`/admin/${value}/members`);
    } else {
      navigate('/admin/members');
    }
  };

  useEffect(() => {
    if (members && search) {
      setFilteredMembers([
        ...members?.filter((m) => m.fullName.toLowerCase().includes(search)),
      ]);
    } else {
      setFilteredMembers(members);
    }
  }, [members, search]);

  const runHorseTrade = async () => {
    if (!matcherKey) {
      return;
    }
    setGetMatches(true);
    const token = localStorage.getItem('AUTH_TOKEN');
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/horse-trade`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ matcherKey }),
      },
    );
    const url = await response.text();
    window.open(url, '_blank');
    setGetMatches(false);
  };

  return (
    <ContentSection
      subSection={true}
      title="Members"
      fullWidth={true}
      loading={membersLoading}
      className="match-queue-page">
      <div className="match-queue-page__controls">
        {membersMatcherKeys ? (
          <LabelSelect
            label="Select Algo View"
            name="selectedAlgo"
            optionsArray={[ALL_LABEL, ...membersMatcherKeys]}
            value={matcherKey || ALL_LABEL}
            onChange={matcherKeySelected}
          />
        ) : null}
        <LabelInput
          label="Search"
          name="search"
          value={search}
          onChange={(e) =>
            setSearch((e.target as HTMLInputElement).value.toLowerCase())
          }
        />
        <LinkButton
          className="match-queue-page__clear-search"
          label="✖︎"
          action={() => setSearch('')}
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Member Since</th>
            <th>Function</th>
            <th>Years Experience</th>
            <th>Cohort</th>
            <th>Requested Match Date</th>
            <th>Total Matches</th>
            <th>Total Approved Matches</th>
            <th>Needs Match</th>
            <th>Last Scored</th>
          </tr>
        </thead>

        <tbody>
          {filteredMembers?.map((member) => (
            <tr key={member.id}>
              <td>
                <Link
                  className="link"
                  to={
                    matcherKey
                      ? `/admin/${matcherKey}/members/member/${member.id}`
                      : `/admin/members/member/${member.id}`
                  }>
                  {member.fullName}
                </Link>
              </td>
              <td>{member.memberSince?.format('DD/MM/YYYY')}</td>
              <td>{member.functionalArea}</td>
              <td>
                {member.careerStartYear
                  ? new Date().getFullYear() -
                    parseInt(member.careerStartYear, 10)
                  : null}
              </td>
              <td>{member.cohort}</td>
              <td>{member.addNewMatchesDate?.format('DD/MM/YYYY')}</td>
              <td>{member.totalMatches}</td>
              <td>{member.totalApprovedMatches}</td>
              <td>{member.seekingMatch ? 'Yes' : 'No'}</td>
              <td
                title={`Score run at: ${member.scoreDate?.format(
                  'DD/MM/YYYY HH:mm:ss',
                )}`}>
                {member.scoreDate?.format('DD/MM/YYYY')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        action={() => scoreAllUsers()}
        label="Rescore All Users"
        local={true}
        className="match-queue-page__button"
        disabled={scoreAllUsersCooldown}
      />
      <Button
        action={() => runHorseTrade()}
        label="Download Suggested Matches"
        className="match-queue-page__button"
        disabled={scoreAllUsersCooldown || getMatches || !matcherKey}
      />
    </ContentSection>
  );
};

export default MembersPage;
