import './InfoMessage.css';

import { FunctionComponent, ReactNode } from 'react';

import helpCalloutRight from '../../svgs/help-callout-right.svg';
import helpCalloutLeft from '../../svgs/help-callout-left.svg';
import Avatar from '../Avatar/Avatar';

export type InfoMessageProps = {
  children: ReactNode;
  rightHanded?: boolean;
  className?: string;
};

const InfoMessage: FunctionComponent<InfoMessageProps> = ({
  children,
  rightHanded = false,
  className,
}) => {
  return (
    <div className={className ? `info-message ${className}` : 'info-message'}>
      {!rightHanded ? <Avatar className="info-message__avatar" /> : null}
      <div
        className={`info-message__message info-message__message-${
          rightHanded ? 'right' : 'left'
        }`}>
        <div
          style={{
            backgroundImage: `url(${
              rightHanded ? helpCalloutRight : helpCalloutLeft
            })`,
          }}
          className={`info-message__message-callout info-message__message-callout-${
            rightHanded ? 'right' : 'left'
          }`}></div>
        {children}
      </div>
      {rightHanded ? <Avatar className="info-message__avatar" /> : null}
    </div>
  );
};

export default InfoMessage;
