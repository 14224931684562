import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Member } from '../models/Member';

const GET_MEMBERS_QUEUE = gql`
  query member($id: ID, $matcherKey: String) {
    member(id: $id) {
      id
      firstName
      lastName
      functionalArea
      requestedMatchesCount
      totalMatches
      memberSince
      addNewMatchesDate
      linkedIn
      timezone
      sector
      careerStartYear
      jobLevel
      orgSize
      context
      dependents
      source
      appId
      cohort
      lastMeeting
      matches {
        id
        firstName
        lastName
        linkedIn
        timezone
        appId
        source
        cohort
        requestedMatchesCount
        totalMatches
        lastMeeting
        lastMatchMeeting
        matchStatus
      }
      matchQueue(matcherKey: $matcherKey) {
        id
        member {
          id
          firstName
          lastName
          linkedIn
          timezone
          appId
          source
          cohort
          requestedMatchesCount
          totalMatches
          lastMeeting
        }
        matcherKey
        scoreDate
        score
        meta {
          careerLengthScore
          careerLengthScore
          contextScore
          sectorScore
          functionalAreaScore
          introvertExtrovertScore
          plannerScore
          interestScore
          seniorityScore
          motivatorScore
        }
      }
      approvedMatches(matcherKey: $matcherKey) {
        id
        member {
          id
          firstName
          lastName
          linkedIn
          timezone
          appId
          source
          cohort
          requestedMatchesCount
          totalMatches
          lastMeeting
        }
        matcherKey
        scoreDate
        score
        decision
        decisionNotes
        meta {
          careerLengthScore
          careerLengthScore
          contextScore
          sectorScore
          functionalAreaScore
          introvertExtrovertScore
          plannerScore
          interestScore
          seniorityScore
          motivatorScore
        }
      }
      rejectedMatches(matcherKey: $matcherKey) {
        id
        member {
          id
          firstName
          lastName
          linkedIn
          timezone
          appId
          source
          cohort
          requestedMatchesCount
          totalMatches
          lastMeeting
        }
        matcherKey
        scoreDate
        score
        decision
        decisionNotes
        meta {
          careerLengthScore
          careerLengthScore
          contextScore
          sectorScore
          functionalAreaScore
          introvertExtrovertScore
          plannerScore
          interestScore
          seniorityScore
          motivatorScore
        }
      }
      matchedMatches(matcherKey: $matcherKey) {
        id
        member {
          id
        }
        matcherKey
        scoreDate
        score
        decision
        decisionNotes
        meta {
          careerLengthScore
          careerLengthScore
          contextScore
          sectorScore
          functionalAreaScore
          introvertExtrovertScore
          plannerScore
          interestScore
          seniorityScore
          motivatorScore
        }
      }
    }
  }
`;

const SCORE_MEMBER = gql`
  mutation scoreMember($memberId: ID) {
    scoreUsers(memberId: $memberId) {
      id
      success
    }
  }
`;

const MATCH_DECISION = gql`
  mutation matchDecision($matchDecisionInput: MatchDecisionInput!) {
    matchDecision(matchDecisionInput: $matchDecisionInput) {
      success
    }
  }
`;

const MAKE_MATCH = gql`
  mutation makeMatch($makeMatchInput: MakeMatchInput!) {
    makeMatch(makeMatchInput: $makeMatchInput) {
      success
    }
  }
`;

const NEEDS_MATCH = gql`
  mutation memberAddMatch($memberId: ID!, $requestMatch: Boolean) {
    memberAddMatch(memberId: $memberId, requestMatch: $requestMatch) {
      success
    }
  }
`;

export default function useMember(id?: string, matcherKey?: string) {
  const { loading, data, refetch } = useQuery(GET_MEMBERS_QUEUE, {
    variables: { id, matcherKey },
    fetchPolicy: 'cache-and-network',
  });

  const [scoreMemberCooldown, setScoreMemberCooldown] = useState(false);
  const [scoreMemberMutation, scoreMemberMutationData] =
    useMutation(SCORE_MEMBER);

  const [matchDecisionMutation, matchDecisionMutationData] =
    useMutation(MATCH_DECISION);

  const [makeMatchMutation, makeMatchMutationData] = useMutation(MAKE_MATCH);
  const [needsMatchMutation, needsMatchMutationData] = useMutation(NEEDS_MATCH);

  const [member, setMember] = useState<Member>();

  const scoreMember = useCallback(async () => {
    if (!scoreMemberCooldown) {
      setScoreMemberCooldown(true);
      // cool down for 30 seconds
      setTimeout(() => {
        setScoreMemberCooldown(false);
        refetch({ id, matcherKey });
      }, 10 * 1000);
      await scoreMemberMutation({ variables: { memberId: id } });
    }
  }, [scoreMemberCooldown, scoreMemberMutation, refetch, id, matcherKey]);

  const matchDecision = async (
    matchScoreId: string,
    decision: string,
    notes?: string,
    matcherKey?: string,
  ) => {
    if (['approve', 'reject', 'requeue'].includes(decision)) {
      await matchDecisionMutation({
        variables: { matchDecisionInput: { matchScoreId, decision, notes } },
      });

      await refetch({ id, matcherKey });
    }
  };

  const makeMatch = async (matchScoreId: string) => {
    await makeMatchMutation({
      variables: { makeMatchInput: { matchScoreId } },
    });
    await refetch({ id, matcherKey });
  };

  const needsMatch = async (memberId: string, requestMatch: boolean) => {
    await needsMatchMutation({
      variables: { memberId, requestMatch },
    });
    await refetch({ id, matcherKey });
  };

  useEffect(() => {
    if (data) {
      setMember(new Member(data.member));
    }
  }, [data]);

  return {
    member: member,
    memberLoading: loading,
    refetchMember: refetch,
    scoreMemberCooldown,
    scoreMember,
    scoreMemberLoading: scoreMemberMutationData.loading,
    matchDecision,
    matchDecisionLoading: matchDecisionMutationData.loading,
    makeMatch,
    makeMatchLoading: makeMatchMutationData.loading,
    needsMatch,
    needsMatchLoading: needsMatchMutationData.loading,
  };
}
