export class Guide {
  id?: string;
  name?: string;
  slug?: string;
  mainImageURL?: string;
  shortDescription?: string;
  body?: string;
  publishedAt?: Date;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.mainImageURL = data.mainImageURL;
    this.shortDescription = data.shortDescription;
    this.body = data.body;
    this.publishedAt = data.publishedAt;
  }
}
