import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["eventKey"],
      _excluded2 = ["eventKey"];
import { urls } from '../constants';
import { Get, Patch, Post, Delete, Put } from '../fetch';
export const getSlackConfiguration = () => {
  return Get(`${urls.integrations.configurations.v1}/slack`);
};
export const getSlackScope = () => {
  return Get(`${urls.integrations.configurations.v1}/slack/applications`);
};
export const getSlackChannels = () => {
  return Get(`${urls.integrations.configurations.v1}/slack/channels`);
};
export const postSlackConfiguration = data => {
  if (data.id) {
    return Patch(`${urls.integrations.configurations.v1}/slack/subscription/${data.id}`, data);
  }

  return Post(`${urls.integrations.configurations.v1}/slack/subscriptions`, data);
};
export const deleteSlackConfiguration = data => {
  return Delete(`${urls.integrations.configurations.v1}/slack/subscriptions/${data.id}`);
};
export const postSlackCode = code => {
  return Post(`${urls.integrations.configurations.v1}/slack/applications/registrations`, {
    code
  });
};
export const getEmailConfiguration = () => {
  return Get(`${urls.integrations.configurations.v1}/emails`);
};
export const postEmailConfiguration = _ref => {
  let {
    eventKey
  } = _ref,
      data = _objectWithoutPropertiesLoose(_ref, _excluded);

  return Post(`${urls.integrations.configurations.v1}/emails/${eventKey}`, data);
};
export const patchEmailConfiguration = ({
  eventKey,
  enabled
}) => {
  return Patch(`${urls.integrations.configurations.v1}/emails/${eventKey}`, {
    enabled
  });
};
export const deleteEmailSubscriptions = (eventKey, subscriptionId) => {
  return Delete(`${urls.integrations.configurations.v1}/emails/${eventKey}/subscriptions/${subscriptionId}`);
};
export const putEmailSubscriptions = (subscriptionId, eventKey, data) => {
  return Put(`${urls.integrations.configurations.v1}/emails/${eventKey}/subscriptions/${subscriptionId}`, data);
};
export const deleteEmailConfiguration = eventKey => {
  return Delete(`${urls.integrations.configurations.v1}/emails/${eventKey}`);
};
export const getSMSConfiguration = () => {
  return Get(`${urls.integrations.configurations.v2}/sms`);
};
export const postSMSConfiguration = _ref2 => {
  let {
    eventKey
  } = _ref2,
      data = _objectWithoutPropertiesLoose(_ref2, _excluded2);

  return Post(`${urls.integrations.configurations.v2}/sms/${eventKey}`, data);
};
export const patchSMSConfiguration = ({
  eventKey,
  enabled
}) => {
  return Patch(`${urls.integrations.configurations.v2}/sms/${eventKey}`, {
    enabled
  });
};
export const deleteSMSSubscriptions = (eventKey, subscriptionId) => {
  return Delete(`${urls.integrations.configurations.v2}/sms/${eventKey}/subscriptions/${subscriptionId}`);
};
export const putSMSSubscriptions = (subscriptionId, eventKey, data) => {
  return Put(`${urls.integrations.configurations.v2}/sms/${eventKey}/subscriptions/${subscriptionId}`, data);
};
export const deleteSMSConfiguration = eventKey => {
  return Delete(`${urls.integrations.configurations.v2}/sms/${eventKey}`);
};
export const getWebhooksConfigurations = () => {
  return Get(urls.webhooks.v1);
};
export const postWebhooksConfiguration = data => {
  if (data._id) {
    return Patch(`${urls.webhooks.v1}/${data._id}`, data);
  } else {
    return Post(`${urls.webhooks.v1}/custom`, data);
  }
};
export const deleteWebhooksConfiguration = id => {
  return Delete(`${urls.webhooks.v1}/${id}`);
};
export const getWebhookLog = (id, offset = 0, limit = 10) => {
  const query = new URLSearchParams({
    id,
    offset: `${offset}`,
    limit: `${limit}`
  });
  return Get(`${urls.webhooks.v1}/logs/?${query.toString()}`);
};
export const postWebhookTest = data => {
  return Post(`${urls.webhooks.v1}/test`, data);
};
export const postWebhookRetry = id => {
  return Post(`${urls.webhooks.v1}/logs/${id}/retries`);
};
export const getCategories = () => {
  return Get(`${urls.events.configurations.v1}/categories`);
};
export const getChannelMaps = channels => {
  return Get(urls.events.configurations.v1, {
    channels
  });
};