import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["deviceId", "callback"],
  _excluded5 = ["deviceId", "callback"],
  _excluded6 = ["callback"],
  _excluded7 = ["deviceId"],
  _excluded8 = ["deviceId"],
  _excluded9 = ["callback"],
  _excluded10 = ["deviceId"],
  _excluded11 = ["callback"];
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { MFAStep } from './interfaces';
import { base64urlDecode, delay, publicKeyCredentialToJSON } from '../utils';
import { userDemo } from '../dummy';
function* getMfaStateAfterEnroll(res) {
  const {
    step
  } = yield select(state => state.auth.mfaState);
  const mfaState = {
    step: res.recoveryCode ? MFAStep.recoveryCode : step,
    loading: false,
    saving: false,
    error: undefined,
    recoveryCode: res == null ? void 0 : res.recoveryCode
  };
  return mfaState;
}
function* enrollMfa() {
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    const {
      qrCode
    } = yield call(api.auth.enrollMfa);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      qrCode
    }));
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
  }
}
function* verifyMfa(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    const user = yield select(state => state.auth.user);
    const data = yield call(api.auth.verifyMfa, payload);
    const mfaDevices = yield call(api.auth.getMFADevices);
    const mfaState = yield getMfaStateAfterEnroll(data);
    yield put(actions.setMfaState(_extends({}, mfaState, {
      mfaDevices
    })));
    yield put(actions.setUser(_extends({}, user, {
      mfaEnrolled: true
    })));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* verifyMfaAfterForce(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setMfaState({
    loading: true
  }));
  try {
    const user = yield call(api.auth.loginWithMfa, payload);
    const mfaState = {
      step: MFAStep.recoveryCode,
      loading: false,
      saving: false,
      error: undefined
    };
    if (user != null && user.recoveryCode) {
      mfaState.recoveryCode = user.recoveryCode;
    }
    yield put(actions.setMfaState(mfaState));
    yield put(actions.setState({
      user
    }));
    yield put(actions.loadTenants());
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  } catch (e) {
    yield put(actions.setMfaState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* disableMfa({
  payload
}) {
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _payload$callback;
    yield call(api.auth.disableMfa, payload);
    const mfaDevices = yield call(api.auth.getMFADevices);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      mfaDevices
    }));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
  }
}
function* preEnrollMfaWebAuthn({
  payload
}) {
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _data$options$exclude, _payload$callback3;
    const data = yield call(api.auth.preEnrollMFAWebAuthn);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      user: _extends({}, data.options.user, {
        id: base64urlDecode(data.options.user.id)
      }),
      excludeCredentials: (_data$options$exclude = data.options.excludeCredentials) == null ? void 0 : _data$options$exclude.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(actions.setMfaState({
      saving: false
    }));
    (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, {
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    var _payload$callback4;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
  }
}
function* enrollMfaWebAuthn(_ref3) {
  let {
      payload: {
        callback
      }
    } = _ref3,
    payload = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    const data = yield call(api.auth.enrollMFAWebAuthn, _extends({}, payload, {
      options: publicKey
    }));
    const mfaDevices = yield call(api.auth.getMFADevices);
    const mfaState = yield getMfaStateAfterEnroll(data);
    yield put(actions.setMfaState(_extends({}, mfaState, {
      mfaDevices
    })));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* preDisableMfaWebAuthn(_ref4) {
  let {
      payload: {
        deviceId,
        callback
      }
    } = _ref4,
    payload = _objectWithoutPropertiesLoose(_ref4.payload, _excluded4);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _data$options$allowCr;
    const data = yield call(api.auth.preDisableMFAWebAuthn, deviceId);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      allowCredentials: (_data$options$allowCr = data.options.allowCredentials) == null ? void 0 : _data$options$allowCr.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(actions.setMfaState({
      saving: false
    }));
    callback == null ? void 0 : callback({
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* disableMfaWebAuthn(_ref5) {
  let {
      payload: {
        deviceId,
        callback
      }
    } = _ref5,
    payload = _objectWithoutPropertiesLoose(_ref5.payload, _excluded5);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    yield call(api.auth.disableMFAWebAuthn, deviceId, _extends({}, payload, {
      options: publicKey
    }));
    const mfaDevices = yield call(api.auth.getMFADevices);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      mfaDevices
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* preEnrollMfaSms({
  payload
}) {
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _payload$callback5;
    const res = yield call(api.auth.preEnrollMFASMS, payload);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      otcToken: res.otcToken,
      phoneNumber: res.phoneNumber
    }));
    (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
  } catch (e) {
    var _payload$callback6;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, false, e);
  }
}
function* enrollMfaSms(_ref6) {
  let {
      payload: {
        callback
      }
    } = _ref6,
    payload = _objectWithoutPropertiesLoose(_ref6.payload, _excluded6);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    const data = yield call(api.auth.enrollMFASMS, payload);
    const mfaDevices = yield call(api.auth.getMFADevices);
    const mfaState = yield getMfaStateAfterEnroll(data);
    yield put(actions.setMfaState(_extends({}, mfaState, {
      mfaDevices
    })));
    callback == null ? void 0 : callback(mfaState.recoveryCode);
  } catch (e) {
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* preDisableMfaSms(_ref7) {
  let {
      payload: {
        deviceId
      }
    } = _ref7,
    payload = _objectWithoutPropertiesLoose(_ref7.payload, _excluded7);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _payload$callback7;
    const res = yield call(api.auth.preDisableMFASMS, deviceId);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      otcToken: res.otcToken,
      phoneNumber: res.phoneNumber
    }));
    (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
  } catch (e) {
    var _payload$callback8;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback8 = payload.callback) == null ? void 0 : _payload$callback8.call(payload, false, e);
  }
}
function* disableMfaSms(_ref8) {
  let {
      payload: {
        deviceId
      }
    } = _ref8,
    payload = _objectWithoutPropertiesLoose(_ref8.payload, _excluded8);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _payload$callback9;
    yield call(api.auth.disableMFASMS, deviceId, payload);
    const mfaDevices = yield call(api.auth.getMFADevices);
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      mfaDevices
    }));
    (_payload$callback9 = payload.callback) == null ? void 0 : _payload$callback9.call(payload, true);
  } catch (e) {
    var _payload$callback10;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback10 = payload.callback) == null ? void 0 : _payload$callback10.call(payload, false, e);
  }
}
function* getMFADevices() {
  yield put(actions.setMfaState({
    loading: true
  }));
  try {
    const user = yield select(state => state.auth.user);
    const mfaDevices = yield call(api.auth.getMFADevices);
    yield put(actions.setMfaState({
      loading: false,
      error: undefined,
      mfaDevices
    }));
  } catch (e) {
    yield put(actions.setMfaState({
      loading: false,
      error: e.message
    }));
  }
}
function* getMFAStrategies() {
  yield put(actions.setMfaState({
    loading: true
  }));
  try {
    const res = yield call(api.auth.getMFAStrategiesConfig);
    const mfaStrategies = res.strategies.filter(({
      isActive
    }) => isActive).map(({
      strategy
    }) => strategy);
    yield put(actions.setMfaState({
      loading: false,
      error: undefined,
      mfaStrategies
    }));
  } catch (e) {
    yield put(actions.setMfaState({
      loading: false,
      error: e.message
    }));
  }
}
export function* mfaSagas() {
  yield takeEvery(actions.enrollMfa, enrollMfa);
  yield takeEvery(actions.getMFADevices, getMFADevices);
  yield takeEvery(actions.getMFAStrategies, getMFAStrategies);
  yield takeEvery(actions.verifyMfa, verifyMfa);
  yield takeEvery(actions.disableMfa, disableMfa);
  yield takeEvery(actions.verifyMfaAfterForce, verifyMfaAfterForce);
  yield takeEvery(actions.preDisableMfaSms, preDisableMfaSms);
  yield takeEvery(actions.disableMfaSms, disableMfaSms);
  yield takeEvery(actions.preEnrollMfaSms, preEnrollMfaSms);
  yield takeEvery(actions.enrollMfaSms, enrollMfaSms);
  yield takeEvery(actions.preEnrollMfaWebAuthn, preEnrollMfaWebAuthn);
  yield takeEvery(actions.enrollMfaWebAuthn, enrollMfaWebAuthn);
  yield takeEvery(actions.preDisableMfaWebAuthn, preDisableMfaWebAuthn);
  yield takeEvery(actions.disableMfaWebAuthn, disableMfaWebAuthn);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* enrollMfaMock() {
  yield put(actions.setMfaState({
    saving: true
  }));
  const qrCode = 'test';
  yield put(actions.setMfaState({
    saving: false,
    error: undefined,
    qrCode
  }));
}
function* verifyMfaMock({
  payload: {
    callback
  }
}) {
  const {
    mfaDevices = {
      webauthn: [],
      phones: [],
      authenticators: []
    }
  } = yield select(state => state.auth.mfaState);
  yield put(actions.setMfaState({
    saving: true
  }));
  yield delay();
  const data = {
    token: 'token',
    recoveryCode: 'recoveryCode'
  };
  const mfaState = {
    step: MFAStep.recoveryCode,
    loading: false,
    error: undefined,
    saving: false,
    mfaDevices: _extends({}, mfaDevices, {
      authenticators: [{
        id: 'authenticator-id'
      }]
    })
  };
  if (data != null && data.recoveryCode) {
    mfaState.recoveryCode = data.recoveryCode;
  }
  yield put(actions.setMfaState(mfaState));
  yield put(actions.setUser(_extends({}, userDemo, {
    mfaEnrolled: true
  })));
  callback == null ? void 0 : callback(mfaState.recoveryCode);
}
function* verifyMfaAfterForceMock(_ref9) {
  let {
      payload: {
        callback
      }
    } = _ref9,
    payload = _objectWithoutPropertiesLoose(_ref9.payload, _excluded9);
  yield put(actions.setMfaState({
    loading: true
  }));
  yield delay();
  const data = _extends({}, payload, {
    recoveryCode: 'recoveryCode'
  });
  const mfaState = {
    step: MFAStep.recoveryCode,
    loading: false,
    error: undefined,
    saving: false
  };
  if (data != null && data.recoveryCode) {
    mfaState.recoveryCode = data.recoveryCode;
  }
  yield put(actions.setMfaState(mfaState));
  yield delay();
  yield put(actions.setUser(_extends({}, userDemo, {
    mfaEnrolled: true
  })));
  callback == null ? void 0 : callback(mfaState.recoveryCode);
}
function* disableMfaMock({
  payload
}) {
  var _payload$callback11;
  const {
    mfaDevices = {
      webauthn: [],
      phones: [],
      authenticators: []
    }
  } = yield select(state => state.auth.mfaState);
  yield put(actions.setMfaState({
    saving: true
  }));
  yield delay();
  yield put(actions.setMfaState({
    saving: false,
    error: undefined,
    mfaDevices: _extends({}, mfaDevices, {
      authenticators: []
    })
  }));
  yield put(actions.setUser(_extends({}, userDemo)));
  (_payload$callback11 = payload.callback) == null ? void 0 : _payload$callback11.call(payload, true);
}
function* preDisableMfaSmsMock(_ref10) {
  let {} = _ref10,
    payload = _objectWithoutPropertiesLoose(_ref10.payload, _excluded10);
  yield put(actions.setMfaState({
    saving: true
  }));
  try {
    var _payload$callback12;
    yield put(actions.setMfaState({
      saving: false,
      error: undefined,
      otcToken: 'otc-token',
      phoneNumber: '+972504589346'
    }));
    (_payload$callback12 = payload.callback) == null ? void 0 : _payload$callback12.call(payload, true);
  } catch (e) {
    var _payload$callback13;
    yield put(actions.setMfaState({
      saving: false,
      error: e.message
    }));
    (_payload$callback13 = payload.callback) == null ? void 0 : _payload$callback13.call(payload, false, e);
  }
}
function* disableMfaSmsMock({
  payload
}) {
  var _payload$callback14;
  const {
    mfaDevices = {
      webauthn: [],
      phones: [],
      authenticators: []
    }
  } = yield select(state => state.auth.mfaState);
  yield put(actions.setMfaState({
    saving: true
  }));
  yield delay();
  yield put(actions.setMfaState({
    loading: false,
    error: undefined,
    mfaDevices: _extends({}, mfaDevices, {
      phones: []
    })
  }));
  yield put(actions.setUser(_extends({}, userDemo)));
  (_payload$callback14 = payload.callback) == null ? void 0 : _payload$callback14.call(payload, true);
}
function* preEnrollMfaSmsMock({
  payload
}) {
  var _payload$callback15;
  yield put(actions.setMfaState({
    saving: true
  }));
  yield delay();
  yield put(actions.setMfaState({
    saving: false,
    error: undefined,
    otcToken: 'otc-token',
    phoneNumber: '+972504589346'
  }));
  (_payload$callback15 = payload.callback) == null ? void 0 : _payload$callback15.call(payload, true);
}
function* enrollMfaSmsMock(_ref11) {
  let {
      payload: {
        callback
      }
    } = _ref11,
    payload = _objectWithoutPropertiesLoose(_ref11.payload, _excluded11);
  const {
    mfaDevices = {
      webauthn: [],
      phones: [],
      authenticators: []
    }
  } = yield select(state => state.auth.mfaState);
  yield put(actions.setMfaState({
    saving: true
  }));
  yield delay();
  const data = {
    token: 'token',
    recoveryCode: 'recoveryCode'
  };
  const mfaState = {
    step: MFAStep.recoveryCode,
    loading: false,
    error: undefined,
    saving: false,
    mfaDevices: _extends({}, mfaDevices, {
      phones: [{
        id: 'phone-id',
        phoneNumber: '+972504589346'
      }]
    })
  };
  if (data != null && data.recoveryCode) {
    mfaState.recoveryCode = data.recoveryCode;
  }
  yield put(actions.setMfaState(mfaState));
  yield put(actions.setUser(_extends({}, userDemo, {
    mfaEnrolled: true
  })));
  callback == null ? void 0 : callback(mfaState.recoveryCode);
}
export function* mfaSagasMock() {
  yield takeEvery(actions.enrollMfa, enrollMfaMock);
  yield takeEvery(actions.verifyMfa, verifyMfaMock);
  yield takeEvery(actions.disableMfa, disableMfaMock);
  yield takeEvery(actions.verifyMfaAfterForce, verifyMfaAfterForceMock);
  yield takeEvery(actions.preDisableMfaSms, preDisableMfaSmsMock);
  yield takeEvery(actions.disableMfaSms, disableMfaSmsMock);
  yield takeEvery(actions.preEnrollMfaSms, preEnrollMfaSmsMock);
  yield takeEvery(actions.enrollMfaSms, enrollMfaSmsMock);
}