import './ThemeContent.css';

import { FunctionComponent } from 'react';
import { Theme } from '../../models/Theme';
import TextCard from '../TextCard/TextCard';

export type ThemeContentProps = {
  theme?: Theme;
};

const ThemeContent: FunctionComponent<ThemeContentProps> = ({ theme }) => {
  return (
    <div className="theme-content">
      <TextCard bodyHTML={theme?.introduction} className="theme-content_item" />

      {theme?.agenda?.map((agenda, i) => (
        <TextCard key={i} bodyHTML={agenda} className="theme-content_item" />
      ))}
      {theme?.body?.map((body, i) => (
        <TextCard key={i} bodyHTML={body} className="theme-content_item" />
      ))}
    </div>
  );
};

export default ThemeContent;
