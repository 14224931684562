import './TitleCard.css';
import { FunctionComponent } from 'react';
import Button from '../Button/Button';

export type TitleCardProps = {
  title?: string;
  subtitle?: string;
  colour?: 'orange' | 'teal' | 'dark-orange';
  className?: string;
  link?: string;
  linkTitle?: string;
  linkTarget?: '_blank' | '_parent' | '_self' | '_top';
  pageAlignContent?: boolean;
};

const TitleCard: FunctionComponent<TitleCardProps> = ({
  title,
  subtitle,
  colour,
  className,
  link,
  linkTitle = 'View More',
  linkTarget,
  pageAlignContent = false,
}) => {
  return (
    <div
      className={`title-card title-card--${colour}${
        className ? ` ${className}` : ''
      }`}>
      <div
        className={`title-card-content${
          pageAlignContent ? ` content-wrapper` : ''
        }`}>
        <div className="title-card__text">
          <h4 className="title-card__subtitle">{subtitle}</h4>
          <h3 className="h1 title-card__title">{title}</h3>
        </div>
        {link ? (
          <div className="title-card__buttons">
            <Button
              className="title-card__button"
              label={linkTitle}
              local={true}
              link={link}
              target={linkTarget}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TitleCard;
