import './MemberPicker.css';

import { FunctionComponent, useEffect, useState } from 'react';
import { AvatarData, Member } from '../../models/Member';
import Avatar from '../Avatar/Avatar';

import vocoAvatarURL from '../../svgs/voco-avatar.svg';
import binocularsIcon from '../../svgs/icon-binoculars.svg';
import addMatchesIcon from '../../svgs/icon-add-matches.svg';

import { Link } from 'react-router-dom';
import InlineSVG from 'react-inlinesvg/esm';
const vocoAvatar: AvatarData = { url: vocoAvatarURL, alt: 'Team Voco' };

export type MemberPickerProps = {
  members: Member[];
  me?: Member;
  showDetails?: boolean;
  showAddMatch?: boolean;
  showVoco?: boolean;
  showMe?: boolean;
  selectFirst?: boolean;
  memberSelected: (selectedId: string | undefined) => void;
};

const MemberPicker: FunctionComponent<MemberPickerProps> = ({
  members,
  me,
  showDetails = true,
  showAddMatch = true,
  showVoco = true,
  showMe = true,
  selectFirst = false,
  memberSelected,
}) => {
  const [_selectedMemberId, setSelectedMemberId] = useState<string>();
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [filteredMembers, setFilteredMembers] = useState<Member[]>();

  const handleSelectedMember = (selectedId: string) => {
    if (selectedId === _selectedMemberId) {
      setSelectedMemberId(undefined);
      memberSelected(undefined);
    } else {
      setSelectedMemberId(selectedId);
      setSelectedMember(members.find((m) => m.id === selectedId));
      memberSelected(selectedId);
    }
  };

  useEffect(() => {
    if (selectFirst && filteredMembers && filteredMembers.length > 0) {
      setSelectedMemberId(filteredMembers[0].id);
      setSelectedMember(filteredMembers[0]);
    }
  }, [selectFirst, filteredMembers]);

  useEffect(() => {
    if (members && members.length > 0) {
      if (me) {
        setFilteredMembers(
          members.filter(
            (member) =>
              !me?.exludedMatchIds.includes(member.id) &&
              (member.lastMatchMeeting || member.nextMatchMeeting),
          ),
        );
      }
    }
  }, [members, me]);

  return (
    <div className="member-picker">
      <div className="member-picker__members">
        {showVoco ? (
          <div
            className="member-picker__member"
            onClick={() => handleSelectedMember('voco')}>
            <Avatar
              memberAvatar={vocoAvatar}
              className={`member-picker__member-avatar ${
                _selectedMemberId === 'voco'
                  ? 'member-picker__member-avatar--selected'
                  : ''
              }`}
            />
            <span className="member-picker__member-name">Voco</span>
          </div>
        ) : null}
        {showMe && me ? (
          <div
            className="member-picker__member"
            onClick={() => handleSelectedMember('you')}>
            <Avatar
              memberAvatar={me.avatar}
              className={`member-picker__member-avatar ${
                _selectedMemberId === 'you'
                  ? 'member-picker__member-avatar--selected'
                  : ''
              }`}
            />
            <span className="member-picker__member-name">You</span>
          </div>
        ) : null}
        {filteredMembers && filteredMembers?.length > 0 ? (
          <>
            {filteredMembers.map((member) => (
              <div
                className="member-picker__member"
                key={member.id}
                onClick={() => handleSelectedMember(member.id)}>
                <Avatar
                  memberAvatar={member.avatar}
                  className={`member-picker__member-avatar ${
                    _selectedMemberId === member.id
                      ? 'member-picker__member-avatar--selected'
                      : ''
                  }`}
                />
                <span className="member-picker__member-name">
                  {member.firstName}
                </span>
                <span className="member-picker__member-name">
                  {member.lastName}
                </span>
              </div>
            ))}
          </>
        ) : null}
        {/* Add new match */}
        {showAddMatch ? (
          <>
            {me?.membershipActive &&
            (members?.length < 1 || me?.matchRequested) ? (
              <div className="member-picker__member member-picker__member--no-click">
                <InlineSVG
                  src={binocularsIcon}
                  className="member-picker__member-looking "
                />
                <span className="member-picker__member-name">Looking for</span>
                <span className="member-picker__member-name">Matches</span>
              </div>
            ) : (
              <>
                {![
                  'molsongrowth',
                  'mcbc6',
                  'mcbcsales23',
                  'saatchi-mentor',
                  'saatchi-mentee',
                  'tbg-grow',
                  'immediate-voco',
                ].includes(me?.source?.toLowerCase() || '') ? (
                  <Link
                    to={me?.membershipActive ? 'members/add-match' : ''}
                    className={
                      me?.membershipActive
                        ? 'member-picker__member'
                        : 'member-picker__member member-picker__member--disabled'
                    }>
                    <InlineSVG
                      src={addMatchesIcon}
                      className="member-picker__member-add"
                    />
                    <span className="member-picker__member-name">
                      {me?.membershipActive
                        ? 'Request more'
                        : 'Activate profile'}
                    </span>
                    <span className="member-picker__member-name">
                      {me?.membershipActive ? 'Matches' : 'to match'}
                    </span>
                  </Link>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
      {/* Selected Member Details */}
      {showDetails &&
      _selectedMemberId &&
      !['voco', 'you'].includes(_selectedMemberId) ? (
        <div className="member-picker__selected-member">
          <span className="member-picker__selected-member-next">
            Next Meeting:{' '}
            <span className="member-picker__selected-member-date">
              {selectedMember?.nextMatchMeeting
                ? selectedMember?.nextMatchMeeting?.format('DD/MM/YYYY')
                : 'To Be Confirmed'}
            </span>
          </span>

          <span className="member-picker__selected-member-previous">
            {selectedMember?.lastMatchMeeting ? (
              <>
                Previous Meeting:{' '}
                <span className="member-picker__selected-member-date">
                  {selectedMember?.lastMatchMeeting?.format('DD/MM/YYYY')}
                </span>{' '}
              </>
            ) : null}
          </span>

          <Link
            className="member-picker__selected-member-link"
            to={`/members/${_selectedMemberId}/info`}>
            View Profile
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default MemberPicker;
