import './EditIntroTextModal.css';

import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';

export type EditIntroTextProps = {
  introText: string | undefined;
  updateIntroText: Function;
  updatingIntroText: boolean;
  limit: number;
};

const EditIntroText: FunctionComponent<EditIntroTextProps> = ({
  introText,
  updateIntroText,
  updatingIntroText,
  limit,
}) => {
  const [content, setContent] = useState<string>();

  const setTruncatedContent = useCallback(
    (text: string) => {
      setContent(text.slice(0, limit));
    },
    [limit, setContent],
  );

  useEffect(() => {
    setContent(introText);
  }, [introText]);

  const save: ModalActionFunction = async (event, done) => {
    const result = await updateIntroText({
      variables: { me: { introText: content } },
    });
    if (!result?.data?.updateMe?.success) {
      console.error(result);
    }
    return done();
  };

  return (
    <Modal
      action={save}
      title="Edit Public Profile"
      actionInProgress={updatingIntroText}
      returnURL="/profile">
      <textarea
        autoFocus
        className="edit-intro-text__text"
        value={content}
        onChange={(event) => setTruncatedContent(event.target.value)}
        disabled={updatingIntroText}
      />
      <span className="edit-intro-text__count">
        {content?.length || 0} of {limit} Characters
      </span>
    </Modal>
  );
};

export default EditIntroText;
