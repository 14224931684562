import './InfoMotivatorsModal.css';

import { FunctionComponent } from 'react';
import Modal from '../../components/Modal/Modal';

export type InfoMotivatorsModalProps = {};

const InfoMotivatorsModal: FunctionComponent<InfoMotivatorsModalProps> = () => {
  return (
    <Modal title="What are Career Motivators?" returnURL="/profile">
      <div className="info-motivators">
        <p className="info-motivators__text">
          Career motivators are reflections of your preferences towards work.
          They are a composite of the things you enjoy doing, the things you
          care about, and the things you see as priorities. Understanding your
          motivators can help you make better career choices. If you would like
          to read more about Motivators, what they mean and how to use them, you
          can{' '}
          <a
            className="link"
            href={'https://www.joinvoco.io/career-motivators'}
            target="_blank"
            rel="noreferrer">
            click here
          </a>{' '}
          to explore our long read.
        </p>
        <p className="info-motivators__text">
          If you have not yet completed your Motivators, or would like to update
          them , then{' '}
          <a
            className="link"
            href="https://admin751248.typeform.com/to/XMXXuTha"
            target="_blank"
            rel="noreferrer">
            click here
          </a>{' '}
          to and understand what really drives you!
        </p>
      </div>
    </Modal>
  );
};

export default InfoMotivatorsModal;
