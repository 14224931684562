import './SubscriptionConfirmationPage.css';
import { FunctionComponent } from 'react';
import InfoMessage from '../../components/InfoMessage/InfoMessage';

import vocoWoman from '../../svgs/voco-woman.svg';

const SubscriptionConfirmationPage: FunctionComponent = () => {
  return (
    <div className="subscription-confirmation">
      <InfoMessage className="subscription-confirmation__info">
        <p className="subscription-confirmation__info-text">Boom! All done!</p>
        <p className="subscription-confirmation__info-text">
          You will receive an email from us very soon inviting you to login and
          get started!
        </p>
        <p className="subscription-confirmation__info-text">
          And one last thing… please add{' '}
          <a className="link" href="mailto:hello@joinvoco.io">
            hello@joinvoco.io
          </a>{' '}
          to your safe list, ⭐ it or mark it as 'important', Otherwise you
          might miss important emails from us about your Voco matches. Thanks!
        </p>
      </InfoMessage>
      <img
        src={vocoWoman}
        alt="Woman happy having signed up for Voco"
        className="subscription-confirmation__image"
      />
    </div>
  );
};

export default SubscriptionConfirmationPage;
