import SVG from 'react-inlinesvg';
import './NavButton.css';

export type BannerProps = {
  label: string;
  icon?: string;
};

function NavButton({ label, icon }: BannerProps) {
  return (
    <div className="nav-button">
      {icon ? (
        <>
          <SVG src={icon} className="nav-button__icon" />
        </>
      ) : null}
      <span className="nav-button__label">{label}</span>
    </div>
  );
}

export default NavButton;
