import { Get, Put } from '../fetch';
import { urls } from '../constants';
export async function switchTenant(body) {
  return Put(`${urls.identity.users.v1}/tenant`, body);
}
export async function getTenants() {
  return Get(`${urls.identity.users.v2}/me/tenants`);
}
export async function getSubTenants() {
  return Get(urls.tenants.hierarchy.v1);
}
export async function getSubTenantsAsTree() {
  return Get(urls.tenants.hierarchy.tree.v1);
}