import './MeetingScheduler.css';

import dayjs, { Dayjs } from 'dayjs';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import DateTile from '../DateTile/DateTile';

import plusIcon from '../../svgs/icon-plus.svg';
import backArroeIcon from '../../svgs/icon-back-arrow.svg';

import InlineSVG from 'react-inlinesvg/esm';
import LinkButton from '../Button/LinkButton';
import TimeTile from '../TimeTile/TimeTile';

export type MeetingSchedulerProps = {
  suggestedDates: Dayjs[] | undefined;
  showStruggle?: boolean;
  dateSelected: (selectedDates: string[]) => void;
  struggleClick: () => void;
};

const groupDates = (ungroupedDates: Dayjs[]) => {
  const groupedDates: Dayjs[][] = [];
  let currentDay: Dayjs;
  let currentDayArray: Dayjs[];
  ungroupedDates.forEach((d) => {
    if (currentDay && currentDay.startOf('day').isSame(d.startOf('day'))) {
      currentDayArray.push(d);
    } else {
      currentDayArray = [d];
      groupedDates.push(currentDayArray);
    }
    currentDay = d;
  });
  return groupedDates;
};

const MeetingScheduler: FunctionComponent<MeetingSchedulerProps> = ({
  suggestedDates,
  dateSelected,
  showStruggle = false,
  struggleClick,
}) => {
  const [groupedDates, setGroupedDates] = useState<Dayjs[][]>([]);
  const [visibleWeekNo, setVisibleWeekNo] = useState<number>(0);
  const [visibleWeek, setVisibleWeek] = useState<Dayjs[][]>([]);
  const [buttonVisibility, setButtonVisibility] = useState({
    back: false,
    forward: false,
  });
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [fade, setFade] = useState<boolean>(false);

  useEffect(() => {
    if (!suggestedDates) {
      return;
    }
    setGroupedDates(groupDates(suggestedDates));
  }, [suggestedDates]);

  useEffect(() => {
    if (!groupedDates) {
      return;
    }
    const startIndex = 7 * visibleWeekNo;
    let back = true;
    let forward = true;
    if (visibleWeekNo <= 0) {
      back = false;
    }
    if (visibleWeekNo >= groupedDates.length / 7 - 1) {
      forward = false;
    }
    setButtonVisibility({ back, forward });
    setVisibleWeek(
      groupedDates?.slice(
        startIndex,
        Math.min(startIndex + 7, groupedDates.length),
      ),
    );
  }, [groupedDates, visibleWeekNo]);

  const handleDateClick = (time: string) => {
    const newSet = new Set([...selectedDates]);
    if (selectedDates.includes(time)) {
      newSet.delete(time);
    } else {
      newSet.add(time);
    }
    setSelectedDates([...newSet]);
    dateSelected([...newSet]);
  };

  const fadeInOut = async () => {
    setFade(true);
    await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 400);
    });
    setFade(false);
  };

  const backAWeek = async () => {
    if (!visibleWeekNo) {
      return;
    }
    await fadeInOut();
    setVisibleWeekNo(Math.max(0, visibleWeekNo - 1));
  };

  const forwardAWeek = async () => {
    if (!suggestedDates || visibleWeekNo >= suggestedDates.length / 7 - 1) {
      return;
    }
    await fadeInOut();
    setVisibleWeekNo(
      Math.min(suggestedDates.length / 7 - 1, visibleWeekNo + 1),
    );
  };

  return (
    <div className="meeting-scheduler">
      <div className="meeting-scheduler__week-view">
        <button
          className="meeting-scheduler__week-button"
          onClick={backAWeek}
          disabled={!buttonVisibility.back}>
          <InlineSVG
            src={backArroeIcon}
            className="meeting-scheduler__week-button-icon"
          />
          <span className="meeting-scheduler__week-button-text">
            Previous Dates
          </span>
        </button>
        <div
          className={
            fade
              ? 'meeting-scheduler__week meeting-scheduler__week--fade'
              : 'meeting-scheduler__week'
          }>
          {
            visibleWeek
              ? visibleWeek?.map((date, index) => (
                  <Fragment key={index}>
                    {date.length > 0 ? (
                      <ol className="meeting-scheduler__day">
                        <li className="meeting-scheduler__date-header">
                          <DateTile
                            date={date[0]}
                            disabled={date[0].isBefore(dayjs().startOf('day'))}
                          />
                        </li>
                        <li>
                          <ol className="meeting-scheduler__times">
                            {date.map((time) => (
                              <li
                                key={time.toISOString()}
                                className="meeting-scheduler__time">
                                <TimeTile
                                  date={time}
                                  selected={selectedDates.includes(
                                    time.toISOString(),
                                  )}
                                  onClick={() =>
                                    handleDateClick(time.toISOString())
                                  }
                                  disabled={time.isBefore(dayjs())}
                                />
                              </li>
                            ))}
                          </ol>
                        </li>
                      </ol>
                    ) : null}
                  </Fragment>
                ))
              : null // Placeholder for empty state
          }
        </div>

        <button
          className="meeting-scheduler__week-button"
          onClick={forwardAWeek}
          disabled={!buttonVisibility.forward}>
          <InlineSVG
            src={plusIcon}
            className="meeting-scheduler__week-button-icon"
          />
          <span className="meeting-scheduler__week-button-text">
            Show More Dates
          </span>
        </button>
      </div>
      {showStruggle && !buttonVisibility.forward && selectedDates.length < 1 ? (
        <span>
          Looks like you might be struggling to find 5 times…? If none of the
          options shown work for you then please{' '}
          <LinkButton label="click here" action={struggleClick} />
        </span>
      ) : null}
    </div>
  );
};

export default MeetingScheduler;
