import { useEffect } from 'react';
import { profileActions, profileReducers } from '@frontegg/redux-store';
import { reducerActionsGenerator, stateHookGenerator } from './hooks';
export const reloadProfileIfNeeded = () => {
  const {
    loading
  } = useProfileState();
  const {
    loadProfile
  } = useProfileActions();
  useEffect(() => {
    !loading && loadProfile();
  }, []);
};
const defaultMapper = state => state;
export function useProfileState(stateMapper = defaultMapper) {
  return stateHookGenerator(stateMapper, 'profileState');
}
export const useProfileActions = () => reducerActionsGenerator(profileActions, profileReducers);