import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import AceEditor from 'react-ace';
import 'prettier/parser-babel';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import ContentSection from '../../../components/ContentSection/ContentSection';
import LabelSelect from '../../../components/LabelSelect/LabelSelect';
import { MatcherConfigValidation } from '../../../models/MatcherConfigValidation';
import Button from '../../../components/Button/Button';
import { useMatcherConfig } from '../../../hooks/useMatcherConfig';
import { useParams } from 'react-router-dom';
import { MatcherConfig } from '../../../models/MatcherConfig';
import Loader from '../../../components/Loader/Loader';

const ace = require('ace-builds/src-noconflict/ace');
ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/',
);
ace.config.setModuleUrl(
  'ace/mode/json_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-noconflict/worker-json.js',
);

export type ConfigEditorPageProps = {};

export const ConfigEditorPage: FunctionComponent<
  ConfigEditorPageProps
> = () => {
  const { matcherKey } = useParams();
  const {
    loadMatcherConfig,
    matcherConfigLoading,
    matcherConfig,
    updateMatcherConfigMutation,
    matcherConfigUpdating,
  } = useMatcherConfig();

  const [matcherConfigNames, setMatcherConfigNames] = useState<{
    [key: string]: string;
  }>();
  const [selectedConfigSection, setSelectedConfigSection] = useState<string>();
  const [editedMatcherConfig, setEditedMatcherConfig] = useState<MatcherConfig>(
    new MatcherConfig({ matcherKey }),
  );

  const [validationText, setValidationText] = useState<string>();
  const [saveValidationText, setSaveValidationText] =
    useState<{ section: string; message: string }[]>();
  const [configEditorText, setConfigEditorText] = useState<any>();

  const formatConfig = () => {
    try {
      setConfigEditorText(
        JSON.stringify(JSON.parse(configEditorText), null, 2),
      );
    } catch (e) {}
  };

  /**
   * Initialisation Function
   * - Setup section list in dropdown and select first entry
   */

  useEffect(() => {
    const fileList = { ...MatcherConfigValidation.configFiles };
    setMatcherConfigNames(fileList);
    setSelectedConfigSection(
      Object.keys(MatcherConfigValidation.configFiles)[0],
    );
  }, []);

  /**
   * When the matcherKey from params is present lazyLoad the matcher key
   * - load the config for the selected path
   */
  useEffect(() => {
    if (matcherKey) {
      loadMatcherConfig({ variables: { matcherKey } });
    }
  }, [matcherKey, loadMatcherConfig]);

  /**
   *
   * WHen the matcherConfig is loaded from the server
   * -- create a new matcher content for editing
   */

  useEffect(() => {
    if (matcherConfig) {
      const newEditableMatcherConfig = new MatcherConfig(matcherConfig);
      setEditedMatcherConfig(newEditableMatcherConfig);
    }
  }, [matcherConfig]);

  // /**
  //  * If the editedMatcherConfig or the selected
  //  */
  useEffect(() => {
    if (
      selectedConfigSection &&
      editedMatcherConfig[
        selectedConfigSection as keyof typeof editedMatcherConfig
      ]
    ) {
      setConfigEditorText(
        editedMatcherConfig[
          selectedConfigSection as keyof typeof editedMatcherConfig
        ] as string,
      );
    } else {
      setConfigEditorText('{}');
    }
  }, [editedMatcherConfig, selectedConfigSection]);

  const configSectionSelected: ChangeEventHandler = (event) => {
    const value = (event.target as HTMLInputElement).value;
    setSelectedConfigSection(value);
  };

  const configUpdated = (value: string) => {
    if (
      matcherConfigNames &&
      selectedConfigSection &&
      !matcherConfigNames[selectedConfigSection].startsWith('* ')
    ) {
      setMatcherConfigNames({
        ...matcherConfigNames,
        [selectedConfigSection]: `* ${matcherConfigNames[selectedConfigSection]}`,
      });
    }
    if (selectedConfigSection) {
      const { message } = MatcherConfigValidation.validate(
        selectedConfigSection,
        value,
      );
      setValidationText(message);
      setSaveValidationText([]);
      setEditedMatcherConfig(
        new MatcherConfig({
          ...editedMatcherConfig,
          [selectedConfigSection]: value,
        }),
      );
    }
    setConfigEditorText(value);
  };

  const saveConfig = () => {
    setValidationText('');
    formatConfig();
    const { valid, errors } =
      MatcherConfigValidation.validateAll(editedMatcherConfig);
    setSaveValidationText(errors);
    if (valid) {
      updateMatcherConfigMutation({
        variables: { matcherConfig: editedMatcherConfig },
      });
    }
  };

  return (
    <div className="config-editor-page">
      <ContentSection title="Config Editor">
        <LabelSelect
          options={matcherConfigNames}
          label="Select Algorithm"
          name="selectedAlgo"
          onChange={configSectionSelected}
          value={selectedConfigSection}
        />
        {matcherConfigLoading ? (
          <Loader />
        ) : (
          <>
            <AceEditor
              showGutter={true}
              theme="github"
              mode="json"
              width="100%"
              onChange={configUpdated}
              highlightActiveLine={true}
              setOptions={{
                showPrintMargin: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              value={configEditorText}
            />
            <div>{validationText}</div>
            {saveValidationText && saveValidationText.length > 0 ? (
              <div>
                {saveValidationText?.map((error) => (
                  <ul>
                    <strong>{error.section}:</strong>
                    {error.message}
                  </ul>
                ))}
              </div>
            ) : null}
            <Button
              label="Format JSON"
              action={formatConfig}
              disabled={matcherConfigUpdating || matcherConfigLoading}
            />
            <Button
              label="Save"
              action={saveConfig}
              disabled={matcherConfigUpdating || matcherConfigLoading}
            />
          </>
        )}
      </ContentSection>
    </div>
  );
};
