import { createAction } from '@reduxjs/toolkit';
import { errorsReducerForKey, loadersReducerForKey, resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const ssoState = {
  firstLoad: true,
  loading: true,
  saving: false,
  errors: {},
  loaders: {},
  ssoConfigurations: []
};
const reducers = {
  setSSOLoader: loadersReducerForKey('ssoState'),
  setSSOError: errorsReducerForKey('ssoState'),
  setSSOState: typeReducerForKey('ssoState'),
  resetSSOState: resetStateByKey('ssoState', {
    ssoState
  })
};
const actions = {
  loadSSOConfigurationsV2: createAction(`${authStoreName}/loadSSOConfigurationsV2`),
  saveSSOConfigurationV2: createAction(`${authStoreName}/saveSSOConfigurationV2`, payload => ({
    payload
  })),
  updateSSOConfiguration: createAction(`${authStoreName}/updateSSOConfiguration`, payload => ({
    payload
  })),
  deleteSSOConfiguration: createAction(`${authStoreName}/deleteSSOConfiguration`, payload => ({
    payload
  })),
  saveSSODomain: createAction(`${authStoreName}/saveSSODomain`, payload => ({
    payload
  })),
  deleteSSODomain: createAction(`${authStoreName}/deleteSSODomain`, payload => ({
    payload
  })),
  validateSSODomainV2: createAction(`${authStoreName}/validateSSODomainV2`, payload => ({
    payload
  })),
  setSSODefaultRoles: createAction(`${authStoreName}/setSSODefaultRoles`, payload => ({
    payload
  })),
  setSSOGroups: createAction(`${authStoreName}/setSSOGroups`, payload => ({
    payload
  })),
  updateSSOGroups: createAction(`${authStoreName}/updateSSOGroups`, payload => ({
    payload
  })),
  saveSSOConfigurationByMetadata: createAction(`${authStoreName}/saveSSOConfigurationByMetadata`, payload => ({
    payload
  })),
  updateSSOConfigurationByMetadata: createAction(`${authStoreName}/updateSSOConfigurationByMetadata`, payload => ({
    payload
  })),
  loadSSOConfigurations: createAction(`${authStoreName}/loadSSOConfigurations`),
  loadSSOAuthorizationRoles: createAction(`${authStoreName}/loadSSOAuthorizationRoles`),
  saveSSOConfigurations: createAction(`${authStoreName}/saveSSOConfigurations`, payload => ({
    payload
  })),
  // NOTE: use withCallback in case of promise response
  saveSSOConfigurationsFile: createAction(`${authStoreName}/saveSSOConfigurationsFile`, payload => ({
    payload
  })),
  saveSSOConfigurationsFileWithCallback: createAction(`${authStoreName}/saveSSOConfigurationsFileWithCallback`, payload => ({
    payload
  })),
  validateSSODomain: createAction(`${authStoreName}/validateSSODomain`, payload => ({
    payload
  })),
  updateSSOAuthorizationRoles: createAction(`${authStoreName}/updateSSOAuthorizationRoles`, payload => ({
    payload
  })),
  deleteSamlGroup: createAction(`${authStoreName}/deleteSamlGroup`, payload => ({
    payload
  })),
  createSamlGroup: createAction(`${authStoreName}/createSamlGroup`, payload => ({
    payload
  })),
  oidcPostlogin: createAction(`${authStoreName}/oidcPostlogin`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { ssoState, reducers as ssoReducers, actions as ssoActions };