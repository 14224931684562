import './TextCard.css';
import { FunctionComponent } from 'react';

export type TextCardProps = {
  title?: string;
  bodyHTML?: string;
  className?: string;
};

const TextCard: FunctionComponent<TextCardProps> = ({
  title,
  bodyHTML,
  className,
}) => {
  return (
    <div className={`text-card${className ? ` ${className}` : ''}`}>
      {title ? <h2 className="text-card__title">{title}</h2> : null}
      {bodyHTML ? (
        <div
          className="text-card__body"
          dangerouslySetInnerHTML={{ __html: bodyHTML }}
        />
      ) : null}
    </div>
  );
};

export default TextCard;
