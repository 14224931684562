import dayjs, { Dayjs } from 'dayjs';
import { AvatarData, Member } from './Member';

import matchAvatar from '../svgs/match-avatar.svg';
import { IFeedItem } from './FeedItem';

export class Message implements IFeedItem {
  id: string;
  date: Dayjs;

  text: string;
  member: Member;
  relatedMember?: Member;

  constructor(data: any) {
    this.id = data.id;

    this.date = dayjs(data.date);
    this.text = data.text;
    this.member = new Member(data.member);
    this.relatedMember = data.relatedMember
      ? new Member(data.relatedMember)
      : undefined;
  }

  public get avatars(): AvatarData[] {
    const fallbackAvatar: AvatarData = { url: matchAvatar, alt: 'Your Match' };
    const memberAvatar = this.member.avatar || fallbackAvatar;
    const relatedMemberAvatar = this.relatedMember?.avatar || fallbackAvatar;
    if (this.relatedMember) {
      return [memberAvatar, relatedMemberAvatar];
    }
    return [memberAvatar];
  }
}
