import dayjs, { Dayjs } from 'dayjs';

export class Goal {
  id?: string;
  title?: string;
  createdAt: Dayjs;
  sortOrder: number;

  constructor(data: any) {
    this.id = data.id;
    this.title = data.title;
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : dayjs();
    this.sortOrder = data.sortOrder;
  }
}
