import './Modal.css';

import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import closeModalIcon from '../../svgs/icon-close-modal.svg';
import Button from '../Button/Button';
import { Waveform } from '@uiball/loaders';
import InlineSVG from 'react-inlinesvg/esm';

import heartIcon from '../../svgs/icon-heart.svg';

export type ModalActionFunction = (
  event: React.MouseEvent,
  done: Function,
) => void;

export type ModalProps = {
  title: string;
  children: ReactNode;
  action?: ModalActionFunction;
  actionInProgress?: boolean;
  returnURL?: string;
  showHeart?: boolean;
  actionText?: string;
  infoText?: string;
  dismissHandler?: (actionCompleted: boolean) => void;
};

const Modal: FunctionComponent<ModalProps> = ({
  title,
  children,
  action,
  actionInProgress = false,
  actionText = 'Save',
  returnURL,
  showHeart = false,
  infoText,
  dismissHandler,
}) => {
  let navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleBackgroundClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      handleDismiss(event);
    }
  };

  const handleDismiss = (
    event: React.MouseEvent,
    actionComplete: boolean = false,
  ) => {
    if (typeof dismissHandler === 'function') {
      return dismissHandler(actionComplete);
    }
    if (returnURL) {
      return navigate(returnURL);
    }
    navigate(-1);
  };

  const handleAction = (event: React.MouseEvent) => {
    const done = () => handleDismiss(event, true);
    if (action) {
      action(event, done);
    }
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <div className="modal__wrapper">
        <div className="modal__content">
          <div className="modal__content-header">
            <h2 className="modal__content_title">{title}</h2>
            {infoText ? (
              <span className="modal__content_info">{infoText}</span>
            ) : null}
            {false && actionInProgress ? (
              <span className="modal__content-action-in-progress">
                <Waveform size={25} lineWeight={3} speed={1} color="#0c343d" />
              </span>
            ) : null}
            {action ? (
              <Button
                action={handleAction}
                label={actionText}
                className="modal__content_action"
                disabled={actionInProgress}
                active={actionInProgress}
              />
            ) : null}
          </div>
          {children}
          {showHeart ? (
            <InlineSVG src={heartIcon} className="modal__content-heart" />
          ) : null}
        </div>
        <div className="modal__dismiss" onClick={handleDismiss}>
          <button className="modal__dismiss-button" disabled={actionInProgress}>
            <img src={closeModalIcon} alt="Close" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
