import './ProfilePage.css';
import { FunctionComponent } from 'react';
import ContentSection from '../../components/ContentSection/ContentSection';
import TextSection from '../../components/TextSection/TextSection';
import { useProfile } from '../../hooks/useProfile';
import GoalContainer from '../../components/GoalContainer/GoalContainer';
import CareerInterests from '../../components/CareerInterests/CareerInterests';
import CareerMotivators from '../../components/CareerMotivators/CareerMotivators';
import MemberInfoGrid, {
  MemberInfoObject,
} from '../../components/MemberInfo/MemberInfoGrid';
import { Outlet, Route, Routes } from 'react-router-dom';
import EditIntroText from './EditIntroTextModal';
import EditGoalModal from './EditGoalsModal';
import { useGoals } from '../../hooks/useGoals';
import InfoMotivatorsModal from './InfoMotivatorsModal';
import InfoPenPortrait from './InfoPenPortraitModal';
import EditEmploymentModal from './EditEmploymentModal';
import Loader from '../../components/Loader/Loader';

export type ProfilePageProps = {};

const ProfilePage: FunctionComponent<ProfilePageProps> = () => {
  const {
    profile,
    profileLoading,
    updateIntroTextMutation,
    updatingIntroText,
    updateEmploymentMutation,
    updatingEmployment,
  } = useProfile();
  const { goals, goalsLoading, updateGoalsMutation, goalsUpdating } =
    useGoals();

  return (
    <section className="profile">
      <ContentSection title="Public Profile" withSections={true}>
        <ContentSection
          title="Introduction"
          infoText="A bit about you to share."
          subSection={true}
          actionLinkURL="/profile/edit-intro-text">
          {profileLoading ? (
            <Loader />
          ) : (
            <TextSection body={profile?.introText} className="profile__intro" />
          )}
        </ContentSection>
      </ContentSection>
      <ContentSection title="Private Profile" withSections={true}>
        <ContentSection
          title="My Goals"
          subSection={true}
          actionLinkURL="/profile/edit-goals"
          actionLinkDisabled={!profile?.membershipActive}>
          {goalsLoading ? <Loader /> : <GoalContainer goals={goals} />}
        </ContentSection>

        {profile && profile?.careerInterests?.length > 0 ? (
          <ContentSection title="Career Interests" subSection={true}>
            <CareerInterests interests={profile?.careerInterests} />
          </ContentSection>
        ) : null}

        <ContentSection
          title="Career Motivators"
          subSection={true}
          actionLinkURL="/profile/info-motivators"
          actionLinkText="Find out more"
          actionLinkDisabled={!profile?.membershipActive}>
          {profileLoading ? (
            <Loader />
          ) : (
            <CareerMotivators motivators={profile?.careerMotivators} />
          )}
        </ContentSection>
        <ContentSection
          title="Personality Portrait"
          subSection={true}
          actionLinkURL="/profile/info-pen-portrait"
          actionLinkText="Find out more">
          {profileLoading ? (
            <Loader />
          ) : (
            <TextSection
              body={profile?.penPortrait}
              className="profile__intro"
            />
          )}
        </ContentSection>
        <ContentSection
          title="Current Employment"
          subSection={true}
          actionLinkURL="/profile/edit-employment">
          <MemberInfoGrid
            properties={[
              { label: 'Current Employer', property: 'currentEmployer' },
              { label: 'Job Title', property: 'currentJobTitle' },
              { label: 'Year Career Started', property: 'careerStartYear' },
              { label: 'Functional Area', property: 'functionalArea' },
            ]}
            member={profile as unknown as MemberInfoObject}
          />
        </ContentSection>
      </ContentSection>
      <Routes>
        <Route
          path="edit-intro-text"
          element={
            <EditIntroText
              introText={profile?.introText}
              updateIntroText={updateIntroTextMutation}
              updatingIntroText={updatingIntroText}
              limit={1000}
            />
          }
        />
        <Route
          path="edit-goals"
          element={
            <EditGoalModal
              goals={goals}
              updatingGoals={goalsLoading || goalsUpdating}
              updateGoals={updateGoalsMutation}
            />
          }
        />
        <Route path="info-motivators" element={<InfoMotivatorsModal />} />
        <Route path="info-pen-portrait" element={<InfoPenPortrait />} />
        <Route
          path="edit-employment"
          element={
            <EditEmploymentModal
              profile={profile}
              updatingProfile={profileLoading || updatingEmployment}
              updateProfile={updateEmploymentMutation}
            />
          }
        />
      </Routes>
      <Outlet />
    </section>
  );
};

export default ProfilePage;
