import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["roleIds", "userIds"];
import { put, takeLatest, select as sagaSelect, call, delay } from 'redux-saga/effects';
import { GroupManagedByEnum, api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { GroupsStateKeys, GroupRelations } from './interfaces';
import { authStoreName } from '../../constants';
import { allGroupsDummy } from '../dummy';
const selectGroupsState = () => sagaSelect(_ => _[authStoreName].groupsState);
function* getTeamUsers({
  payload
}) {
  const key = GroupsStateKeys.GET_TEAM_USERS;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    const {
      items: users
    } = yield call(api.teams.loadUsers, {
      filter: payload.filter,
      pageOffset: payload.pageOffset,
      pageSize: payload.pageSize
    });
    yield put(actions.setGroupsState({
      teamUsers: users
    }));
  } catch (e) {
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* getGroupByIdSaga({
  payload
}) {
  const key = GroupsStateKeys.GET_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    const group = yield call(api.groups.getGroupById, {
      groupId: payload.groupId
    }, {
      _groupsRelations: GroupRelations.rolesAndUsers
    });
    yield put(actions.setGroupsState({
      selectedGroup: group
    }));
  } catch (e) {
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* loadGroups() {
  const key = GroupsStateKeys.GET_GROUPS;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    const {
      groups
    } = yield call(api.groups.getGroups, {
      _groupsRelations: GroupRelations.rolesAndUsers
    });
    yield put(actions.setGroupsState(_extends({
      groups
    }, state.selectedGroup && {
      selectedGroup: groups.find(g => {
        var _state$selectedGroup;
        return g.id === ((_state$selectedGroup = state.selectedGroup) == null ? void 0 : _state$selectedGroup.id);
      })
    })));
  } catch (e) {
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* createGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.CREATE_GROUP;
  const {
      roleIds,
      userIds
    } = payload,
    createGroupPayload = _objectWithoutPropertiesLoose(payload, _excluded);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback;
    const response = yield call(api.groups.createGroup, _extends({
      name: createGroupPayload.name
    }, !!createGroupPayload.description && {
      description: createGroupPayload.description
    }, {
      color: createGroupPayload.color
    }));
    if (userIds != null && userIds.length) {
      yield put(actions.addUsersToGroup({
        groupId: response.id,
        userIds
      }));
    }
    if (roleIds != null && roleIds.length) {
      yield put(actions.addRolesToGroup({
        groupId: response.id,
        roleIds
      }));
    }
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* updateGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.UPDATE_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback3;
    yield call(api.groups.updateGroup, payload);
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  } catch (e) {
    var _payload$callback4;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, false);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* deleteGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.DELETE_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback5;
    yield call(api.groups.deleteGroup, payload.groupId);
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
  } catch (e) {
    var _payload$callback6;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, false, e.message);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* addRolesToGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.ADD_ROLES_TO_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback7;
    yield call(api.groups.addRolesToGroup, payload.groupId, {
      roleIds: payload.roleIds
    });
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
  } catch (e) {
    var _payload$callback8;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback8 = payload.callback) == null ? void 0 : _payload$callback8.call(payload, false);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* deleteRolesFromGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.DELETE_ROLES_FROM_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback9;
    yield call(api.groups.deleteRolesFromGroup, payload.groupId, {
      roleIds: payload.roleIds
    });
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback9 = payload.callback) == null ? void 0 : _payload$callback9.call(payload, true);
  } catch (e) {
    var _payload$callback10;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback10 = payload.callback) == null ? void 0 : _payload$callback10.call(payload, false);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* addUsersToGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.ADD_USERS_TO_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback11;
    yield call(api.groups.addUsersToGroup, payload.groupId, {
      userIds: payload.userIds
    });
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback11 = payload.callback) == null ? void 0 : _payload$callback11.call(payload, true);
  } catch (e) {
    var _payload$callback12;
    payload == null ? void 0 : (_payload$callback12 = payload.callback) == null ? void 0 : _payload$callback12.call(payload, false);
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* deleteUsersFromGroupSaga({
  payload
}) {
  const key = GroupsStateKeys.DELETE_USERS_FROM_GROUP;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    var _payload$callback13;
    yield call(api.groups.deleteUsersFromGroup, payload.groupId, {
      userIds: payload.userIds
    });
    yield put(actions.loadGroups());
    payload == null ? void 0 : (_payload$callback13 = payload.callback) == null ? void 0 : _payload$callback13.call(payload, true);
  } catch (e) {
    var _payload$callback14;
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
    payload == null ? void 0 : (_payload$callback14 = payload.callback) == null ? void 0 : _payload$callback14.call(payload, false);
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* getGroupConfigurationSaga() {
  const key = GroupsStateKeys.GET_GROUP_CONFIGURATION;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    const response = yield call(api.groups.getGroupConfiguration);
    yield put(actions.setGroupsState({
      groupsConfiguration: response
    }));
  } catch (e) {
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
function* updateGroupConfigurationSaga({
  payload
}) {
  const key = GroupsStateKeys.UPDATE_GROUP_CONFIGURATION;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  try {
    yield call(api.groups.updateGroupConfiguration, payload);
    yield put(actions.getGroupConfiguration());
  } catch (e) {
    yield put(actions.setUsersGroupsError({
      key,
      value: e.message
    }));
  } finally {
    yield put(actions.setUsersGroupsLoader({
      key,
      value: false
    }));
  }
}
export function* groupsSagas() {
  yield takeLatest(actions.loadGroups, loadGroups);
  yield takeLatest(actions.getGroupById, getGroupByIdSaga);
  yield takeLatest(actions.createGroup, createGroupSaga);
  yield takeLatest(actions.updateGroup, updateGroupSaga);
  yield takeLatest(actions.deleteGroup, deleteGroupSaga);
  yield takeLatest(actions.addRolesToGroup, addRolesToGroupSaga);
  yield takeLatest(actions.deleteRolesFromGroup, deleteRolesFromGroupSaga);
  yield takeLatest(actions.addUsersToGroup, addUsersToGroupSaga);
  yield takeLatest(actions.deleteUsersFromGroup, deleteUsersFromGroupSaga);
  yield takeLatest(actions.getGroupConfiguration, getGroupConfigurationSaga);
  yield takeLatest(actions.updateGroupConfiguration, updateGroupConfigurationSaga);
  yield takeLatest(actions.getTeamUsers, getTeamUsers);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadGroupsMock() {
  const key = GroupsStateKeys.GET_GROUPS;
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  const groups = allGroupsDummy;
  yield put(actions.setGroupsState({
    groups
  }));
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* createGroupMock({
  payload
}) {
  var _payload$callback15;
  const key = GroupsStateKeys.CREATE_GROUP;
  const {
    groups
  } = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: [_extends({}, payload, {
      id: `${payload.name}ID`,
      managedBy: GroupManagedByEnum.FRONTEGG
    }), ...(groups != null ? groups : [])]
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback15 = payload.callback) == null ? void 0 : _payload$callback15.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* updateGroupMock({
  payload
}) {
  var _state$groups, _payload$name2, _payload$color, _payload$description, _payload$callback16;
  const key = GroupsStateKeys.UPDATE_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups = state.groups) == null ? void 0 : _state$groups.map(group => {
      var _payload$name;
      return group.id === payload.groupId ? _extends({}, payload, {
        id: payload.groupId,
        name: (_payload$name = payload == null ? void 0 : payload.name) != null ? _payload$name : '',
        managedBy: GroupManagedByEnum.FRONTEGG
      }) : group;
    }),
    selectedGroup: _extends({}, state == null ? void 0 : state.selectedGroup, {
      name: (_payload$name2 = payload == null ? void 0 : payload.name) != null ? _payload$name2 : '',
      color: (_payload$color = payload == null ? void 0 : payload.color) != null ? _payload$color : '',
      description: (_payload$description = payload == null ? void 0 : payload.description) != null ? _payload$description : ''
    })
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback16 = payload.callback) == null ? void 0 : _payload$callback16.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* deleteGroupMock({
  payload
}) {
  var _state$groups2, _payload$callback17;
  const key = GroupsStateKeys.DELETE_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups2 = state.groups) == null ? void 0 : _state$groups2.filter(group => group.id !== payload.groupId)
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback17 = payload.callback) == null ? void 0 : _payload$callback17.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* addRolesToGroupMock({
  payload
}) {
  var _state$groups3, _payload$callback18;
  const key = GroupsStateKeys.ADD_ROLES_TO_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups3 = state.groups) == null ? void 0 : _state$groups3.map(group => {
      var _group$roles;
      return group.id === payload.groupId ? _extends({}, group, {
        roles: [...((_group$roles = group == null ? void 0 : group.roles) != null ? _group$roles : []), ...payload.roleIds.map(role => ({
          id: role,
          name: role
        }))]
      }) : group;
    })
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback18 = payload.callback) == null ? void 0 : _payload$callback18.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* deleteRolesFromGroupMock({
  payload
}) {
  var _state$groups4, _payload$callback19;
  const key = GroupsStateKeys.DELETE_ROLES_FROM_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups4 = state.groups) == null ? void 0 : _state$groups4.map(group => {
      var _group$roles2;
      return group.id === payload.groupId ? _extends({}, group, {
        roles: group == null ? void 0 : (_group$roles2 = group.roles) == null ? void 0 : _group$roles2.filter(role => !payload.roleIds.includes(role.id))
      }) : group;
    })
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback19 = payload.callback) == null ? void 0 : _payload$callback19.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* addUsersToGroupMock({
  payload
}) {
  var _state$groups5, _payload$callback20;
  const key = GroupsStateKeys.ADD_USERS_TO_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups5 = state.groups) == null ? void 0 : _state$groups5.map(group => {
      var _group$users;
      return group.id === payload.groupId ? _extends({}, group, {
        users: [...((_group$users = group == null ? void 0 : group.users) != null ? _group$users : []), ...payload.userIds.map(user => ({
          id: user,
          name: user
        }))]
      }) : group;
    })
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback20 = payload.callback) == null ? void 0 : _payload$callback20.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* deleteUsersFromGroupMock({
  payload
}) {
  var _state$groups6, _payload$callback21;
  const key = GroupsStateKeys.DELETE_USERS_FROM_GROUP;
  const state = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  yield put(actions.setGroupsState({
    groups: state == null ? void 0 : (_state$groups6 = state.groups) == null ? void 0 : _state$groups6.map(group => {
      var _group$users2;
      return group.id === payload.groupId ? _extends({}, group, {
        users: group == null ? void 0 : (_group$users2 = group.users) == null ? void 0 : _group$users2.filter(user => !payload.userIds.includes(user.id))
      }) : group;
    })
  }));
  yield delay(500);
  payload == null ? void 0 : (_payload$callback21 = payload.callback) == null ? void 0 : _payload$callback21.call(payload, true);
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* getGroupConfigurationMock() {
  const key = GroupsStateKeys.GET_GROUP_CONFIGURATION;
  const {
    groupsConfiguration
  } = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  if (groupsConfiguration) {
    yield put(actions.setGroupsState({
      groupsConfiguration: _extends({}, groupsConfiguration)
    }));
  }
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
function* updateGroupConfigurationMock({
  payload
}) {
  const key = GroupsStateKeys.UPDATE_GROUP_CONFIGURATION;
  const {
    groupsConfiguration
  } = yield selectGroupsState();
  yield put(actions.setUsersGroupsLoader({
    key,
    value: true
  }));
  if (groupsConfiguration) {
    yield put(actions.setGroupsState({
      groupsConfiguration: _extends({}, groupsConfiguration, payload)
    }));
  }
  yield put(actions.setUsersGroupsLoader({
    key,
    value: false
  }));
}
export function* groupsSagasMock() {
  yield takeLatest(actions.loadGroups, loadGroupsMock);
  yield takeLatest(actions.createGroup, createGroupMock);
  yield takeLatest(actions.updateGroup, updateGroupMock);
  yield takeLatest(actions.deleteGroup, deleteGroupMock);
  yield takeLatest(actions.addRolesToGroup, addRolesToGroupMock);
  yield takeLatest(actions.deleteRolesFromGroup, deleteRolesFromGroupMock);
  yield takeLatest(actions.addUsersToGroup, addUsersToGroupMock);
  yield takeLatest(actions.deleteUsersFromGroup, deleteUsersFromGroupMock);
  yield takeLatest(actions.getGroupConfiguration, getGroupConfigurationMock);
  yield takeLatest(actions.updateGroupConfiguration, updateGroupConfigurationMock);
}