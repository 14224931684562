import { FunctionComponent } from 'react';
import './ActionCard.css';

import { Action } from '../../models/Action';
import DropDownMenu from '../DropDown/DropDownMenu';
import DropDownMenuLink from '../DropDown/DropDownMenuLink';
import InfoCard from '../InfoCard/InfoCard';

export type ActionCardProps = {
  action: Action;
  deleteAction: Function;
};

const ActionCard: FunctionComponent<ActionCardProps> = ({
  action,
  deleteAction,
}) => {
  const menu = action.hasSecondaryAction ? (
    <DropDownMenu>
      {action.isReschedulable ? (
        <DropDownMenuLink text="Reschedule" url={action.rescheduleURL} />
      ) : null}
      {action.isDeletable ? (
        <DropDownMenuLink
          text="Delete"
          onClick={() => deleteAction(action.id)}
        />
      ) : null}
    </DropDownMenu>
  ) : null;

  return (
    <InfoCard
      icon={action.icon}
      date={action.date}
      bodyText={action.description}
      linkURL={action.linkTo}
      linkText={action.linkText}
      menu={menu}
      colour="orange"
    />
  );
};

export default ActionCard;
