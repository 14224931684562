import './ActionRescheduleModal.css';

import { FunctionComponent, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import { Action } from '../../models/Action';
import { Member } from '../../models/Member';

export type ActionRescheduleModalProps = {
  actions: Action[];
};

const ActionRescheduleModal: FunctionComponent<ActionRescheduleModalProps> = ({
  actions,
}) => {
  const { id } = useParams();
  const [relatedMember, setRelatedMember] = useState<Member>();

  useEffect(() => {
    if (actions && actions.length > 0) {
      setRelatedMember(
        actions.find((action) => action.id === id)?.relatedMember,
      );
    }
  }, [actions, id]);

  return (
    <Modal title="Reschedule Your Call" showHeart={true} returnURL="/">
      <div className="action-reschedule-modal">
        <p className="p">
          OK, so it looks like you need to reschedule, no problem.
        </p>
        <p className="p bold">Here is what you need to do:</p>
        {relatedMember?.email ? (
          <p className="p">
            1. Please{' '}
            <a
              className="link"
              href={`mailto:${relatedMember?.email}?subject=I'd%20like%20to%20reschedule%20our%20call&cc=hello@joinvoco.io`}>
              email {relatedMember?.firstName} at {relatedMember?.email}
            </a>{' '}
            directly as soon as you can to let them know you will no longer be
            able to make the call.{' '}
            <span className="bold">
              Please copy hello@joinvoco.io in on this email
            </span>
          </p>
        ) : (
          <p className="p">
            1. Within the invite in your calendar you will be able to see the
            email address of your match. Please email your match directly as
            soon as you can to let them know you will no longer be able to make
            the call.{' '}
            <span className="bold">
              Please copy hello@joinvoco.io in on this email
            </span>
          </p>
        )}
        <p className="p">
          2. Also please decline the calendar invite in case your match missed
          the email.
        </p>{' '}
        <p className="p">
          3. You may find that it feels natural for you and your match to
          arrange a different time to talk over email. If so please simply move
          the Voco invite in your calendar to the new time, you agree (rather
          than creating a new one). This will mean the Voco systems will remain
          up-to-date and any communication around your meeting will be sent at
          the correct time. If you would rather Voco handled rescheduling - no
          problem. As long as you notify us that you need to reschedule we will
          handle that for you!
        </p>
        <p className="p">
          Voco is a community of people that work hard to help each other be
          their best at work, as with any community - good behaviour matters so
          thank you for diligently and proactively advising your match (and us!)
          of the need to reschedule!
        </p>
      </div>
    </Modal>
  );
};

export default ActionRescheduleModal;
