import { bindActionCreators, subscriptionActions, subscriptionsStoreName } from '@frontegg/redux-store';
import { useDispatch, useSelector } from '../FronteggStoreContext';
import { shallowEqual } from 'react-redux';
import { useMemo } from 'react';
function getSubscriptionState(state) {
  return state[subscriptionsStoreName];
}
export const usePaymentProvider = () => {
  return useSelector(state => getSubscriptionState(state).config, shallowEqual);
};
export const usePaymentProviderActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.config, dispatch), [dispatch]);
};
export const usePlans = () => {
  return useSelector(state => getSubscriptionState(state).plans, shallowEqual);
};
export const usePlansActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.plans, dispatch), [dispatch]);
};
export const useCheckout = () => {
  return useSelector(state => getSubscriptionState(state).checkout, shallowEqual);
};
export const useCheckoutActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.checkout, dispatch), [dispatch]);
};
const useBillingActions = billingKey => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.billing[billingKey], dispatch), [billingKey, dispatch]);
};
export const useBillingInformation = () => useSelector(state => getSubscriptionState(state).billing.information, shallowEqual);
export const useBillingInformationActions = () => useBillingActions('information');
export const usePaymentMethod = () => useSelector(state => getSubscriptionState(state).billing.paymentMethod, shallowEqual);
export const usePaymentMethodActions = () => useBillingActions('paymentMethod');
export const useInvoices = () => useSelector(state => getSubscriptionState(state).billing.invoices, shallowEqual);
export const useInvoicesActions = () => useBillingActions('invoices');
export const useStripeState = () => {
  return useSelector(state => getSubscriptionState(state).stripe, shallowEqual);
};
export const useStripeActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.stripe, dispatch), [dispatch]);
};
export const useSubscriptionCancellation = () => {
  return useSelector(state => getSubscriptionState(state).billing.subscription.cancellation, shallowEqual);
};
export const useSubscriptionRenewal = () => {
  return useSelector(state => getSubscriptionState(state).billing.subscription.renewal, shallowEqual);
};
export const useSubscription = () => {
  return useSelector(state => {
    const {
      billing: {
        subscription
      }
    } = getSubscriptionState(state);
    return subscription;
  }, shallowEqual);
};
export const useSubscriptionActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.billing.subscription, dispatch), [dispatch]);
};
export const useVendorPublicConfig = () => {
  return useSelector(state => {
    const {
      vendorPublicConfig
    } = getSubscriptionState(state);
    return vendorPublicConfig;
  }, shallowEqual);
};
export const useVendorPublicConfigActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(subscriptionActions.vendorPublicConfig, dispatch), [dispatch]);
};