import { AuthStrategyEnum } from '@frontegg/rest-api';
export let LoginStep;
(function (LoginStep) {
  LoginStep["preLogin"] = "preLogin";
  LoginStep["magicLinkPreLoginSuccess"] = "magicLinkPreLoginSuccess";
  LoginStep["loginWithSmsOtc"] = "loginWithSmsOtc";
  LoginStep["loginWithQuickSmsOtc"] = "loginWithQuickSmsOtc";
  LoginStep["loginWithOtc"] = "loginWithOtc";
  LoginStep["loginWithPassword"] = "loginWithPassword";
  LoginStep["loginWithTwoFactor"] = "loginWithTwoFactor";
  LoginStep["redirectToSSO"] = "redirectToSSO";
  LoginStep["loginWithSSOFailed"] = "loginWithSSOFailed";
  LoginStep["success"] = "success";
  LoginStep["forceTwoFactor"] = "forceTwoFactor";
  LoginStep["recoverTwoFactor"] = "recoverTwoFactor";
  LoginStep["promptPasskeys"] = "promptPasskeys";
})(LoginStep || (LoginStep = {}));
export let LoginFlow;
(function (LoginFlow) {
  LoginFlow["Login"] = "login";
  LoginFlow["RegisterQuickLogin"] = "registerQuickLogin";
})(LoginFlow || (LoginFlow = {}));
export let QuickLoginStrategy;
(function (QuickLoginStrategy) {
  QuickLoginStrategy["Internal"] = "internal";
  QuickLoginStrategy["UsbKey"] = "usb-key";
  QuickLoginStrategy["Android"] = "android";
  QuickLoginStrategy["Sms"] = "sms";
})(QuickLoginStrategy || (QuickLoginStrategy = {}));
export { AuthStrategyEnum };