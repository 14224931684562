import './MemberInfoGrid.css';

import { FunctionComponent } from 'react';

export type MemberInfoGridProperty = {
  label: string;
  property: string;
  value?: any;
};

export type MemberInfoObject = {
  [key: string]: string;
};

export type MemberInfoProps = {
  member: MemberInfoObject | undefined;
  properties: MemberInfoGridProperty[];
};

const MemberInfoGrid: FunctionComponent<MemberInfoProps> = ({
  member,
  properties,
}) => {
  const isLink = (property: string) => {
    return (
      member &&
      member[property] &&
      member[property].toString().startsWith('http')
    );
  };

  const getPropertyText = (property: any): string => {
    if (!member || !member[property]) {
      return '-';
    }

    const value = member[property].toString();
    return value.replace('http://', '').replace('https://', '');
  };

  return (
    <div className="member-info-grid">
      {member
        ? properties.map((property: MemberInfoGridProperty) => (
            <div className="member-info" key={property.property}>
              <h4 className="member-info__label">{property.label}:</h4>
              <span className="member-info__value">
                {property.value ? (
                  property.value
                ) : isLink(property.property) ? (
                  <a
                    href={member[property.property]}
                    target="_blank"
                    rel="noreferrer">
                    {getPropertyText(property.property)}
                  </a>
                ) : (
                  getPropertyText(property.property)
                )}
              </span>
            </div>
          ))
        : null}
    </div>
  );
};

export default MemberInfoGrid;
