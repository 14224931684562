import dayjs, { Dayjs } from 'dayjs';

export type MatcherNumberLookup = { [key: string]: number };
export type KeyedMatcherNumberLookup = { [key: string]: MatcherNumberLookup };
export type MatcherStringLookup = { [key: string]: string };
export type MatcherStringArrayLookup = { [key: string]: string[] };
export type DriverKeyedMatcherNumberLookup = {
  driver: KeyedMatcherNumberLookup;
};
export type DriverWeightingLookup = { weightings: MatcherNumberLookup };
export type NumericKeyedMatcherNumberLookup = {
  numericScores: MatcherNumberLookup;
};

export interface IMatcherConfig {
  id?: string;
  matcherKey: string;
  ageCompatibility?: string;
  careerLengthDriver?: string;
  contextDriver?: string;
  driverWeighting?: string;
  extrovertDriver?: string;
  functionalAreaDriver?: string;
  functionalAreaTranslation?: string;
  plannerDriver?: string;
  sectorDriver?: string;
  seniorityCompatibility?: string;
  seniorityDriver?: string;
  sourceExclusions?: string;
  createdAt?: Dayjs;
}

export class MatcherConfig implements IMatcherConfig {
  id?: string;
  matcherKey: string;
  ageCompatibility?: string;
  careerLengthDriver?: string;
  contextDriver?: string;
  driverWeighting?: string;
  extrovertDriver?: string;
  functionalAreaDriver?: string;
  functionalAreaTranslation?: string;
  plannerDriver?: string;
  sectorDriver?: string;
  seniorityCompatibility?: string;
  seniorityDriver?: string;
  sourceExclusions?: string;
  createdAt?: Dayjs;

  constructor(data: any) {
    this.id = data.id;
    this.matcherKey = data.matcherKey || '{}';
    this.ageCompatibility = data.ageCompatibility || '{}';
    this.careerLengthDriver = data.careerLengthDriver || '{}';
    this.contextDriver = data.contextDriver || '{}';
    this.driverWeighting = data.driverWeighting || '{}';
    this.extrovertDriver = data.extrovertDriver || '{}';
    this.functionalAreaDriver = data.functionalAreaDriver || '{}';
    this.functionalAreaTranslation = data.functionalAreaTranslation || '{}';
    this.plannerDriver = data.plannerDriver || '{}';
    this.sectorDriver = data.sectorDriver || '{}';
    this.seniorityCompatibility = data.seniorityCompatibility || '{}';
    this.seniorityDriver = data.seniorityDriver || '{}';
    this.sourceExclusions = data.sourceExclusions || '{}';
    this.createdAt = data.createdAt ? dayjs(data.createdAt) : dayjs();
  }

  toJSON() {
    const json = {
      ...this,
    };
    delete json.createdAt;
    return json;
  }

  formatProperties() {
    this.matcherKey = this.formatJSON(this.matcherKey || '{}');
    this.ageCompatibility = this.formatJSON(this.ageCompatibility || '{}');
    this.careerLengthDriver = this.formatJSON(this.careerLengthDriver || '{}');
    this.contextDriver = this.formatJSON(this.contextDriver || '{}');
    this.driverWeighting = this.formatJSON(this.driverWeighting || '{}');
    this.extrovertDriver = this.formatJSON(this.extrovertDriver || '{}');
    this.functionalAreaDriver = this.formatJSON(
      this.functionalAreaDriver || '{}',
    );
    this.functionalAreaTranslation = this.formatJSON(
      this.functionalAreaTranslation || '{}',
    );
    this.plannerDriver = this.formatJSON(this.plannerDriver || '{}');
    this.sectorDriver = this.formatJSON(this.sectorDriver || '{}');
    this.seniorityCompatibility = this.formatJSON(
      this.seniorityCompatibility || '{}',
    );
    this.seniorityDriver = this.formatJSON(this.seniorityDriver || '{}');
    this.sourceExclusions = this.formatJSON(this.sourceExclusions || '{}');
  }

  private formatJSON(text: string) {
    try {
      return JSON.stringify(JSON.parse(text), null, 2);
    } catch {
      return text;
    }
  }
}
