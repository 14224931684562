import './LabelSelect.css';

import { ChangeEventHandler, FunctionComponent } from 'react';

export type LabelSelectProps = {
  label: string;
  name: string;
  options?: { [key: string]: string };
  optionsArray?: string[];
  value?: string;
  onChange?: ChangeEventHandler;
  className?: string;
  disabled?: boolean;
};

const LabelSelect: FunctionComponent<LabelSelectProps> = ({
  label,
  name,
  options,
  optionsArray,
  value,
  onChange,
  className,
  disabled,
}) => {
  return (
    <div className={`label-select ${className}`}>
      <label className="label-select__label" htmlFor={name}>
        {label}
      </label>
      <select
        className="label-select__select"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}>
        {options
          ? Object.keys(options).map((optionKey) => (
              <option key={optionKey} value={optionKey}>
                {options[optionKey]}
              </option>
            ))
          : optionsArray?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
      </select>
    </div>
  );
};

export default LabelSelect;
