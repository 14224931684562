import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"];
import { takeLatest, put, call, takeLeading, select } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { sessionsConfigDummies } from '../dummy';
function* loadSessionPolicyState() {
  yield put(actions.setSessionsPolicyState({
    loading: true,
    error: null
  }));
  try {
    const sessionsPolicy = yield call(api.auth.getSessionConfigurations);
    yield put(actions.setSessionsPolicyState({
      configurations: sessionsPolicy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
  }
}
function* createOrUpdateSessionsPolicy({
  payload
}) {
  yield put(actions.setSessionsPolicyState({
    loading: true,
    error: null
  }));
  try {
    const {
        callback
      } = payload,
      rest = _objectWithoutPropertiesLoose(payload, _excluded);
    yield call(api.auth.createOrUpdateSessionConfigrations, rest);
    yield put(actions.loadSessionsPolicy());
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSessionsPolicyState({
      error: e,
      loading: false
    }));
  }
}
export function* sessionsPolicySaga() {
  yield takeLeading(actions.loadSessionsPolicy, loadSessionPolicyState);
  yield takeLatest(actions.createOrUpdateSessionsPolicy, createOrUpdateSessionsPolicy);
}
function* loadSessionPolicyStateMock() {
  yield put(actions.setSessionsPolicyState({
    loading: true,
    error: null
  }));
  const sessionPolicyState = yield select(state => {
    var _state$auth$sessionsP, _state$auth;
    return (_state$auth$sessionsP = state == null ? void 0 : (_state$auth = state.auth) == null ? void 0 : _state$auth.sessionsPolicyState) != null ? _state$auth$sessionsP : sessionsConfigDummies;
  });
  yield put(actions.setSessionsPolicyState(_extends({}, sessionPolicyState, {
    loading: false
  })));
}
function* createOrUpdateSessionsPolicyMock({
  payload
}) {
  yield put(actions.setSessionsPolicyState({
    loading: true,
    error: null
  }));
  const {
      callback
    } = payload,
    rest = _objectWithoutPropertiesLoose(payload, _excluded2);
  callback == null ? void 0 : callback(true);
  yield put(actions.setSessionsPolicyState({
    configurations: rest,
    loading: false
  }));
}
export function* sessionsPolicySagaMock() {
  yield takeLeading(actions.loadSessionsPolicy, loadSessionPolicyStateMock);
  yield takeLatest(actions.createOrUpdateSessionsPolicy, createOrUpdateSessionsPolicyMock);
}