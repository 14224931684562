import { createAction } from '@reduxjs/toolkit';
import { errorsReducerForKey, loadersReducerForKey, resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const teamState = {
  loaders: {},
  pageOffset: 0,
  pageSize: 20,
  errors: {},
  users: [],
  allUsers: [],
  roles: [],
  permissions: [],
  totalPages: 0,
  filter: [],
  sort: [],
  allUsersQueryParams: {
    _filter: null,
    _offset: 0,
    _limit: 20,
    _order: 'DESC',
    _sortBy: 'name'
  },
  addUserDialogState: {
    loading: false,
    open: false
  },
  deleteUserDialogState: {
    loading: false,
    open: false
  },
  lockUserDialogState: {
    loading: false,
    open: false
  }
};
const reducers = {
  setTeamLoader: loadersReducerForKey('teamState'),
  setTeamError: errorsReducerForKey('teamState'),
  setTeamState: typeReducerForKey('teamState'),
  resetTeamState: resetStateByKey('teamState', {
    teamState
  })
};
const actions = {
  loadUsers: createAction(`${authStoreName}/loadUsers`, payload => ({
    payload
  })),
  loadAllSubTenantsUsers: createAction(`${authStoreName}/loadAllSubTenantsUsers`, payload => ({
    payload
  })),
  loadRoles: createAction(`${authStoreName}/loadRoles`, payload => ({
    payload
  })),
  addUser: createAction(`${authStoreName}/addUser`, payload => ({
    payload
  })),
  addUsersBulk: createAction(`${authStoreName}/addUsersBulk`, payload => ({
    payload
  })),
  addUserToSubTenants: createAction(`${authStoreName}/addUserToSubTenants`, payload => ({
    payload
  })),
  updateUser: createAction(`${authStoreName}/updateUser`, payload => ({
    payload
  })),
  deleteUser: createAction(`${authStoreName}/deleteUser`, payload => ({
    payload
  })),
  setUserRolesForSubTenants: createAction(`${authStoreName}/setUserRolesForSubTenants`, payload => ({
    payload
  })),
  deleteUserFromSubTenants: createAction(`${authStoreName}/deleteUserFromSubTenants`, payload => ({
    payload
  })),
  lockUser: createAction(`${authStoreName}/lockUser`, payload => ({
    payload
  })),
  resendActivationLink: createAction(`${authStoreName}/resendActivationLink`, payload => ({
    payload
  })),
  resendInvitationLink: createAction(`${authStoreName}/resendInvitationLink`, payload => ({
    payload
  })),
  resendInvitationEmail: createAction(`${authStoreName}/resendInvitationEmail`, payload => ({
    payload
  })),
  resendInvitationLinkToAllSubTenants: createAction(`${authStoreName}/resendInvitationLinkToAllSubTenants`, payload => ({
    payload
  })),
  getInvitationLink: createAction(`${authStoreName}/getInvitationLink`),
  createInvitationLink: createAction(`${authStoreName}/createInvitationLink`, payload => ({
    payload
  })),
  updateInvitationLink: createAction(`${authStoreName}/updateInvitationLink`, payload => ({
    payload
  })),
  deleteInvitationLink: createAction(`${authStoreName}/deleteInvitationLink`, payload => ({
    payload
  })),
  openAddUserDialog: createAction(`${authStoreName}/openAddUserDialog`, payload => ({
    payload
  })),
  closeAddUserDialog: createAction(`${authStoreName}/closeAddUserDialog`, payload => ({
    payload
  })),
  openDeleteUserDialog: createAction(`${authStoreName}/openDeleteUserDialog`, payload => ({
    payload
  })),
  closeDeleteUserDialog: createAction(`${authStoreName}/closeDeleteUserDialog`, payload => ({
    payload
  })),
  openLockUserDialog: createAction(`${authStoreName}/openLockUserDialog`, payload => ({
    payload
  })),
  closeLockUserDialog: createAction(`${authStoreName}/closeLockUserDialog`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { teamState, reducers as teamReducers, actions as teamActions };