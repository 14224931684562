export class ContextHolder {
  constructor() {
    this.context = null;
    this.accessToken = null;
    this.user = null;

    this.onRedirectTo = path => window.location.href = path;

    this.logout = () => window.location.href = '/account/logout';
  }

  static getInstance() {
    if (!ContextHolder.instance) {
      ContextHolder.instance = new ContextHolder();
    }

    return ContextHolder.instance;
  }

  static setContext(context) {
    ContextHolder.getInstance().context = context;
  }

  static setAccessToken(accessToken) {
    ContextHolder.getInstance().accessToken = accessToken;
  }

  static setUser(user) {
    ContextHolder.getInstance().user = user;
  }

  static setOnRedirectTo(onRedirectTo) {
    ContextHolder.getInstance().onRedirectTo = onRedirectTo;
  }

  static setLogout(logout, logoutUrl) {
    ContextHolder.getInstance().logout = callback => {
      if (!callback) {
        ContextHolder.onRedirectTo(logoutUrl);
      } else {
        logout(callback);
      }
    };
  }

  static getContext() {
    var _ContextHolder$getIns;

    return (_ContextHolder$getIns = ContextHolder.getInstance().context) != null ? _ContextHolder$getIns : {
      baseUrl: window.location.href,
      tokenResolver: () => 'my-authentication-token',
      logLevel: 'error'
    };
  }

  static getAccessToken() {
    return ContextHolder.getInstance().accessToken;
  }

  static getUser() {
    return ContextHolder.getInstance().user;
  }

  static onRedirectTo(path, opts) {
    return ContextHolder.getInstance().onRedirectTo(path, opts);
  }

  static logout(callback) {
    return ContextHolder.getInstance().logout(callback);
  }

}
ContextHolder.instance = void 0;
export const FronteggContext = {
  getContext: () => ContextHolder.getContext(),
  getAccessToken: () => ContextHolder.getAccessToken(),
  getUser: () => ContextHolder.getUser(),
  onRedirectTo: (path, opts) => ContextHolder.onRedirectTo(path, opts),
  logout: callback => ContextHolder.logout(callback)
};