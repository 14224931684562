import './Avatar.css';

import { AvatarData } from '../../models/Member';
import { FunctionComponent } from 'react';
import vocoAvatarURL from '../../svgs/voco-avatar.svg';

export type AvatarProps = {
  memberAvatar?: AvatarData;
  relatedMember?: AvatarData;
  className?: string;
};

const Avatar: FunctionComponent<AvatarProps> = ({
  memberAvatar,
  relatedMember,
  className,
}) => {
  return (
    <div className={`avatar ${className}`}>
      {memberAvatar && relatedMember ? (
        <div className="avatar__avatar-stacked">
          <img
            className="avatar__avatar-image avatar__avatar-stacked--main"
            src={memberAvatar.url}
            alt={memberAvatar.alt}
          />
          <img
            className="avatar__avatar-image avatar__avatar-stacked--secondary"
            src={relatedMember.url}
            alt={relatedMember.alt}
          />
        </div>
      ) : memberAvatar ? (
        <img
          className="avatar__avatar-image"
          src={memberAvatar.url}
          alt={memberAvatar.alt}
        />
      ) : (
        <img
          className="avatar__avatar-image"
          src={vocoAvatarURL}
          alt="Team Voco"
        />
      )}
    </div>
  );
};

export default Avatar;
