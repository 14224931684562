import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["endpoint", "headerProps"];
import { Get, PostDownload } from '../fetch';
import { urls } from '../constants';
export async function getAudits(params) {
  return Get(urls.audits.v1, params);
}
export async function getAuditsStats(params) {
  return Get(`${urls.audits.v1}/stats`, params);
}
export async function exportAudits(params) {
  const {
    endpoint,
    headerProps
  } = params,
        restParams = _objectWithoutPropertiesLoose(params, _excluded);

  return PostDownload(`${urls.audits.v1}/export/${endpoint}`, {
    properties: headerProps
  }, restParams);
}