import './EditGoalsModal.css';

import { FunctionComponent, useEffect, useState } from 'react';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';
import { Goal } from '../../models/Goal';

export type EditGoalModalProps = {
  goals: Goal[] | undefined;
  updateGoals: Function;
  updatingGoals: boolean;
};

const EditGoalModal: FunctionComponent<EditGoalModalProps> = ({
  goals,
  updateGoals,
  updatingGoals,
}) => {
  const [editableGoals, setEditableGoals] = useState<Goal[]>();
  const LIMIT = 70;

  // LOAD INITIAL GOAL DATA
  useEffect(() => {
    if (goals && Array.isArray(goals)) {
      const initialGoals: Goal[] = [];
      for (let index = 0; index < 4; index++) {
        if (goals[index]?.id) {
          const goal = goals[index];
          initialGoals[index] = new Goal({ ...goal, sortOrder: index });
        } else {
          initialGoals[index] = new Goal({ sortOrder: index });
        }
      }
      setEditableGoals(initialGoals);
    }
  }, [goals]);

  // HANDLE EDITS TO TITLES
  const updateGoalTitle: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    event.preventDefault();
    const editedGoals = editableGoals?.map((goal) => {
      if (
        goal.sortOrder === parseInt(event.target.name.replace('goal_', ''), 10)
      ) {
        return new Goal({ ...goal, title: event.target.value.slice(0, LIMIT) });
      }
      return new Goal({ ...goal });
    });
    setEditableGoals(editedGoals);
  };

  // SAVE GOAL UPDATES
  const save: ModalActionFunction = async (event, done) => {
    if (!editableGoals) {
      return;
    }

    // Only include goals that have a title and reindex sortOrder
    const goalsToSave = editableGoals
      .filter((goal) => goal.id || goal.title)
      .map((goal, index) => new Goal({ ...goal, sortOrder: index }));

    const result = await updateGoals({
      variables: { goals: goalsToSave },
    });
    if (!result?.data?.updateGoals?.success) {
      console.error(result);
    }
    return done();
  };

  return (
    <Modal
      actionInProgress={updatingGoals}
      title="Goals"
      action={save}
      returnURL="/profile">
      <div>
        {editableGoals?.map((goal) => (
          <div key={goal.id} className="edit-goal-modal__goal">
            <div className="edit-goal-modal__goal-number">
              #{goal.sortOrder + 1}
            </div>
            <textarea
              className="edit-goal-modal__goal-title"
              key={goal.sortOrder}
              name={`goal_${goal.sortOrder}`}
              value={goal.title}
              onChange={updateGoalTitle}
              disabled={updatingGoals}></textarea>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default EditGoalModal;
