import { FunctionComponent, useEffect, useState } from 'react';
import { FeedItem } from '../../models/FeedItem';
import { JournalEntry } from '../../models/JournalEntry';
import { Member } from '../../models/Member';
import { Message } from '../../models/Message';
import JournalEntryCard from '../JournalEntryCard/JournalEntryCard';

import './Journal.css';

export type JournalProps = {
  feedItems?: FeedItem[];
  members?: Member[];
  selectedMember?: string;
  me?: Member;
  className?: string;
};

const Journal: FunctionComponent<JournalProps> = ({
  feedItems = [],
  members,
  selectedMember,
  me,
  className,
}) => {
  const [filteredItems, setFilteredItems] = useState<FeedItem[]>([]);
  const [memberExclusions, setMemberExclusions] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (members && members.length > 1) {
      const exclusions = members.reduce<{ [key: string]: boolean }>(
        (acc, member) => {
          acc[member.id as keyof typeof acc] =
            // User is an excluded member

            me?.exludedMatchIds.includes(member.id) ||
            // User has neither previous or upcomming meeting
            (!member.lastMatchMeeting && !member.nextMatchMeeting);
          return acc;
        },
        {},
      );

      setMemberExclusions(exclusions);
    }
  }, [me, members]);

  useEffect(() => {
    if (feedItems.length > 0) {
      setFilteredItems([
        ...feedItems.filter((item) => {
          if (
            members &&
            item.relatedMember?.id &&
            memberExclusions[
              item.relatedMember?.id as keyof typeof memberExclusions
            ]
          ) {
            return false;
          }
          if (!selectedMember) {
            return true;
          }
          if (item instanceof JournalEntry) {
            if (selectedMember === 'you') {
              return [
                JournalEntry.FEEDBACK_GIVEN,
                JournalEntry.NOTES_MADE,
                JournalEntry.PROFILE_UPDATED,
              ].includes(item.journalType);
            }

            if (selectedMember === 'voco') {
              return !item.relatedMember;
            }

            if (selectedMember) {
              return selectedMember === item.relatedMember?.id;
            }
          }

          if (item instanceof Message) {
            if (selectedMember === 'you') {
              return item.member.id === me?.id;
            }
          }

          return false;
        }),
      ]);
    }
  }, [selectedMember, feedItems, me?.id, members, memberExclusions]);

  return (
    <div className={`journal${className ? ' ' + className : ''}`}>
      {filteredItems.map((item) => (
        <JournalEntryCard key={item.id} feedItem={item} />
      ))}
    </div>
  );
};

export default Journal;
