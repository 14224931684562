import { securityPolicyActions, securityPolicyReducers } from '@frontegg/redux-store';
import { reducerActionsGenerator, stateHookGenerator } from './hooks';
import { useEffect } from 'react';
const defaultMapper = state => state;
export function useSecurityPolicyState(stateMapper = defaultMapper) {
  return stateHookGenerator(stateMapper, 'securityPolicyState');
}
export const useSecurityPolicyActions = () => reducerActionsGenerator(securityPolicyActions, securityPolicyReducers);
export const usePublicPolicySettings = (loadOnMount = false) => {
  const {
    loading,
    policy,
    saving,
    error
  } = useSecurityPolicyState(state => state.publicPolicy);
  const {
    loadPublicSecurityPolicy
  } = useSecurityPolicyActions();
  useEffect(() => {
    (loadOnMount || !policy) && loadPublicSecurityPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnMount, loadPublicSecurityPolicy]);
  return {
    loading,
    saving,
    error,
    policy
  };
};
export const usePublicAuthStrategiesPolicySettings = (loadOnMount = false) => {
  const {
    loading,
    policy,
    saving,
    error
  } = useSecurityPolicyState(state => state.publicAuthStrategyPolicy);
  const {
    loadPublicAuthStrategiesPolicy
  } = useSecurityPolicyActions();
  useEffect(() => {
    (loadOnMount || !policy) && loadPublicAuthStrategiesPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnMount, loadPublicAuthStrategiesPolicy]);
  return {
    loading,
    saving,
    error,
    policy
  };
};