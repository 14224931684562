import './InfoPenPortraitModal.css';

import { FunctionComponent } from 'react';
import Modal from '../../components/Modal/Modal';

export type InfoPenPortraitProps = {};

const InfoPenPortrait: FunctionComponent<InfoPenPortraitProps> = () => {
  return (
    <Modal title="What is a Pen Portrait?" returnURL="/profile">
      <div className="info-pen-portrait">
        <p className="info-pen-portrait__text">
          Understanding yourself is key to knowing what you want at work. A pen
          portrait is an informal sketch of your personality type but it
          provides topline insights into whether you get your energy from
          yourself or from others, how you like to organise your life, and
          whether you're most often led by your heart or by your head.
        </p>
        <p className="info-pen-portrait__text">
          Knowing more about yourself also helps you build stronger
          relationships and understand others, better. Your pen portrait
          reflects the answers you gave when you joined Voco.{' '}
          <a
            className="link"
            href={'https://www.joinvoco.io/personality-pen-portrait'}
            target="_blank"
            rel="noreferrer">
            click here
          </a>{' '}
          to and understand what really drives you!
        </p>
      </div>
    </Modal>
  );
};

export default InfoPenPortrait;
