import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import dayjs from 'dayjs';
import jwt, { JwtPayload } from 'jsonwebtoken';

const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;

const link = new HttpLink({ uri: `${REACT_APP_ENDPOINT}/graphql` });
const cache = new InMemoryCache({
  typePolicies: {
    Member: {
      fields: {
        actions: {
          merge(existing, incoming) {
            // console.log('existing', existing);
            // console.log('incoming', incoming);
            return incoming;
          },
        },
      },
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('AUTH_TOKEN');

  if (!token) {
    return headers;
  }

  const decodedToken = jwt.decode(token || '', { json: true }) as JwtPayload;

  if (!decodedToken || !decodedToken.sub) {
    return headers;
  }

  // If token has expired then to not send to server
  if (!decodedToken.exp || dayjs.unix(decodedToken.exp).isBefore(dayjs())) {
    return headers;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache,
  connectToDevTools: true,
});

export default apolloClient;
