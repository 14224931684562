import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback"];
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { delay } from '../utils';
import { policyDemo, policyMfaDemo, policyLockoutDemo, policyPasswordHistoryDemo, publicSecurityPolicy } from '../dummy';
import { getFeatureFlags } from '../../helpers';
function* loadSecurityPolicy() {
  yield put(actions.setSecurityPolicyGlobalState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getGlobalSecurityPolicy);
    yield put(actions.setSecurityPolicyGlobalState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyGlobalState({
      error: e.message,
      loading: false
    }));
  }
  yield put(actions.loadSecurityPolicyMfa());
  yield put(actions.loadSecurityPolicyVendorMfa());
  yield put(actions.loadSecurityPolicyLockout());
  yield put(actions.loadSecurityPolicyVendorLockout());
  yield put(actions.loadSecurityPolicyVendorPasswordHistory());
  yield put(actions.loadSecurityPolicyCaptcha());
  yield put(actions.loadPublicAuthStrategiesPolicy());
}
export function* loadPublicSecurityPolicy() {
  yield put(actions.setSecurityPolicyPublicState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getVendorConfig);
    const [withLoginPerTenant] = yield call(getFeatureFlags, ['admin_portal_login_per_tenant']);
    if (withLoginPerTenant) {
      const authStrategies = yield call(api.auth.getVendorPublicAuthStrategiesConfig);
      if (authStrategies.mainAuthStrategies.length > 0) {
        policy.authStrategy = authStrategies.mainAuthStrategies[0].strategy;
      }
    }
    yield put(actions.setSecurityPolicyPublicState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyPublicState({
      error: e.message,
      loading: false
    }));
  }
}
export function* loadPublicAuthStrategiesPolicy() {
  yield put(actions.setSecurityPolicyAuthStrategyPublicState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getVendorPublicAuthStrategiesConfig);
    yield put(actions.setSecurityPolicyAuthStrategyPublicState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyAuthStrategyPublicState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyMfa() {
  yield put(actions.setSecurityPolicyMfaState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getMfaPolicy);
    yield put(actions.setSecurityPolicyMfaState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyMfaState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyVendorMfa() {
  yield put(actions.setSecurityPolicyMfaState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getVendorMfaPolicy);
    yield put(actions.setSecurityPolicyVendorMfaState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyVendorMfaState({
      error: e.message,
      loading: false
    }));
  }
}
function* saveSecurityPolicyMfa(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setSecurityPolicyMfaState({
    saving: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.saveMfaPolicy, newSecurityPolicy);
    yield put(actions.setSecurityPolicyMfaState({
      policy,
      saving: false
    }));
    callback == null ? void 0 : callback(policy);
  } catch (e) {
    yield put(actions.setSecurityPolicyMfaState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* loadSecurityPolicyLockout() {
  yield put(actions.setSecurityPolicyLockoutState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getLockoutPolicy);
    yield put(actions.setSecurityPolicyLockoutState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyLockoutState({
      error: e.message,
      loading: false
    }));
  }
}
function* saveSecurityPolicyLockout(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setSecurityPolicyLockoutState({
    saving: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.saveLockoutPolicy, newSecurityPolicy);
    yield put(actions.setSecurityPolicyLockoutState({
      policy,
      saving: false
    }));
    callback == null ? void 0 : callback(policy);
  } catch (e) {
    yield put(actions.setSecurityPolicyLockoutState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* loadSecurityPolicyVendorLockout() {
  yield put(actions.setSecurityPolicyVendorLockoutState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getVendorLockoutPolicy);
    yield put(actions.setSecurityPolicyVendorLockoutState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyVendorLockoutState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyCaptcha() {
  yield put(actions.setSecurityPolicyLockoutState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getCaptchaPolicy);
    yield put(actions.setSecurityPolicyCaptchaState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyCaptchaState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyPasswordHistory() {
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getPasswordHistoryPolicy);
    yield put(actions.setSecurityPolicyPasswordHistoryState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyPasswordHistoryState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyVendorPasswordHistory() {
  yield put(actions.setSecurityPolicyVendorPasswordHistoryState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getVendorPasswordHistoryPolicy);
    yield put(actions.setSecurityPolicyVendorPasswordHistoryState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyVendorPasswordHistoryState({
      error: e.message,
      loading: false
    }));
  }
}
function* saveSecurityPolicyPasswordHistory(_ref3) {
  let {
      payload: {
        callback
      }
    } = _ref3,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    saving: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.savePasswordHistoryPolicy, newSecurityPolicy);
    yield put(actions.setSecurityPolicyPasswordHistoryState({
      policy,
      saving: false
    }));
    callback == null ? void 0 : callback(policy);
  } catch (e) {
    yield put(actions.setSecurityPolicyPasswordHistoryState({
      saving: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* loadVendorPasswordConfig() {
  yield put(actions.setSecurityPolicyPasswordState({
    loading: true,
    error: null
  }));
  try {
    const policy = yield call(api.auth.getPasswordConfigPolicy);
    yield put(actions.setSecurityPolicyPasswordState({
      policy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyPasswordState({
      error: e.message,
      loading: false
    }));
  }
}
export function* securityPolicySagas() {
  yield takeLeading(actions.loadSecurityPolicy, loadSecurityPolicy);
  yield takeEvery(actions.saveSecurityPolicyMfa, saveSecurityPolicyMfa);
  yield takeEvery(actions.loadSecurityPolicyMfa, loadSecurityPolicyMfa);
  yield takeEvery(actions.loadSecurityPolicyVendorMfa, loadSecurityPolicyVendorMfa);
  yield takeEvery(actions.saveSecurityPolicyLockout, saveSecurityPolicyLockout);
  yield takeEvery(actions.loadSecurityPolicyLockout, loadSecurityPolicyLockout);
  yield takeEvery(actions.loadSecurityPolicyVendorLockout, loadSecurityPolicyVendorLockout);
  yield takeEvery(actions.loadSecurityPolicyCaptcha, loadSecurityPolicyCaptcha);
  yield takeEvery(actions.saveSecurityPolicyPasswordHistory, saveSecurityPolicyPasswordHistory);
  yield takeEvery(actions.loadSecurityPolicyPasswordHistory, loadSecurityPolicyPasswordHistory);
  yield takeEvery(actions.loadSecurityPolicyVendorPasswordHistory, loadSecurityPolicyVendorPasswordHistory);
  yield takeEvery(actions.loadVendorPasswordConfig, loadVendorPasswordConfig);
  yield takeEvery(actions.loadPublicSecurityPolicy, loadPublicSecurityPolicy);
  yield takeEvery(actions.loadPublicAuthStrategiesPolicy, loadPublicAuthStrategiesPolicy);
}

/*********************************
 *  Preview Sagas
 *********************************/

export function* loadPublicSecurityPolicyMock() {
  yield put(actions.setSecurityPolicyPublicState({
    loading: true,
    error: null
  }));
  try {
    yield put(actions.setSecurityPolicyPublicState({
      policy: publicSecurityPolicy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSecurityPolicyPublicState({
      error: e.message,
      loading: false
    }));
  }
}
function* loadSecurityPolicyMock() {
  yield put(actions.setSecurityPolicyGlobalState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyGlobalState({
    policy: policyDemo,
    loading: false
  }));
  yield put(actions.loadSecurityPolicyMfa());
  yield put(actions.loadSecurityPolicyVendorMfa());
  yield put(actions.loadSecurityPolicyLockout());
  yield put(actions.loadSecurityPolicyVendorLockout());
  yield put(actions.loadSecurityPolicyPasswordHistory());
  yield put(actions.loadSecurityPolicyVendorPasswordHistory());
  yield put(actions.loadSecurityPolicyCaptcha());
}
function* loadSecurityPolicyMfaMock() {
  yield put(actions.setSecurityPolicyMfaState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyMfaState({
    policy: policyMfaDemo,
    loading: false
  }));
}
function* loadSecurityPolicyVendorMfaMock() {
  yield put(actions.setSecurityPolicyVendorMfaState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyVendorMfaState({
    policy: policyMfaDemo,
    loading: false
  }));
}
function* saveSecurityPolicyMfaMock(_ref4) {
  var _newSecurityPolicy$id;
  let {
      payload: {
        callback
      }
    } = _ref4,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref4.payload, _excluded4);
  yield put(actions.setSecurityPolicyMfaState({
    saving: true,
    error: null
  }));
  const policy = _extends({}, policyLockoutDemo, {
    id: (_newSecurityPolicy$id = newSecurityPolicy.id) != null ? _newSecurityPolicy$id : policyLockoutDemo.id,
    enforceMFAType: newSecurityPolicy.enforceMFAType
  });
  yield delay();
  yield put(actions.setSecurityPolicyMfaState({
    policy,
    saving: false
  }));
  callback == null ? void 0 : callback(policy);
}
function* loadSecurityPolicyLockoutMock() {
  yield put(actions.setSecurityPolicyLockoutState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyLockoutState({
    policy: policyLockoutDemo,
    loading: false
  }));
}
function* loadSecurityPolicyVendorLockoutMock() {
  yield put(actions.setSecurityPolicyVendorLockoutState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyVendorLockoutState({
    policy: policyLockoutDemo,
    loading: false
  }));
}
function* saveSecurityPolicyLockoutMock(_ref5) {
  let {
      payload: {
        callback
      }
    } = _ref5,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref5.payload, _excluded5);
  yield put(actions.setSecurityPolicyLockoutState({
    saving: true,
    error: null
  }));
  yield delay();
  const policy = _extends({}, policyLockoutDemo, newSecurityPolicy, {
    id: newSecurityPolicy.id ? newSecurityPolicy.id : policyLockoutDemo.id
  });
  callback == null ? void 0 : callback(policy);
  yield put(actions.setSecurityPolicyLockoutState({
    policy,
    saving: false
  }));
}
function* loadSecurityPolicyCaptchaMock() {
  yield put(actions.setSecurityPolicyLockoutState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyLockoutState({
    policy: policyLockoutDemo,
    loading: false
  }));
}
function* loadSecurityPolicyPasswordHistoryMock() {
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    policy: policyPasswordHistoryDemo,
    loading: false
  }));
}
function* loadSecurityPolicyVendorPasswordHistoryMock() {
  yield put(actions.setSecurityPolicyVendorPasswordHistoryState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setSecurityPolicyVendorPasswordHistoryState({
    policy: policyPasswordHistoryDemo,
    loading: false
  }));
}
function* saveSecurityPolicyPasswordHistoryMock(_ref6) {
  let {
      payload: {
        callback
      }
    } = _ref6,
    newSecurityPolicy = _objectWithoutPropertiesLoose(_ref6.payload, _excluded6);
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    saving: true,
    error: null
  }));
  yield delay();
  const policy = _extends({}, policyPasswordHistoryDemo, newSecurityPolicy, {
    id: newSecurityPolicy.id ? newSecurityPolicy.id : policyPasswordHistoryDemo.id
  });
  callback == null ? void 0 : callback(policy);
  yield put(actions.setSecurityPolicyPasswordHistoryState({
    policy,
    saving: false
  }));
}
export function* securityPolicySagasMock() {
  yield takeLeading(actions.loadSecurityPolicy, loadSecurityPolicyMock);
  yield takeEvery(actions.loadPublicSecurityPolicy, loadPublicSecurityPolicyMock);
  yield takeEvery(actions.saveSecurityPolicyMfa, saveSecurityPolicyMfaMock);
  yield takeEvery(actions.loadSecurityPolicyMfa, loadSecurityPolicyMfaMock);
  yield takeEvery(actions.loadSecurityPolicyVendorMfa, loadSecurityPolicyVendorMfaMock);
  yield takeEvery(actions.saveSecurityPolicyLockout, saveSecurityPolicyLockoutMock);
  yield takeEvery(actions.loadSecurityPolicyLockout, loadSecurityPolicyLockoutMock);
  yield takeEvery(actions.loadSecurityPolicyVendorLockout, loadSecurityPolicyVendorLockoutMock);
  yield takeEvery(actions.loadSecurityPolicyCaptcha, loadSecurityPolicyCaptchaMock);
  yield takeEvery(actions.saveSecurityPolicyPasswordHistory, saveSecurityPolicyPasswordHistoryMock);
  yield takeEvery(actions.loadSecurityPolicyPasswordHistory, loadSecurityPolicyPasswordHistoryMock);
  yield takeEvery(actions.loadSecurityPolicyVendorPasswordHistory, loadSecurityPolicyVendorPasswordHistoryMock);
}