import './CareerInterests.css';
import { FunctionComponent } from 'react';

export type CareerInterestsProps = {
  interests: string[] | undefined;
};

const CareerInterests: FunctionComponent<CareerInterestsProps> = ({
  interests,
}) => {
  return (
    <div className="career-interests">
      {interests
        ? interests.map((i, index) => (
            <span className="career-interests__interest" key={index}>
              {i}
            </span>
          ))
        : null}
    </div>
  );
};

export default CareerInterests;
