import './LabelInput.css';

import { ChangeEventHandler, FunctionComponent } from 'react';

export type LabelInputProps = {
  label: string;
  name: string;
  value?: string;
  onChange?: ChangeEventHandler;
  type?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  inline?: boolean;
};

const LabelInput: FunctionComponent<LabelInputProps> = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  className,
  disabled,
  placeholder,
  inline = false,
}) => {
  return (
    <div
      className={`label-input ${className} ${
        inline ? 'label-input--inline' : ''
      }`}>
      <label className="label-input__label" htmlFor={name}>
        {label}
      </label>
      <input
        className="label-input__input"
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default LabelInput;
