import './MeetingPage.css';

import {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import SVG from 'react-inlinesvg';

import useMeeting from '../../hooks/useMeeting';
import { useAuthUserOrNull } from '@frontegg/react';
import { Member } from '../../models/Member';
import Button from '../../components/Button/Button';
import Avatar from '../../components/Avatar/Avatar';

import LinkButton from '../../components/Button/LinkButton';
import Loader from '../../components/Loader/Loader';
import Journal from '../../components/Journal/Journal';
import useFeed from '../../hooks/useFeed';

import TitleCard from '../../components/TitleCard/TitleCard';
import ThemeContent from '../../components/Theme/ThemeContent';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { config } from '../../config/config';

import plusIcon from '../../svgs/icon-plus.svg';
import meetingLogo from '../../svgs/logo-meeting.svg';
import useTheme from '../../hooks/useTheme';
import { Theme } from '../../models/Theme';
import { MeetingEvent, MeetingEventType } from '../../models/Meeting';
import { MeetingCallout } from '../../components/MeetingCallout/MeetingCallout';

export type MeetingPageProps = {};

const MeetingPage: FunctionComponent<MeetingPageProps> = () => {
  const user = useAuthUserOrNull();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { sendJsonMessage, lastJsonMessage } = useWebSocket<MeetingEvent>(
    config.wssEndpoint,
    {
      queryParams: { meetingId: id || '' },
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    },
  );

  const {
    loadAnonymousMeeting,
    loadMeeting,
    meeting,
    loading,
    refetchAnonymousMeeting,
    refetchMeeting,
  } = useMeeting(id);
  const { loadFeed, feedItems } = useFeed();
  const { loadTheme, theme } = useTheme();

  const [mainUser, setMainUser] = useState<Member>();
  const [otherUser, setOtherUser] = useState<Member>();
  const [me, setMe] = useState<Member>();
  const [showWelcomePage, setShowWelcomePage] = useState(true);
  const [showCallout, setShowCallout] = useState(false);
  const [offPiste, setOffPiste] = useState(false);
  const [intendedAdHoc, setIntendedAdHoc] = useState(false);
  const [firstHereCallout, setFirstHereCallout] = useState(false);
  const [firstCalloutHidden, setFirstCalloutHidden] = useState(false);
  const timerRef = useRef<NodeJS.Timer>();

  /**
   * Hack to allow for weird characters added by Active Campaign
   */
  useEffect(() => {
    if (location.pathname.endsWith('schedule%E2%80%8B')) {
      navigate(`/meeting/${id}/schedule`);
    }
    if (location.pathname.endsWith('reschedule%E2%80%8B')) {
      navigate(`/meeting/${id}/reschedule`);
    }
  }, [location, navigate, id]);

  useEffect(() => {
    if (!loadAnonymousMeeting || !loadMeeting) {
      return;
    }
    if (user) {
      loadMeeting();
    } else {
      loadAnonymousMeeting();
    }
  }, [user, loadAnonymousMeeting, loadMeeting]);

  useEffect(() => {
    if (meeting) {
      let ou: Member | undefined;
      let m: Member | undefined;

      if (meeting.me) {
        // See if we are a member of the meeting
        m = meeting.members.find((member) => member.id === meeting.me?.id);
      }

      if (m) {
        // We're logged in and a member of this meeting
        setMe(m);
        // Find the other user
        ou = meeting.members.find((member) => member.id !== meeting.me?.id);
        if (ou) {
          loadFeed({ variables: { relatedMemberId: ou?.id } });
        }
      } else {
        // We are either not logged in or not invited to this meeting
        m = meeting.members[0];
        ou = meeting.members[1];
      }

      setOtherUser(ou);
      setMainUser(m);

      if (meeting.themeSlug) {
        loadTheme({ variables: { slug: meeting.themeSlug } });
      }

      if (!meeting.originalThemeSlug && meeting.themeSlug === Theme.AD_HOC) {
        setIntendedAdHoc(true);
      }

      if (
        meeting.originalThemeSlug &&
        meeting.originalThemeSlug !== meeting.themeSlug
      ) {
        setOffPiste(true);
      } else {
        setOffPiste(false);
      }
    }
  }, [meeting, loadFeed, loadTheme]);

  /**
   * SOCKET
   */

  useEffect(() => {
    if (lastJsonMessage?.type === 'THEME_CHANGE') {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      const { themeSlug } = lastJsonMessage.meta;
      setShowCallout(false);
      loadTheme({ variables: { slug: themeSlug } });
      setOffPiste(
        lastJsonMessage.meta.themeSlug !== meeting?.originalThemeSlug,
      );
      if (meeting?.originalThemeSlug) {
        return;
      }
      if (meeting?.me) {
        refetchMeeting();
      } else {
        refetchAnonymousMeeting();
      }
    }
  }, [
    lastJsonMessage,
    loadTheme,
    meeting?.me,
    meeting?.originalThemeSlug,
    refetchAnonymousMeeting,
    refetchMeeting,
  ]);

  useEffect(() => {
    let timer: any;
    const handleMeetingStart = ({ data, origin }: MessageEvent) => {
      if (origin === 'https://voco.whereby.com') {
        if (
          !intendedAdHoc &&
          !meeting?.originalThemeSlug &&
          data?.type === 'participantupdate' &&
          data?.payload?.count > 1
        ) {
          timerRef.current = setTimeout(() => {
            setShowCallout(true);
          }, 60 * 1000);
        }
        if (data?.type === 'participantupdate' && data?.payload?.count === 1) {
          setFirstHereCallout(true);
        }
        if (data?.type === 'participantupdate' && data?.payload?.count > 1) {
          setFirstHereCallout(false);
          setFirstCalloutHidden(true);
        }
      }
    };
    window.addEventListener('message', handleMeetingStart);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      window.removeEventListener('message', handleMeetingStart);
    };
  }, [intendedAdHoc, meeting]);

  const handleWelcomeStart = () => {
    setShowWelcomePage(false);
  };

  const handleLogin: MouseEventHandler<HTMLButtonElement> = () => {
    localStorage.setItem('AUTH_LOCATION', location.pathname);
    navigate('/account/login');
  };

  const handleChangeTheme = (slug?: string) => {
    if (meeting && slug && theme?.slug) {
      sendJsonMessage({
        action: 'sendmessage',
        data: {
          type: MeetingEventType.THEME_CHANGE,
          eventAt: new Date().toISOString(),
          memberId: meeting.me?.id,
          meetingId: meeting.id,
          meta: {
            themeSlug: slug,
            previousThemeSlug: theme.slug,
          },
        } satisfies MeetingEvent,
      });
    }
    loadTheme({ variables: { slug } });
    setShowCallout(false);
  };

  const toggleCallout = () => {
    setShowCallout(!showCallout);
  };

  return (
    <>
      <div
        className={`meeting-wrapper${
          !me ? ' meeting-wrapper--anonymous' : ''
        }`}>
        <div className="meeting-nav">
          <img
            src={meetingLogo}
            alt="Voco"
            className="meeting-nav__logo"
            onClick={toggleCallout}
          />
          {!intendedAdHoc && showCallout ? (
            <>
              {offPiste ? (
                <MeetingCallout
                  highlight={true}
                  title="Cool, you're off-piste!"
                  dismissHandler={() => setShowCallout(false)}
                  linkAction={() =>
                    handleChangeTheme(meeting?.originalThemeSlug)
                  }
                  linkText="Go back to suggest topic">
                  <p>
                    The talking points below will help you talk about anything
                    you want to discuss.
                  </p>
                </MeetingCallout>
              ) : (
                <MeetingCallout
                  title={`Suggested Topic: ${theme?.title}`}
                  dismissHandler={() => setShowCallout(false)}
                  linkAction={() => handleChangeTheme(Theme.AD_HOC)}
                  linkText="Want to talk about something else?">
                  <p>
                    There are some talking points below to help you make the
                    most of your conversation.
                  </p>
                </MeetingCallout>
              )}
            </>
          ) : null}
          {firstHereCallout && !firstCalloutHidden ? (
            <MeetingCallout
              title={`Looks like you got here first 👊`}
              dismissHandler={() => {
                setFirstHereCallout(false);
                setFirstCalloutHidden(true);
              }}>
              <p>Sit tight, we're just waiting for others to join.</p>
            </MeetingCallout>
          ) : null}
        </div>
        {meeting && meeting.embedURL ? (
          <>
            {showWelcomePage ? (
              <div className="meeting-frame meeting-frame--welcome">
                <div className="meeting__welcome">
                  <div className="meeting__welcome-content">
                    <div className="meeting__welcome-avatars">
                      <Avatar
                        memberAvatar={mainUser?.avatar}
                        className="meeting__welcome-avatar"
                      />
                      <SVG
                        className="meeting__welcome-avatar-plus"
                        src={plusIcon}
                      />
                      <Avatar
                        memberAvatar={otherUser?.avatar}
                        className="meeting__welcome-avatar"
                      />
                    </div>
                    <div className="meeting__welcome-info">
                      <h2 className="h2">
                        Call: {mainUser?.firstName} + {otherUser?.firstName}
                      </h2>
                    </div>
                    <div className="meeting__welcome-date">
                      <h3 className="h3">
                        Meeting start {meeting.meetingStartAt?.format('ha')},{' '}
                        {meeting.meetingStartAt?.format('DD MMMM YYYY')}
                      </h3>
                    </div>
                    {user ? (
                      <Button
                        action={handleWelcomeStart}
                        label="Start"
                        className="meeting__welcome-button"
                      />
                    ) : (
                      <>
                        <Button
                          action={handleLogin}
                          label="Login for best experience"
                          className="meeting__welcome-button"
                        />
                        <LinkButton
                          action={handleWelcomeStart}
                          label="Or start call now without logging in"
                          className="meeting__welcome-link"
                        />
                      </>
                    )}
                  </div>
                  <div className="meeting__welcome-speech"></div>
                </div>
              </div>
            ) : (
              <>
                <div className="meeting">
                  {loading ? null : (
                    <iframe
                      title="Meeting"
                      className="meeting-frame meeting-frame-expands"
                      src={`${
                        meeting.embedURL
                      }?settingsButton=off&floatSelf=on&roomIntegrations=on&participantCount=off&locking=off&logo=off&leaveButton=on&video=on&audio=on${
                        me?.fullName
                          ? '&displayName=' + encodeURI(me?.fullName)
                          : null
                      }`}
                      allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
                    //
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="meeting-frame meeting-frame--welcome">
            {loading ? (
              <Loader color="#fff" />
            ) : (
              <div className="meeting__welcome">
                <div className="meeting__welcome-content">
                  <div className="meeting__welcome-info">
                    <h2 className="h2">Meeting not found</h2>
                  </div>
                  <Button
                    action={() => navigate('/')}
                    label="OK"
                    className="meeting__welcome-button"
                  />
                </div>
                <div className="meeting__welcome-speech"></div>
              </div>
            )}
          </div>
        )}
      </div>

      <section className="meeting-content">
        {theme ? (
          <TitleCard
            className="meeting-content__title"
            title={theme.name}
            subtitle="Talking points"
            colour={theme.isAdhoc ? 'dark-orange' : 'orange'}
            link={
              theme.guide?.slug ? `/guides/${theme.guide?.slug}` : undefined
            }
            linkTitle="Deep Dive"
            linkTarget="_blank"
            pageAlignContent={true}
          />
        ) : null}
        <div className="content-wrapper">
          <div className="meeting_content__buttons content-wrapper">
            {me && otherUser && id ? (
              <>
                <Button
                  className={`meeting_content__button${
                    location.pathname.endsWith(id)
                      ? ' meeting_content__button--active'
                      : ''
                  }`}
                  link={`/meeting/${id}`}
                  label="Talking Points"
                  local
                />
                <Button
                  className={`meeting_content__button${
                    location.pathname.endsWith('/notes')
                      ? ' meeting_content__button--active'
                      : ''
                  }`}
                  link={`/meeting/${id}/notes`}
                  label="Notes"
                  local
                />
              </>
            ) : null}
            {!intendedAdHoc ? (
              offPiste ? (
                <LinkButton
                  className="meeting_content__link"
                  action={() => handleChangeTheme(meeting?.originalThemeSlug)}>
                  Return To Suggested Talking Points
                </LinkButton>
              ) : (
                <LinkButton
                  className="meeting_content__link"
                  action={() => handleChangeTheme(Theme.AD_HOC)}>
                  Not Talking About {theme?.name}?
                </LinkButton>
              )
            ) : null}
          </div>
          <Routes>
            <Route index path="/" element={<ThemeContent theme={theme} />} />

            <Route
              path="/notes"
              element={
                <Journal
                  className="meeting_content__notes"
                  me={me}
                  feedItems={feedItems}
                />
              }
            />
          </Routes>
        </div>
      </section>
    </>
  );
};

export default MeetingPage;
