export class FronteggApiError extends Error {
  constructor(message, statusCode) {
    super(message);
    this._statusCode = void 0;
    this._statusCode = statusCode;
  }

  get statusCode() {
    return this._statusCode;
  }

}