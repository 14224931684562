import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["id"],
  _excluded2 = ["id", "enabled"];
/* eslint-disable */

import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions as connectivityActions } from './reducer';
import { channels, channels2Platform, type2ApiGet, type2ApiPost } from './consts';
import { api } from '@frontegg/rest-api';
const addApi = ['categories', 'channelMap'];
function* loadPlatformData(ch) {
  return yield call(loadFunction, {
    payload: {
      api: ch
    },
    type: ''
  });
}
function* loadDataFunction({
  payload = channels
}) {
  yield put(connectivityActions.setConnectivityState({
    isLoading: true
  }));
  try {
    // @ts-ignore
    const values = yield all([
    // @ts-ignore
    ...payload.map(loadPlatformData),
    // @ts-ignore
    yield loadFunction({
      payload: {
        api: 'categories'
      },
      type: ''
    }),
    // @ts-ignore
    yield function* () {
      // @ts-ignore
      const res = yield all(payload.map(function* (ch) {
        // @ts-ignore
        return yield loadFunction({
          payload: {
            api: 'channelMap',
            params: ch
          },
          type: ''
        });
      }));
      return res.reduce((acc, curr, idx) => _extends({}, acc, {
        [`${payload[idx]}`]: curr
      }), {});
    }()]);
    // @ts-ignore
    const webhooks = yield loadFunction({
      payload: {
        api: 'webhook'
      },
      type: ''
    });
    const data = values.reduce((acc, curr, idx) => payload[idx] ? values[values.length - 1][payload[idx]].length ? _extends({}, acc, {
      [`${payload[idx]}`]: curr,
      list: [...acc.list, {
        id: idx,
        key: payload[idx],
        events: channels2Platform[payload[idx]].events(curr),
        active: channels2Platform[payload[idx]].isActive(curr),
        platform: channels2Platform[payload[idx]].title,
        image: channels2Platform[payload[idx]].image
      }]
    }) : acc : _extends({}, acc, {
      [`${addApi[idx - payload.length]}`]: curr
    }), {
      list: [],
      webhook: webhooks
    });
    yield put(connectivityActions.setConnectivityState(_extends({}, data, {
      error: undefined,
      isSaving: false,
      isLoading: false
    })));
  } catch (e) {
    yield put(connectivityActions.setConnectivityState({
      isLoading: false
    }));
  }
}
function* loadFunction({
  payload: {
    api,
    params
  }
}) {
  try {
    // TODO: [ Refactor Saga ] refactor mapping routes
    // @ts-ignore
    return yield call(type2ApiGet[api], params);
  } catch (e) {
    return undefined;
  }
}
function* loadSlackFunction() {
  try {
    yield put(connectivityActions.setConnectivityState({
      slackChannels: {
        isLoading: true
      }
    }));

    // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
    // @ts-ignore
    const data = yield call(api.connectivity.getSlackChannels);
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      slackChannels: {
        isLoading: false,
        data
      }
    }));
  } catch (e) {
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      slackChannels: {
        isLoading: false
      }
    }));
  }
}
function* checkNewStatus(platform, data) {
  const {
    connectivity: {
      list
    }
  } = yield select();
  const currPlatform = list.find(({
    key
  }) => key === platform);
  if (!currPlatform) return;
  const newActive = channels2Platform[platform].isActive(data);
  if (newActive === currPlatform.active) return;
  //TODO: double check
  yield put(connectivityActions.setConnectivityState({
    list: list.map(elm => elm.key === platform ? _extends({}, elm, {
      active: newActive
    }) : elm)
  }));
}
function* postDataFunction({
  payload: {
    platform,
    data,
    callback
  }
}) {
  const {
    processIds
  } = yield select(state => state.connectivity);
  try {
    yield put(connectivityActions.setConnectivityState({
      isSaving: true,
      //@ts-ignore
      processIds: platform === 'webhook' ? [data._id, ...processIds] : processIds
    }));
    if (platform === 'slack') {
      yield postSlackData({
        payload: data,
        type: ''
      });
    } else if (['sms', 'email'].includes(platform)) {
      yield postEmailSMSData({
        payload: data,
        type: platform
      });
    } else {
      yield call(type2ApiPost[platform], data);
    }
    if (!['sms', 'email'].includes(platform)) {
      // @ts-ignore
      const newData = yield loadFunction({
        payload: {
          api: platform
        },
        type: ''
      });
      if (platform === 'webhook') {
        newData.id = data._id;
      }
      yield put(connectivityActions.setConnectivityState({
        error: undefined,
        isSaving: false,
        [`${platform}`]: newData,
        processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
      }));
      yield checkNewStatus(platform, newData);
      callback == null ? void 0 : callback(true);
    }
  } catch (e) {
    var _e$message;
    yield put(connectivityActions.setConnectivityState({
      error: (_e$message = e.message) != null ? _e$message : e.toString(),
      isSaving: false,
      isLoading: false
    }));
  }
}
function* postSlackData({
  payload
}) {
  const {
    connectivity: {
      slack
    }
  } = yield select();
  if (!slack) {
    return;
  }
  const {
    slackSubscriptions: stateSlackSubscriptions
  } = slack;
  const {
    slackSubscriptions
  } = payload;
  yield all([...slackSubscriptions.reduce((acc, curr) => {
    var _curr$slackEvents$0$c, _curr$slackEvents$0$c2;
    if (!curr.id && curr.slackEvents && (_curr$slackEvents$0$c = curr.slackEvents[0].channelIds) != null && _curr$slackEvents$0$c.length) {
      return [...acc, curr];
    }
    const el = stateSlackSubscriptions == null ? void 0 : stateSlackSubscriptions.find(_ref => {
      let {
          id
        } = _ref,
        props = _objectWithoutPropertiesLoose(_ref, _excluded);
      return id === curr.id && JSON.stringify(_extends({
        id
      }, props)) !== JSON.stringify(curr);
    });
    if (el && curr.slackEvents && (_curr$slackEvents$0$c2 = curr.slackEvents[0].channelIds) != null && _curr$slackEvents$0$c2.length) {
      return [...acc, curr];
    }
    return acc;
  }, []).map(function* (el) {
    // @ts-ignore
    return yield call(type2ApiPost.slack, el);
  }), ...slackSubscriptions.reduce((acc, curr) => {
    if (curr.id && !curr.slackEvents[0].channelIds.length) {
      return [...acc, curr];
    }
    return acc;
  }, []).map(function* (el) {
    // @ts-ignore
    return yield call(api.connectivity.deleteSlackConfiguration, el);
  })
  // clean the old data
  // ...stateSlackSubscriptions
  //   // @ts-ignore
  //   .reduce((acc, curr) => {
  //     const el = slackSubscriptions.find(({ id }) => id === curr.id);
  //     if (!el) {
  //       return [...acc, curr];
  //     }
  //     return acc;
  //   }, [])
  //   // @ts-ignore
  //   .map(function* (el) {
  //     return yield call(api.connectivity.deleteSlackConfiguration, el as Required<ISlackSubscription>);
  //   }),
  ]);
}

function* postEmailSMSData({
  payload,
  type
}) {
  const {
    connectivity
  } = yield select();
  const {
    processIds
  } = yield select(state => state.connectivity);
  const stateData = connectivity[type];
  if (!stateData) return;
  let actionsResult = [];
  try {
    actionsResult = yield all([
    // create new
    ...payload.reduce((acc, curr) => {
      const state = stateData.find(({
        eventKey
      }) => eventKey === curr.eventKey);
      if (!state && curr.subscriptions[0].recipients.filter(el => el).length) {
        return [...acc, curr];
      }
      return acc;
    }, []).map(function* (data) {
      // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
      // @ts-ignore
      return yield call(type === 'email' ? api.connectivity.postEmailConfiguration : api.connectivity.postSMSConfiguration, data);
    }),
    // update exists
    ...payload.reduce((acc, curr) => {
      const state = stateData.find(({
        eventKey
      }) => eventKey === curr.eventKey);
      if (state && JSON.stringify(state) !== JSON.stringify(curr)) {
        return [...acc, curr];
      }
      return acc;
    }, []).map(function* (data) {
      const {
        subscriptions,
        eventKey
      } = data;
      const _subscriptions$ = subscriptions[0],
        {
          id = '',
          enabled
        } = _subscriptions$,
        body = _objectWithoutPropertiesLoose(_subscriptions$, _excluded2);
      // @ts-ignore
      return yield all([
      // @ts-ignore
      yield call(type === 'email' ? api.connectivity.patchEmailConfiguration : api.connectivity.patchSMSConfiguration, {
        eventKey,
        enabled
      }),
      // @ts-ignore
      yield call(type === 'email' ? api.connectivity.putEmailSubscriptions : api.connectivity.putSMSSubscriptions, id, eventKey, _extends({}, body, {
        enabled
      }))]);
    }),
    // delete record with empty recipients
    ...payload.reduce((acc, curr) => {
      const state = stateData.find(({
        eventKey
      }) => eventKey === curr.eventKey);
      if (state && state.subscriptions[0].recipients.length && !curr.subscriptions[0].recipients.filter(el => !!el).length) {
        return [...acc, curr];
      }
      return acc;
    }, []).map(function* ({
      eventKey,
      subscriptions
    }) {
      return call(type === 'email' ? api.connectivity.deleteEmailSubscriptions : api.connectivity.deleteSMSSubscriptions, eventKey, subscriptions[0].id || '');
    })]);
  } catch {}
  if (actionsResult.length) {
    // @ts-ignore
    const newData = yield loadFunction({
      payload: {
        api: type
      },
      type: ''
    });
    // yield put(connectivityActions.postDataSuccess({ platform: type, data: newData }));
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      isSaving: false,
      [`${type}`]: newData,
      processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
    }));
    yield checkNewStatus(type, newData);
  } else {
    // yield put(connectivityActions.postDataSuccess({ platform: type, data: stateData }));
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      isSaving: false,
      [`${type}`]: stateData,
      processIds: processIds
    }));
  }
}
function* postCodeFunction({
  payload
}) {
  try {
    yield api.connectivity.postSlackCode(payload);
  } catch {}
  // yield put(connectivityActions.postCodeSuccess());
  yield put(connectivityActions.setConnectivityState({
    error: undefined,
    isSaving: false
  }));
}
function* loadSlackPermissions() {
  const {
    slackChannels
  } = yield select(state => state.connectivity);
  try {
    yield put(connectivityActions.setConnectivityState({
      slackChannels: _extends({}, slackChannels, {
        isLoadingScope: true
      })
    }));
    const {
      clientId
    } = yield call(api.connectivity.getSlackScope);
    // yield put(connectivityActions.loadScopeSuccess(clientId));
    yield put(connectivityActions.setConnectivityState({
      slackChannels: {
        error: undefined,
        clientId,
        isLoadingScope: false,
        isLoading: false
      }
    }));
  } catch {
    // yield put(connectivityActions.loadScopeSuccess(null));
    yield put(connectivityActions.setConnectivityState({
      slackChannels: {
        error: undefined,
        isLoadingScope: false,
        isLoading: false
      }
    }));
  }
}
function* deleteWebhookConfigFunction({
  payload
}) {
  const {
    callback,
    webhookId
  } = payload;
  const {
    processIds
  } = yield select(state => state.connectivity);
  try {
    yield put(connectivityActions.setConnectivityState({
      isSaving: true
    }));
    yield call(api.connectivity.deleteWebhooksConfiguration, webhookId);
  } catch (e) {
    callback == null ? void 0 : callback(null, e.message);
  }
  // @ts-ignore
  const newData = yield loadFunction({
    payload: {
      api: 'webhook'
    },
    type: ''
  });
  if (newData) {
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      isSaving: false,
      webhook: newData,
      processIds: newData.id ? processIds.filter(el => el !== newData.id) : processIds
    }));
  }
  callback == null ? void 0 : callback(true);
}
function* postWebhookTestFunction({
  payload
}) {
  try {
    yield put(connectivityActions.setConnectivityState({
      isTesting: true
    }));
    const {
      statusCode,
      body
    } = yield call(api.connectivity.postWebhookTest, payload);
    if ([201, 200].includes(statusCode)) {
      // yield put(connectivityActions.postWebhookTestSuccess('success', JSON.stringify(body, null, 2)));
      const message = JSON.stringify(body, null, 2);
      yield put(connectivityActions.setConnectivityState({
        isTesting: false,
        testResult: {
          status: 'success',
          message
        }
      }));
    } else {
      // yield put(connectivityActions.postWebhookTestSuccess('failed', body.toString()));
      yield put(connectivityActions.setConnectivityState({
        isTesting: false,
        testResult: {
          status: 'failed'
        }
      }));
    }
  } catch (e) {
    // yield put(connectivityActions.postWebhookTestSuccess('failed', e.toString()));
    yield put(connectivityActions.setConnectivityState({
      isTesting: false,
      testResult: {
        status: 'failed',
        message: e.toString()
      }
    }));
  }
}
function* postWebhookRetryFunction({
  payload
}) {
  try {
    const {
      statusCode
    } = yield call(api.connectivity.postWebhookRetry, payload);
    yield put(connectivityActions.postWebhookRetryResult({
      [payload]: {
        isProcess: false,
        success: statusCode === 202
      }
    }));
  } catch (e) {
    yield put(connectivityActions.postWebhookRetryResult({
      [payload]: {
        isProcess: false,
        success: false
      }
    }));
  }
}
function* loadWebhookLogsFunction({
  payload: {
    id,
    limit,
    offset
  }
}) {
  const {
    webhookLogs
  } = yield select(state => state.connectivity);
  try {
    yield put(connectivityActions.setConnectivityState({
      webhookLogs: _extends({}, webhookLogs, {
        isLoading: true
      })
    }));
    //TODO: Fix types
    //@ts-ignore
    const data = yield call(api.connectivity.getWebhookLog, id, offset, limit);
    // yield put(connectivityActions.loadWebhookLogsSuccess(data));
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      webhookLogs: _extends({
        isLoading: false
      }, data)
    }));
  } catch (e) {
    yield put(connectivityActions.setConnectivityState({
      error: undefined,
      webhookLogs: {
        isLoading: false
      }
    }));
  }
}
export function* sagas() {
  yield takeEvery(connectivityActions.loadDataAction, loadDataFunction);
  yield takeLatest(connectivityActions.loadSlackActions, loadSlackFunction);
  yield takeEvery(connectivityActions.postDataAction, postDataFunction);
  yield takeEvery(connectivityActions.postCodeAction, postCodeFunction);
  yield takeEvery(connectivityActions.loadScope, loadSlackPermissions);
  yield takeEvery(connectivityActions.deleteWebhookConfigAction, deleteWebhookConfigFunction);
  yield takeEvery(connectivityActions.postWebhookTestAction, postWebhookTestFunction);
  yield takeEvery(connectivityActions.postWebhookRetryAction, postWebhookRetryFunction);
  yield takeLatest(connectivityActions.loadWebhookLogsAction, loadWebhookLogsFunction);
}