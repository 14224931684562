const isAbsoluteUrl = path => {
  try {
    new URL(path);
    return true;
  } catch (e) {
    return false;
  }
};
const isValidRedirectUrl = (redirectUrl, allowedRedirectOrigins) => {
  const currentUrl = new URL(window.location.href);
  const redirectURL = new URL(redirectUrl);
  const isSameSite = redirectURL.origin === currentUrl.origin;
  const isOriginInWhiteList = allowedRedirectOrigins.includes(redirectURL.origin);
  return isOriginInWhiteList || isSameSite;
};
export const getRedirectUrl = ({
  authenticatedUrl,
  enforceRedirectToSameSite,
  allowedRedirectOrigins,
  includeQueryParam
}) => {
  const currentUrl = new URL(window.location.href);
  let redirectUrl = authenticatedUrl;
  const redirectUrlQueryParam = currentUrl.searchParams.get('redirectUrl');
  if (redirectUrlQueryParam) {
    if (!enforceRedirectToSameSite || !isAbsoluteUrl(redirectUrlQueryParam)) {
      redirectUrl = redirectUrlQueryParam;
    } else {
      if (isValidRedirectUrl(redirectUrlQueryParam, allowedRedirectOrigins)) {
        redirectUrl = redirectUrlQueryParam;
      } else {
        console.warn('The redirect url param is not valid, the origin is a different site');
      }
    }
  } else if (includeQueryParam) {
    redirectUrl += window.location.search;
  }
  return redirectUrl;
};