import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Journey } from '../models/Journey';
import { Meeting } from '../models/Meeting';

import { Theme } from '../models/Theme';

// GET Users Actions
const GET_JOURNEYS = gql`
  query journey($matchId: ID) {
    me {
      id
      journeys {
        id
        title
        introduction
        slug
        themes {
          id
          name
          slug
          title
          introduction
          guide {
            id
            slug
          }
        }
        guides {
          id
          name
          slug
          shortDescription
        }
      }
      meetings(matchId: $matchId) {
        id
        journey
        theme {
          id
          name
          slug
          title
          introduction
          guide {
            id
            slug
          }
        }
        meetingStartAt
        memberIds
      }
    }
  }
`;

const useJourneys = (matchId?: string) => {
  const { data: journeyData, loading: journeysLoading } = useQuery(
    GET_JOURNEYS,
    {
      variables: { matchId },
    },
  );

  const [journeys, setJourneys] = useState<Journey[]>();

  useEffect(() => {
    if (journeyData?.me?.journeys && journeyData.me.meetings) {
      const meetingsData: Meeting[] = journeyData.me.meetings.map(
        (m: any) => new Meeting(m),
      );
      const journeysData: any[] = journeyData.me.journeys;

      const meetings = [...meetingsData]
        .filter((m) => m.meetingStartAt)
        .sort(
          (a: any, b: any) =>
            // Chronological, oldest first
            dayjs(a.meetingStartAt).valueOf() -
            dayjs(b.meetingStartAt).valueOf(),
        );

      const adHocJourney = new Journey(
        journeysData.find((j) => j.slug === Journey.AD_HOC),
      );
      const adHocTheme = new Theme(
        adHocJourney.themes.find((j) => j.slug === Theme.AD_HOC),
      );
      const journeys: Journey[] = meetings.reduce<Journey[]>(
        (journeyAccumulator, meeting, index) => {
          // For each meeting
          // 1. Find the journey
          let journey: Journey | undefined;
          if (meeting.journey) {
            // See if we have previously found the journey
            journey = journeyAccumulator.find(
              (j) => j.slug === meeting.journey,
            );
            if (!journey && meeting.journey !== Journey.AD_HOC) {
              // If we have not found the journey, && the slug is not ad-hoc, do we have data for it
              const journeyData = journeysData.find(
                (j) => j.slug === meeting.journey,
              );

              // add to accumulator
              if (journeyData) {
                journey = new Journey(journeyData);
                journeyAccumulator.push(journey);
              }
            }
          }
          // If we have not found a previous journey or one from the data, it is AD_HOC
          if (!journey) {
            if (journeyAccumulator.length < 1) {
              journey = adHocJourney;
              journeyAccumulator.push(journey);
            } else {
              journey = journeyAccumulator[0];
            }
          }
          // If the first meeting and no journey or ad-hoc journey, find ah hoc meeting and make current journey add meeting with ad-hoc theme
          // if no journey or ad-hoc add meeting to the current journey (normal or ad-hoc) journeys[0] using the ad-hoc theme.
          // if found add meeting to that journey with the theme fromt he current step and repeat but as follows remove theme from journey.themes

          if (!meeting.theme) {
            meeting.theme = adHocTheme;
          }

          journey.meetings.push(meeting);
          return journeyAccumulator;
        },
        [],
      );

      if (journeys.length < 1) {
        journeys.push(adHocJourney);
      }

      setJourneys([
        ...journeys.sort((a, b) => {
          if (a.meetings.at(-1)?.meetingStartAt == null) {
            return 1;
          }
          if (a.meetings.at(-1)?.meetingStartAt == null) {
            return -1;
          }
          return (
            dayjs(b.meetings.at(-1)?.meetingStartAt).valueOf() -
            dayjs(a.meetings.at(-1)?.meetingStartAt).valueOf()
          );
        }),
      ]);
    }
  }, [journeyData]);

  return {
    journeys,
    journeysLoading,
  };
};

export default useJourneys;
