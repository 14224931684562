import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { vendorPublicConfigActions } from './index';
import { api } from '@frontegg/rest-api';
import { vendorPublicConfigurationResponseMock } from '../dummy';
export function* vendorPublicConfigSagas() {
  yield takeEvery(vendorPublicConfigActions.loadVendorPublicConfiguration, loadVendorPublicConfiguration);
}
function* loadVendorPublicConfiguration() {
  yield put(vendorPublicConfigActions.setLoading(true));
  try {
    const vendorPublicConfig = yield call(api.subscriptions.getVendorPublicConfigurations);
    yield put(vendorPublicConfigActions.setState({
      fetching: false,
      loading: false,
      vendorPublicConfig
    }));
  } catch (e) {
    yield put(vendorPublicConfigActions.setError(e.message));
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadVendorPublicConfigurationMock() {
  yield put(vendorPublicConfigActions.setLoading(true));
  yield delay(500);
  yield put(vendorPublicConfigActions.setState({
    fetching: false,
    loading: false,
    vendorPublicConfig: vendorPublicConfigurationResponseMock
  }));
}
export function* vendorPublicConfigSagasMock() {
  yield takeEvery(vendorPublicConfigActions.loadVendorPublicConfiguration, loadVendorPublicConfigurationMock);
}