import { FunctionComponent, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import './DropDownMenuLink.css';

export type DropDownMenuLinkProps = {
  text: string;
  url?: string | { pathname: string };
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const DropDownMenuLink: FunctionComponent<DropDownMenuLinkProps> = ({
  text,
  url,
  onClick,
}) => {
  return (
    <>
      {url ? (
        <Link to={url} className="drop-down-menu-link">
          {text}
        </Link>
      ) : (
        <a href={url} onClick={onClick} className="drop-down-menu-link">
          {text}
        </a>
      )}
    </>
  );
};

export default DropDownMenuLink;
