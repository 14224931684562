import './Button.css';
import { FunctionComponent } from 'react';
import { Waveform } from '@uiball/loaders';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  label: string;
  link?: string;
  className?: string;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
  local?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top';
};

const Button: FunctionComponent<ButtonProps> = ({
  label,
  link,
  className,
  action,
  disabled = false,
  active = false,
  local = false,
  target,
}) => {
  return (
    <>
      {link ? (
        <>
          {local && !target ? (
            <Link
              className={`link-button button--primary ${className}`}
              to={link}>
              {label}
            </Link>
          ) : (
            <a
              className={`link-button button--primary ${className}`}
              href={link}
              target={target}>
              {' '}
              {label}
            </a>
          )}
        </>
      ) : (
        <button
          className={`button button--primary ${className}`}
          onClick={action}
          disabled={disabled}>
          {active ? (
            <Waveform size={25} lineWeight={3} speed={1} color="#0c343d" />
          ) : (
            label
          )}
        </button>
      )}
    </>
  );
};

export default Button;
