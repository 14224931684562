import { FunctionComponent } from 'react';
import { Action } from 'src/models/Action';
import ActionCard from '../ActionCard/ActionCard';
import './Actions.css';

export type ActionsProps = {
  actions: Action[];
  deleteAction: Function;
};

const Actions: FunctionComponent<ActionsProps> = ({
  actions,
  deleteAction,
}) => {
  return (
    <div className="actions">
      {actions && actions.length > 0 ? (
        <div className="actions__cards">
          {actions.map((action) => (
            <ActionCard
              key={action.id}
              action={action}
              deleteAction={deleteAction}
            />
          ))}
        </div>
      ) : (
        <div className="actions-empty-state">
          <span className="actions-empty-state__message">
            Boom - You're all caught up! 👊
          </span>
        </div>
      )}
    </div>
  );
};

export default Actions;
