import { Delete, Get, Patch, Post } from "../fetch";
import { urls } from "../constants";
export async function loadAllUsers(params) {
  return Get(urls.identity.subTenants.v1, params);
}
export async function addUserToTenantAndSubTenants(body) {
  return Post(urls.identity.subTenants.v1, body);
}
export async function removeUserFromTenantAndSubTenants(body) {
  return Delete(urls.identity.subTenants.v1, body);
}
export async function addUserRolesForSubTenants(userId, body) {
  return Post(`${urls.identity.subTenants.v1}/${userId}/roles`, body);
}
export async function removeUserRolesFromSubTenants(userId, body) {
  return Delete(`${urls.identity.subTenants.v1}/${userId}/roles`, body);
}
export async function setUserRolesForSubTenants(userId, body) {
  return Patch(`${urls.identity.subTenants.v1}/${userId}/roles`, body);
}