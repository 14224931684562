import _extends from "@babel/runtime/helpers/esm/extends";
import { select as sagaSelect, put, call, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { auditsStoreName } from '../../constants';
import { auditsLogsFilterAndSort } from '../dummy';
const select = () => sagaSelect(_ => _[auditsStoreName].auditLogsState);
const selectMetadata = () => sagaSelect(_ => _[auditsStoreName].auditsMetadataState);
function* exportAuditsCsv() {
  const state = yield select();
  const {
    columns
  } = yield selectMetadata();
  try {
    const filter = state.filter;
    const sort = state.sort;
    const sortParams = sort.reduce((p, n) => _extends({}, p, {
      sortBy: n.id,
      sortDirection: n.desc ? 'desc' : 'asc'
    }), {});
    const filterParams = filter.reduce((p, n) => _extends({}, p, {
      [n.id]: n.value
    }), {});
    yield put(actions.setAuditLogsState({
      isDownloadingCsv: true
    }));
    const outputFileName = `audits.csv`;
    yield api.audits.exportAudits(_extends({
      endpoint: 'csv/v2',
      headerProps: columns,
      offset: 0,
      outputFileName
    }, sortParams, filterParams));
  } catch (e) {
    console.error('failed to export audits - ', e);
  }
  yield put(actions.setAuditLogsState({
    isDownloadingCsv: false
  }));
}
function* loadAuditLogs({
  payload
}) {
  yield put(actions.setAuditLogsState({
    loading: !(payload != null && payload.silentLoading),
    error: null
  }));
  const state = yield select();
  const {
    columns
  } = yield selectMetadata();
  try {
    var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort, _payload$callback;
    const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : state.pageSize;
    const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : state.pageOffset;
    const filter = (_payload$filter = payload.filter) != null ? _payload$filter : state.filter;
    const sort = (_payload$sort = payload.sort) != null ? _payload$sort : state.sort;
    yield put(actions.setAuditLogsState({
      pageSize,
      pageOffset,
      filter,
      sort
    }));
    const sortParams = sort.reduce((p, n) => _extends({}, p, {
      sortBy: n.id,
      sortDirection: n.desc ? 'desc' : 'asc'
    }), {});
    const filterParams = filter.reduce((p, n) => _extends({}, p, {
      [n.id]: n.value
    }), {});
    if (!columns) {
      yield put(actions.loadAuditsMetadata());
    }
    const {
      data,
      total
    } = yield call(api.audits.getAudits, _extends({
      offset: pageOffset,
      count: pageSize
    }, sortParams, filterParams));
    yield put(actions.setAuditLogsState({
      loading: false,
      logs: data != null ? data : [],
      totalPages: +Math.ceil(total / pageSize)
    }));
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setAuditLogsState({
      loading: false,
      error: e.message
    }));
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
  }
}
export function* auditLogsSagas() {
  yield takeEvery(actions.exportAuditsCsv, exportAuditsCsv);
  yield takeEvery(actions.loadAuditLogs, loadAuditLogs);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadAuditLogsMock({
  payload
}) {
  yield put(actions.setAuditLogsState({
    loading: !(payload != null && payload.silentLoading),
    error: null
  }));
  const state = yield select();
  const {
    columns
  } = yield selectMetadata();
  try {
    var _payload$pageSize2, _payload$pageOffset2, _payload$filter2, _payload$sort2, _payload$callback3;
    const pageSize = (_payload$pageSize2 = payload.pageSize) != null ? _payload$pageSize2 : state.pageSize;
    const pageOffset = (_payload$pageOffset2 = payload.pageOffset) != null ? _payload$pageOffset2 : state.pageOffset;
    const filter = (_payload$filter2 = payload.filter) != null ? _payload$filter2 : state.filter;
    const sort = (_payload$sort2 = payload.sort) != null ? _payload$sort2 : state.sort;
    yield put(actions.setAuditLogsState({
      pageSize,
      pageOffset,
      filter,
      sort
    }));
    if (!columns) {
      yield put(actions.loadAuditsMetadata());
    }
    const {
      data,
      total
    } = auditsLogsFilterAndSort(filter, sort);
    yield put(actions.setAuditLogsState({
      loading: false,
      logs: data != null ? data : [],
      totalPages: +Math.ceil(total / pageSize)
    }));
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  } catch (e) {
    var _payload$callback4;
    yield put(actions.setAuditLogsState({
      loading: false,
      error: e.message
    }));
    payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
  }
}
export function* auditLogsSagasMock() {
  yield takeEvery(actions.loadAuditLogs, loadAuditLogsMock);
}