import './AccountPage.css';

import { FunctionComponent } from 'react';
import { useAccount } from '../../hooks/useAccount';
import ContentSection from '../../components/ContentSection/ContentSection';
import MemberInfoGrid, {
  MemberInfoObject,
} from '../../components/MemberInfo/MemberInfoGrid';
import Avatar from '../../components/Avatar/Avatar';
import Button from '../../components/Button/Button';
import EditAccountModal from './EditAccountModal';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import LinkButton from '../../components/Button/LinkButton';
import { SubscriptionStatus } from '../../models/Member';
import dayjs from 'dayjs';
import { config } from '../../config/config';
// import { AdminPortal } from '@frontegg/react';

export type AccountPageProps = {};

const AccountPage: FunctionComponent<AccountPageProps> = () => {
  const { account, accountLoading, updateAccountMutation, accountUpdating } =
    useAccount();

  const navigate = useNavigate();

  // const handleAuthSettingsClick = () => {
  //   AdminPortal.show();
  // };

  const handleLogoutClick = () => {
    localStorage.removeItem('AUTH_TOKEN');
    navigate('/account/logout');
  };

  return (
    <section className="account-page">
      <ContentSection
        title="Account Details"
        actionLinkURL="/account/edit-account">
        <>
          <div className="account-page__header">
            {account?.avatar ? (
              <Avatar
                memberAvatar={account.avatar}
                className="account-page__avatar"
              />
            ) : null}
            <span className="account-page__avatar-info">
              Want to create a Voco avatar? Go to{' '}
              <a
                className="link"
                href="https://gravatar.com"
                target="_blank"
                rel="noreferrer">
                gravatar.com
              </a>
            </span>
          </div>
          {accountLoading ? (
            <Loader />
          ) : (
            <MemberInfoGrid
              member={account as unknown as MemberInfoObject}
              properties={[
                { label: 'Preferred Pronoun', property: 'preferredPronouns' },
                { label: 'First Name', property: 'firstName' },
                { label: 'Surname', property: 'lastName' },
                // { label: 'Ethnicity', property: 'ethnicity' },
                // { label: 'Date of Birth', property: 'formattedDoB' },
                // { label: 'Dependents', property: 'dependents' },
                { label: 'LinkedIn Profile', property: 'linkedIn' },
                { label: 'Email Address', property: 'email' },
                { label: 'Time Zone Preference', property: 'timezone' },
                { label: 'Telephone Number', property: 'phoneNumber' },
                // {
                //   label: 'Communication Preferences',
                //   property: 'commsPreference',
                // },
                { label: 'Membership Status', property: 'memberStatus' },
              ]}
            />
          )}
          {/* <div className="account-page__auth-settings">
            Want to change your password or set up MFA?{' '}
            <button className="link" onClick={handleAuthSettingsClick}>
              Click here
            </button>
            .
          </div> */}
          {account?.subscriptionStatus &&
          ![
            SubscriptionStatus.COMPLIMENTARY,
            SubscriptionStatus.CORPORATE,
          ].includes(account?.subscriptionStatus) ? (
            <div className="account-page__subscription">
              <p>
                Your subscription is currently{' '}
                <span className="account-page__subscription-status">
                  {account?.subscriptionStatus}
                </span>
                .{' '}
                {account?.subscription?.endAt
                  ? `Your membership
                ${
                  account.subscription.endAt.isAfter(dayjs())
                    ? 'expires'
                    : 'expired'
                } ${account.subscription.endAt.format('DD/MM/YYYY')}.`
                  : null}
              </p>
              {account.subscription?.stripeEmail ? (
                <LinkButton
                  label="Manage your
            Subscription"
                  link={`${
                    config.env === 'production'
                      ? 'https://billing.stripe.com/p/login/9AQbM0aWF3gm9vaaEE'
                      : 'https://billing.stripe.com/p/login/test_5kAfZAbvh7qF1c48ww'
                  }?prefilled_email=${encodeURI(
                    account.subscription.stripeEmail,
                  )}`}
                />
              ) : null}
            </div>
          ) : null}
          <div className="account-page__buttons">
            <Button action={() => handleLogoutClick()} label="Sign Out" />
          </div>
          <div className="account-page__terminate">
            If you would like to terminate your Voco account, please email{' '}
            <a
              className="link"
              href="mailto:hello@joinvoco.io?subject=Please%20Terminate%20My%20Account">
              hello@joinvoco.io
            </a>
          </div>
        </>
      </ContentSection>
      <Routes>
        <Route
          path="edit-account"
          element={
            <EditAccountModal
              account={account}
              updateAccount={updateAccountMutation}
              updatingAccount={accountLoading || accountUpdating}
            />
          }
        />
      </Routes>
    </section>
  );
};

export default AccountPage;
