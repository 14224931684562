import { FunctionComponent } from 'react';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';

export type AddMatchModalProps = {
  addMatchLoading: boolean;
  addMatchMutation: Function;
};

const AddMatchModal: FunctionComponent<AddMatchModalProps> = ({
  addMatchLoading,
  addMatchMutation,
}) => {
  const confirmAddMatch: ModalActionFunction = async (event, done) => {
    const result = await addMatchMutation();
    if (!result?.data?.addMatch?.success) {
      console.error(result);
    }
    return done();
  };

  return (
    <Modal
      title="Request More Matches"
      showHeart={true}
      action={confirmAddMatch}
      actionInProgress={addMatchLoading}
      actionText="Confirm">
      <p className="p">
        Ok great, we will get looking for new matches for you. Please be aware
        that we prioritise quality over speed in matching. This means that we
        would rather wait to find you the right match than rush you into a
        conversation that may not add value.
      </p>
      <p className="p">
        So sit tight, we are on it - just click the button above to confirm!
      </p>
    </Modal>
  );
};

export default AddMatchModal;
