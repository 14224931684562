import dayjs, { Dayjs } from 'dayjs';
import { Member } from './Member';
import { Theme } from './Theme';

export type MeetingTimeSlots = {
  [day: string]: number[];
};

export enum ScheduleSubmissionStage {
  UNSCHEDULED = 'UNSCHEDULED',
  SUGGESTED = 'SUGGESTED',
  PROPOSAL = 'PROPOSAL',
  SCHEDULED = 'SCHEDULED',
  RESCHEDULE = 'RESCHEDULE',
  RESCHEDULE_PROPOSAL = 'RESCHEDULE_PROPOSAL',
  FIRST_FAIL = 'FIRST_FAIL',
  SECOND_FAIL = 'SECOND_FAIL',
}

export type ScheduleSubmission = {
  memberId: string;
  submissionDate: Dayjs;
  stage: ScheduleSubmissionStage;
};

export enum MeetingEventType {
  THEME_CHANGE = 'THEME_CHANGE',
}

export type MeetingEventThemeChange = {
  themeSlug: string;
  previousThemeSlug: string;
};

export type MeetingEvent = {
  type: MeetingEventType;
  eventAt: string;
  meetingId: string;
  memberId?: string;
  meta: MeetingEventThemeChange;
};

export class Meeting {
  id: string;
  members: Member[];
  memberIds: string[];
  meetingType?: string;
  videoPlatform?: string;
  meetingURL?: string;
  meetingStartAt?: Dayjs;
  meetingEndAt?: Dayjs;
  embedURL?: string;
  me?: Member;
  meetingTimeSlots?: MeetingTimeSlots;
  availableDates?: Dayjs[];
  suggestedDates?: Dayjs[];
  scheduleSubmissions?: ScheduleSubmission[];
  scheduleStage: ScheduleSubmissionStage;
  lastScheduleMemberId?: string;
  journey?: string;
  theme?: Theme;
  themeId?: string;
  themeSlug?: string;
  originalThemeSlug?: string;
  meetingEvents?: MeetingEvent[];

  constructor(params: any) {
    this.id = params.id;
    this.members = params.members
      ? params.members.map((m: any) => new Member(m))
      : undefined;
    this.memberIds = params.memberIds;
    this.meetingType = params.meetingType;
    this.videoPlatform = params.videoPlatform;
    this.meetingURL = params.meetingURL;
    this.meetingStartAt =
      params.meetingStartAt && dayjs(params.meetingStartAt).isValid()
        ? dayjs(params.meetingStartAt)
        : undefined;
    this.meetingEndAt = dayjs(params.meetingEndAt);
    this.embedURL = params.embedURL;
    this.me = params.me;
    this.meetingTimeSlots = params.meetingTimeSlots;
    this.availableDates =
      params.availableDates?.length > 0
        ? params.availableDates.map((d: string) => dayjs(d))
        : undefined;
    this.suggestedDates =
      params.suggestedDates?.length > 0
        ? params.suggestedDates.map((d: string) => dayjs(d))
        : undefined;
    this.scheduleSubmissions =
      params.scheduleSubmissions?.length > 0
        ? params.scheduleSubmissions.map(
            (submission: {
              memberId: string;
              submissionDate: Dayjs;
              stage: string;
            }) => ({
              memberId: submission.memberId,
              submissionDate: dayjs(submission.submissionDate),
              stage:
                ScheduleSubmissionStage[
                  submission.stage as keyof typeof ScheduleSubmissionStage
                ],
            }),
          )
        : undefined;
    this.scheduleStage =
      ScheduleSubmissionStage[
        params.scheduleStage as keyof typeof ScheduleSubmissionStage
      ] || ScheduleSubmissionStage.UNSCHEDULED;
    this.lastScheduleMemberId = params.lastScheduleMemberId;
    this.journey = params.journey;
    this.themeSlug = params.themeSlug;
    this.originalThemeSlug = params.originalThemeSlug;
    this.theme = params.theme ? new Theme(params.theme) : undefined;
  }
}
