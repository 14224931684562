import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FeedItem } from '../models/FeedItem';
import { JournalEntry } from '../models/JournalEntry';
import { Message } from '../models/Message';

// GET Users Actions
const GET_FEED = gql`
  query feed($relatedMemberId: ID) {
    me {
      id
      feed(relatedMemberId: $relatedMemberId) {
        id
        text
        date
        ... on Journal {
          title
          journalType
        }
        member {
          id
          firstName
          lastName
          avatarURL
        }
        relatedMember {
          id
          firstName
          lastName
          avatarURL
        }
      }
    }
  }
`;

const useFeed = () => {
  const [loadFeed, { data: feedData, loading: feedLoading }] =
    useLazyQuery(GET_FEED);
  const [feedItems, setFeedItems] = useState<FeedItem[]>();

  useEffect(() => {
    if (feedData) {
      const feed: FeedItem[] = feedData.me.feed.map((a: any) =>
        a.__typename === 'Message' ? new Message(a) : new JournalEntry(a),
      );

      setFeedItems(feed);
    }
  }, [feedData]);

  return {
    loadFeed,
    feedItems,
    feedLoading,
  };
};

export default useFeed;
