import { FunctionComponent } from 'react';
import { Goal } from 'src/models/Goal';
import InfoCard from '../InfoCard/InfoCard';

import calendarIcon from '../../svgs/icon-goal-calendar.svg';

export type GoalCardProps = {
  goal: Goal | undefined;
  number: number;
};

const GoalCard: FunctionComponent<GoalCardProps> = ({ goal, number }) => {
  return (
    <>
      {goal ? (
        <InfoCard
          bodyText={goal.title}
          date={goal.createdAt}
          textIcon={`#${number}`}
          colour="teal"
        />
      ) : (
        <InfoCard
          bodyText="Set yourself a goal"
          icon={calendarIcon}
          colour="teal"
        />
      )}
    </>
  );
};

export default GoalCard;
