import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const GET_MATCHER_KEYS = gql`
  query {
    membersMatcherKeys
  }
`;

export const useMatcherKeys = () => {
  const { data: memberMatcherKeysData, loading: membersMathcerKeysLoading } =
    useQuery(GET_MATCHER_KEYS);
  const [membersMatcherKeys, setMembersMatcherKeys] = useState<string[]>();

  useEffect(() => {
    if (memberMatcherKeysData?.membersMatcherKeys) {
      setMembersMatcherKeys(memberMatcherKeysData.membersMatcherKeys);
    }
  }, [memberMatcherKeysData]);

  return {
    membersMatcherKeys,
    membersMathcerKeysLoading,
  };
};
