import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Member } from '../models/Member';

const GET_MATCH_QUEUE = gql`
  query ($matcherKey: String) {
    members(matcherKey: $matcherKey) {
      id
      firstName
      lastName
      totalMatches
      requestedMatchesCount
      totalApprovedMatches
      functionalArea
      careerStartYear
      cohort
      memberSince
      addNewMatchesDate
      scoreDate
    }
  }
`;

const SCORE_ALL_USERS = gql`
  mutation {
    scoreUsers {
      id
      success
    }
  }
`;

export function useMembers() {
  const [members, setMembers] = useState<Member[]>();
  const [loadMembers, { data, loading, refetch }] =
    useLazyQuery(GET_MATCH_QUEUE);

  const [scoreAllUsersCooldown, setScoreAllUsersCooldown] = useState(false);
  const [scoreAllUsersMutation, scoreAllUsersMutationData] =
    useMutation(SCORE_ALL_USERS);

  useEffect(() => {
    if (data?.members) {
      const membersMembers = data.members.map(
        (member: any) => new Member(member),
      );
      setMembers(membersMembers);
    }
  }, [data]);

  const scoreAllUsers = useCallback(async () => {
    if (!scoreAllUsersCooldown) {
      setScoreAllUsersCooldown(true);
      // cool down for 2 minutes
      setTimeout(() => {
        setScoreAllUsersCooldown(false);
        refetch();
      }, 2 * 60 * 1000);
      await scoreAllUsersMutation();
    }
  }, [scoreAllUsersCooldown, scoreAllUsersMutation, refetch]);

  return {
    members,
    membersLoading: loading,

    loadMembers,
    scoreAllUsers,
    scoreAllUsersCooldown,
    scoreAllUsersLoading: scoreAllUsersMutationData.loading,
  };
}
