import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Goal } from '../models/Goal';

// GET Users Goals
const GET_GOALS = gql`
  query {
    me {
      id
      goals {
        id
        title
        createdAt
        sortOrder
      }
    }
  }
`;

const UPDATE_GOALS = gql`
  mutation updateGoals($goals: [GoalInput]!) {
    updateGoals(goals: $goals) {
      me {
        goals {
          id
          title
          createdAt
          sortOrder
        }
      }
      success
    }
  }
`;

export function useGoals() {
  const [goals, setGoals] = useState<Goal[]>([]);
  const { data, loading } = useQuery(GET_GOALS);
  const [updateGoalsMutation, updateGoalsMutationData] = useMutation(
    UPDATE_GOALS,
    {
      update: (
        cache,
        {
          data: {
            updateGoals: {
              me: { goals },
              success,
            },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_GOALS });

        // If the cache is empty or we failed to update
        if (!success || !cachedRequest?.me?.goals) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_GOALS,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              goals,
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    if (data?.me?.goals) {
      setGoals(data.me.goals.map((goal: any) => new Goal(goal)));
    }
  }, [data]);

  return {
    goals,
    goalsLoading: loading,
    updateGoalsMutation,
    goalsUpdating: updateGoalsMutationData.loading,
  };
}
