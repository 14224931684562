import { restrictionsReducers, restrictionsActions } from '@frontegg/redux-store';
import { useEffect } from 'react';
import { reducerActionsGenerator, stateHookGenerator } from './hooks';
const defaultMapper = state => state;
export function useRestrictionsState(stateMapper = defaultMapper) {
  return stateHookGenerator(stateMapper, 'restrictionsState');
}
export const useRestrictionsActions = () => reducerActionsGenerator(restrictionsActions, restrictionsReducers);
export const useIpRestrictions = (loadOnMount = false) => {
  const {
    loading,
    data,
    saving,
    error,
    pagination,
    totalPages,
    userIpValid
  } = useRestrictionsState(state => state.ipRestrictions);
  const {
    loadIpRestrictions
  } = useRestrictionsActions();
  useEffect(() => {
    (loadOnMount || !data) && loadIpRestrictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnMount, loadIpRestrictions]);
  return {
    loading,
    saving,
    error,
    data,
    pagination,
    totalPages,
    userIpValid
  };
};
export const useEmailDomainRestrictions = (loadOnMount = false) => {
  const {
    loading,
    data,
    saving,
    error
  } = useRestrictionsState(state => state.emailDomainRestrictions);
  const {
    loadEmailDomainRestrictions
  } = useRestrictionsActions();
  useEffect(() => {
    (loadOnMount || !data) && loadEmailDomainRestrictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnMount, loadEmailDomainRestrictions]);
  return {
    loading,
    saving,
    error,
    data
  };
};