import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback", "ssoConfigId"],
  _excluded3 = ["callback", "ssoConfigId"];
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { SSOStateKeys } from './interfaces';
import { readFileAsText } from '../../helpers';
function* loadOidc() {
  try {
    const oidcConfiguration = yield call(api.auth.getOidcConfiguration);
    return oidcConfiguration;
  } catch (e) {
    return null;
  }
}
function* loadSaml() {
  try {
    const samlMetadata = yield call(api.metadata.getSamlMetadata);
    return samlMetadata;
  } catch (e) {
    return null;
  }
}
function* loadSSOConfigurationsV2() {
  try {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.LOAD_SSO_CONFIGURATIONS,
      value: true
    }));
    const [oidcConfiguration, samlMetadata] = yield all([call(loadOidc), call(loadSaml)]);
    const ssoConfigurations = yield call(api.auth.getSSOConfigurations);
    yield put(actions.setSSOState({
      ssoConfigurations,
      oidcConfiguration,
      samlMetadata
    }));
  } catch (e) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.LOAD_SSO_CONFIGURATIONS,
      value: e.message
    }));
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.LOAD_SSO_CONFIGURATIONS,
      value: false
    }));
  }
}
function* saveSSOConfigurationV2({
  payload
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.SAVE_SSO_CONFIGURATION,
    value: true
  }));
  const {
      callback
    } = payload,
    ssoConfiguration = _objectWithoutPropertiesLoose(payload, _excluded);
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    var _ssoConfiguration$ena;
    const newSsoConfiguration = yield call(api.auth.createSSOConfiguration, _extends({}, ssoConfiguration, {
      enabled: (_ssoConfiguration$ena = ssoConfiguration.enabled) != null ? _ssoConfiguration$ena : false
    }));
    yield put(actions.setSSOState({
      ssoConfigurations: [...ssoConfigurations, newSsoConfiguration]
    }));
    callback == null ? void 0 : callback(newSsoConfiguration);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.SAVE_SSO_CONFIGURATION,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.SAVE_SSO_CONFIGURATION,
      value: false
    }));
  }
}
function* saveSSOConfigurationByMetadata({
  payload
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.SAVE_SSO_CONFIGURATION_BY_METADATA,
    value: true
  }));
  const {
    configFile,
    callback
  } = payload;
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    const metadata = yield readFileAsText(configFile);
    const newSsoConfiguration = yield call(api.auth.createSSOConfigurationByMetadata, {
      metadata
    });
    yield put(actions.setSSOState({
      ssoConfigurations: [...ssoConfigurations, newSsoConfiguration]
    }));
    callback == null ? void 0 : callback(newSsoConfiguration);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.SAVE_SSO_CONFIGURATION_BY_METADATA,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.SAVE_SSO_CONFIGURATION_BY_METADATA,
      value: false
    }));
  }
}
function* updateSSOConfiguration({
  payload
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.UPDATE_SSO_CONFIGURATION,
    value: true
  }));
  const {
      callback,
      ssoConfigId
    } = payload,
    ssoConfiguration = _objectWithoutPropertiesLoose(payload, _excluded2);
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    const newSsoConfiguration = yield call(api.auth.updateSSOConfiguration, ssoConfigId, ssoConfiguration);
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(newSsoConfiguration);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.UPDATE_SSO_CONFIGURATION,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.UPDATE_SSO_CONFIGURATION,
      value: false
    }));
  }
}
function* updateSSOConfigurationByMetadata({
  payload
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.UPDATE_SSO_CONFIGURATION_BY_METADATA,
    value: true
  }));
  const {
    callback,
    ssoConfigId,
    configFile
  } = payload;
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    const metadata = yield readFileAsText(configFile);
    const newSsoConfiguration = yield call(api.auth.updateSSOConfigurationByMetadata, ssoConfigId, {
      metadata
    });
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => ssoConfig.id === ssoConfigId ? newSsoConfiguration : ssoConfig);
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(newSsoConfiguration);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.UPDATE_SSO_CONFIGURATION,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.UPDATE_SSO_CONFIGURATION,
      value: false
    }));
  }
}
function* deleteSSOConfiguration({
  payload
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.DELETE_SSO_CONFIGURATION,
    value: true
  }));
  const {
    ssoConfigId,
    callback
  } = payload;
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    yield call(api.auth.deleteSSOConfiguration, ssoConfigId);
    yield put(actions.setSSOState({
      ssoConfigurations: ssoConfigurations.filter(({
        id
      }) => id !== ssoConfigId)
    }));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.DELETE_SSO_CONFIGURATION,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.DELETE_SSO_CONFIGURATION,
      value: false
    }));
  }
}
function* saveSSODomain({
  payload
}) {
  const {
      callback,
      ssoConfigId
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded3);
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.SAVE_SSO_DOMAIN,
    value: true
  }));
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    const domain = yield call(api.auth.createSSODomain, ssoConfigId, body);
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
      if (ssoConfig.id === ssoConfigId) {
        return _extends({}, ssoConfig, {
          domains: [...ssoConfig.domains, domain]
        });
      }
      return ssoConfig;
    });
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(domain);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.SAVE_SSO_DOMAIN,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.SAVE_SSO_DOMAIN,
      value: false
    }));
  }
}
function* deleteSSODomain({
  payload
}) {
  const {
    ssoConfigId,
    domainId,
    callback
  } = payload;
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.DELETE_SSO_DOMAIN,
    value: true
  }));
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    yield call(api.auth.deleteSSODomain, ssoConfigId, domainId);
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
      if (ssoConfig.id === ssoConfigId) {
        return _extends({}, ssoConfig, {
          domains: ssoConfig.domains.filter(({
            id
          }) => id !== domainId)
        });
      }
      return ssoConfig;
    });
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.DELETE_SSO_DOMAIN,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.DELETE_SSO_DOMAIN,
      value: false
    }));
  }
}
function* validateSSODomainV2({
  payload
}) {
  const {
    callback,
    domainId,
    ssoConfigId
  } = payload;
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.VALIDATE_SSO_DOMAIN,
    value: true
  }));
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    yield call(api.auth.validateSSODomain, ssoConfigId, domainId);
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
      if (ssoConfig.id === ssoConfigId) {
        return _extends({}, ssoConfig, {
          domains: ssoConfig.domains.map(ssoDomain => ssoDomain.id === domainId ? _extends({}, ssoDomain, {
            validated: true
          }) : ssoDomain)
        });
      }
      return ssoConfig;
    });
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.VALIDATE_SSO_DOMAIN,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.VALIDATE_SSO_DOMAIN,
      value: false
    }));
  }
}
function* setSSODefaultRoles({
  payload: {
    callback,
    ssoConfigId,
    roleIds
  }
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.UPDATE_SSO_DEFAULT_ROLES,
    value: true
  }));
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    yield call(api.auth.setSSODefaultRoles, ssoConfigId, {
      roleIds
    });
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
      if (ssoConfig.id === ssoConfigId) {
        return _extends({}, ssoConfig, {
          roleIds
        });
      }
      return ssoConfig;
    });
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.UPDATE_SSO_DEFAULT_ROLES,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.UPDATE_SSO_DEFAULT_ROLES,
      value: false
    }));
  }
}
function* setSSOGroups({
  payload: {
    groupsToAdd,
    groupsToDelete,
    ssoConfigId,
    callback
  }
}) {
  yield put(actions.setSSOLoader({
    key: SSOStateKeys.SAVE_SSO_GROUPS,
    value: true
  }));
  const ssoConfigurations = yield select(state => state.auth.ssoState.ssoConfigurations);
  try {
    yield all(groupsToDelete.map(groupId => call(api.auth.deleteSSOGroup, ssoConfigId, groupId)));
    yield all(groupsToAdd.map(group => call(api.auth.createSSOGroup, ssoConfigId, group)));
    const groups = yield call(api.auth.getSSOGroups, ssoConfigId);
    const newSsoConfigurations = ssoConfigurations.map(ssoConfig => {
      if (ssoConfig.id === ssoConfigId) {
        return _extends({}, ssoConfig, {
          groups
        });
      }
      return ssoConfig;
    });
    yield put(actions.setSSOState({
      ssoConfigurations: newSsoConfigurations
    }));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.SAVE_SSO_GROUPS,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.SAVE_SSO_GROUPS,
      value: false
    }));
  }
}
function* updateSSOGroups({
  payload: {
    ssoConfigId,
    changedGroups,
    callback
  }
}) {
  try {
    yield all(changedGroups.map(group => call(api.auth.updateSSOGroup, ssoConfigId, group)));
    callback == null ? void 0 : callback(true);
  } catch (error) {
    yield put(actions.setSSOError({
      key: SSOStateKeys.SAVE_SSO_GROUPS,
      value: error.message
    }));
    callback == null ? void 0 : callback(null, error);
  } finally {
    yield put(actions.setSSOLoader({
      key: SSOStateKeys.SAVE_SSO_GROUPS,
      value: false
    }));
  }
}
export function* ssoSagas() {
  yield takeEvery(actions.loadSSOConfigurationsV2, loadSSOConfigurationsV2);
  yield takeEvery(actions.saveSSOConfigurationV2, saveSSOConfigurationV2);
  yield takeEvery(actions.saveSSODomain, saveSSODomain);
  yield takeEvery(actions.deleteSSODomain, deleteSSODomain);
  yield takeEvery(actions.validateSSODomainV2, validateSSODomainV2);
  yield takeEvery(actions.updateSSOConfiguration, updateSSOConfiguration);
  yield takeEvery(actions.deleteSSOConfiguration, deleteSSOConfiguration);
  yield takeEvery(actions.setSSOGroups, setSSOGroups);
  yield takeEvery(actions.updateSSOGroups, updateSSOGroups);
  yield takeEvery(actions.setSSODefaultRoles, setSSODefaultRoles);
  yield takeEvery(actions.saveSSOConfigurationByMetadata, saveSSOConfigurationByMetadata);
  yield takeEvery(actions.updateSSOConfigurationByMetadata, updateSSOConfigurationByMetadata);
}