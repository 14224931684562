import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback", "events"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"],
  _excluded9 = ["callback"],
  _excluded10 = ["callback"],
  _excluded11 = ["callback"],
  _excluded12 = ["callback"],
  _excluded13 = ["callback"],
  _excluded14 = ["callback"],
  _excluded15 = ["callback", "deviceId"],
  _excluded16 = ["callback", "deviceId"],
  _excluded17 = ["callback", "deviceId"],
  _excluded18 = ["callback", "deviceId"];
import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { api, AuthStrategyEnum, ContextHolder, fetch, MFAStrategyEnum, WebAuthnDeviceType } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { FRONTEGG_AFTER_AUTH_REDIRECT_URL, HOSTED_LOGIN_VERIFIER_KEY } from '../../constants';
import { UserVeirifedOriginTypes } from '../interfaces';
import { LoginFlow, LoginStep } from './interfaces';
import { loadAllowSignUps } from '../SignUp/saga';
import { MFAStep } from '../MfaState/interfaces';
import { dummyIps, userDemo } from '../dummy';
import { SamlVendors } from '../SSOState/interfaces';
import { loadVendorPublicInfo } from '../../vendor/saga';
import { createRandomString, generateCodeChallenge, getFeatureFlags } from '../../helpers';
import { ResetPhoneNumberStep } from '../ResetPhoneNumberState/interfaces';
import { base64urlDecode, delay, publicKeyCredentialToJSON } from '../utils';
import { loadPublicAuthStrategiesPolicy } from '../SecurityPolicyState/saga';
import { getPasskeysVendorPolicy } from '../PasskeysState/helpers';
import { getRedirectUrl } from './utils';
const authStrategyLoginStepMap = {
  [AuthStrategyEnum.Code]: LoginStep.loginWithOtc,
  [AuthStrategyEnum.EmailAndPassword]: LoginStep.loginWithPassword,
  [AuthStrategyEnum.MagicLink]: LoginStep.magicLinkPreLoginSuccess,
  [AuthStrategyEnum.SmsCode]: LoginStep.loginWithSmsOtc
};
export function* afterAuthNavigation() {
  const onRedirectTo = ContextHolder.onRedirectTo;
  const {
    routes,
    includeQueryParam,
    enforceRedirectToSameSite = false,
    allowedRedirectOrigins = []
  } = yield select(state => state.auth);
  const {
    loginUrl,
    logoutUrl,
    socialLoginCallbackUrl,
    activateUrl
  } = routes;
  let {
    authenticatedUrl
  } = routes;
  const afterAuthRedirect = window.localStorage.getItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
  if (afterAuthRedirect && ![loginUrl, logoutUrl, socialLoginCallbackUrl, activateUrl].includes(afterAuthRedirect)) {
    authenticatedUrl = afterAuthRedirect;
  }
  window.localStorage.removeItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
  yield delay(200);
  put(actions.resetLoginState());
  const redirectUrl = getRedirectUrl({
    authenticatedUrl,
    includeQueryParam,
    enforceRedirectToSameSite,
    allowedRedirectOrigins
  });
  onRedirectTo(redirectUrl, {
    refresh: redirectUrl.startsWith('http')
  });
}
export function* refreshMetadata() {
  let ssoACS;
  try {
    var _metadata$configurati;
    const metadata = yield call(api.metadata.getSamlMetadata);
    ssoACS = metadata == null ? void 0 : (_metadata$configurati = metadata.configuration) == null ? void 0 : _metadata$configurati.acsUrl;
  } catch (e) {
    console.error(e);
  }
  yield put(actions.setState({
    ssoACS
  }));
}
export function* loadSSOPublicConfigurationFunction() {
  try {
    const {
      isActive
    } = yield call(api.auth.getSSOPublicConfiguration);
    yield put(actions.setState({
      isSSOAuth: isActive
    }));
  } catch (e) {
    console.error(e);
  }
}
export const isMfaRequired = user => {
  if (user.mfaRequired && user.mfaToken) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    return true;
  } else {
    ContextHolder.setAccessToken(user.accessToken);
    ContextHolder.setUser(user);
    return false;
  }
};
const getNumberOfMfaDevices = mfaDevices => {
  const numberOfWebAuthnDevices = +Boolean(mfaDevices.webauthn.length);
  const numberOfPhoneDevices = +Boolean(mfaDevices.phones.length);
  const numberOfAuthenticators = +Boolean(mfaDevices.authenticators.length);
  const totalDevices = numberOfWebAuthnDevices + numberOfPhoneDevices + numberOfAuthenticators;
  return {
    numberOfWebAuthnDevices,
    numberOfPhoneDevices,
    numberOfAuthenticators,
    totalDevices
  };
};
const getMfaStepForEnrolledUsers = mfaDevices => {
  const {
    numberOfPhoneDevices,
    numberOfAuthenticators,
    totalDevices
  } = getNumberOfMfaDevices(mfaDevices);
  if (totalDevices === 1) {
    if (numberOfAuthenticators) {
      return MFAStep.authenticatorApp;
    } else if (numberOfPhoneDevices) {
      return MFAStep.smsVerifyCode;
    }
  }
  return MFAStep.verify;
};
const getMfaStepForNotEnrolledUsers = mfaStrategies => {
  if (mfaStrategies.length === 1) {
    const [strategy] = mfaStrategies;
    if (strategy === MFAStrategyEnum.AuthenticatorApp) {
      return MFAStep.authenticatorApp;
    } else if (strategy === MFAStrategyEnum.SMS) {
      return MFAStep.smsSetPhoneNumber;
    }
  }
  return MFAStep.verify;
};
export function* getMfaRequiredState(user) {
  let step = LoginStep.loginWithTwoFactor;
  let mfaState = {};
  const {
    loginState
  } = yield select(state => state.auth);
  const {
    isAllowedToRemember,
    mfaDeviceExpiration
  } = yield call(api.auth.checkIfAllowToRememberMfaDevice, user.mfaToken);
  const isEnrolled = !(user.hasOwnProperty('mfaEnrolled') && !user.mfaEnrolled);
  if (isEnrolled) {
    mfaState = {
      mfaDevices: user.mfaDevices,
      step: getMfaStepForEnrolledUsers(user.mfaDevices)
    };
  } else {
    mfaState = {
      step: getMfaStepForNotEnrolledUsers(user.mfaStrategies),
      qrCode: user.qrCode,
      recoveryCode: user.recoveryCode,
      loading: false,
      mfaToken: user.mfaToken,
      mfaStrategies: user.mfaStrategies
    };
    step = LoginStep.forceTwoFactor;
  }
  let quickLoginState = {};
  const quickLoginToRegister = localStorage.getItem('register-quick-login');
  if (quickLoginToRegister) {
    quickLoginState = {
      quickLoginToRegister,
      flow: LoginFlow.RegisterQuickLogin
    };
  }
  return {
    user: undefined,
    isAuthenticated: false,
    mfaState,
    loginState: _extends({}, loginState, quickLoginState, {
      mfaToken: user.mfaToken,
      mfaRequired: user.mfaRequired,
      loading: false,
      error: undefined,
      step,
      tenantsLoading: true,
      email: user.userEmail,
      tenants: [],
      allowRememberMfaDevice: isAllowedToRemember,
      mfaDeviceExpiration
    })
  };
}
function* shouldNevigateToRegisterQuickLogin(user) {
  var _ref;
  const {
    routes,
    loginState
  } = yield select(state => state.auth);
  const quickLoginToRegister = (_ref = localStorage.getItem('register-quick-login')) != null ? _ref : loginState.quickLoginToRegister;
  return quickLoginToRegister && localStorage.getItem(`${user.id}-${quickLoginToRegister}`) !== 'true' && !window.location.pathname.endsWith(routes.logoutUrl);
}
export function* refreshToken() {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  if (hostedLoginBox) {
    yield call(refreshTokenHosted);
  } else {
    yield call(refreshTokenEmbedded);
  }
}
export function* refreshTokenForSocialLogins() {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  if (hostedLoginBox) {
    yield call(refreshTokenHostedSocialLogins);
  } else {
    yield call(refreshTokenEmbeddedSocialLogins);
  }
}
function* refreshTokenHosted() {
  const {
    user
  } = yield select(state => state.auth);
  if (!(user != null && user.refreshToken)) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
    return;
  }
  try {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: user == null ? void 0 : user.refreshToken
    };
    const authenticatedUser = yield call(api.auth.exchangeOAuthTokens, body);
    yield put(actions.setState({
      user: authenticatedUser,
      isAuthenticated: true
    }));
    yield put(actions.loadTenants());
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenHostedSocialLogins() {
  const {
    user
  } = yield select(state => state.auth);
  if (!(user != null && user.refreshToken)) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
    return;
  }
  try {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: user == null ? void 0 : user.refreshToken
    };
    const authenticatedUser = yield call(api.auth.exchangeOAuthTokens, body);
    yield put(actions.setState({
      user: authenticatedUser,
      isAuthenticated: true
    }));
    yield put(actions.loadTenants());
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenEmbedded() {
  try {
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes,
      loginState
    } = yield select(state => state.auth);
    const {
      user,
      tenants
    } = yield call(api.auth.refreshTokenV2);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      var _ref2;
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      const quickLoginToRegister = (_ref2 = localStorage.getItem('register-quick-login')) != null ? _ref2 : loginState.quickLoginToRegister;
      const shouldNavigateToRegisterQuickLogin = yield shouldNevigateToRegisterQuickLogin(user);
      yield put(actions.setTenantsState({
        tenants,
        loading: false
      }));
      yield put(actions.setState({
        user,
        isAuthenticated: true,
        loginState: _extends({}, loginState, {
          quickLoginToRegister,
          flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
        })
      }));
      yield handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenEmbeddedSocialLogins() {
  try {
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes,
      loginState
    } = yield select(state => state.auth);
    const {
      user,
      tenants
    } = yield call(api.auth.refreshTokenV2);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
      if (shouldShowPrompt) {
        yield put(actions.setLoginState({
          step: LoginStep.promptPasskeys,
          loading: false
        }));
        yield put(actions.setTenantsState({
          tenants,
          loading: false
        }));
        yield put(actions.setState({
          user
        }));
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        var _ref3;
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        const quickLoginToRegister = (_ref3 = localStorage.getItem('register-quick-login')) != null ? _ref3 : loginState.quickLoginToRegister;
        const shouldNavigateToRegisterQuickLogin = yield shouldNevigateToRegisterQuickLogin(user);
        yield put(actions.setTenantsState({
          tenants,
          loading: false
        }));
        yield put(actions.setState({
          user,
          isAuthenticated: true,
          loginState: _extends({}, loginState, {
            quickLoginToRegister,
            flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
          })
        }));
        yield handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
      }
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
  }
}
function* handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin) {
  var _window;
  const url = new URL((_window = window) == null ? void 0 : _window.location.href);
  const onRedirectTo = ContextHolder.onRedirectTo;
  const {
    routes,
    loginState
  } = yield select(state => state.auth);
  const invitationToken = url.searchParams.get('invitationToken');
  const redirectRoutes = [routes.socialLoginCallbackUrl, routes.oidcRedirectUrl, routes.samlCallbackUrl];
  if (!invitationToken) {
    redirectRoutes.push(routes.loginUrl, routes.signUpUrl);
  }
  if (shouldNavigateToRegisterQuickLogin) {
    onRedirectTo(routes.loginUrl);
  } else if (redirectRoutes.some(url => url && window.location.pathname.endsWith(url))) {
    if (loginState.isNewUser && routes.signUpSuccessUrl && routes.socialLoginCallbackUrl === window.location.pathname) {
      onRedirectTo(routes.signUpSuccessUrl, {
        refresh: routes.signUpSuccessUrl.startsWith('http')
      });
    } else {
      yield afterAuthNavigation();
    }
  }
}
function* getUserIP({
  payload: {
    callback
  }
}) {
  try {
    const {
      ip
    } = yield call(api.metadata.getCurrentUserIpMetadata);
    yield put(actions.setState({
      userIp: ip
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    callback == null ? void 0 : callback(false);
  }
}
function* requestAuthorize({
  payload: firstTime
}) {
  const calls = [];
  if (firstTime) {
    yield put(actions.setState({
      isLoading: true
    }));
    yield put(actions.loadSocialLoginsConfigurationV2());
    calls.push(call(loadAllowSignUps));
    calls.push(call(loadPublicAuthStrategiesPolicy));
    calls.push(call(loadSSOPublicConfigurationFunction));
    calls.push(call(loadVendorPublicInfo));
    calls.push(call(refreshMetadata));
  }
  calls.push(call(refreshToken));
  yield all(calls);
  yield put(actions.setState({
    isLoading: false
  }));
}
function* isMFARequiredSSR({
  accessToken,
  user
}) {
  if (!accessToken) {
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
    return;
  }
  const onRedirectTo = ContextHolder.onRedirectTo;
  const {
    routes
  } = yield select(state => state.auth);
  if (isMfaRequired(user)) {
    const mfaRequiredState = yield getMfaRequiredState(user);
    yield put(actions.setState(mfaRequiredState));
    onRedirectTo(routes.loginUrl, {
      preserveQueryParams: true
    });
  }
}
function* requestAuthorizeSSR({
  payload
}) {
  const calls = [];
  yield put(actions.loadSocialLoginsConfigurationV2());
  calls.push(call(loadAllowSignUps));
  calls.push(call(loadSSOPublicConfigurationFunction));
  calls.push(call(loadVendorPublicInfo));
  calls.push(call(refreshMetadata));
  calls.push(call(isMFARequiredSSR, payload));
  yield all(calls);
}
const getUri = urlStrategy => {
  if (urlStrategy === 'path') {
    var _window2;
    return ((_window2 = window) != null ? _window2 : document).location.pathname;
  } else {
    var _window3;
    const uri = (((_window3 = window) != null ? _window3 : document).location.hash || '#').substring(1);
    if (uri.indexOf('?') !== -1) {
      return uri.substring(0, uri.indexOf('?'));
    } else {
      return uri;
    }
  }
};
function* requestHostedLoginSilentAuthorize() {
  const user = yield call(api.auth.silentOAuthRefreshToken);
  if (user) {
    yield put(actions.setState({
      user,
      isAuthenticated: true
    }));
    yield put(actions.loadTenants());
    yield put(actions.setState({
      isLoading: false
    }));
  } else {
    throw new Error(`couldn't refresh user token with oauth service`);
  }
}
function* refreshOrRequestHostedLoginAuthorize({
  payload: additionalParams
}) {
  const {
    disableSilentRefresh
  } = yield select(state => ({
    disableSilentRefresh: state.auth.disableSilentRefresh
  }));
  try {
    if (disableSilentRefresh) {
      throw new Error('silent refresh is disabled');
    }
    yield requestHostedLoginSilentAuthorize();
  } catch (e) {
    yield requestHostedLoginAuthorize(additionalParams);
  }
}
function* requestHostedLoginAuthorize(additionalParams) {
  const {
    routes,
    context,
    onRedirectTo,
    urlStrategy
  } = yield select(state => ({
    routes: state.auth.routes,
    onRedirectTo: state.auth.onRedirectTo,
    context: state.root.context,
    urlStrategy: state.root.urlStrategy
  }));
  const activeUri = getUri(urlStrategy);
  if (activeUri === routes.hostedLoginRedirectUrl) {
    console.debug('Calling loginWithRedirect while in hostedLoginCallback route');
    return;
  }
  yield put(actions.setState({
    isLoading: true
  }));
  // Generate the relevant params for the redirect
  const nonce = createRandomString();
  const code_verifier = createRandomString();
  const code_challenge = yield call(generateCodeChallenge, code_verifier);

  // We are saving the verifier in session storage to be able to validate the response
  localStorage.setItem(HOSTED_LOGIN_VERIFIER_KEY, code_verifier);
  const redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${routes.hostedLoginRedirectUrl}`;
  const baseUrl = fetch.getBaseUrl(context, '/oauth/authorize');
  // Hard coded for now
  const oauthUrl = `${baseUrl}/oauth/authorize`;
  const params = _extends({
    response_type: 'code',
    client_id: context.clientId || 'INVALID-CLIENT-ID',
    scope: 'openid email profile',
    redirect_uri: redirectUrl,
    code_challenge: code_challenge,
    code_challenge_method: 'S256',
    nonce
  }, additionalParams);
  if (context.tenantResolver) {
    var _context$tenantResolv;
    const resolvedTenantResult = yield (_context$tenantResolv = context.tenantResolver) == null ? void 0 : _context$tenantResolv.call(context);
    if (resolvedTenantResult != null && resolvedTenantResult.tenant) {
      params['organization'] = resolvedTenantResult.tenant;
    }
  }
  const searchParams = new URLSearchParams(params);
  const url = `${oauthUrl}?${searchParams.toString()}`;
  onRedirectTo(url, {
    refresh: true
  });
}
function* handleHostedLoginCallback({
  payload
}) {
  // Hard coded for now
  const code_verifier = localStorage.getItem(HOSTED_LOGIN_VERIFIER_KEY) || 'INVALID-CODE-VERIFIER';
  const {
    routes,
    urlStrategy
  } = yield select(state => ({
    routes: state.auth.routes,
    urlStrategy: state.root.urlStrategy
  }));
  const redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${routes.hostedLoginRedirectUrl}`;
  const body = {
    code: payload.code,
    redirect_uri: redirectUrl,
    code_verifier,
    grant_type: 'authorization_code'
  };
  try {
    const user = yield call(api.auth.exchangeOAuthTokens, body);
    // TODO: Validate nonce and aud

    yield put(actions.setState({
      user,
      isAuthenticated: true
    }));
    yield put(actions.loadTenants());
  } catch (e) {
    console.error('Failed to exchangeOAuthTokens', e);
  } finally {
    yield afterAuthNavigation();
  }
}
function* changePhoneNumberWithVerification(_ref4) {
  let {
      payload: {
        callback
      }
    } = _ref4,
    payload = _objectWithoutPropertiesLoose(_ref4.payload, _excluded);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const changePhoneRes = yield call(api.auth.changePhoneNumberWithVerification, payload);
    yield put(actions.setLoginState({
      phoneNumber: payload.phoneNumber,
      loading: false,
      changePhoneId: changePhoneRes.changePhoneId,
      step: LoginStep.loginWithQuickSmsOtc,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
  }
}
function* verifyChangePhoneNumber(_ref5) {
  let {
      payload: {
        callback
      }
    } = _ref5,
    payload = _objectWithoutPropertiesLoose(_ref5.payload, _excluded2);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    yield call(api.auth.verifyChangePhoneNumber, payload);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
  }
}
function* quickSmsPasswordlessPreLogin(_ref6) {
  let {
      payload: {
        callback
      }
    } = _ref6,
    payload = _objectWithoutPropertiesLoose(_ref6.payload, _excluded3);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));

    // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
    // @ts-ignore
    const preloginRes = yield call(api.auth.passwordlessPreLogin, _extends({}, payload, {
      type: AuthStrategyEnum.SmsCode
    }));
    yield put(actions.setLoginState({
      step: LoginStep.loginWithQuickSmsOtc,
      loading: false,
      phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
    callback == null ? void 0 : callback(e);
  }
}
function* passwordlessPreLogin(_ref7) {
  let {
      payload: {
        callback
      }
    } = _ref7,
    payload = _objectWithoutPropertiesLoose(_ref7.payload, _excluded4);
  try {
    const {
      onRedirectTo,
      routes
    } = yield select(({
      auth: {
        onRedirectTo,
        routes
      }
    }) => ({
      onRedirectTo,
      routes
    }));
    yield put(actions.setLoginState({
      loading: true
    }));

    // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
    // @ts-ignore
    const preloginRes = yield call(api.auth.passwordlessPreLogin, payload);
    const step = authStrategyLoginStepMap[payload.type];
    if (step === LoginStep.loginWithSmsOtc && preloginRes.resetPhoneNumberToken) {
      yield put(actions.setResetPhoneNumberState({
        resetPhoneNumberToken: preloginRes.resetPhoneNumberToken,
        step: ResetPhoneNumberStep.VerifyResetPhoneNumber
      }));
      onRedirectTo(routes.resetPhoneNumberUrl);
      return;
    }
    yield put(actions.setLoginState({
      step,
      loading: false,
      phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
      email: payload.email,
      error: undefined
    }));
    callback == null ? void 0 : callback();
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
    callback == null ? void 0 : callback();
  }
}
export function* shouldShowPromptPasskeys() {
  const {
    publicAuthStrategyPolicy
  } = yield select(state => state.auth.securityPolicyState);
  const {
    policy
  } = publicAuthStrategyPolicy;
  const isPasskeysEnabledByVendor = getPasskeysVendorPolicy(policy);
  const isLoggedInWithPasskeys = localStorage.getItem('preferred-login-method') === 'Passkeys';
  const isMarkedDontShowAgainPrompt = localStorage.getItem('dont-show-again-prompt-passkeys') === 'true';
  const [showPasskeys] = yield call(getFeatureFlags, ['show-passkeys-new']);
  if (!showPasskeys || !isPasskeysEnabledByVendor || isLoggedInWithPasskeys || isMarkedDontShowAgainPrompt) {
    return false;
  } else {
    const {
      devices
    } = yield call(api.auth.getWebAuthnDevices);
    yield put(actions.setPasskeysState({
      devices: devices != null ? devices : []
    }));
    const numOfDevices = !(devices != null && devices.length) ? 0 : devices.length;
    return numOfDevices === 0;
  }
}
function* passwordlessPostLogin(_ref8) {
  let {
      payload: {
        callback,
        events
      }
    } = _ref8,
    payload = _objectWithoutPropertiesLoose(_ref8.payload, _excluded5);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const data = yield call(api.auth.passwordlessPostLogin, payload);
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes
    } = yield select(state => state.auth);
    if (isMfaRequired(data)) {
      const mfaRequiredState = yield getMfaRequiredState(data);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const {
        loginState
      } = yield select(state => state.auth);
      const user = yield call(api.auth.generateLoginResponse, data);
      if (data.emailVerified) {
        var _events$userVerified;
        events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, {
          email: user.email,
          origin: UserVeirifedOriginTypes.PASSWORDLESS,
          id: user.id,
          tenantId: user.tenantId,
          createdAt: new Date(),
          name: user.name
        });
      }
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield put(actions.setState({
        user
      }));
      yield put(actions.setLoginState({
        error: undefined
      }));
      yield put(actions.loadTenants());
      yield put(actions.setState({
        isLoading: false
      }));
      if (loginState.flow === LoginFlow.Login) {
        const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
        if (shouldShowPrompt) {
          yield put(actions.setLoginState({
            step: LoginStep.promptPasskeys,
            loading: false
          }));
          onRedirectTo(routes.loginUrl, {
            preserveQueryParams: true
          });
        } else {
          yield put(actions.setState({
            isAuthenticated: true
          }));
          yield afterAuthNavigation();
        }
      } else {
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      }
      localStorage.removeItem('register-quick-login');
      callback == null ? void 0 : callback(true);
    }
  } catch (e) {
    var _e$message;
    yield put(actions.setLoginState({
      error: (_e$message = e.message) != null ? _e$message : 'Failed to authenticate'
    }));
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* verifyInviteToken({
  payload
}) {
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      name: inviteTokenTenantName
    } = yield call(api.auth.verifyInviteToken, payload);
    yield put(actions.setLoginState({
      inviteTokenTenantName
    }));
  } catch (e) {
    var _e$message2;
    console.error(e);
    yield put(actions.setLoginState({
      inviteTokenError: (_e$message2 = e.message) != null ? _e$message2 : `We couldn't verify your invitation`
    }));
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* preLogin({
  payload: {
    email,
    recaptchaToken,
    invitationToken,
    callback
  }
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const onRedirectTo = yield select(({
      auth: {
        onRedirectTo
      }
    }) => onRedirectTo);
    const {
      address,
      idpType
    } = yield call(api.auth.preLoginV2, {
      email
    });
    if (address) {
      let ssoRedirectUrl = address;
      if (idpType === SamlVendors.Oidc && !ssoRedirectUrl.includes('redirect_uri')) {
        const {
          routes: {
            oidcRedirectUrl
          }
        } = yield select(({
          auth: {
            routes
          }
        }) => ({
          routes
        }));
        ssoRedirectUrl += `&redirect_uri=${window.location.origin}${oidcRedirectUrl}`;
      }
      yield put(actions.setLoginState({
        step: LoginStep.redirectToSSO,
        loading: false,
        ssoRedirectUrl
      }));
      setTimeout(() => {
        onRedirectTo(ssoRedirectUrl, {
          refresh: true
        });
      }, 2000);
    } else {
      yield ssoPreloginFailed({
        email,
        recaptchaToken,
        callback,
        invitationToken
      });
    }
  } catch (e) {
    yield ssoPreloginFailed({
      email,
      recaptchaToken,
      callback,
      invitationToken
    });
  }
}
function* ssoPreloginFailed(_ref9) {
  let {
      callback
    } = _ref9,
    body = _objectWithoutPropertiesLoose(_ref9, _excluded6);
  const publicPolicy = yield select(({
    auth: {
      securityPolicyState: {
        publicPolicy: {
          policy: publicPolicy
        }
      }
    }
  }) => publicPolicy);
  if (!(publicPolicy != null && publicPolicy.authStrategy)) {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
    return;
  }
  if ((publicPolicy == null ? void 0 : publicPolicy.authStrategy) === AuthStrategyEnum.EmailAndPassword) {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
  } else if ([AuthStrategyEnum.MagicLink, AuthStrategyEnum.Code, AuthStrategyEnum.SmsCode].includes(publicPolicy == null ? void 0 : publicPolicy.authStrategy)) {
    yield put(actions.passwordlessPreLogin(_extends({}, body, {
      type: publicPolicy == null ? void 0 : publicPolicy.authStrategy,
      callback
    })));
  } else {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
  }
}
function* webAuthnCreateNewDeviceSession({
  payload: {
    callback
  }
}) {
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      options
    } = yield call(api.auth.webAuthnCreateNewDeviceSession);
    options.user.id = base64urlDecode(options.user.id);
    options.challenge = base64urlDecode(options.challenge);
    options.excludeCredentials = [];
    callback == null ? void 0 : callback(options);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnVerifyNewDeviceSession(_ref10) {
  let {
      payload: {
        callback
      }
    } = _ref10,
    body = _objectWithoutPropertiesLoose(_ref10.payload, _excluded7);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const publicKey = publicKeyCredentialToJSON(body.publicKey);
    const deviceType = (publicKey == null ? void 0 : publicKey.authenticatorAttachment) === 'platform' ? WebAuthnDeviceType.Platform : WebAuthnDeviceType.CrossPlatform;
    yield call(api.auth.verifyNewDeviceSession, {
      id: publicKey.id,
      response: publicKey.response,
      deviceType: deviceType
    });
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnPrelogin(_ref11) {
  let {
      payload: {
        callback
      }
    } = _ref11,
    body = _objectWithoutPropertiesLoose(_ref11.payload, _excluded8);
  try {
    var _options$allowCredent;
    yield put(actions.setPasskeysState({
      loading: true
    }));
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      options
    } = yield call(api.auth.webAuthnPreLogin, body);
    options.challenge = base64urlDecode(options.challenge);
    options.allowCredentials = (_options$allowCredent = options.allowCredentials) == null ? void 0 : _options$allowCredent.map(credentials => _extends({}, credentials, {
      id: base64urlDecode(credentials.id)
    }));
    yield put(actions.setLoginState({
      error: undefined
    }));
    callback == null ? void 0 : callback(options);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setPasskeysState({
      loading: false
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnPostLogin(_ref12) {
  let {
      payload: {
        callback
      }
    } = _ref12,
    body = _objectWithoutPropertiesLoose(_ref12.payload, _excluded9);
  try {
    var _publicKey$response$u;
    yield put(actions.setPasskeysState({
      loading: true
    }));
    yield put(actions.setLoginState({
      loading: true
    }));
    const publicKey = publicKeyCredentialToJSON(body.publicKey);
    const data = yield call(api.auth.webAuthnPostLogin, _extends({}, publicKey, {
      response: _extends({}, publicKey.response, {
        userHandle: (_publicKey$response$u = publicKey.response.userHandle) != null ? _publicKey$response$u : undefined
      }),
      recaptchaToken: body.recaptchaToken,
      invitationToken: body.invitationToken
    }));
    if (isMfaRequired(data)) {
      const onRedirectTo = ContextHolder.onRedirectTo;
      const {
        routes
      } = yield select(state => state.auth);
      const mfaRequiredState = yield getMfaRequiredState(data);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const user = yield call(api.auth.generateLoginResponse, data);
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield put(actions.loadTenants());
      yield put(actions.setState({
        user,
        isAuthenticated: true
      }));
      yield put(actions.setLoginState({
        error: undefined
      }));
      yield afterAuthNavigation();
      // TODO: Itamar why moving callback to inside the else block
      callback == null ? void 0 : callback(true);
    }
    // callback?.(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setPasskeysState({
      loading: false
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* postLogin({
  payload
}) {
  const {
    onRedirectTo,
    routes
  } = yield select(({
    auth: {
      onRedirectTo,
      routes
    }
  }) => ({
    onRedirectTo,
    routes
  }));
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const user = yield call(api.auth.postLogin, payload);
    ContextHolder.setAccessToken(user.accessToken);
    ContextHolder.setUser(user);
    yield put(actions.setState({
      user: !!user.accessToken ? user : undefined,
      isAuthenticated: !!user.accessToken
    }));
    yield afterAuthNavigation();
  } catch (e) {
    setTimeout(() => {
      onRedirectTo(routes.authenticatedUrl);
    }, 1000);
    yield put(actions.setLoginState({
      step: LoginStep.loginWithSSOFailed,
      loading: false
    }));
  }
}
function* login({
  payload: {
    email,
    password,
    recaptchaToken,
    invitationToken,
    callback
  }
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const user = yield call(api.auth.login, {
      email,
      password,
      recaptchaToken,
      invitationToken
    });
    ContextHolder.setAccessToken(user.accessToken);
    ContextHolder.setUser(user);
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes
    } = yield select(state => state.auth);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const {
        loginState
      } = yield select(state => state.auth);
      const isAuthenticated = !!user.accessToken;
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield put(actions.setState({
        user,
        isAuthenticated,
        loginState: {
          flow: loginState.flow,
          quickLoginToRegister: loginState.quickLoginToRegister,
          email,
          loading: false,
          error: undefined,
          mfaToken: user.mfaToken,
          step: loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step,
          tenants: [],
          tenantsLoading: true
        }
      }));
      yield put(actions.loadTenants());
      if (isAuthenticated && loginState.flow === LoginFlow.Login) {
        const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
        if (shouldShowPrompt) {
          yield put(actions.setLoginState({
            step: LoginStep.promptPasskeys,
            loading: false
          }));
          onRedirectTo(routes.loginUrl, {
            preserveQueryParams: true
          });
        } else {
          yield afterAuthNavigation();
        }
      }
    }

    // TODO: extract item name to constants
    localStorage.removeItem('register-quick-login');
    callback == null ? void 0 : callback(true);
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    callback == null ? void 0 : callback(false, e);
    yield put(actions.setLoginState({
      email,
      error: e.message,
      loading: false
    }));
  }
}
function* loginWithMfa({
  payload: {
    mfaToken,
    value,
    rememberDevice,
    callback
  }
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const user = yield call(api.auth.loginWithMfa, {
      mfaToken,
      value,
      rememberDevice
    });
    const {
      loginState
    } = yield select(state => state.auth);
    const step = loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step;
    yield put(actions.setState({
      loginState: {
        flow: loginState.flow,
        quickLoginToRegister: loginState.quickLoginToRegister,
        loading: false,
        step,
        error: undefined,
        tenantsLoading: true,
        tenants: []
      },
      user,
      isAuthenticated: true
    }));
    if (user.id) {
      localStorage.setItem('userId', user.id);
    }
    yield put(actions.loadTenants());
    yield put(actions.setLoginState({
      error: undefined,
      loading: false
    }));
    if (loginState.flow === LoginFlow.Login) {
      const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
      if (shouldShowPrompt) {
        yield put(actions.setLoginState({
          step: LoginStep.promptPasskeys,
          loading: false
        }));
      } else {
        yield afterAuthNavigation();
      }
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* recoverMfa({
  payload
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    var _payload$callback;
    yield call(api.auth.recoverMfaToken, payload);
    yield put(actions.setLoginState({
      loading: false,
      error: undefined,
      step: LoginStep.preLogin
    }));
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setLoginState({
      error: e.message,
      loading: false
    }));
    (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
  }
}
function* logout({
  payload
}) {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  yield put(actions.setState({
    isLoading: true
  }));
  try {
    if (hostedLoginBox) {
      yield call(api.auth.OAuthLogout);
    } else {
      yield call(api.auth.logout);
    }
  } catch {}
  yield put(actions.resetState());
  yield put(actions.requestAuthorize(true));
  payload == null ? void 0 : payload();
}
function* silentLogout({
  payload
}) {
  var _payload$callbackTime;
  try {
    yield call(api.auth.logout);
  } catch {}
  setTimeout(() => {
    var _payload$callback3;
    return payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  }, (_payload$callbackTime = payload == null ? void 0 : payload.callbackTimeout) != null ? _payload$callbackTime : 500);
}
function* handleEnrollMFAResponse({
  user,
  tenants
}) {
  const mfaState = {
    step: MFAStep.recoveryCode,
    loading: false,
    error: undefined,
    saving: false
  };
  if (user != null && user.recoveryCode) {
    mfaState.recoveryCode = user.recoveryCode;
  }
  yield put(actions.setMfaState(mfaState));
  yield put(actions.setUser(user));
  yield put(actions.setTenantsState({
    tenants,
    loading: false
  }));
  if (user.id) {
    localStorage.setItem('userId', user.id);
  }
}
function* handleVerifyMFAResponse({
  user,
  tenants
}) {
  const {
    loginState
  } = yield select(state => state.auth);
  yield put(actions.setUser(user));
  yield put(actions.setTenantsState({
    tenants,
    loading: false
  }));
  if (user.id) {
    localStorage.setItem('userId', user.id);
  }
  const {
    step: mfaStep
  } = yield select(state => state.auth.mfaState);
  if (loginState.flow === LoginFlow.Login) {
    const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
    if (mfaStep === MFAStep.smsVerifyCode && shouldShowPrompt) {
      yield put(actions.setLoginState({
        step: LoginStep.promptPasskeys,
        loading: false
      }));
    } else {
      yield afterAuthNavigation();
    }
  }
  yield put(actions.setState({
    isAuthenticated: true
  }));
}
function* preEnrollMFASMSForLogin(_ref13) {
  let {
      payload: {
        callback
      }
    } = _ref13,
    payload = _objectWithoutPropertiesLoose(_ref13.payload, _excluded10);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.preEnrollMFASMSForLogin, payload);
    yield put(actions.setMfaState({
      otcToken: data.otcToken,
      step: MFAStep.smsVerifyCode,
      phoneNumber: data.phoneNumber
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFASMSForLogin(_ref14) {
  let {
      payload: {
        callback
      }
    } = _ref14,
    payload = _objectWithoutPropertiesLoose(_ref14.payload, _excluded11);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.enrollMFASMSForLogin, payload);
    const response = yield call(api.auth.generateLoginResponseV2, data);
    yield handleEnrollMFAResponse(response);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* preEnrollMFAWebAuthnForLogin(_ref15) {
  let {
      payload: {
        callback
      }
    } = _ref15,
    payload = _objectWithoutPropertiesLoose(_ref15.payload, _excluded12);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    var _data$options$exclude;
    const data = yield call(api.auth.preEnrollMFAWebAuthnForLogin, payload);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      user: _extends({}, data.options.user, {
        id: base64urlDecode(data.options.user.id)
      }),
      excludeCredentials: (_data$options$exclude = data.options.excludeCredentials) == null ? void 0 : _data$options$exclude.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback({
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFAWebAuthnForLogin(_ref16) {
  let {
      payload: {
        callback
      }
    } = _ref16,
    payload = _objectWithoutPropertiesLoose(_ref16.payload, _excluded13);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    const data = yield call(api.auth.enrollMFAWebAuthnForLogin, _extends({}, payload, {
      options: publicKey
    }));
    const response = yield call(api.auth.generateLoginResponseV2, data);
    yield handleEnrollMFAResponse(response);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFAAuthenticatorAppForLogin(_ref17) {
  let {
      payload: {
        callback
      }
    } = _ref17,
    payload = _objectWithoutPropertiesLoose(_ref17.payload, _excluded14);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.enrollMFAAuthenticatorAppForLogin, payload);
    const response = yield call(api.auth.generateLoginResponseV2, data);
    yield handleEnrollMFAResponse(response);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* preVerifyMFASMSForLogin(_ref18) {
  let {
      payload: {
        callback,
        deviceId
      }
    } = _ref18,
    payload = _objectWithoutPropertiesLoose(_ref18.payload, _excluded15);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.preVerifyMFASMSForLogin, deviceId, payload);
    yield put(actions.setMfaState({
      otcToken: data.otcToken,
      step: MFAStep.smsVerifyCode,
      phoneNumber: data.phoneNumber
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* verifyMFASMSForLogin(_ref19) {
  let {
      payload: {
        callback,
        deviceId
      }
    } = _ref19,
    payload = _objectWithoutPropertiesLoose(_ref19.payload, _excluded16);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.verifyMFASMSForLogin, deviceId, payload);
    const response = yield call(api.auth.generateLoginResponseV2, data);
    yield handleVerifyMFAResponse(response);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* preVerifyMFAWebAuthnForLogin(_ref20) {
  let {
      payload: {
        callback,
        deviceId
      }
    } = _ref20,
    payload = _objectWithoutPropertiesLoose(_ref20.payload, _excluded17);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    var _data$options$allowCr;
    const data = yield call(api.auth.preVerifyMFAWebAuthnForLogin, deviceId, payload);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      allowCredentials: (_data$options$allowCr = data.options.allowCredentials) == null ? void 0 : _data$options$allowCr.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback({
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* verifyMFAWebAuthnForLogin(_ref21) {
  let {
      payload: {
        callback,
        deviceId
      }
    } = _ref21,
    payload = _objectWithoutPropertiesLoose(_ref21.payload, _excluded18);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    const data = yield call(api.auth.verifyMFAWebAuthnForLogin, deviceId, _extends({}, payload, {
      options: publicKey
    }));
    const response = yield call(api.auth.generateLoginResponseV2, data);
    yield handleVerifyMFAResponse(response);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null);
  }
}
export function* loginSagas() {
  yield takeLeading(actions.requestAuthorize, requestAuthorize);
  yield takeLeading(actions.requestAuthorizeSSR, requestAuthorizeSSR);
  yield takeLeading(actions.requestHostedLoginAuthorize, refreshOrRequestHostedLoginAuthorize);
  yield takeLeading(actions.handleHostedLoginCallback, handleHostedLoginCallback);
  yield takeLeading(actions.preLogin, preLogin);
  yield takeLeading(actions.postLogin, postLogin);
  yield takeLeading(actions.login, login);
  yield takeLeading(actions.logout, logout);
  yield takeLeading(actions.silentLogout, silentLogout);
  yield takeLeading(actions.loginWithMfa, loginWithMfa);
  yield takeLeading(actions.recoverMfa, recoverMfa);
  yield takeLeading(actions.quickSmsPasswordlessPreLogin, quickSmsPasswordlessPreLogin);
  yield takeLeading(actions.changePhoneNumberWithVerification, changePhoneNumberWithVerification);
  yield takeLeading(actions.verifyChangePhoneNumber, verifyChangePhoneNumber);
  yield takeLeading(actions.passwordlessPreLogin, passwordlessPreLogin);
  yield takeLeading(actions.passwordlessPostLogin, passwordlessPostLogin);
  yield takeLeading(actions.verifyInviteToken, verifyInviteToken);
  yield takeLeading(actions.webAuthnPrelogin, webAuthnPrelogin);
  yield takeLeading(actions.webAuthnPostLogin, webAuthnPostLogin);
  yield takeLeading(actions.webAuthnCreateNewDeviceSession, webAuthnCreateNewDeviceSession);
  yield takeLeading(actions.webAuthnVerifyNewDeviceSession, webAuthnVerifyNewDeviceSession);
  yield takeLeading(actions.afterAuthNavigation, afterAuthNavigation);
  yield takeLeading(actions.getUserIP, getUserIP);
  yield takeLeading(actions.preEnrollMFASMSForLogin, preEnrollMFASMSForLogin);
  yield takeLeading(actions.enrollMFASMSForLogin, enrollMFASMSForLogin);
  yield takeLeading(actions.preEnrollMFAWebAuthnForLogin, preEnrollMFAWebAuthnForLogin);
  yield takeLeading(actions.enrollMFAWebAuthnForLogin, enrollMFAWebAuthnForLogin);
  yield takeLeading(actions.enrollMFAAuthenticatorAppForLogin, enrollMFAAuthenticatorAppForLogin);
  yield takeLeading(actions.preVerifyMFASMSForLogin, preVerifyMFASMSForLogin);
  yield takeLeading(actions.verifyMFASMSForLogin, verifyMFASMSForLogin);
  yield takeLeading(actions.preVerifyMFAWebAuthnForLogin, preVerifyMFAWebAuthnForLogin);
  yield takeLeading(actions.verifyMFAWebAuthnForLogin, verifyMFAWebAuthnForLogin);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* requestAuthorizeMock({
  payload: firstTime
}) {
  if (firstTime) {
    yield put(actions.setState({
      isLoading: true
    }));
  }
  const user = userDemo;
  yield put(actions.loadTenants());
  yield put(actions.setState({
    user,
    isAuthenticated: true,
    isLoading: false
  }));
}
function* getUserIPMock({
  payload: {
    callback
  }
}) {
  try {
    const {
      ip
    } = dummyIps[0];
    yield put(actions.setState({
      userIp: ip
    }));
    callback == null ? void 0 : callback(true, ip);
  } catch (e) {
    callback == null ? void 0 : callback(false, e);
  }
}
export function* loginSagasMock() {
  yield takeLeading(actions.requestAuthorize, requestAuthorizeMock);
  yield takeLeading(actions.afterAuthNavigation, afterAuthNavigation);
  yield takeLeading(actions.getUserIP, getUserIPMock);
}