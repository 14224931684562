import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './ContentSection.css';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

export type ContentSectionProps = {
  title: string;
  mobileTitle?: string;
  withSections?: boolean;
  subSection?: boolean;
  infoText?: string;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  actionLinkURL?: string;
  actionLinkText?: string;
  actionLinkDisabled?: boolean;
  children?: ReactNode;
  className?: string;
  fullWidth?: boolean;
  loading?: boolean;
};

const ContentSection: FunctionComponent<ContentSectionProps> = ({
  title,
  mobileTitle = title,
  infoText,
  children,
  withSections = false,
  subSection = false,
  action,
  actionLinkURL,
  actionLinkText = 'Edit',
  actionLinkDisabled = false,
  className,
  fullWidth = false,
  loading = false,
}) => {
  return (
    <section
      className={`content-section${
        subSection ? ' content-section--subsection' : ''
      } ${className}`}>
      <div
        className={`content-section__body ${
          fullWidth ? '' : 'content-wrapper'
        }`}>
        <div
          className={`content-section__heading${
            subSection ? ' content-section__heading--subsection' : ''
          }${withSections ? ' content-section__heading--with-sections' : ''}`}>
          <h3 className="content-section__title">
            <span className="content-section__title--desktop">{title}</span>
            <span className="content-section__title--mobile">
              {mobileTitle}
            </span>
          </h3>
          <span className="content-section__info">{infoText}</span>
          {loading ? (
            <div className="content-section__action">
              <Loader />
            </div>
          ) : null}
          {action ? (
            <Button
              className="content-section__action content-section__action-button"
              action={action}
              label={actionLinkText}
              disabled={actionLinkDisabled}
            />
          ) : null}
          {actionLinkURL ? (
            <>
              {!actionLinkDisabled ? (
                <Link
                  // state={{ backgroundLocation: location }}
                  className="content-section__action"
                  to={actionLinkURL}>
                  {actionLinkText}
                </Link>
              ) : (
                <span className="content-section__action content-section__action--disabled">
                  Edit
                </span>
              )}
            </>
          ) : null}
        </div>
        <div className="content-section__content">{children}</div>
      </div>
    </section>
  );
};

export default ContentSection;
