import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Theme } from '../models/Theme';

const GET_THEME = gql`
  query getTheme($slug: String) {
    theme(slug: $slug) {
      id
      name
      slug
      title
      introduction
      agenda
      body
      guide {
        id
        slug
        name
      }
      worksheetURL
    }
  }
`;

const useTheme = () => {
  const [loadTheme, { data: themeData, loading: themeLoading }] =
    useLazyQuery(GET_THEME);
  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    if (themeData?.theme) {
      setTheme(new Theme(themeData.theme));
    }
  }, [themeData]);

  return { loadTheme, theme, themeLoading };
};

export default useTheme;
