import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FeedItem } from '../models/FeedItem';
import { JournalEntry } from '../models/JournalEntry';
import { Member } from '../models/Member';
import { Message } from '../models/Message';

const GET_JOURNAL = gql`
  query {
    me {
      id
      avatarURL
      matchRequested
      exludedMatchIds
      membershipActive
      source
      matches {
        id
        avatarURL
        firstName
        lastName
        introText
        linkedIn
        currentJobTitle
        currentEmployer
        lastMatchMeeting
        nextMatchMeeting
      }
      feed {
        id
        text
        date
        ... on Journal {
          title
          journalType
        }
        member {
          id
          firstName
          lastName
          avatarURL
        }
        relatedMember {
          id
          firstName
          lastName
          avatarURL
        }
      }
    }
  }
`;

// Delete an action by ID
const ADD_MATCH_ACTION = gql`
  mutation AddMatch {
    addMatch {
      id
      success
    }
  }
`;

const ADD_MESSAGE = gql`
  mutation AddMessage($message: MessageInput) {
    addMessage(message: $message) {
      message {
        id
        text
        date
        member {
          id
          firstName
          lastName
          avatarURL
        }
        relatedMember {
          id
          firstName
          lastName
          avatarURL
        }
      }
      success
    }
  }
`;

const ARCHIVE_MATCH = gql`
  mutation ArchiveMatch($memberId: ID!, $archive: Boolean) {
    archiveMatch(memberId: $memberId, archive: $archive) {
      id
      success
    }
  }
`;

export function useJournals() {
  const [feedItems, setFeedItems] = useState<FeedItem[]>([]);
  const [matches, setMatches] = useState<Member[]>([]);
  const [me, setMe] = useState<Member>();
  const { data, loading } = useQuery(GET_JOURNAL);

  const [addMatchMutation, addMatchMutationData] = useMutation(
    ADD_MATCH_ACTION,
    {
      //
      update: (
        cache,
        {
          data: {
            addMatch: { id, success },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_JOURNAL });

        // If the cache is empty or we failed to delete then return
        if (!success || cachedRequest?.me?.matchRequested === undefined) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_JOURNAL,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              matchRequested: success,
            },
          },
        });
      },
    },
  );

  const [addMessageMutation, addMessageMutationData] = useMutation(
    ADD_MESSAGE,
    {
      //
      update: (
        cache,
        {
          data: {
            addMessage: { message, success },
          },
        },
      ) => {
        const cachedRequest: any = cache.readQuery({ query: GET_JOURNAL });

        // If the cache is empty or we failed to delete then return
        if (!success || cachedRequest?.me?.feed === undefined) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_JOURNAL,
          data: {
            ...cachedRequest,
            me: {
              ...cachedRequest.me,
              feed: [message, ...cachedRequest.me.feed],
            },
          },
        });
      },
    },
  );

  const [archiveMatchMutation, archiveMatchMutationData] = useMutation(
    ARCHIVE_MATCH,
    {
      refetchQueries: [{ query: GET_JOURNAL }],
    },
  );

  useEffect(() => {
    if (data?.me) {
      const loadedMe = new Member(data.me);
      setMe(loadedMe);
    }
    if (data?.me?.feed) {
      const feed: FeedItem[] = data.me.feed.map((a: any) =>
        a.__typename === 'Message' ? new Message(a) : new JournalEntry(a),
      );

      setFeedItems(feed);
    }
    if (data?.me?.matches) {
      const matches: Member[] = data.me.matches.map((m: any) => new Member(m));
      setMatches(matches);
    }
  }, [data]);

  return {
    journalItems: feedItems,
    journalsLoading: loading,
    matches,
    me,
    addMatchMutation,
    addMatchLoading: addMatchMutationData.loading,
    addMessageMutation,
    addMessageLoading: addMessageMutationData.loading,
    archiveMatchMutation,
    archiveMatchLoading: archiveMatchMutationData.loading,
  };
}
