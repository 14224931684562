import './LinkButton.css';
import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type ButtonProps = {
  label?: string;
  children?: ReactNode;
  link?: string;
  className?: string;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
  local?: boolean;
};

const LinkButton: FunctionComponent<ButtonProps> = ({
  label,
  link,
  className,
  action,
  disabled = false,
  local = false,
  children,
}) => {
  return (
    <>
      {link ? (
        <>
          {local ? (
            <Link
              className={`link-button link-button--primary${
                className ? ' ' + className : ''
              }`}
              to={link}>
              {label ? label : children}
            </Link>
          ) : (
            <a
              className={`link-button link-button--primary${
                className ? ' ' + className : ''
              }`}
              href={link}>
              {' '}
              {label ? label : children}
            </a>
          )}
        </>
      ) : (
        <button
          className={`link-button link-button--primary${
            className ? ' ' + className : ''
          }`}
          onClick={action}
          disabled={disabled}>
          {label ? label : children}
        </button>
      )}
    </>
  );
};

export default LinkButton;
