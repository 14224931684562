//TODO: move act to IUserProfile in rest-api

export let UserVeirifedOriginTypes;
(function (UserVeirifedOriginTypes) {
  UserVeirifedOriginTypes["SOCIAL_LOGIN"] = "SOCIAL_LOGIN";
  UserVeirifedOriginTypes["PASSWORDLESS"] = "PASSWORDLESS";
  UserVeirifedOriginTypes["ACTIVATION_LINK"] = "ACTIVATION_LINK";
})(UserVeirifedOriginTypes || (UserVeirifedOriginTypes = {}));
export let AuthenticationTypes;
(function (AuthenticationTypes) {
  AuthenticationTypes["SOCIAL_LOGIN"] = "SOCIAL_LOGIN";
  AuthenticationTypes["PASSWORD"] = "PASSWORD";
})(AuthenticationTypes || (AuthenticationTypes = {}));
export let AdminPortalPagesForEvents;
(function (AdminPortalPagesForEvents) {
  AdminPortalPagesForEvents["profile"] = "profile";
  AdminPortalPagesForEvents["privacy"] = "privacy";
  AdminPortalPagesForEvents["personalApiTokens"] = "personalApiTokens";
  AdminPortalPagesForEvents["account"] = "account";
  AdminPortalPagesForEvents["users"] = "users";
  AdminPortalPagesForEvents["groups"] = "groups";
  AdminPortalPagesForEvents["security"] = "security";
  AdminPortalPagesForEvents["sso"] = "sso";
  AdminPortalPagesForEvents["audits"] = "audits";
  AdminPortalPagesForEvents["webhooks"] = "webhooks";
  AdminPortalPagesForEvents["apiTokens"] = "apiTokens";
  AdminPortalPagesForEvents["roles"] = "roles";
  AdminPortalPagesForEvents["subscriptions"] = "subscription";
})(AdminPortalPagesForEvents || (AdminPortalPagesForEvents = {}));
export const AdminPortalPages = {
  profile: AdminPortalPagesForEvents.profile,
  privacy: AdminPortalPagesForEvents.privacy,
  personalApiTokens: AdminPortalPagesForEvents.personalApiTokens,
  account: AdminPortalPagesForEvents.account,
  users: AdminPortalPagesForEvents.users,
  groups: AdminPortalPagesForEvents.groups,
  security: AdminPortalPagesForEvents.security,
  sso: AdminPortalPagesForEvents.sso,
  audits: AdminPortalPagesForEvents.audits,
  webhooks: AdminPortalPagesForEvents.webhooks,
  apiTokens: AdminPortalPagesForEvents.apiTokens,
  roles: AdminPortalPagesForEvents.roles,
  subscriptions: AdminPortalPagesForEvents.subscriptions
};