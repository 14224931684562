import './MemberProfileInfoModal.css';

import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import { Member } from '../../models/Member';

import linkedInIcon from '../../svgs/icon-linkedin.svg';
import Avatar from '../../components/Avatar/Avatar';

export type MemberProfileInfoModalProps = {
  members: Member[];
};

const MemberProfileInfoModal: FunctionComponent<
  MemberProfileInfoModalProps
> = ({ members }) => {
  const { id } = useParams();
  const [member, setMember] = useState<Member>();

  useEffect(() => {
    if (members && members.length > 0) {
      setMember(members.find((member) => member.id === id));
    }
  }, [members, id]);

  return (
    <Modal title="Public Profile" returnURL="/">
      <div className="member-profile-info">
        <div className="member-profile-info__header">
          <div className="member-profile-info__avatar">
            <Avatar memberAvatar={member?.avatar} />
          </div>
          <div className="member-profile-info__name">{member?.fullName}</div>
          <div className="member-profile-info__role">
            {[member?.currentJobTitle, member?.currentEmployer]
              .filter((x) => !!x)
              .join(' at ')}
          </div>
        </div>
        <div className="member-profile-info__text">{member?.introText}</div>
        {member?.linkedIn ? (
          <div className="member-profile-info__linkedin">
            <a target="_blank" href={member?.linkedIn} rel="noreferrer">
              <img src={linkedInIcon} alt="LinkedIn" />
            </a>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default MemberProfileInfoModal;
