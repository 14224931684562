import './CareerMotivators.css';

import { FunctionComponent, useEffect, useState } from 'react';
import CareerMotivator from 'src/models/CareerMotivator';

export type CareerMotivatorsProps = {
  motivators: CareerMotivator[] | undefined;
};

const groupBy = (array: any[], n: number) => {
  return array.reduce(
    (r, e, i) => (i % n ? r[r.length - 1].push(e) : r.push([e])) && r,
    [],
  );
};

const CareerMotivators: FunctionComponent<CareerMotivatorsProps> = ({
  motivators = [],
}) => {
  const [groupedMotivators, setGroupedMotivators] = useState<any[]>();

  useEffect(() => {
    if (motivators.length > 0) {
      setGroupedMotivators(groupBy(motivators, 3));
    }
  }, [motivators]);

  return (
    <div className="career-motivators">
      {groupedMotivators
        ? groupedMotivators.map((motivator, i) => (
            <div
              className={`career-motivators__group ${
                i === 0 ? 'career-motivators__group--green' : ''
              }${i === 2 ? 'career-motivators__group--orange' : ''}`}
              key={i}>
              {motivator.map((m: CareerMotivator, ii: number) => (
                <div key={`motivator${i}-${ii}`} className="career-motivator">
                  <span className="career-motivator__score">
                    {m.formattedScore}
                  </span>
                  <span className="career-motivator__title">{m.title}</span>
                </div>
              ))}
              <span className="career-motivators__group-brace"></span>
              <span className="career-motivators__group-explainer">{`${
                i === 0 ? 'Boosts your energy' : ''
              }${i === 2 ? 'Drains your energy' : ''}`}</span>
            </div>
          ))
        : null}
    </div>
  );
};

export default CareerMotivators;
