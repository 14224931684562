import './MatchScoresTable.css';

import {
  ChangeEventHandler,
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import SVG from 'react-inlinesvg';

import iconLinkedIn from '../../../svgs/icon-linkedin.svg';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

import ConfirmMakeMatchModal from './ConfirmMakeMatchModal';
import { MatchQueue, Member } from '../../../models/Member';
import LabelInput from '../../../components/LabelInput/LabelInput';
import ContentSection from '../../../components/ContentSection/ContentSection';

export type MatchScoresTableProps = {
  title?: string;
  matchQueue?: MatchQueue[];
  matcherKey?: string;
  decisionFunction?: (
    matchScoreId: string,
    decision: string,
    notes?: string | undefined,
  ) => Promise<void>;
  makeMatchFunction?: (matchScoreId: string) => Promise<void>;
  member?: Member;
  makeMatchLoading?: boolean;
  search?: string;
  hideActions?: boolean;
  showLastMatchMeeting?: boolean;
};

const EditRow: FunctionComponent<{
  match: MatchQueue;
  matcherKey?: string;
  hideActions?: boolean;
  showLastMatchMeeting?: boolean;
  actionDecision: (
    matchScoreId: string,
    decision: string,
    notes?: string,
    matcherKey?: string,
  ) => Promise<void>;
  makeMatch: Function;
}> = ({
  match,
  actionDecision,
  makeMatch,
  matcherKey,
  hideActions = false,
  showLastMatchMeeting = false,
}) => {
  const [notes, setNotes] = useState<string>('');
  const [saving, setSaving] = useState(false);

  const handleNotesChanges: ChangeEventHandler = (event) => {
    const { value } = event.target as HTMLInputElement;
    setNotes(value);
  };

  const handleDecision = async (action: string) => {
    setSaving(true);
    await actionDecision(match.id, action, notes, matcherKey);
    setSaving(false);
  };

  const handleMakeMatch = async () => {
    await makeMatch(match);
  };

  const formatPercent = (value?: number): string => {
    return value != null ? `${Math.round(value * 100) / 100}%` : '';
  };

  return (
    <>
      <tr
        className={
          saving
            ? 'member-queue-page__member-row--saving'
            : 'member-queue-page__member-row'
        }
        title={
          match.scoreDate
            ? `Matched: ${match.scoreDate?.format('DD/MM/YY HH:mm')}`
            : undefined
        }>
        <td>
          <Link
            className="link"
            to={
              matcherKey
                ? `/admin/${matcherKey}/members/member/${match.member.id}`
                : `/admin/members/member/${match.member.id}`
            }>
            {match.member.fullName}
          </Link>
        </td>
        <td>{match.matcherKey}</td>
        {/* <td>{match.member.appId}</td> */}
        <td>{match.member.source}</td>
        <td>{match.member.cohort}</td>
        <td>{match.member.totalMatches}</td>
        <td>{match.member.lastMeeting?.format('DD/MM/YYYY')}</td>
        {showLastMatchMeeting ? (
          <td>{match.member.lastMatchMeeting?.format('DD/MM/YYYY')}</td>
        ) : null}
        <td>{formatPercent(match.meta?.seniorityScore)}</td>
        <td>{formatPercent(match.meta?.careerLengthScore)}</td>
        <td>{formatPercent(match.meta?.contextScore)}</td>
        <td>{formatPercent(match.meta?.motivatorScore)}</td>
        <td>{formatPercent(match.meta?.interestScore)}</td>
        <td>{formatPercent(match.meta?.sectorScore)}</td>
        <td>{formatPercent(match.meta?.functionalAreaScore)}</td>
        <td>{formatPercent(match.meta?.introvertExtrovertScore)}</td>
        <td>{formatPercent(match.meta?.plannerScore)}</td>
        <td>{formatPercent(match.score)}</td>
        <td>{match.member.seekingMatch ? 'Yes' : 'No'}</td>
        <td className="member-queue-page__matches-linkedin">
          <a href={match.member.linkedIn} target="_blank" rel="noreferrer">
            <SVG src={iconLinkedIn} />
          </a>
        </td>
        <td title={match.member.timezone}>
          {match.member.timezone?.substring(0, 8)}
        </td>
      </tr>
      {!hideActions ? (
        <>
          {['approve', 'reject'].includes(match.decision) ? (
            <>
              <tr
                className={
                  saving ? 'member-queue-page__member-row--saving' : ''
                }>
                <td colSpan={13}>{match.decisionNotes}</td>
                <td colSpan={4}>
                  <button
                    className="link match-scores-table__button"
                    onClick={() => handleDecision('requeue')}
                    disabled={saving}>
                    {match.decision === 'approve' ? 'Un-Approve' : 'Un-Reject'}
                  </button>
                  {match.decision === 'approve' ? (
                    <>
                      {' '}
                      -{' '}
                      <button
                        className="link match-scores-table__button"
                        onClick={() => handleMakeMatch()}
                        disabled={saving}>
                        Match
                      </button>
                    </>
                  ) : null}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr
                className={
                  saving ? 'member-queue-page__member-row--saving' : ''
                }>
                <td colSpan={13}>
                  <LabelInput
                    label="Notes:"
                    name="notes"
                    inline={true}
                    value={notes}
                    onChange={handleNotesChanges}
                    disabled={saving}
                  />
                </td>
                <td colSpan={4}>
                  <button
                    className="link match-scores-table__button"
                    onClick={() => handleDecision('approve')}
                    disabled={saving}>
                    Approve
                  </button>{' '}
                  -{' '}
                  <button
                    className="link match-scores-table__button"
                    onClick={() => handleDecision('reject')}
                    disabled={saving}>
                    Reject
                  </button>
                </td>
              </tr>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export const MatchScoresTable: FunctionComponent<MatchScoresTableProps> = ({
  matchQueue,
  matcherKey,
  title = 'Match Queue',
  decisionFunction,
  makeMatchFunction,
  member,
  makeMatchLoading = false,
  search,
  hideActions = false,
  showLastMatchMeeting = false,
}) => {
  const [selectedMatch, setSelectedMatch] = useState<MatchQueue>();
  const [filteredMatches, setFilteredMatches] = useState<MatchQueue[]>();
  const navigate = useNavigate();

  const actionDecision = async (
    id: string,
    decision: string,
    notes?: string,
  ) => {
    if (decisionFunction) {
      await decisionFunction(id, decision, notes);
    }
  };

  useEffect(() => setFilteredMatches([...(matchQueue ?? [])]), [matchQueue]);

  useEffect(() => {
    if (matchQueue && search) {
      setFilteredMatches([
        ...matchQueue?.filter((m) =>
          m.member.fullName.toLowerCase().includes(search),
        ),
      ]);
    } else {
      setFilteredMatches(matchQueue);
    }
  }, [matchQueue, search]);

  const confirmMatch = (match: MatchQueue) => {
    setSelectedMatch(match);
    navigate('confirm-match');
  };

  return (
    <ContentSection
      title={title}
      subSection={true}
      fullWidth={true}
      className="member-queue-page__matches-section">
      <table className="table member-queue-page__matches">
        <thead className="member-queue-page__matches-header">
          <tr>
            <th>Match Name</th>
            <th>Matcher</th>
            {/* <th>Airtable Id</th> */}
            <th>Source</th>
            <th>Cohort</th>
            <th>No Matches</th>
            <th>Last Meeting</th>
            {showLastMatchMeeting ? <th>Last Match Meeting</th> : null}
            <th>Seniority</th>
            <th>Career Length</th>
            <th>Context</th>
            <th>Motivators</th>
            <th>Interests</th>
            <th>Sector</th>
            <th>Function</th>
            <th title="Introvert vs Extrovery">I vs E</th>
            <th title="Planner vs Spontaneity">P vs S</th>
            <th>Total</th>
            <th>Needs Match</th>
            <th>LinkedIn</th>
            <th>Timezone</th>
          </tr>
        </thead>
        <tbody>
          {filteredMatches?.map((match) => (
            <EditRow
              match={match}
              matcherKey={matcherKey}
              hideActions={hideActions}
              showLastMatchMeeting={showLastMatchMeeting}
              actionDecision={actionDecision}
              makeMatch={confirmMatch}
              key={`${match.matcherKey}-${match.member.id}`}
            />
          ))}
        </tbody>
      </table>
      <Routes>
        <Route
          path="/confirm-match"
          element={
            <ConfirmMakeMatchModal
              member={member}
              match={selectedMatch}
              makeMatch={makeMatchFunction}
              makeMatchLoading={makeMatchLoading}
            />
          }
        />
      </Routes>
    </ContentSection>
  );
};
