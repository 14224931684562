import _extends from "@babel/runtime/helpers/esm/extends";
import { loginState } from './LoginState';
import { activateState } from './ActivateState';
import { acceptInvitationState } from './AcceptInvitationState';
import { forgotPasswordState } from './ForgotPasswordState';
import { resetPhoneNumberState } from './ResetPhoneNumberState';
import { ssoState } from './SSOState';
import { profileState } from './ProfileState';
import { mfaState } from './MfaState';
import { teamState } from './TeamState';
import { socialLoginState } from './SocialLogins';
import { signUpState } from './SignUp';
import { apiTokensState } from './ApiTokensState';
import { securityPolicyState } from './SecurityPolicyState';
import { accountSettingsState } from './AccountSettingsState';
import { tenantsState } from './TenantsState';
import { rolesState } from './RolesState';
import { sessionsState } from './SessionsState';
import { sessionsPolicyState } from './SessionsPolicyState';
import { restrictionsState } from './RestrictionsState';
import { provisioningState } from './Provisioning';
import { passkeysState } from './PasskeysState';
import { groupsState } from './GroupsState';
import { groupsDialogsState } from './GroupsState/groupsDialogsState';
export const reinitializeState = {
  isAuthenticated: false,
  isLoading: true,
  isSSOAuth: false,
  user: null,
  signUpState,
  loginState,
  activateState,
  acceptInvitationState,
  forgotPasswordState,
  ssoState,
  profileState,
  mfaState,
  teamState,
  groupsState,
  groupsDialogsState,
  socialLoginState,
  apiTokensState,
  securityPolicyState,
  accountSettingsState,
  tenantsState,
  rolesState,
  resetPhoneNumberState,
  sessionsState,
  sessionsPolicyState,
  restrictionsState,
  provisioningState,
  passkeysState
};
export const initialState = _extends({
  routes: {
    authenticatedUrl: '/',
    loginUrl: '/account/login',
    logoutUrl: '/account/logout',
    activateUrl: '/account/activate',
    impersonationUrl: '/account/impersonate',
    acceptInvitationUrl: '/account/invitation/accept',
    forgetPasswordUrl: '/account/forget-password',
    resetPhoneNumberUrl: '/account/reset-phone-number',
    resetPasswordUrl: '/account/reset-password',
    socialLoginCallbackUrl: '/account/social/success',
    signUpUrl: '/account/sign-up',
    oidcRedirectUrl: '/account/oidc/callback',
    samlCallbackUrl: '/account/saml/callback',
    magicLinkCallbackUrl: '/account/login/magic-link',
    hostedLoginRedirectUrl: '/oauth/callback'
  },
  onRedirectTo: () => {}
}, reinitializeState);