import { Guide } from './Guide';
import { Meeting } from './Meeting';

export class Theme {
  static get AD_HOC() {
    return 'ad-hoc';
  }

  id?: string;
  name?: string;
  slug?: string;
  title?: string;
  introduction?: string;
  agenda?: string[];
  body?: string[];
  worksheetURL?: string;
  meeting?: Meeting;
  guide?: Guide;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.slug = data.slug;
    this.title = data.title;
    this.introduction = data.introduction;
    this.agenda = data.agenda;
    this.body = data.body;
    this.worksheetURL = data.worksheetURL;
    this.guide = data.guide ? new Guide(data.guide) : undefined;
  }

  get isComplete() {
    return !!this.meeting;
  }

  get isAdhoc() {
    return this.slug === Theme.AD_HOC;
  }
}
