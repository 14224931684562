import './TimeTile.css';

import { Dayjs } from 'dayjs';
import { FunctionComponent, MouseEventHandler, useState } from 'react';

export type TimeTileProps = {
  date: Dayjs;
  onClick: MouseEventHandler<HTMLElement>;
  selected: boolean;
  disabled?: boolean;
};

const EMOJIS = ['👍', '👊', '👌', '💪', '🙏'];

const TimeTile: FunctionComponent<TimeTileProps> = ({
  date,
  onClick,
  selected,
  disabled = false,
}) => {
  const [emoji] = useState(EMOJIS[Math.floor(Math.random() * EMOJIS.length)]);

  return (
    <div
      className={`time-tile${selected ? ' time-tile--selected' : ''}${
        disabled ? ' time-tile--disabled' : ''
      }`}
      onClick={!disabled ? onClick : undefined}>
      <span className="time-tile__time">{date.format('h')}</span>
      <span className="time-tile__ampm">{date.format('a')}</span>
      {selected ? <span className="time-tile__emoji">{emoji}</span> : null}
    </div>
  );
};

export default TimeTile;
