import _extends from "@babel/runtime/helpers/esm/extends";
import { Get, Post, Put, Delete, Patch } from '../fetch';
import { urls } from '../constants';
export async function getProfile(params) {
  return Get(`${urls.identity.users.v2}/me`, params != null ? params : {
    addRoles: true
  });
}
export async function updateProfile(body) {
  return Put(`${urls.identity.users.v2}/me`, body);
}
export async function updateProfileImage(body) {
  return Put(`${urls.team.profile.v1}/me/image/v1`, body, {
    responseType: 'plain',
    contentType: undefined
  });
}
export async function changePassword(body) {
  return Post(`${urls.identity.users.v1}/passwords/change`, body);
}
export async function loadUsers(params) {
  var _params$sort, _params$sort$0$id, _params$sort2, _params$sort2$, _params$sort3, _params$sort3$;

  const filters = (params.filter || []).reduce((p, n) => _extends({}, p, {
    [n.id]: n.value
  }), {});
  const sorts = (_params$sort = params.sort) != null && _params$sort.length ? {
    sortBy: (_params$sort$0$id = (_params$sort2 = params.sort) == null ? void 0 : (_params$sort2$ = _params$sort2[0]) == null ? void 0 : _params$sort2$.id) != null ? _params$sort$0$id : 'name',
    sortDirection: (_params$sort3 = params.sort) != null && (_params$sort3$ = _params$sort3[0]) != null && _params$sort3$.desc ? 'desc' : 'asc'
  } : null;
  return Get(urls.team.members.v1, _extends({
    pageOffset: params.pageOffset,
    pageSize: params.pageSize
  }, filters, sorts));
}
export async function addUser(body) {
  return Post(urls.identity.users.v2, _extends({}, body, {
    provider: 'local'
  }));
}
export async function deleteUser(body) {
  return Delete(`${urls.team.members.v1}/${body.userId}`);
}
export async function updateUser(body) {
  return Put(urls.team.members.v1, body);
}
export async function createInviteUserLink(body) {
  return Post(urls.identity.tenants.invites.user.v1, _extends({}, body));
}
export async function updateInviteUserLink(body) {
  return Patch(urls.identity.tenants.invites.user.v1, _extends({}, body));
}
export async function deleteInviteUserLink() {
  return Delete(urls.identity.tenants.invites.user.v1);
}
export async function getInviteUserLink() {
  return Get(urls.identity.tenants.invites.user.v1);
}
export async function getInviteLinkConfiguration() {
  return Get(urls.identity.tenants.invites.configuration.v1);
}
export async function loadAvailableRoles() {
  return Get(urls.team.roles.v1);
}
export async function loadAvailablePermissions() {
  return Get(urls.identity.permissions.v1);
}
export async function loadStats() {
  return Get(`${urls.team.stats.v1}/members`);
}
export async function resendActivationLink(body) {
  return Post(`${urls.team.members.v1}/${body.userId}/resendActivationEmail`, {});
}
export async function resendInvitationLink(body) {
  return Post(urls.identity.users.v2, _extends({}, body, {
    provider: 'local'
  }));
}
export async function resendInvitationLinkToAllTenants(body) {
  return Post(urls.identity.users.invitation.resendAll.v1, _extends({}, body));
}