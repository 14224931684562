import { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Meeting } from '../models/Meeting';

const GET_MEETING_SCHEDULE = gql`
  query meeting($id: ID) {
    meeting(id: $id) {
      id
      members {
        id
        firstName
        lastName
        avatarURL
      }
      me {
        id
      }
      meetingStartAt
      availableDates
      suggestedDates
      scheduleSubmissions {
        memberId
        submissionDate
        stage
      }
      scheduleStage
      lastScheduleMemberId
    }
  }
`;

const UPDATE_MEETING_SCHEDULE = gql`
  mutation updateMeetingSchedule(
    $meetingId: ID!
    $stage: ScheduleSubmissionStage!
    $dates: [Date]
  ) {
    updateMeetingSchedule(meetingId: $meetingId, stage: $stage, dates: $dates) {
      id
      success
    }
  }
`;

function useMeetingSchedule(id: string | undefined) {
  const {
    data,
    loading: meetingLoading,
    refetch: refetchMeeting,
  } = useQuery(GET_MEETING_SCHEDULE, {
    variables: { id },
  });
  const [updateMeetingSchedule, updateMeetingScheduleData] = useMutation(
    UPDATE_MEETING_SCHEDULE,
  );
  const [meeting, setMeeting] = useState<Meeting>();

  useEffect(() => {
    if (data?.meeting) {
      setMeeting(new Meeting(data.meeting));
    }
  }, [data]);

  return {
    meetingLoading,
    meeting,
    refetchMeeting,
    updateMeetingSchedule,
    updateMeetingScheduleLoading: updateMeetingScheduleData.loading,
  };
}

export default useMeetingSchedule;
