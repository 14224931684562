import _extends from "@babel/runtime/helpers/esm/extends";
import { FeatureFlags } from '@frontegg/rest-api';
import { createAction } from '@reduxjs/toolkit';
import { select } from 'redux-saga/effects';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omitProps(props, keys) {
  const newProps = _extends({}, props);
  keys.forEach(key => {
    delete newProps[key];
  });
  return newProps;
}
export function generateActionCreator(storeName) {
  return (key, withPayload) => withPayload ? createAction(`${storeName}/${key}`, payload => ({
    payload
  })) : createAction(`${storeName}/${key}`);
}
export async function generateCodeChallenge(codeVerifier) {
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));

  // @ts-ignore
  return btoa(String.fromCharCode(...new Uint8Array(digest))).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
export function createRandomString(length = 16) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
export const readFileAsText = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});
export function* getFeatureFlags(flags) {
  const appName = yield select(state => state.root.appName);
  return FeatureFlags.getFeatureFlags(flags, appName);
}