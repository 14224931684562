import dayjs, { Dayjs } from 'dayjs';
import CareerMotivator from './CareerMotivator';
import { Goal } from './Goal';

export type AvatarData = {
  url: string;
  alt: string;
};

export type MatchQueue = {
  id: string;
  matcherKey?: string;
  scoreDate?: Dayjs;
  member: Member;
  score?: number;
  meta?: {
    careerLengthScore: number;
    contextScore: number;
    sectorScore: number;
    functionalAreaScore: number;
    introvertExtrovertScore: number;
    plannerScore: number;
    interestScore: number;
    seniorityScore: number;
    motivatorScore: number;
  };
  decision: string;
  decisionNotes?: String;
};

export enum SubscriptionStatus {
  COMPLIMENTARY = 'COMPLIMENTARY',
  CORPORATE = 'CORPORATE',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID',
}

export type Subscription = {
  id?: string;
  memberId?: string;
  stripeEmail?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  subscriptionStatus?: SubscriptionStatus;
  startAt?: Dayjs;
  endAt?: Dayjs;
};

export class Member {
  id: string;
  email?: string;
  firstName;
  lastName?: string;
  avatarURL?: string;
  introText?: string;
  penPortrait?: string;
  memberStatus?: string;
  currentEmployer?: string;
  currentJobTitle?: string;
  functionalArea?: string;
  careerStartYear?: string;
  linkedIn?: string;
  preferredPronouns?: string;
  ethnicity?: string;
  dependents?: string;
  dateOfBirth?: string;
  timezone?: string;
  phoneNumber?: string;
  commsPreference?: string;
  goals: Goal[];
  careerInterests: string[];
  careerMotivators: CareerMotivator[];
  matchRequested: boolean;
  exludedMatchIds: string[];
  totalMatches?: number;
  requestedMatchesCount?: number;
  matchQueueSize?: number;
  memberSince?: Dayjs;
  addNewMatchesDate?: Dayjs;
  matchQueue?: MatchQueue[];
  sector?: string;
  orgSize?: string;
  jobLevel?: string;
  context: string[];
  source?: string;
  appId?: string;
  cohort?: string;
  matches: Member[];
  lastMeeting?: Dayjs;
  lastMatchMeeting?: Dayjs;
  nextMatchMeeting?: Dayjs;
  matchStatus?: string;
  scoreDate?: Dayjs;
  approvedMatches: MatchQueue[];
  rejectedMatches: MatchQueue[];
  matchedMatches?: MatchQueue[];
  totalApprovedMatches?: number;
  subscriptionStatus?: SubscriptionStatus;
  subscription?: Subscription;
  membershipActive: Boolean;
  activeMeetings: string;

  constructor(data: any) {
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.avatarURL = data.avatarURL;
    this.introText = data.introText;
    this.penPortrait = data.penPortrait;
    this.memberStatus = data.memberStatus;
    this.linkedIn = data.linkedIn;
    this.careerInterests = data.careerInterests;
    this.currentEmployer = data.currentEmployer;
    this.currentJobTitle = data.currentJobTitle;
    this.functionalArea = data.functionalArea;
    this.careerStartYear = data.careerStartYear;
    this.preferredPronouns = data.preferredPronouns;
    this.ethnicity = data.ethnicity;
    this.dependents = data.dependents;
    this.dateOfBirth = data.dateOfBirth;
    this.timezone = data.timezone;
    this.phoneNumber = data.phoneNumber;
    this.commsPreference = data.commsPreference;
    this.matchRequested = !!data.matchRequested;
    this.goals =
      data.goals && data.goals.length > 0
        ? data.goals.map((goal: any) => new Goal(goal))
        : [];
    this.careerMotivators =
      data.careerMotivators && data.careerMotivators.length > 0
        ? data.careerMotivators.map((cm: any) => new CareerMotivator(cm))
        : [];
    this.exludedMatchIds =
      data.exludedMatchIds && data.exludedMatchIds.length > 0
        ? data.exludedMatchIds
        : [];
    this.totalMatches = data.totalMatches;
    this.requestedMatchesCount = data.requestedMatchesCount;
    this.matchQueueSize = data.matchQueueSize;
    this.memberSince = data.memberSince ? dayjs(data.memberSince) : undefined;
    this.addNewMatchesDate = data.addNewMatchesDate
      ? dayjs(data.addNewMatchesDate)
      : undefined;
    this.matchQueue =
      data.matchQueue && data.matchQueue.length > 0
        ? data.matchQueue
            .map((m: MatchQueue) => {
              return {
                ...m,
                scoreDate: m.scoreDate ? dayjs(m.scoreDate) : undefined,
                member: new Member(m.member),
              };
            })
            .sort(
              (matcha: MatchQueue, matchb: MatchQueue) =>
                (matchb.score || 0) - (matcha.score || 0),
            )
        : [];
    this.sector = data.sector;
    this.jobLevel = data.jobLevel;
    this.orgSize = data.orgSize;
    this.source = data.source;
    this.appId = data.appId;
    this.cohort = data.cohort;
    this.context =
      data.context && Array.isArray(data.context) ? data.context : [];
    this.matches =
      data.matches && data.matches.length > 0
        ? data.matches.map((m: any) => new Member(m))
        : [];
    this.lastMeeting = data.lastMeeting ? dayjs(data.lastMeeting) : undefined;
    this.lastMatchMeeting = data.lastMatchMeeting
      ? dayjs(data.lastMatchMeeting)
      : undefined;
    this.nextMatchMeeting = data.nextMatchMeeting
      ? dayjs(data.nextMatchMeeting)
      : undefined;
    this.matchStatus = data.matchStatus;
    this.scoreDate = data.scoreDate ? dayjs(data.scoreDate) : undefined;

    this.approvedMatches =
      data.approvedMatches && data.approvedMatches.length > 0
        ? data.approvedMatches
            .map((m: MatchQueue) => {
              return {
                ...m,
                scoreDate: m.scoreDate ? dayjs(m.scoreDate) : undefined,
                member: m.member ? new Member(m.member) : null,
              };
            })
            .sort(
              (matcha: MatchQueue, matchb: MatchQueue) =>
                (matchb.score || 0) - (matcha.score || 0),
            )
            .filter((m: MatchQueue) => m.member)
        : [];

    this.rejectedMatches =
      data.rejectedMatches && data.rejectedMatches.length > 0
        ? data.rejectedMatches
            .map((m: MatchQueue) => {
              return {
                ...m,
                scoreDate: m.scoreDate ? dayjs(m.scoreDate) : undefined,
                member: m.member ? new Member(m.member) : null,
              };
            })
            .sort(
              (matcha: MatchQueue, matchb: MatchQueue) =>
                (matchb.score || 0) - (matcha.score || 0),
            )
            .filter((m: MatchQueue) => m.member)
        : [];

    this.matchedMatches =
      data.matchedMatches && data.matchedMatches.length > 0
        ? data.matchedMatches
            .map((m: MatchQueue) => {
              return {
                ...m,
                scoreDate: m.scoreDate ? dayjs(m.scoreDate) : undefined,
                member: m.member ? new Member(m.member) : null,
              };
            })
            .sort(
              (matcha: MatchQueue, matchb: MatchQueue) =>
                (matchb.score || 0) - (matcha.score || 0),
            )
            .filter((m: MatchQueue) => m.member)
        : [];

    this.totalApprovedMatches = data.totalApprovedMatches;
    this.subscriptionStatus = data.subscriptionStatus;
    this.subscription = data.subscription
      ? {
          ...data.subscription,
          startAt: data.subscription.startAt
            ? dayjs(data.subscription.startAt)
            : undefined,
          endAt: data.subscription.endAt
            ? dayjs(data.subscription.endAt)
            : undefined,
        }
      : undefined;
    this.membershipActive = data.membershipActive || false;
    this.activeMeetings = data.activeMeetings;
  }

  public get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  public get avatar(): AvatarData | undefined {
    return this.avatarURL
      ? { url: this.avatarURL, alt: this.fullName }
      : undefined;
  }

  public get formattedDoB(): string | undefined {
    return this.dateOfBirth
      ? dayjs(this.dateOfBirth).format('DD/MM/YYYY')
      : undefined;
  }

  public get seekingMatch(): boolean | undefined {
    if (this.totalMatches === 0) {
      return true;
    }
    return this.requestedMatchesCount != null && this.totalMatches != null
      ? this.totalMatches < this.requestedMatchesCount
      : undefined;
  }

  /**
   * This method is needed as there isn't always a matchMatches entry for each match
   */
  public get allMatchedMatches(): MatchQueue[] {
    return this.matches.map<MatchQueue>((member) => {
      const foundMQ = this.matchedMatches?.find(
        (mq) => mq.member.id === member.id,
      );
      console.log(member.firstName, member.id, JSON.stringify(foundMQ?.member));

      if (foundMQ) {
        foundMQ.member = member;
        return foundMQ;
      }
      return { id: member.id, member } as MatchQueue;
    });
  }
}
