import './Loader.css';

import { Waveform } from '@uiball/loaders';
import { FunctionComponent } from 'react';

export type LoadProps = {
  color?: string;
};

const Loader: FunctionComponent<LoadProps> = ({ color = '#0c343d' }) => {
  return (
    <div className="loader" style={{ color }}>
      <div className="loader__message">
        <Waveform size={25} lineWeight={3} speed={1} color={color} />{' '}
        <span className="loader__message-text">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
