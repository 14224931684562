import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { MatcherConfig } from '../models/MatcherConfig';

const GET_MATCHER_CONFIG = gql`
  query matcherConfig($matcherKey: String) {
    matcherConfig(matcherKey: $matcherKey) {
      id
      matcherKey
      ageCompatibility
      careerLengthDriver
      contextDriver
      driverWeighting
      extrovertDriver
      functionalAreaDriver
      functionalAreaTranslation
      plannerDriver
      sectorDriver
      seniorityCompatibility
      seniorityDriver
      sourceExclusions
      createdAt
    }
  }
`;

const UPDATE_MATCHER_CONFIG = gql`
  mutation updateMatcherConfig($matcherConfig: MatcherConfigInput) {
    updateMatcherConfig(matcherConfig: $matcherConfig) {
      id
      matcherKey
      ageCompatibility
      careerLengthDriver
      contextDriver
      driverWeighting
      extrovertDriver
      functionalAreaDriver
      functionalAreaTranslation
      plannerDriver
      sectorDriver
      seniorityCompatibility
      seniorityDriver
      sourceExclusions
      createdAt
    }
  }
`;

export const useMatcherConfig = () => {
  const [
    loadMatcherConfig,
    { data: matcherConfigData, loading: matcherConfigLoading },
  ] = useLazyQuery(GET_MATCHER_CONFIG);
  const [matcherConfig, setMatcherConfig] = useState<MatcherConfig>();
  const [updateMatcherConfigMutation, { loading: matcherConfigUpdating }] =
    useMutation(UPDATE_MATCHER_CONFIG, {
      update: (cache, { data: { updateMatcherConfig } }) => {
        //const cachedRequest: any = cache.readQuery({ query: GET_MATCHER_CONFIG });

        // If we failed to update
        if (!updateMatcherConfig) {
          return;
        }

        // write the data back to the cache
        cache.writeQuery({
          query: GET_MATCHER_CONFIG,
          data: { matcherConfig: updateMatcherConfig },
        });
      },
    });

  useEffect(() => {
    if (matcherConfigData?.matcherConfig) {
      const config = new MatcherConfig(matcherConfigData.matcherConfig);
      config.formatProperties();
      setMatcherConfig(config);
    }
  }, [matcherConfigData]);

  return {
    loadMatcherConfig,
    matcherConfig,
    matcherConfigLoading,
    updateMatcherConfigMutation,
    matcherConfigUpdating,
  };
};
