import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["groupId"];
import { urls } from "../constants";
import { Delete, Get, Patch, Post } from "../fetch";
export async function getGroupById({
  groupId
}, query) {
  return Get(`${urls.identity.groups.v1}/${groupId}`, _extends({}, query));
}
export async function getGroups(query) {
  return Get(`${urls.identity.groups.v1}`, _extends({}, query));
}
export async function getGroupsByIds(body, params) {
  return Post(`${urls.identity.groups.getByIds.v1}`, body, {
    params
  });
}
export async function createGroup(body) {
  return Post(`${urls.identity.groups.v1}`, body);
}
export async function updateGroup(_ref) {
  let {
    groupId
  } = _ref,
      body = _objectWithoutPropertiesLoose(_ref, _excluded);

  return Patch(`${urls.identity.groups.v1}/${groupId}`, body);
}
export async function deleteGroup(groupId) {
  return Delete(`${urls.identity.groups.v1}/${groupId}`);
}
export async function addRolesToGroup(groupId, body) {
  return Post(`${urls.identity.groups.v1}/${groupId}/roles`, body);
}
export async function deleteRolesFromGroup(groupId, body) {
  return Delete(`${urls.identity.groups.v1}/${groupId}/roles`, body);
}
export async function addUsersToGroup(groupId, body) {
  return Post(`${urls.identity.groups.v1}/${groupId}/users`, body);
}
export async function deleteUsersFromGroup(groupId, body) {
  return Delete(`${urls.identity.groups.v1}/${groupId}/users`, body);
}
export async function getGroupConfiguration() {
  return Get(`${urls.identity.groups.configurations.v1}`);
}
export async function updateGroupConfiguration(body) {
  return Post(`${urls.identity.groups.configurations.v1}`, body);
}