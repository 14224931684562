// Default to production in case during a build the envs are not parsed.
//
export const config = {
  env: process.env.NODE_ENV || 'production',
  frontEggBaseURL:
    process.env.REACT_APP_FRONTEGG_BASE_URL ||
    'https://app-e3vyl2fr01vj.frontegg.com',
  graphQLEndpoint:
    `${process.env.REACT_APP_ENDPOINT}/graphql` ||
    'https://api.joinvoco.com/graphql',
  serviceEndpoint:
    `${process.env.REACT_APP_ENDPOINT}` || 'https://api.joinvoco.com',
  wssEndpoint:
    `${process.env.REACT_APP_ENDPOINT_WSS}` || 'wss://wss.joinvoco.com',
};
