import './ArchiveMatchesModal.css';

import { FunctionComponent, useEffect, useState } from 'react';
import Avatar from '../../components/Avatar/Avatar';
import ContentSection from '../../components/ContentSection/ContentSection';
import Modal from '../../components/Modal/Modal';
import { Member } from '../../models/Member';
import Button from '../../components/Button/Button';

export type ArchiveMatchesModalProps = {
  matches?: Member[];
  me?: Member;
  archiveMatchMutation: Function;
  archiveMatchLoading: boolean;
};

const ArchiveMatchesModal: FunctionComponent<ArchiveMatchesModalProps> = ({
  matches,
  me,
  archiveMatchMutation,
  archiveMatchLoading,
}) => {
  const [activeMatches, setActiveMatches] = useState<Member[]>();
  const [archivedMatches, setArchivedMatches] = useState<Member[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (matches && matches.length > 0 && me?.exludedMatchIds) {
      setLoading(false);
      setActiveMatches(
        matches.filter(
          (match) =>
            !me?.exludedMatchIds.includes(match.id) &&
            (match.lastMatchMeeting || match.nextMatchMeeting),
        ),
      );
      setArchivedMatches(
        matches.filter(
          (match) =>
            me?.exludedMatchIds.includes(match.id) &&
            (match.lastMatchMeeting || match.nextMatchMeeting),
        ),
      );
    }
  }, [matches, me?.exludedMatchIds]);

  const archiveMatch = async (memberId: string, archive = true) => {
    setLoading(true);
    await archiveMatchMutation({ variables: { memberId, archive } });
  };

  const matchCard = (match: Member, archive = true) => (
    <div key={match.id} className="archive-match-modal__member">
      <Avatar
        className="archive-match-modal__avatar"
        key={match.id}
        memberAvatar={match.avatar}></Avatar>
      <div className="archive-match-modal__firstname">{match.firstName}</div>
      <div className="archive-match-modal__lastname">{match.lastName}</div>
      <Button
        action={() => archiveMatch(match.id, archive)}
        label={archive ? 'Archive' : 'Unarchive'}
        className="archive-match-modal__button"
        disabled={archiveMatchLoading || loading}
      />
    </div>
  );

  return (
    <Modal
      title="Edit Squad Members"
      returnURL="/"
      infoText="Archive members out of your journal">
      <div className="archive-match-modal">
        <div className="archive-match-modal__matches">
          {activeMatches?.map((match) => matchCard(match))}
        </div>

        {archivedMatches && archivedMatches.length > 0 ? (
          <ContentSection
            title="Archived Members"
            infoText="Unarchive squad members">
            <div className="archive-match-modal__matches">
              {archivedMatches?.map((match) => matchCard(match, false))}
            </div>
          </ContentSection>
        ) : null}
      </div>
    </Modal>
  );
};

export default ArchiveMatchesModal;
