import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "samlVendor"],
  _excluded2 = ["callback", "samlVendor"];
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { SamlVendors } from './interfaces';
import { omitProps, readFileAsText } from '../../helpers';
import { delay } from '../utils';
import { samlConfigurationDemo, samlMetadataDemo, ssoStateDemo } from '../dummy';
import { refreshToken } from '../LoginState/saga';
function* loadSSOConfigurations() {
  try {
    var _samlMetadata$configu, _samlMetadata$configu2;
    yield put(actions.setSSOState({
      loading: true
    }));
    const samlConfiguration = yield call(api.auth.getSamlConfiguration);
    const oidcConfiguration = yield call(api.auth.getOidcConfiguration);
    const samlMetadata = yield call(api.metadata.getSamlMetadata);
    samlConfiguration.acsUrl = samlMetadata == null ? void 0 : (_samlMetadata$configu = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu.acsUrl;
    samlConfiguration.spEntityId = samlMetadata == null ? void 0 : (_samlMetadata$configu2 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu2.spEntityId;
    samlConfiguration.isSamlActive = samlMetadata == null ? void 0 : samlMetadata.isActive;
    yield put(actions.setSSOState({
      samlConfiguration,
      oidcConfiguration,
      loading: false,
      firstLoad: false
    }));
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message,
      loading: false
    }));
  }
}
function* saveSSOConfigurationsFile({
  payload: configFile
}) {
  const oldSamlConfiguration = yield select(state => state.auth.ssoState.samlConfiguration);
  const loaderKey = 'saving';
  yield put(actions.setSSOState({
    error: undefined,
    [loaderKey]: true
  }));
  try {
    const metadata = yield readFileAsText(configFile[0]);
    const newSamlConfiguration = yield call(api.auth.updateSamlVendorMetadata, {
      metadata
    });
    newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
    yield put(actions.setSSOState({
      samlConfiguration: newSamlConfiguration,
      error: undefined,
      [loaderKey]: false
    }));
  } catch (e) {
    yield put(actions.setSSOState({
      samlConfiguration: oldSamlConfiguration,
      error: e.message,
      [loaderKey]: false
    }));
  }
}
function* saveSSOConfigurationsFileWithCallback({
  payload
}) {
  const {
    configFile,
    callback
  } = payload;
  const oldSamlConfiguration = yield select(state => state.auth.ssoState.samlConfiguration);
  const loaderKey = 'saving';
  yield put(actions.setSSOState({
    error: undefined,
    [loaderKey]: true
  }));
  try {
    const metadata = yield readFileAsText(configFile);
    const newSamlConfiguration = yield call(api.auth.updateSamlVendorMetadata, {
      metadata
    });
    newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
    yield put(actions.setSSOState({
      samlConfiguration: newSamlConfiguration,
      error: undefined,
      [loaderKey]: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      samlConfiguration: oldSamlConfiguration,
      error: e.message,
      [loaderKey]: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* saveSSOConfigurations({
  payload
}) {
  const {
      callback,
      samlVendor
    } = payload,
    newSamlConfiguration = _objectWithoutPropertiesLoose(payload, _excluded);
  const oldSamlConfiguration = yield select(state => state.auth.ssoState.samlConfiguration);
  const samlConfiguration = _extends({}, oldSamlConfiguration, newSamlConfiguration);
  let loaderKey = 'saving';
  if ((samlConfiguration == null ? void 0 : samlConfiguration.enabled) !== oldSamlConfiguration.enabled) {
    loaderKey = 'loading';
  }
  try {
    const firstTimeConfigure = !(samlConfiguration != null && samlConfiguration.domain);
    if (firstTimeConfigure) {
      yield put(actions.setSSOState({
        samlConfiguration: _extends({}, oldSamlConfiguration, samlConfiguration)
      }));
      return;
    } else {
      yield put(actions.setSSOState({
        error: undefined,
        [loaderKey]: true
      }));
    }
    if (payload.type === SamlVendors.Saml) {
      var _samlMetadata$configu3, _samlMetadata$configu4;
      const samlMetadata = yield call(api.metadata.getSamlMetadata);
      samlConfiguration.acsUrl = samlMetadata == null ? void 0 : (_samlMetadata$configu3 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu3.acsUrl;
      samlConfiguration.spEntityId = samlMetadata == null ? void 0 : (_samlMetadata$configu4 = samlMetadata.configuration) == null ? void 0 : _samlMetadata$configu4.spEntityId;
    }
    const updateSamlConfiguration = omitProps(samlConfiguration, ['validated', 'generatedVerification', 'createdAt', 'updatedAt']);
    if ((oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.domain) !== (updateSamlConfiguration == null ? void 0 : updateSamlConfiguration.domain)) {
      updateSamlConfiguration.ssoEndpoint = '';
      updateSamlConfiguration.publicCertificate = '';
      updateSamlConfiguration.signRequest = false;
    }
    updateSamlConfiguration.type = samlVendor == null ? void 0 : samlVendor.toLowerCase();
    const newSamlConfiguration = yield call(api.auth.updateSamlConfiguration, updateSamlConfiguration);
    newSamlConfiguration.isSamlActive = oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.isSamlActive;
    yield put(actions.setSSOState({
      samlConfiguration: newSamlConfiguration,
      error: undefined,
      [loaderKey]: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      samlConfiguration: oldSamlConfiguration,
      error: e.message,
      [loaderKey]: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* validateSSODomain({
  payload: {
    callback
  } = {}
}) {
  const samlConfiguration = yield select(state => state.auth.ssoState.samlConfiguration);
  try {
    yield put(actions.setSSOState({
      error: undefined,
      saving: true
    }));
    yield call(api.auth.validateSamlDomain);
    yield put(actions.setSSOState({
      samlConfiguration: _extends({}, samlConfiguration, {
        validated: true
      }),
      error: undefined,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      samlConfiguration: _extends({}, samlConfiguration, {
        validated: false
      }),
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* getAuthorizationRoles() {
  try {
    const data = yield call(api.auth.getSamlRoles);
    const groupsData = yield call(api.auth.getSamlRolesGroups);
    yield put(actions.setSSOState({
      authorizationRoles: data.roleIds,
      rolesGroups: groupsData,
      error: undefined
    }));
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message
    }));
  }
}
function* updateRolesGroups(groups) {
  try {
    yield all(groups.map(group => call(api.auth.updateSamlGroup, {
      id: group.id,
      roleIds: group.roleIds,
      group: group.group
    })));
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message
    }));
  }
}
function* updateAuthorizationRoles({
  payload: {
    callback,
    authorizationRoles,
    groups
  }
}) {
  try {
    yield put(actions.setSSOState({
      error: undefined,
      saving: true
    }));
    yield call(api.auth.updateSamlRoles, {
      roleIds: authorizationRoles
    });
    if (groups) {
      yield updateRolesGroups(groups);
    }
    yield getAuthorizationRoles();
    yield put(actions.setSSOState({
      error: undefined,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteSamlGroupFunction({
  payload: {
    callback,
    id
  }
}) {
  try {
    yield put(actions.setSSOState({
      error: undefined,
      saving: true
    }));
    yield call(api.auth.deleteSamlGroup, {
      id
    });
    yield getAuthorizationRoles();
    yield put(actions.setSSOState({
      error: undefined,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* createSamlGroupFunction({
  payload: {
    group,
    roleIds,
    callback
  }
}) {
  try {
    yield put(actions.setSSOState({
      error: undefined,
      saving: true
    }));
    yield call(api.auth.createSamlGroup, {
      group,
      roleIds
    });
    yield getAuthorizationRoles();
    yield put(actions.setSSOState({
      error: undefined,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSSOState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* oidcPostloginFunction({
  payload
}) {
  const data = {
    code: payload.code,
    redirectUri: payload.redirectUri,
    RelayState: payload.state
  };
  try {
    yield put(actions.setSSOState({
      loading: true
    }));
    yield call(api.auth.oidcPostLoginV2, data);
    yield call(refreshToken);
    yield put(actions.setSSOState({
      loading: false
    }));
  } catch (e) {
    var _e$message;
    yield put(actions.setSSOState({
      loading: false,
      error: (_e$message = e.message) != null ? _e$message : 'Failed to authenticate',
      firstLoad: false
    }));
  }
}
export function* ssoSagas() {
  yield takeEvery(actions.loadSSOConfigurations, loadSSOConfigurations);
  yield takeEvery(actions.saveSSOConfigurations, saveSSOConfigurations);
  yield takeEvery(actions.saveSSOConfigurationsFile, saveSSOConfigurationsFile);
  yield takeEvery(actions.saveSSOConfigurationsFileWithCallback, saveSSOConfigurationsFileWithCallback);
  yield takeEvery(actions.validateSSODomain, validateSSODomain);
  yield takeEvery(actions.loadSSOAuthorizationRoles, getAuthorizationRoles);
  yield takeEvery(actions.updateSSOAuthorizationRoles, updateAuthorizationRoles);
  yield takeEvery(actions.deleteSamlGroup, deleteSamlGroupFunction);
  yield takeEvery(actions.createSamlGroup, createSamlGroupFunction);
  yield takeEvery(actions.oidcPostlogin, oidcPostloginFunction);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadSSOConfigurationsMock() {
  yield put(actions.setSSOState({
    loading: true
  }));
  yield delay();
  yield put(actions.setSSOState({
    samlConfiguration: samlConfigurationDemo,
    loading: false,
    firstLoad: false
  }));
}
function* saveSSOConfigurationsFileMock({
  payload: configFile
}) {
  const loaderKey = 'saving';
  yield put(actions.setSSOState({
    error: undefined,
    [loaderKey]: true
  }));
  const oldSamlConfiguration = yield select(state => state.auth.ssoState.samlConfiguration);
  const newSamlConfiguration = _extends({}, oldSamlConfiguration, {
    enabled: true
  }, configFile[0]);
  yield put(actions.setSSOState({
    samlConfiguration: newSamlConfiguration,
    error: undefined,
    [loaderKey]: false
  }));
}
function* saveSSOConfigurationsMock({
  payload
}) {
  var _samlMetadataDemo$con, _samlMetadataDemo$con2;
  const {
      callback,
      samlVendor
    } = payload,
    newSamlConfiguration = _objectWithoutPropertiesLoose(payload, _excluded2);
  const oldSamlConfiguration = ssoStateDemo.samlConfiguration;
  const samlConfiguration = _extends({}, oldSamlConfiguration, newSamlConfiguration);
  let loaderKey = 'saving';
  if ((samlConfiguration == null ? void 0 : samlConfiguration.enabled) !== oldSamlConfiguration.enabled) {
    loaderKey = 'loading';
  }
  const firstTimeConfigure = !(samlConfiguration != null && samlConfiguration.domain);
  if (firstTimeConfigure) {
    yield put(actions.setSSOState({
      samlConfiguration: _extends({}, oldSamlConfiguration, samlConfiguration)
    }));
    return;
  } else {
    yield put(actions.setSSOState({
      error: undefined,
      [loaderKey]: true
    }));
  }
  yield delay();
  samlConfiguration.acsUrl = samlMetadataDemo == null ? void 0 : (_samlMetadataDemo$con = samlMetadataDemo.configuration) == null ? void 0 : _samlMetadataDemo$con.acsUrl;
  samlConfiguration.spEntityId = samlMetadataDemo == null ? void 0 : (_samlMetadataDemo$con2 = samlMetadataDemo.configuration) == null ? void 0 : _samlMetadataDemo$con2.spEntityId;
  const updateSamlConfiguration = omitProps(samlConfiguration, ['validated', 'generatedVerification', 'createdAt', 'updatedAt']);
  if ((oldSamlConfiguration == null ? void 0 : oldSamlConfiguration.domain) !== (updateSamlConfiguration == null ? void 0 : updateSamlConfiguration.domain)) {
    updateSamlConfiguration.ssoEndpoint = '';
    updateSamlConfiguration.publicCertificate = '';
    updateSamlConfiguration.signRequest = false;
  }
  updateSamlConfiguration.type = samlVendor == null ? void 0 : samlVendor.toLowerCase();
  yield delay();
  yield put(actions.setSSOState({
    samlConfiguration: updateSamlConfiguration,
    error: undefined,
    [loaderKey]: false
  }));
  callback == null ? void 0 : callback(true);
}
function* validateSSODomainMock({
  payload: {
    callback
  } = {}
}) {
  const samlConfiguration = ssoStateDemo.samlConfiguration;
  yield put(actions.setSSOState({
    error: undefined,
    saving: true
  }));
  yield delay();
  yield put(actions.setSSOState({
    samlConfiguration: _extends({}, samlConfiguration, {
      validated: true
    }),
    error: undefined,
    saving: false
  }));
  callback == null ? void 0 : callback(true);
}
function* getAuthorizationRolesMock() {
  yield delay();
  const data = {
    roleIds: ['1', '2', '3']
  };
  yield put(actions.setSSOState({
    authorizationRoles: data.roleIds,
    error: undefined
  }));
}
function* updateAuthorizationRolesMock({
  payload: {
    callback,
    authorizationRoles
  }
}) {
  yield put(actions.setSSOState({
    error: undefined,
    saving: true
  }));
  yield delay();
  yield put(actions.setSSOState({
    authorizationRoles,
    error: undefined,
    saving: false
  }));
  callback == null ? void 0 : callback(true);
}
export function* ssoSagasMock() {
  yield takeEvery(actions.loadSSOConfigurations, loadSSOConfigurationsMock);
  yield takeEvery(actions.saveSSOConfigurations, saveSSOConfigurationsMock);
  yield takeEvery(actions.saveSSOConfigurationsFile, saveSSOConfigurationsFileMock);
  yield takeEvery(actions.validateSSODomain, validateSSODomainMock);
  yield takeEvery(actions.loadSSOAuthorizationRoles, getAuthorizationRolesMock);
  yield takeEvery(actions.updateSSOAuthorizationRoles, updateAuthorizationRolesMock);
}