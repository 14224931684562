import { useMemo } from 'react';
import { bindActionCreators, vendorActions, vendorStoreName } from '@frontegg/redux-store';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from '../FronteggStoreContext';
const defaultMapper = state => state;
export function useVendorState(stateMapper = defaultMapper) {
  return useSelector(state => stateMapper(state[vendorStoreName]), shallowEqual);
}
export const useVendorActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(vendorActions, dispatch), [vendorActions]);
};