import { useCallback, useEffect, useState } from 'react';
import { Action } from '../models/Action';
import { gql, useMutation, useQuery } from '@apollo/client';

// GET Users Actions
const GET_ACTIONS = gql`
  query {
    me {
      id
      actions {
        id
        text
        actionType
        description
        date
        url
        createdAt
        relatedMember {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

// Delete an action by ID
const DELETE_ACTION = gql`
  mutation DeleteAction($actionId: ID!) {
    deleteAction(actionId: $actionId) {
      id
      success
    }
  }
`;

export function useActions() {
  const [actions, setActions] = useState<Action[]>([]);
  const { data, loading } = useQuery(GET_ACTIONS);
  const [deleteActionMutation] = useMutation(DELETE_ACTION, {
    update: (
      cache,
      {
        data: {
          deleteAction: { id, success },
        },
      },
    ) => {
      const cachedRequest: any = cache.readQuery({ query: GET_ACTIONS });

      // If the cache is empty or we failed to delete then return
      if (!success || !cachedRequest?.me?.actions) {
        return;
      }
      // Filter out the action that was deleted
      const filteredActions = cachedRequest.me.actions.filter((a: any) => {
        if (a?.id === id) {
          return false;
        }
        return true;
      });

      // write the data back to the cache
      cache.writeQuery({
        query: GET_ACTIONS,
        data: {
          ...cachedRequest,
          me: {
            ...cachedRequest.me,
            actions: filteredActions,
          },
        },
      });
    },
  });

  useEffect(() => {
    if (data?.me?.actions) {
      const mappedActions: Action[] = data.me.actions.map(
        (a: any) => new Action(a),
      );
      setActions(mappedActions);
    }
  }, [data]);

  const deleteAction = useCallback(
    async (actionId: string) => {
      await deleteActionMutation({ variables: { actionId } });
    },
    [deleteActionMutation],
  );

  return { actions, deleteAction, actionsLoading: loading };
}
