import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"];
import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { rolesAdminViewerDemo } from '../dummy';
function* loadRolesAndPermissions({
  payload
}) {
  var _payload$silentLoadin;
  yield put(actions.setRolesState({
    loading: !((_payload$silentLoadin = payload == null ? void 0 : payload.silentLoading) != null ? _payload$silentLoadin : false),
    error: null
  }));
  try {
    const result = yield all([call(api.roles.getRoles), call(api.roles.getPermissions), call(api.roles.getPermissionCategories)]);
    const [roles, permissions, permissionCategories] = result;
    yield put(actions.setRolesState({
      roles,
      permissions,
      permissionCategories,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      loading: false
    }));
  }
}
function* addRole(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    body = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setRolesState({
    saving: true
  }));
  try {
    const role = yield call(api.roles.addRole, body);
    const roles = yield call(api.roles.getRoles);
    yield put(actions.loadRolesAndPermissions({
      silentLoading: true
    }));
    yield put(actions.setRolesState({
      roles,
      saving: false
    }));
    callback == null ? void 0 : callback(role);
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteRole(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    body = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setRolesState({
    saving: true
  }));
  try {
    yield call(api.roles.deleteRole, body);
    yield put(actions.loadRolesAndPermissions({
      silentLoading: true
    }));
    yield put(actions.setRolesState({
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* updateRole(_ref3) {
  let {
      payload: {
        callback
      }
    } = _ref3,
    body = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setRolesState({
    saving: true
  }));
  try {
    const role = yield call(api.roles.updateRole, body);
    yield put(actions.loadRolesAndPermissions({
      silentLoading: true
    }));
    yield put(actions.setRolesState({
      saving: false
    }));
    callback == null ? void 0 : callback(role);
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* attachPermissionsToRole(_ref4) {
  let {
      payload: {
        callback
      }
    } = _ref4,
    body = _objectWithoutPropertiesLoose(_ref4.payload, _excluded4);
  yield put(actions.setRolesState({
    saving: true
  }));
  try {
    const role = yield call(api.roles.attachPermissionsToRole, body);
    yield put(actions.loadRolesAndPermissions({
      silentLoading: true
    }));
    yield put(actions.setRolesState({
      saving: false
    }));
    callback == null ? void 0 : callback(role);
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* attachPermissionToRoles(_ref5) {
  let {
      payload: {
        callback
      }
    } = _ref5,
    body = _objectWithoutPropertiesLoose(_ref5.payload, _excluded5);
  yield put(actions.setRolesState({
    saving: true
  }));
  try {
    const permission = yield call(api.roles.attachPermissionToRoles, body);
    yield put(actions.loadRolesAndPermissions({
      silentLoading: true
    }));
    yield put(actions.setRolesState({
      saving: false
    }));
    callback == null ? void 0 : callback(permission);
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* rolesSagas() {
  yield takeLeading(actions.loadRolesAndPermissions, loadRolesAndPermissions);
  yield takeEvery(actions.addRole, addRole);
  yield takeEvery(actions.deleteRole, deleteRole);
  yield takeEvery(actions.updateRole, updateRole);
  yield takeEvery(actions.attachPermissionsToRole, attachPermissionsToRole);
  yield takeEvery(actions.attachPermissionToRoles, attachPermissionToRoles);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadRolesAndPermissionsMock({
  payload
}) {
  var _payload$silentLoadin2;
  yield put(actions.setRolesState({
    loading: !((_payload$silentLoadin2 = payload == null ? void 0 : payload.silentLoading) != null ? _payload$silentLoadin2 : false),
    error: null
  }));
  try {
    yield put(actions.setRolesState({
      roles: rolesAdminViewerDemo,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setRolesState({
      error: e.message,
      loading: false
    }));
  }
}
export function* rolesSagasMock() {
  yield takeLeading(actions.loadRolesAndPermissions, loadRolesAndPermissionsMock);
}