import './DashboardPage.css';
import { FunctionComponent, useState } from 'react';
import Actions from '../../components/Actions/Actions';
import ContentSection from '../../components/ContentSection/ContentSection';
import { useActions } from '../../hooks/useActions';
import { Action } from '../../models/Action';
import { useJournals } from '../../hooks/useJournals';
import Journal from '../../components/Journal/Journal';
import Loader from '../../components/Loader/Loader';
import MemberPicker from '../../components/MemberPicker/MemberPicker';
import { Route, Routes } from 'react-router-dom';
import MemberProfileInfoModal from './MemberProfileInfoModal';
import ActionRescheduleModal from './ActionRescheduleModal';
import ActionUnpauseModal from './ActionUnpauseModal';
import AddMatchModal from './AddMatchModal';
import MessageInput from '../../components/MessageInput/MessageInput';
import ArchiveMatchesModal from './ArchiveMatchesModal';

export type DashboardPageProps = {};

const DashboardPage: FunctionComponent<DashboardPageProps> = () => {
  const { actions, deleteAction, actionsLoading } = useActions();
  const {
    journalItems,
    journalsLoading,
    matches,
    me,
    addMatchLoading,
    addMatchMutation,
    addMessageMutation,
    addMessageLoading,
    archiveMatchMutation,
    archiveMatchLoading,
  } = useJournals();

  const [selectedMember, setSelectedMember] = useState<string>();

  return (
    <section className="dashboard">
      <ContentSection
        title="Actions"
        infoText="Things for you to do and look at">
        {actionsLoading ? (
          <Loader />
        ) : (
          <Actions
            actions={actions as Action[]}
            deleteAction={deleteAction as Function}
          />
        )}
      </ContentSection>
      <ContentSection
        title="Journal"
        infoText="Stay in touch with your squad"
        actionLinkURL="/matches/archive"
        actionLinkDisabled={!me?.membershipActive}>
        {journalsLoading ? (
          <Loader />
        ) : (
          <div className="journal-section">
            <MemberPicker
              members={matches}
              me={me}
              memberSelected={setSelectedMember}
            />
            <MessageInput
              addingMessage={addMessageLoading}
              me={me}
              addMessageMutation={addMessageMutation}
            />
            <Journal
              feedItems={journalItems}
              selectedMember={selectedMember}
              members={matches}
              me={me}
            />
          </div>
        )}
      </ContentSection>
      <Routes>
        <Route
          path="actions/:id/reschedule"
          element={<ActionRescheduleModal actions={actions} />}
        />
        <Route path="actions/:id/unpause" element={<ActionUnpauseModal />} />
        <Route
          path="members/add-match"
          element={
            <AddMatchModal
              addMatchLoading={addMatchLoading}
              addMatchMutation={addMatchMutation}
            />
          }
        />
        <Route
          path="matches/archive"
          element={
            <ArchiveMatchesModal
              matches={matches}
              me={me}
              archiveMatchMutation={archiveMatchMutation}
              archiveMatchLoading={archiveMatchLoading || journalsLoading}
            />
          }></Route>
        <Route
          path="members/:id/info"
          element={
            <MemberProfileInfoModal members={matches}></MemberProfileInfoModal>
          }
        />
      </Routes>
    </section>
  );
};

export default DashboardPage;
