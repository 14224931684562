import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"];
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { api, AuthStrategyEnum } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { ResetPhoneNumberStep } from './interfaces';
import { LoginStep } from '../LoginState/interfaces';
function* resetPhoneNumber(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    body = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setResetPhoneNumberState({
    loading: true
  }));
  try {
    const res = yield call(api.auth.resetPhoneNumber, body);
    yield put(actions.setResetPhoneNumberState({
      loading: false,
      error: undefined,
      resetPhoneNumberToken: res.resetPhoneNumberToken,
      step: ResetPhoneNumberStep.VerifyResetPhoneNumber
    }));
    yield put(actions.setLoginState({
      email: body.email
    }));
  } catch (e) {
    yield put(actions.setResetPhoneNumberState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* verifyResetPhoneNumber(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    body = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setResetPhoneNumberState({
    loading: true
  }));
  try {
    const res = yield call(api.auth.verifyResetPhoneNumber, body);
    yield put(actions.setResetPhoneNumberState({
      loading: false,
      error: undefined,
      changePhoneNumberToken: res.changePhoneNumberToken,
      step: ResetPhoneNumberStep.ChangePhoneNumber
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setResetPhoneNumberState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* changePhoneNumber(_ref3) {
  let {
      payload: {
        callback
      }
    } = _ref3,
    body = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setResetPhoneNumberState({
    loading: true
  }));
  try {
    const {
      onRedirectTo,
      routes
    } = yield select(({
      auth: {
        onRedirectTo,
        routes
      }
    }) => ({
      onRedirectTo,
      routes
    }));
    yield call(api.auth.changePhoneNumber, {
      phoneNumber: body.phoneNumber,
      changePhoneNumberToken: body.changePhoneNumberToken
    });
    yield put(actions.setLoginState({
      step: LoginStep.loginWithSmsOtc
    }));
    yield put(actions.passwordlessPreLogin({
      type: AuthStrategyEnum.SmsCode,
      email: body.email,
      recaptchaToken: body.recaptchaToken
    }));
    onRedirectTo(routes.loginUrl);
    yield put(actions.setResetPhoneNumberState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setResetPhoneNumberState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* resetPhoneNumberSagas() {
  yield takeLeading(actions.resetPhoneNumber, resetPhoneNumber);
  yield takeLeading(actions.verifyResetPhoneNumber, verifyResetPhoneNumber);
  yield takeLeading(actions.changePhoneNumber, changePhoneNumber);
}