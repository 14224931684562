import './ScheduleMeetingPage.css';

import dayjs, { Dayjs } from 'dayjs';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InlineSVG from 'react-inlinesvg/esm';

import useMeetingSchedule from '../../hooks/useMeetingSchedule';

import InfoMessage from '../../components/InfoMessage/InfoMessage';
import ContentSection from '../../components/ContentSection/ContentSection';
import MeetingScheduler from '../../components/MeetingScheduler/MeetingScheduler';
import Button from '../../components/Button/Button';
import Avatar from '../../components/Avatar/Avatar';

import sadCat from '../../svgs/sad-cat.svg';
import Loader from '../../components/Loader/Loader';
import { Member } from '../../models/Member';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';
import { ScheduleSubmissionStage } from '../../models/Meeting';

export type RescheduleMeetingPageProps = {};

enum PAGE_STATUS {
  LOADING,
  NOT_FOUND,
  SCHEDULE,
  FIRST_FAIL,
  DONE,
}

const RescheduleMeetingPage: FunctionComponent<
  RescheduleMeetingPageProps
> = () => {
  const { id } = useParams();
  const { meeting, meetingLoading, updateMeetingSchedule } =
    useMeetingSchedule(id);
  const [suggestedDates, setSuggestedDates] = useState<Dayjs[]>();
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [me, setMe] = useState<Member>();
  const [otherUser, setOtherUser] = useState<Member>();
  const [pageStatus, setPageStatus] = useState<PAGE_STATUS>(
    PAGE_STATUS.LOADING,
  );
  const [showNotRightTimeModal, setShowNotRightTimeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (meetingLoading) {
      return;
    }
    if (!meeting) {
      setPageStatus(PAGE_STATUS.NOT_FOUND);
      return;
    }

    switch (meeting?.scheduleStage) {
      case ScheduleSubmissionStage.SCHEDULED:
        setSuggestedDates(meeting?.availableDates);
        setPageStatus(PAGE_STATUS.SCHEDULE);
        break;
      case ScheduleSubmissionStage.RESCHEDULE:
        if (me?.id !== meeting.lastScheduleMemberId) {
          navigate(`/meeting/${id}/schedule`);
        }
        if (meeting?.suggestedDates) {
          setSelectedDates(meeting.suggestedDates.map((d) => d.toISOString()));
        }
        setPageStatus(PAGE_STATUS.DONE);
        break;
      default:
        navigate(`/meeting/${id}/schedule`);
        break;
    }

    if (meeting?.me) {
      const m = meeting.members.find((member) => member.id === meeting.me?.id);
      const ou = meeting.members.find((member) => member.id !== meeting.me?.id);
      if (!m) {
        return;
      }
      setMe(m);
      setOtherUser(ou);
    }
  }, [id, meeting, meetingLoading, navigate, me]);

  const handleDateClick = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleSubmit = async () => {
    let stage: ScheduleSubmissionStage = ScheduleSubmissionStage.SCHEDULED;
    switch (meeting?.scheduleStage) {
      case ScheduleSubmissionStage.SCHEDULED:
        stage = ScheduleSubmissionStage.RESCHEDULE;
        break;
      default:
        return;
    }

    await updateMeetingSchedule({
      variables: {
        meetingId: meeting?.id,
        stage,
        dates: selectedDates.map((d) => dayjs(d).toDate()),
      },
    });
    setPageStatus(PAGE_STATUS.DONE);
  };

  const handleDateFail = () => {
    setShowNotRightTimeModal(true);
  };

  const handleDismissModal = () => {
    setShowNotRightTimeModal(false);
  };

  const handleStruggle: ModalActionFunction = async (event, done) => {
    await updateMeetingSchedule({
      variables: {
        meetingId: meeting?.id,
        stage: ScheduleSubmissionStage.FIRST_FAIL,
      },
    });
    setPageStatus(PAGE_STATUS.FIRST_FAIL);
    done();
  };

  return (
    <ContentSection title="Find a new time">
      <div className="schedule-meeting">
        {pageStatus === PAGE_STATUS.LOADING ||
        meetingLoading ||
        pageStatus === PAGE_STATUS.NOT_FOUND ? (
          <>
            {pageStatus === PAGE_STATUS.NOT_FOUND ? (
              <div className="schedule-meeting__lock-page">
                <InlineSVG src={sadCat} className="schedule-meeting__sad-cat" />
                <InfoMessage>
                  Sorry scheduling isn't found for this meeting.
                </InfoMessage>
              </div>
            ) : (
              <Loader />
            )}
          </>
        ) : (
          <>
            <div className="schedule-meeting__members">
              <Avatar
                memberAvatar={me?.avatar}
                className="schedule-meeting__members-avatar"
              />
              <h2 className="h2">
                {me?.firstName} + {otherUser?.firstName}
              </h2>
              <Avatar
                memberAvatar={otherUser?.avatar}
                className="schedule-meeting__members-avatar"
              />
            </div>
            {/* 
             
             ------------------------- SCHEDULE -----------------------------
             
             */}
            {pageStatus === PAGE_STATUS.SCHEDULE ? (
              <>
                <InfoMessage>
                  OK, so you need to rearrange your conversation with{' '}
                  {otherUser?.firstName}? No problem. Just choose five new times
                  below and we'll handle the rest. As soon as you've hit
                  'Submit' we'll let {otherUser?.firstName} know and confirm a
                  new time for you to talk.
                </InfoMessage>
                <MeetingScheduler
                  suggestedDates={suggestedDates}
                  dateSelected={handleDateClick}
                  struggleClick={handleDateFail}
                  showStruggle={true}
                />
                <InfoMessage rightHanded={true}>
                  {5 - selectedDates.length > 0 ? (
                    <>
                      You have selected {selectedDates.length} times, please
                      select {5 - selectedDates.length} more so{' '}
                      {otherUser?.firstName} has some choice! If you need more
                      dates, just click on the plus button
                    </>
                  ) : (
                    <>
                      Great! You've selected five options… just hit submit and
                      then it's over to {otherUser?.firstName}! 🤞
                    </>
                  )}
                  <Button
                    action={handleSubmit}
                    label="Submit"
                    disabled={5 - selectedDates.length > 0}
                    className="schedule-meeting__submit-button"
                  />
                </InfoMessage>
              </>
            ) : null}
            {/* 
             
             ------------------------- FAIL -----------------------------
             
             */}
            {pageStatus === PAGE_STATUS.FIRST_FAIL ? (
              <div className="schedule-meeting__lock-page">
                <InfoMessage>
                  Ok, thanks thanks for letting us know. Our member success team
                  will be in contact with you to figure out next steps 🤫. In
                  the meantime, don't worry, we will manage any communication
                  with {otherUser?.firstName}.
                </InfoMessage>
              </div>
            ) : null}
            {/* 
             
             ------------------------- DONE -----------------------------
             
             */}
            {pageStatus === PAGE_STATUS.DONE ? (
              <div className="schedule-meeting__lock-page">
                <InfoMessage>
                  Thanks for choosing {selectedDates.length} times. we have let{' '}
                  {otherUser?.firstName} know they can now choose from these
                  dates.
                </InfoMessage>
              </div>
            ) : null}
            {/* 
             
             ------------------------- MODALS -----------------------------
             
             */}
            {showNotRightTimeModal ? (
              <Modal
                title="NOT THE RIGHT TIME?"
                actionText="I Need help"
                action={handleStruggle}
                dismissHandler={handleDismissModal}>
                <p>
                  A tough few weeks at work 😬…? Perhaps a well deserved holiday
                  🏖️…? Either way we get it that sometimes the times just aren't
                  right. Don't stress, just click the blue button above if these
                  dates simply don't work for you - the Voco member success team
                  will get in touch to figure out the best way forward. If you
                  want to have another look, just click on the big “x” to go
                  back tot eh previous screen.
                </p>
              </Modal>
            ) : null}
          </>
        )}
      </div>
    </ContentSection>
  );
};

export default RescheduleMeetingPage;
