import Ajv from 'ajv';
import { MatcherConfig } from './MatcherConfig';

// See https://stackoverflow.com/questions/32044761/json-schema-with-unknown-property-names

export const MatcherStringArrayLookupSchema = {
  type: 'object',
  patternProperties: {
    '^.*$': {
      anyOf: [
        {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      ],
    },
  },
  additionalProperties: false,
};

export const MatcherStringLookupSchema = {
  type: 'object',
  patternProperties: {
    '^.*$': {
      anyOf: [{ type: 'string' }],
    },
  },
  additionalProperties: false,
};

export const MatcherNumberLookupSchema = {
  type: 'object',
  patternProperties: {
    '^.*$': {
      anyOf: [{ type: 'number' }],
    },
  },
  additionalProperties: false,
};

export const KeyedMatcherNumberLookupSchema = {
  type: 'object',
  patternProperties: {
    '^.*$': {
      anyOf: [
        {
          type: 'object',
          patternProperties: {
            '^.*$': {
              anyOf: [{ type: 'number' }],
            },
          },
          additionalProperties: false,
        },
      ],
    },
  },
  additionalProperties: false,
};

export const DriverKeyedMatcherNumberLookupSchema = {
  type: 'object',
  properties: {
    driver: {
      type: 'object',
      patternProperties: {
        '^.*$': {
          anyOf: [
            {
              type: 'object',
              patternProperties: {
                '^.*$': {
                  anyOf: [{ type: 'number' }],
                },
              },
            },
          ],
        },
      },
      additionalProperties: false,
    },
    NOTES: { type: 'string' },
  },
  additionalProperties: false,
};

export const DriverWeightingLookupSchema = {
  type: 'object',
  properties: {
    weightings: {
      type: 'object',
      properties: {
        careerLengthScore: { type: 'number' },
        contextScore: { type: 'number' },
        functionalAreaScore: { type: 'number' },
        interestScore: { type: 'number' },
        introvertExtrovertScore: { type: 'number' },
        motivatorScore: { type: 'number' },
        plannerScore: { type: 'number' },
        sectorScore: { type: 'number' },
        seniorityScore: { type: 'number' },
      },
      required: [
        'careerLengthScore',
        'contextScore',
        'functionalAreaScore',
        'interestScore',
        'introvertExtrovertScore',
        'motivatorScore',
        'plannerScore',
        'sectorScore',
        'seniorityScore',
      ],
    },
    NOTES: { type: 'string' },
  },
  additionalProperties: false,
};

export const NumericKeyedMatcherNumberLookupSchema = {
  type: 'object',
  properties: {
    numericScores: {
      type: 'object',
      patternProperties: {
        '^.*$': {
          anyOf: [{ type: 'number' }],
        },
      },
      additionalProperties: false,
    },
    NOTES: { type: 'string' },
  },
  additionalProperties: false,
};

export const algoConfigFiles = [
  {
    name: 'ageCompatibility',
    title: 'Age Compatibility',
    schema: MatcherNumberLookupSchema,
  },
  {
    name: 'careerLengthDriver',
    title: 'Career Length Driver',
    schema: MatcherNumberLookupSchema,
  },
  {
    name: 'contextDriver',
    title: 'Context Driver',
    schema: DriverKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'driverWeighting',
    title: 'Driver Weighting',
    schema: DriverWeightingLookupSchema,
  },
  {
    name: 'extrovertDriver',
    title: 'Extrovert Driver',
    schema: NumericKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'functionalAreaDriver',
    title: 'Functional Area Driver',
    schema: DriverKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'functionalAreaTranslation',
    title: 'Functional Area Translation',
    schema: MatcherStringLookupSchema,
  },
  {
    name: 'plannerDriver',
    title: 'Planner Driver',
    schema: NumericKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'sectorDriver',
    title: 'Sector Driver',
    schema: DriverKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'seniorityCompatibility',
    title: 'Seniority Compatibility',
    schema: MatcherStringArrayLookupSchema,
  },
  {
    name: 'seniorityDriver',
    title: 'Seniority Driver',
    schema: DriverKeyedMatcherNumberLookupSchema,
  },
  {
    name: 'sourceExclusions',
    title: 'Source Exclusions',
    schema: MatcherStringArrayLookupSchema,
  },
];

export class MatcherConfigValidation {
  static get configFiles(): { [key: string]: string } {
    return algoConfigFiles.reduce<{ [key: string]: string }>((acc, file) => {
      acc[file.name] = file.title;
      return acc;
    }, {});
  }

  static validate(
    configName: string,
    configText: string,
  ): { valid: boolean; message: string } {
    try {
      const configFileDetails = algoConfigFiles.find(
        (conf) => conf.name === configName,
      );

      if (!configFileDetails) {
        return { valid: false, message: '❌ Unknown Config File' };
      }
      const parsedData = JSON.parse(configText);

      const ajv = new Ajv();
      var valid = ajv.validate(configFileDetails.schema, parsedData);
      return {
        valid,
        message: valid
          ? '✅ Valid!'
          : `❌ Invalid: ${
              ajv.errors
                ? ajv.errors
                    ?.map((e) => `${e.instancePath}: ${e.message}`)
                    .join(', ')
                : ''
            }`,
      };
    } catch (error) {
      return { valid: false, message: `❌ Invalid JSON: ${error}` };
    }
  }

  static validateAll(matcherConfig: MatcherConfig): {
    valid: boolean;
    errors: { section: string; message: string }[];
  } {
    const errors = algoConfigFiles.reduce<
      { section: string; message: string }[]
    >((acc, section) => {
      if (matcherConfig[section.name as keyof typeof matcherConfig]) {
        const { valid, message } = this.validate(
          section.name,
          matcherConfig[section.name as keyof typeof matcherConfig] as string,
        );
        if (!valid) {
          acc.push({ section: section.name, message });
        }
      }
      return acc;
    }, []);
    return { valid: errors.length < 1, errors };
  }
}
