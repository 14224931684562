import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const socialLoginState = {
  firstLoad: true,
  loading: false,
  error: ''
};
const reducers = {
  setSocialLoginsState: typeReducerForKey('socialLoginState'),
  resetSocialLoginsState: resetStateByKey('socialLoginState', {
    socialLoginState
  })
};
const actions = {
  loadSocialLoginsConfiguration: createAction(`${authStoreName}/loadSocialLoginsConfiguration`),
  loadSocialLoginsConfigurationV2: createAction(`${authStoreName}/loadSocialLoginsConfigurationV2`),
  loginViaSocialLogin: createAction(`${authStoreName}/loginViaSocialLogin`, payload => ({
    payload
  })),
  setSocialLoginError: createAction(`${authStoreName}/setSocialLoginError`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { socialLoginState, reducers as socialLoginsReducer, actions as socialLoginsActions };