import { gql, useQuery } from '@apollo/client';

import { useEffect, useState } from 'react';

import { Member } from '../models/Member';

const GET_MATCHES = gql`
  query matches {
    me {
      id
      exludedMatchIds
      matches {
        id
        avatarURL
        firstName
        lastName
        lastMatchMeeting
        nextMatchMeeting
      }
    }
  }
`;

const useMatches = () => {
  const { data: matchData, loading: matchesLoading } = useQuery(GET_MATCHES);

  const [matches, setMatches] = useState<Member[]>();
  const [me, setMe] = useState<Member>();

  useEffect(() => {
    if (matchData?.me) {
      setMe(new Member(matchData?.me));
    }
  }, [matchData]);

  useEffect(() => {
    if (matchData?.me?.matches) {
      setMatches(
        matchData.me.matches
          .map((m: any) => new Member(m))
          .sort(
            (a: Member, b: Member) =>
              (b.lastMatchMeeting?.toDate().valueOf() || 0) -
              (a.lastMatchMeeting?.toDate().valueOf() || 0),
          ),
      );
    }
  }, [matchData]);

  return {
    matches,
    me,
    matchesLoading,
  };
};

export default useMatches;
