import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"];
import { call, put, takeLeading } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { ForgotPasswordStep } from './interfaces';
function* forgotPassword({
  payload
}) {
  yield put(actions.setForgotPasswordState({
    loading: true
  }));
  try {
    var _payload$callback;
    yield call(api.auth.forgotPassword, payload);
    yield put(actions.setForgotPasswordState({
      loading: false,
      error: undefined,
      step: ForgotPasswordStep.success
    }));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setForgotPasswordState({
      loading: false,
      error: e.message || 'Unknown error occurred'
    }));
    (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
  }
}
function* resetPassword(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    body = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setForgotPasswordState({
    loading: true
  }));
  try {
    yield call(api.auth.resetPassword, body);
    yield put(actions.setForgotPasswordState({
      loading: false,
      error: undefined,
      step: ForgotPasswordStep.success
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setForgotPasswordState({
      loading: false,
      error: e.message
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
export function* loadPasswordConfig({
  payload
}) {
  yield put(actions.setForgotPasswordState({
    loading: true
  }));
  try {
    const passwordConfig = yield call(api.auth.loadPasswordConfig, payload);
    yield put(actions.setForgotPasswordState({
      loading: false,
      passwordConfig
    }));
  } catch (e) {
    yield put(actions.setForgotPasswordState({
      loading: false,
      error: e.message
    }));
  }
}
export function* forgotPasswordSagas() {
  yield takeLeading(actions.forgotPassword, forgotPassword);
  yield takeLeading(actions.resetPassword, resetPassword);
  yield takeLeading(actions.loadPasswordConfig, loadPasswordConfig);
}