import React from 'react';
import { createRoot } from 'react-dom/client';
import 'the-new-css-reset/css/reset.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggAppOptions, FronteggProvider } from '@frontegg/react';
import { ApolloProvider } from '@apollo/client';
import { config } from './config/config';
import apolloClient from './util/apolloClient';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';

require('dayjs/locale/en-gb');
dayjs.locale('en-gb');
const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const contextOptions: FronteggAppOptions['contextOptions'] = {
  baseUrl: config.frontEggBaseURL,
};

const authOptions: FronteggAppOptions['authOptions'] = {
  keepSessionAlive: true,
};

const themeOptions: FronteggAppOptions['themeOptions'] = {
  loginBox: {
    signup: {
      hideSignUpFields: {
        hideCompanyName: true,
      },
    },
  },
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider
        contextOptions={contextOptions}
        authOptions={authOptions}
        themeOptions={themeOptions}>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
