import './MatchJourneySection.css';

import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import JourneyStep from '../../components/Journey/JourneyStep';
import StepCard, { StepCardLink } from '../../components/Journey/StepCard';
import TextCard from '../../components/TextCard/TextCard';
import useJourneys from '../../hooks/useJourneys';
import { Meeting } from '../../models/Meeting';
import { Theme } from '../../models/Theme';
import Loader from '../../components/Loader/Loader';
import { Member } from '../../models/Member';

export type MatchJourneySectionProps = { matches?: Member[] };

export const MatchJourneySection: FunctionComponent<
  MatchJourneySectionProps
> = ({ matches }) => {
  const { matchId } = useParams();
  const { journeysLoading, journeys } = useJourneys(matchId);

  const introText = (text: string | undefined) => {
    if (!matches) {
      return text?.replace('[[MATCH]]', 'your match');
    }
    const selectedMatch = matches.find((m) => m.id === matchId);
    if (text && selectedMatch) {
      return text.replace('[[MATCH]]', selectedMatch.firstName);
    }
  };

  const buildMeetingStepLinks = (meeting: Meeting): StepCardLink[] => {
    const links: StepCardLink[] = [];
    if (meeting?.theme) {
      links.push({
        link: `/themes/${meeting.theme.slug}`,
        title: 'Talking Points',
      });
    }
    if (meeting?.theme?.guide) {
      links.push({
        link: `/guides/` + meeting?.theme?.guide.slug,
        title: 'Deep Dive',
      });
    }
    return links;
  };

  const buildThemeStepLinks = (theme: Theme): StepCardLink[] => {
    const links: StepCardLink[] = [
      { link: `/themes/${theme.slug}`, title: 'Talking Points' },
    ];

    if (theme?.guide) {
      links.push({
        link: `/guides/` + theme.guide?.slug,
        title: 'Deep Dive',
      });
    }
    return links;
  };

  return (
    <div className="match-journey-section">
      {journeysLoading ? (
        <Loader />
      ) : (
        <>
          {journeys?.map((journey) => (
            <div key={journey.id}>
              <TextCard
                title={journey.title}
                bodyHTML={introText(journey.introduction)}
                className="match-journey-section__intro"
              />

              <div>
                <div className="match-journey-section__guides">
                  {journey.guides?.map((guide) => (
                    <JourneyStep key={guide.id}>
                      <StepCard
                        colour="teal"
                        title={guide.name}
                        typeText="Self led read on"
                        links={[
                          {
                            title: 'Read now',
                            link: `/guides/` + guide.slug,
                          },
                        ]}
                      />
                    </JourneyStep>
                  ))}
                </div>
                {journey.meetings && journey.meetings.length ? (
                  <div>
                    {journey.meetings?.map((meeting) => (
                      <JourneyStep
                        key={meeting.id}
                        colour={'orange'}
                        complete={dayjs().isAfter(meeting.meetingStartAt)}
                        date={meeting.meetingStartAt}>
                        <StepCard
                          colour={
                            meeting.theme?.isAdhoc ? 'dark-orange' : 'orange'
                          }
                          title={meeting.theme?.name}
                          typeText="Voco conversation"
                          links={buildMeetingStepLinks(meeting)}
                        />
                      </JourneyStep>
                    ))}
                    {journey.uncompleteThemes?.map((theme) => (
                      <JourneyStep key={theme.id} colour={'orange'}>
                        <StepCard
                          colour={'orange'}
                          title={theme?.name}
                          typeText="Voco conversation"
                          links={buildThemeStepLinks(theme)}
                        />
                      </JourneyStep>
                    ))}
                  </div>
                ) : (
                  <div>
                    <JourneyStep colour={'orange'} complete={true}>
                      <div className="match-journey-section__empty-state">
                        <h2 className="h1 match-journey-section__empty-state-text">
                          YOUR CONVERSATION TOPICS WILL APPEAR HERE
                        </h2>
                      </div>
                    </JourneyStep>
                    <JourneyStep colour={'orange'}>
                      <div className="match-journey-section__empty-state"></div>
                    </JourneyStep>
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
