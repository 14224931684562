import './TimeTile.css';

import { Dayjs } from 'dayjs';
import { FunctionComponent } from 'react';

export type LargeTimeTileProps = {
  date: Dayjs;
  selected: boolean;
};

const LargeTimeTile: FunctionComponent<LargeTimeTileProps> = ({
  date,
  selected,
}) => {
  return (
    <div
      className={`time-tile${
        selected ? ' time-tile--selected' : ''
      } time-tile--large`}>
      <span className="time-tile__time">{date.format('h')}</span>
      <span className="time-tile__ampm">{date.format('a')}</span>
    </div>
  );
};

export default LargeTimeTile;
