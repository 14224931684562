import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Guide } from '../models/Guide';

const GET_GUIDE = gql`
  query getGuide($slug: String) {
    guide(slug: $slug) {
      id
      name
      slug
      mainImageURL
      shortDescription
      body
      publishedAt
      createdAt
      updatedAt
    }
  }
`;

const useGuide = (slug?: string) => {
  const { data: guideData, loading: guideLoading } = useQuery(GET_GUIDE, {
    variables: { slug },
  });
  const [guide, setGuide] = useState<Guide>();

  useEffect(() => {
    if (guideData?.guide) {
      setGuide(new Guide(guideData.guide));
    }
  }, [guideData]);

  return { guide, guideLoading };
};

export default useGuide;
