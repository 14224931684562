import { FunctionComponent, useEffect, useState } from 'react';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import ContentSection from '../../components/ContentSection/ContentSection';
import MemberPicker from '../../components/MemberPicker/MemberPicker';
import useMatches from '../../hooks/useMatches';
import { MatchJourneySection } from './MatchJourneySection';

export const JourneyPage: FunctionComponent = () => {
  const { matches, me } = useMatches();
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/journeys/:matchId/journey');
  const [selectedMatchId, setSelectedMatchId] = useState<string>();

  useEffect(() => {
    if (match?.params.matchId && selectedMatchId !== match?.params.matchId) {
      setSelectedMatchId(match.params.matchId);
    }
  }, [match, selectedMatchId]);

  useEffect(() => {
    if (location.pathname === '/journeys' && matches && matches.length > 0) {
      navigate(`/journeys/${matches[0].id}/journey`);
    }
  }, [location, matches, navigate]);

  const memberSelected = (selectedId: string | undefined) => {
    if (selectedId) {
      navigate(`/journeys/${selectedId}/journey`);
    }
  };

  return (
    <section className="journeys">
      <ContentSection
        title="Your Conversation Journeys"
        mobileTitle="Your Journeys"
        withSections={true}>
        {matches && matches?.length > 0 ? (
          <MemberPicker
            members={matches}
            me={me}
            memberSelected={memberSelected}
            showAddMatch={false}
            showDetails={false}
            showMe={false}
            showVoco={false}
            selectFirst
          />
        ) : (
          <MatchJourneySection />
        )}

        <Routes>
          <Route
            path=":matchId/journey"
            element={<MatchJourneySection matches={matches} />}
          />
        </Routes>
        <Outlet />
      </ContentSection>
    </section>
  );
};
