import dayjs, { Dayjs } from 'dayjs';
import { AvatarData, Member } from './Member';

import matchAvatar from '../svgs/match-avatar.svg';
import vocoAvatarURL from '../svgs/voco-avatar.svg';
import { IFeedItem } from './FeedItem';

export class JournalEntry implements IFeedItem {
  static FEEDBACK_GIVEN = 'Feedback Given';
  static COMMITMENTS = 'Commitments';
  static NOTES_MADE = 'Notes Made';
  static PROFILE_UPDATED = 'Profile Updated';

  id: string;
  journalType: string;
  date: Dayjs;
  title: string;
  text: string;
  member: Member;
  relatedMember?: Member;

  constructor(data: any) {
    this.id = data.id;
    this.journalType = data.journalType;

    this.date = dayjs(data.date);
    this.title = data.title;
    this.text = data.text;
    this.member = new Member(data.member);
    this.relatedMember = data.relatedMember
      ? new Member(data.relatedMember)
      : undefined;
  }

  public get avatars(): AvatarData[] {
    const fallbackAvatar: AvatarData = { url: matchAvatar, alt: 'Your Match' };
    const vocoAvatar: AvatarData = { url: vocoAvatarURL, alt: 'Team Voco' };
    const memberAvatar = this.member.avatar || fallbackAvatar;
    const relatedMemberAvatar = this.relatedMember?.avatar || fallbackAvatar;
    switch (this.journalType) {
      case JournalEntry.FEEDBACK_GIVEN:
      case JournalEntry.COMMITMENTS:
        return [memberAvatar, relatedMemberAvatar];
      case JournalEntry.NOTES_MADE:
      case JournalEntry.PROFILE_UPDATED:
        return [memberAvatar];
      default:
        if (!this.relatedMember) {
          return [vocoAvatar];
        }
        return [relatedMemberAvatar];
    }
  }
}
