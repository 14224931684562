import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_SUBSCRIPTION_TABLE = gql`
  query subscriptionTable($code: String) {
    subscriptionTable(code: $code) {
      pricingTableId
      description
    }
  }
`;

function useSubscriptionTable(code: string | undefined) {
  const { data, loading: pricingTableIdLoading } = useQuery(
    GET_SUBSCRIPTION_TABLE,
    {
      variables: { code },
    },
  );

  const [pricingTableId, setPricingTable] = useState<string>();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    if (data?.subscriptionTable?.pricingTableId) {
      setPricingTable(data?.subscriptionTable?.pricingTableId);
    }
    if (data?.subscriptionTable?.description) {
      setDescription(data?.subscriptionTable?.description);
    }
  }, [data]);

  return {
    pricingTableIdLoading,
    pricingTableId,
    description,
  };
}

export default useSubscriptionTable;
