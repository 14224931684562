import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import LabelInput from '../../components/LabelInput/LabelInput';
import LabelSelect from '../../components/LabelSelect/LabelSelect';
import Modal, { ModalActionFunction } from '../../components/Modal/Modal';
import { Member } from '../../models/Member';
import './EditEmploymentModal.css';

export type EditEmploymentModalProps = {
  profile?: Member;
  updateProfile: Function;
  updatingProfile: boolean;
};

const EditEmploymentModal: FunctionComponent<EditEmploymentModalProps> = ({
  profile,
  updateProfile,
  updatingProfile,
}) => {
  type FormProps = {
    currentEmployer?: string;
    currentJobTitle?: string;
    careerStartYear?: string;
    functionalArea?: string;
  };

  const [currentValues, setCurrentValues] = useState<FormProps>({
    currentEmployer: '',
    currentJobTitle: '',
    careerStartYear: '',
    functionalArea: '',
  });

  useEffect(() => {
    if (profile) {
      setCurrentValues({
        currentEmployer: profile.currentEmployer,
        currentJobTitle: profile.currentJobTitle,
        careerStartYear: profile.careerStartYear,
        functionalArea: profile.functionalArea,
      });
    }
  }, [profile]);

  const handleFormChanges: ChangeEventHandler = (event) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLSelectElement;
    if (currentValues) {
      setCurrentValues({ ...currentValues, [name]: value });
    }
  };

  // SAVE GOAL UPDATES
  const save: ModalActionFunction = async (event, done) => {
    if (!currentValues) {
      return;
    }

    const result = await updateProfile({
      variables: { me: currentValues },
    });
    if (!result?.data?.updateMe?.success) {
      console.error(result);
    }
    return done();
  };

  return (
    <Modal
      title="Employment"
      action={save}
      actionInProgress={updatingProfile}
      returnURL="/profile">
      <form className="current-employement-form">
        <LabelInput
          className="current-employement-form__input"
          label="Current Employer:"
          name="currentEmployer"
          onChange={handleFormChanges}
          value={currentValues?.currentEmployer}
          disabled={updatingProfile}
        />
        <LabelInput
          label="Job Title:"
          name="currentJobTitle"
          className="current-employement-form__input"
          onChange={handleFormChanges}
          value={currentValues?.currentJobTitle}
          disabled={updatingProfile}
        />
        <LabelInput
          label="Year Career Started:"
          name="careerStartYear"
          className="current-employement-form__input"
          value={currentValues?.careerStartYear}
          onChange={handleFormChanges}
          type="number"
          disabled={updatingProfile}
        />
        <LabelSelect
          label="Functional Area:"
          className="current-employement-form__input current-employement-form__input--extended"
          name="functionalArea"
          onChange={handleFormChanges}
          value={currentValues?.functionalArea}
          disabled={updatingProfile}
          optionsArray={[
            'Administration',
            'Business Development',
            'Customer service',
            'Design',
            'Engineering & Manufacturing',
            'Finance',
            'Fundraising',
            'General Management',
            'Human Resources',
            'Law',
            'Market Research',
            'Marketing & Advertising',
            'Media & Communications',
            'Medical & Health Services',
            'Operations',
            'Partnerships',
            'Product Management',
            'Programme Management',
            'Project Management',
            'Research & Development',
            'Sales',
            'Strategy',
            'Teaching',
            'Technology',
            'Trading',
            'Business Consulting & Advisory',
            'Data & Analytics',
            'Property & Construction',
            'Insurance',
            'Environment & Sustainability',
            'Hospitality',
            'Creative Arts',
          ]}
        />
      </form>
    </Modal>
  );
};

export default EditEmploymentModal;
