import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { delay } from '../utils';
import { tenantsDemo } from '../dummy';
import { refreshToken } from '../LoginState/saga';
function* switchTenant({
  payload: {
    tenantId,
    callback
  }
}) {
  yield put(actions.setState({
    isLoading: true
  }));
  try {
    yield call(api.tenants.switchTenant, {
      tenantId
    });
    yield call(refreshToken);
    const callbackConsumed = callback == null ? void 0 : callback(true);
    if (!callbackConsumed) {
      yield put(actions.setState({
        isLoading: false
      }));
    }
  } catch (e) {
    yield put(actions.setState({
      isLoading: false
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* loadTenants({
  payload
}) {
  yield put(actions.setTenantsState({
    loading: true
  }));
  try {
    var _payload$callback;
    const tenants = yield call(api.tenants.getTenants);
    yield put(actions.setTenantsState({
      tenants,
      loading: false
    }));
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, []);
  } catch (e) {
    var _payload$callback2;
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, [], e);
    yield put(actions.setTenantsState({
      loading: false
    }));
  }
}
function* loadSubTenants({
  payload
}) {
  yield put(actions.setTenantsState({
    loading: true
  }));
  try {
    var _payload$callback3;
    const subTenants = yield call(api.tenants.getSubTenants);
    yield put(actions.setTenantsState({
      subTenants,
      loading: false
    }));
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  } catch (e) {
    var _payload$callback4;
    yield put(actions.setTenantsState({
      loading: false
    }));
    payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
  }
}
function* loadSubTenantsTree({
  payload
}) {
  yield put(actions.setTenantsState({
    loading: true
  }));
  try {
    var _payload$callback5;
    const tenantTree = yield call(api.tenants.getSubTenantsAsTree);
    yield put(actions.setTenantsState({
      tenantTree,
      loading: false
    }));
    payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
  } catch (e) {
    var _payload$callback6;
    yield put(actions.setTenantsState({
      loading: false
    }));
    payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, null, e);
  }
}
export function* tenantsSagas() {
  yield takeEvery(actions.loadTenants, loadTenants);
  yield takeEvery(actions.loadSubTenants, loadSubTenants);
  yield takeEvery(actions.switchTenant, switchTenant);
  yield takeEvery(actions.loadSubTenantsTree, loadSubTenantsTree);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadTenantsMock() {
  yield put(actions.setTenantsState({
    loading: true
  }));
  yield delay();
  yield put(actions.setTenantsState({
    tenants: tenantsDemo,
    loading: false
  }));
}
export function* tenantsSagasMock() {
  yield takeEvery(actions.loadTenants, loadTenantsMock);
}