import { groupsActions, groupsReducers, groupsDialogsActions, groupsDialogsReducers } from '@frontegg/redux-store';
import { reducerActionsGenerator, stateHookGenerator } from './hooks';
const defaultMapper = state => state;
export function useGroupsState(stateMapper = defaultMapper) {
  return stateHookGenerator(stateMapper, 'groupsState');
}
export const useGroupsActions = () => reducerActionsGenerator(groupsActions, groupsReducers);

//dialogs

const defaultDialogsMapper = state => state;
export function useGroupsDialogsState(stateMapper = defaultDialogsMapper) {
  return stateHookGenerator(stateMapper, 'groupsDialogsState');
}
export const useGroupsDialogsActions = () => reducerActionsGenerator(groupsDialogsActions, groupsDialogsReducers);