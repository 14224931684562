export * from "./secutiry-poilicy/interfaces";
export let SecondaryAuthStrategy;

(function (SecondaryAuthStrategy) {
  SecondaryAuthStrategy["WebAuthnPlatform"] = "WebAuthnPlatform";
  SecondaryAuthStrategy["WebAuthnCrossPlatform"] = "WebAuthnCrossPlatform";
  SecondaryAuthStrategy["SmsCode"] = "SmsCode";
  SecondaryAuthStrategy["Passkeys"] = "Passkeys";
})(SecondaryAuthStrategy || (SecondaryAuthStrategy = {}));

export let WebAuthnDeviceType;

(function (WebAuthnDeviceType) {
  WebAuthnDeviceType["Platform"] = "Platform";
  WebAuthnDeviceType["CrossPlatform"] = "CrossPlatform";
})(WebAuthnDeviceType || (WebAuthnDeviceType = {}));

export let MFAStrategyEnum;

(function (MFAStrategyEnum) {
  MFAStrategyEnum["AuthenticatorApp"] = "AuthenticatorApp";
  MFAStrategyEnum["WebAuthnPlatform"] = "WebAuthnPlatform";
  MFAStrategyEnum["WebAuthnCrossPlatform"] = "WebAuthnCrossPlatform";
  MFAStrategyEnum["SMS"] = "SMS";
})(MFAStrategyEnum || (MFAStrategyEnum = {}));