import _extends from "@babel/runtime/helpers/esm/extends";
import { useMemo } from 'react';
import { bindActionCreators } from '@frontegg/redux-store';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from '../FronteggStoreContext';
import { auditsActions, auditsStoreName } from '@frontegg/redux-store';
const defaultMapper = {
  state: state => state,
  actions: actions => actions
};
export function useAuditsState(stateMapper = defaultMapper.state) {
  return useSelector(state => stateMapper(state[auditsStoreName]), shallowEqual);
}
export const useAuditsActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(auditsActions, dispatch), [auditsActions]);
};

/**
 * hooks helpers
 */
export const sliceReducerActionsBy = reducer => {
  const reducerKeys = Object.keys(reducer);
  const reducerActions = reducerKeys.map(key => ({
    [key]: auditsActions[key]
  }));
  return reducerActions.reduce((p, n) => _extends({}, p, n), {});
};
export const stateHookGenerator = (stateMapper, subState) => {
  return useSelector(state => {
    var _stateMapper;
    return (_stateMapper = stateMapper == null ? void 0 : stateMapper(state[auditsStoreName][subState])) != null ? _stateMapper : state[auditsStoreName][subState];
  }, shallowEqual);
};
export const reducerActionsGenerator = (actions, reducers) => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(_extends({}, actions, sliceReducerActionsBy(reducers)), dispatch), [dispatch]);
};