import { FunctionComponent, ReactNode } from 'react';
import './MeetingCallout.css';

import SVG from 'react-inlinesvg';
import closeIcon from '../../svgs/icon-close-callout.svg';
import LinkButton from '../Button/LinkButton';

export type MeetingCalloutProps = {
  children: ReactNode;
  highlight?: boolean;
  title?: string;
  linkText?: string;
  linkAction?: () => void;
  dismissHandler?: () => void;
};

export const MeetingCallout: FunctionComponent<MeetingCalloutProps> = ({
  highlight = false,
  dismissHandler,
  title,
  linkText,
  linkAction,
  children,
}) => {
  const handleCloseCallout = () => {
    if (dismissHandler) {
      dismissHandler();
    }
  };

  return (
    <div
      className={`meeting__callout${
        highlight ? ' meeting__callout--off-piste' : ''
      }`}>
      <div className="meeting__callout-speech"></div>
      <button className="meeting__callout-close" onClick={handleCloseCallout}>
        <SVG src={closeIcon} />
      </button>
      <div className="meeting__callout-content">
        <div className="meeting__callout-info">
          {title ? (
            <h2 className="h2 meeting__callout-heading">{title}</h2>
          ) : null}
          {children}
          {linkText && linkAction ? (
            <LinkButton
              className="meeting__callout-link"
              action={() => linkAction()}>
              {linkText}
            </LinkButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};
