import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from '../FronteggStoreContext';
import { bindActionCreators, connectivityActions, connectivityStoreName } from '@frontegg/redux-store';
const defaultMapper = state => state;
export function useConnectivityState(stateMapper = defaultMapper) {
  return useSelector(state => stateMapper(state[connectivityStoreName]), shallowEqual);
}
export const useConnectivityActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(connectivityActions, dispatch), [connectivityActions]);
};