import { FunctionComponent, ReactNode } from 'react';
import './InfoCard.css';

import SVG from 'react-inlinesvg';
import { Dayjs } from 'dayjs';
import exclaimationIcon from '../../svgs/icon-exclaimation.svg';
import { Link } from 'react-router-dom';

export type InfoCardProps = {
  bodyText?: string;
  date?: Dayjs;
  icon?: string;
  textIcon?: string;
  linkURL?: string | { pathname: string };
  linkText?: string;
  colour?: string;
  menu?: ReactNode;
};

const InfoCard: FunctionComponent<InfoCardProps> = ({
  colour = 'orange',
  date,
  icon,
  textIcon,
  linkURL,
  linkText,
  bodyText,
  menu,
}) => {
  return (
    <div className={`info-card info-card--${colour}`}>
      {date ? (
        <div className="info-card__date">
          <span className="info-card__date-day">{date.format('D')}</span>
          <span className="info-card__date-month">{date.format('MMMM')}</span>
        </div>
      ) : (
        <div className="info-card__date">
          <SVG className="info-card__no-date-icon" src={exclaimationIcon} />
          <span className="info-card__no-date-text">Date</span>
        </div>
      )}

      {icon ? (
        <>
          {typeof linkURL === 'string' ? (
            <Link to={linkURL} className="info-card__icon">
              <SVG src={icon} />
              <span className="info-card__link-text">{linkText}</span>
            </Link>
          ) : (
            <a
              href={linkURL?.pathname}
              className="info-card__icon"
              target="_blank"
              rel="noreferrer">
              <SVG src={icon} />
              <span className="info-card__link-text">{linkText}</span>
            </a>
          )}
        </>
      ) : (
        <span className="info-card__icon info-card__text-icon">{textIcon}</span>
      )}

      <div className="info-card__text">
        <span>{bodyText}</span>
      </div>
      <div className="menu">{menu}</div>
    </div>
  );
};

export default InfoCard;
