import _extends from "@babel/runtime/helpers/esm/extends";
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { plansActions } from './index';
import { api } from '@frontegg/rest-api';
import { planResponseMock } from '../dummy';
export function* plansSagas() {
  yield takeEvery(plansActions.loadPlans, loadPlans);
}
function* loadPlans() {
  yield put(plansActions.setLoading(true));
  try {
    const products = yield call(api.subscriptions.getSubscriptionPlans);
    const plans = products.map(item => {
      var _item$price, _item$price2;
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        price: ((_item$price = item.price) == null ? void 0 : _item$price.amount) || 0,
        currency: ((_item$price2 = item.price) == null ? void 0 : _item$price2.currency) || 'usd',
        recurringInterval: 'month',
        slug: item.slug
      };
    });
    yield put(plansActions.setState({
      fetching: false,
      loading: false,
      plans
    }));
  } catch (e) {
    yield put(plansActions.setError(e.message));
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadPlansMock() {
  yield put(plansActions.setLoading(true));
  yield delay(500);
  yield put(plansActions.setState({
    fetching: false,
    loading: false,
    plans: planResponseMock.map(item => {
      var _item$price3, _item$price4;
      return _extends({}, item, {
        price: ((_item$price3 = item.price) == null ? void 0 : _item$price3.amount) || 0,
        currency: ((_item$price4 = item.price) == null ? void 0 : _item$price4.currency) || 'usd',
        recurringInterval: 'month'
      });
    })
  }));
}
export function* plansSagasMock() {
  yield takeEvery(plansActions.loadPlans, loadPlansMock);
}